import { Accordion, AccordionSummary, Alert, Badge, Box, Button, CircularProgress, Fab, FormControl, Grid, Icon, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import url from "app/UrlConfig";
import { SimpleCard } from "app/components";
import { UpdateActiviteAPI, getActiviteImage, getActivitePrimaryRessources, saveActiviteAPI } from "app/data/nosybe/activite";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "../Restaurant/CarteMap";
import TestButton from "app/myComponents/TestButton";
import { displayToast, manageError } from "app/data/piece/dispatch";

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const FormActivite = ({ selectedActivite, activites, setActivites }) => {
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [iles, setIles] = useState([]);
    const [lat, setLat] = useState(-13.330993056594197);
    const [lng, setLng] = useState(48.256898613913386);

    const [horaireOuverture, setHoraireOuverture] = useState(selectedActivite ? selectedActivite.horaireOuverture : [
        { "typeJours": 0, "typeJoursSTR": "Lundi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 1, "typeJoursSTR": "Mardi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 2, "typeJoursSTR": "Mercredi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 3, "typeJoursSTR": "Jeudi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 4, "typeJoursSTR": "Vendredi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 5, "typeJoursSTR": "Samedi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 6, "typeJoursSTR": "Dimanche", "horaireOuverture": ["", "", "", ""] }
    ]);

    const originalActivite = {
        idCategorie: "",
        idIle: "",
        nom: { "fr": "", "eng": "", "ita": "" },
        descriptionsMulti: { "fr": "", "eng": "", "ita": "" },
        email: "",
        siteweb: "",
        lienyoutube: "",
        contacts: [],
        photoCouverture: undefined,
        photos: [],
        horaireOuverture,
        latitude: lat,
        longitude: lng,
    }

    const [activite, setActivite] = useState(selectedActivite ? selectedActivite : { ...originalActivite });
    const [photoCouverture64, setPhotoCouverture64] = useState('');
    const [photos64, setPhotos64] = useState([]);
    const [inputErrors, setInputErrors] = useState({});

    const loadPhotoCouveture = () => {
        if (selectedActivite && activite) {
            getActiviteImage(activite.photoCouverture)
                .then(response => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const base64 = e.target.result;
                        setActivite({ ...activite, photoCouverture: response.data }); // Update path
                        setPhotoCouverture64(base64); // Update base64 data
                    };
                    reader.readAsDataURL(response.data);
                })
                .catch(error => manageError(error, "getActiviteImage: "))
        }
    }

    const loadGallerie = () => {
        if (selectedActivite && activite) {
            const imagesFile = [];
            const images64 = [];
            activite.photos.forEach(photo => {
                getActiviteImage(photo.photo)
                    .then(response => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const base64 = e.target.result;
                            const blob = response.data;
                            const file = new File([blob], 'filename.txt', { type: blob.type });
                            imagesFile.push(file);
                            images64.push(base64)
                            setActivite({ ...activite, photos: imagesFile })
                            setPhotos64(images64)
                        };
                        reader.readAsDataURL(response.data);
                    })
                    .catch(error => manageError(error, "getActiviteImage: "))
            })
        }
    }

    const setObjectMere = (lat, lng) => {
        setActivite({ ...activite, latitude: lat, longitude: lng });
    }

    const handleChange = (e) => {
        setActivite({
            ...activite,
            [e.target.name]: e.target.value
        });
    }

    //CONTACT
    const addContact = () => {
        console.log("addContact");
        const contactData = [...activite.contacts];
        // contactData.push('+261');
        contactData.push({ 'contact': '+261' });

        setActivite({ ...activite, contacts: contactData });
    }
    const handleContactChange = (e, index) => {
        const contactData = [...activite.contacts];
        contactData[index].contact = e.target.value;
        setActivite({ ...activite, contacts: contactData });
    }
    const removeContact = (index) => {
        const contactData = activite.contacts.filter((_, i) => i !== index);
        setActivite({ ...activite, contacts: contactData });
    }

    //IMAGES GALERIE
    const onDrop = (files) => {
        const imagesFile = [...activite.photos];
        const images64 = [...photos64];
        files.map(file => {
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64 = e.target.result;
                    imagesFile.push(file);
                    images64.push(base64);
                    setActivite({ ...activite, photos: imagesFile });
                    setPhotos64(images64);
                }
                reader.readAsDataURL(file);
            }
        })
    }
    const handleFileRemove = (selectedIndex) => {
        const tabImage = activite.photos.filter((_, index) => index !== selectedIndex);
        const tabImage64 = photos64.filter((_, index) => index !== selectedIndex);
        setActivite({ ...activite, photos: tabImage });
        setPhotos64(tabImage64);
    }
    const { getRootProps: getRootPropsOnDrop, getInputProps: getInputPropsOnDrop } = useDropzone({ onDrop });

    //IMAGES COUVERTURE
    const onDropPhotoCouverture = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setActivite({ ...activite, photoCouverture: file });
                setPhotoCouverture64(base64);
            };
            reader.readAsDataURL(file);
        }
    }
    const handleFileRemovePhoto = () => {
        setActivite({ ...activite, photoCouverture: undefined })
        setPhotoCouverture64('');
    }
    const { getRootProps: getRootPropsOnDropPhotoCouverture, getInputProps: getInputPropsOnDropPhotoCouverture } = useDropzone({ onDrop: onDropPhotoCouverture });

    const handleChangeHoraire = (event, ind, typeJours) => {
        // console.log(horaireOuverture)
        const j = horaireOuverture.filter(jour => jour.typeJoursSTR === typeJours);
        // console.log(ind, 'GO ', typeJours, " == ", event.target.value, " = ", j)
        // setHoraireOuverture({})
        let nouvelHoraire = [...horaireOuverture];
        const indexJ = nouvelHoraire.findIndex(jour => jour.typeJoursSTR === typeJours);
        let horaireOUV = [...nouvelHoraire[indexJ].horaireOuverture]
        horaireOUV[ind] = event.target.value;
        if (indexJ !== -1) {
            const nouvellesHeures = horaireOUV;
            // nouvelHoraire[indexMardi].horaireOuverture = event.target.value
            nouvelHoraire[indexJ].horaireOuverture = nouvellesHeures;
            setHoraireOuverture(nouvelHoraire);
        }
        // console.log(nouvelHoraire)
        setActivite({ ...activite, horaireOuverture: nouvelHoraire })
        // for (let i = 0; i < horaireOuverture.length; i + 1) {

        // }
    }

    const [tabNameValue, setTabNameValue] = useState(0);
    const [languages, setLanguages] = useState([{ "name": "fr", "abbrev": "FRANCAIS" }, { "name": "eng", "abbrev": "MAL" }, { "name": "ita", "abbrev": "MAL" }]);
    const [languageChoose, setLanguageChoose] = useState("fr");
    const [tabDescValue, setTabDescValue] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("fr");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [messageSnak, setMessageSnak] = useState("");

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeTabNameValue = (event, newValue) => {
        // console.log(newValue, " language ", languages[newValue].name, " SHOW == ", activite.nom[languages[newValue]?.name])

        setTabNameValue(newValue);
        // console.log(activite)
        setLanguageChoose(languages[newValue]?.name);
    };


    const handleChangeTabDescValue = (event, newValue) => {
        setTabDescValue(newValue);
        setLanguageDescChoose(languages[newValue]?.name);
    }

    const handleChangeNoms = (event) => {
        console.log(languageChoose);
        setActivite({ ...activite, nom: { ...activite.nom, [languageChoose]: event.target.value } })
        // console.log(activite)
    }

    const handleChangeDesc = (event) => {
        console.log(languageDescChoose);
        setActivite({ ...activite, descriptionsMulti: { ...activite.descriptionsMulti, [languageDescChoose]: event.target.value } })
        // console.log(activite)
    }

    const apiKey = url.key_map;

    const saveActivite = () => {
        const admin = JSON.parse(localStorage.getItem('admin'));
        saveActiviteAPI({
            ...activite,
            idAdmin: admin.id,
        })
            .then(response => {
                navigate("/entreprise/activity/list");
            }).catch(error => {
                manageError(error, "saveActivite error: ");
            })
    }

    const UpdateActivite = async () => {
        const admin = JSON.parse(localStorage.getItem('admin'));
        UpdateActiviteAPI({
            ...activite,
            // id: activite.id,
            // photoCouverture: activite.photoCouverture,
            idAdmin: admin.id,
        })
            .then(response => {
                const listActivite = [...activites];
                const index = listActivite.findIndex(element => element.id === activite.id)
                listActivite[index] = { ...response.data.activite };
                setActivites(listActivite);
                displayToast(response.data.message, true);
            })
            .catch(error => manageError(error, "UpdateActiviteAPI: "))
    }


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    const initRessources = () => {
        // setCategories(getActivitePrimaryRessources());
        getActivitePrimaryRessources()
            .then(response => {
                setCategories(response.data.categories);
                setIles(response.data.iles);
            })
            .catch(error => {
                manageError(error, "getActivitePrimaryRessources error: ");
            })
    }

    useEffect(() => {
        initRessources();
        loadPhotoCouveture();
        loadGallerie();
    }, [])

    return (
        <>
            {/* <TestButton toLog={activite} /> */}
            <Typography variant="h5">
                {selectedActivite ? 'Modifier une activite' : 'Ajouter une activite'}
            </Typography>
            {activite ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <SimpleCard>

                                <Tabs
                                    value={tabNameValue}
                                    onChange={handleChangeTabNameValue}
                                    aria-label="basic tabs example"
                                >
                                    {languages?.map((language, index) =>
                                        <Tab key={index} label={language.name} {...a11yProps(index)} />
                                    )}
                                </Tabs>
                                <TextField
                                    fullWidth
                                    size='small'
                                    name="noms"
                                    type="text"
                                    // label="Noms"
                                    value={activite.nom[languageChoose] ? activite.nom[languageChoose] : ''}
                                    onChange={(e) => handleChangeNoms(e)}
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                    {...inputErrors.titre && "error"}
                                    helperText={inputErrors.titre}
                                />

                                <Tabs
                                    value={tabDescValue}
                                    onChange={handleChangeTabDescValue}
                                    aria-label="basic tabs example"
                                >
                                    {languages?.map((language, index) =>
                                        <Tab key={index} label={language.name} {...a11yProps(index)} />
                                    )}
                                </Tabs>
                                <TextField
                                    fullWidth
                                    size='small'
                                    name="descriptions"
                                    label="Descriptions"
                                    value={activite.descriptionsMulti[languageDescChoose] ? activite.descriptionsMulti[languageDescChoose] : ''}
                                    onChange={(e) => handleChangeDesc(e)}
                                    multiline
                                    maxRows={4}
                                    sx={{ mt: 1.5, mb: 2 }}
                                    {...inputErrors.description && "error"}
                                    helperText={inputErrors.description}
                                />

                                <FormControl fullWidth size="small" sx={{ mb: 1.5 }}>
                                    <InputLabel filled id="demo-simple-select-autowidth-label">Categorie</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        name='idCategorie'
                                        value={activite.idCategorie !== 0 ? activite.idCategorie : ''}
                                        onChange={(e) => handleChange(e)}
                                        autoWidth
                                        label="Categorie"
                                    // readOnly={selectedPiece ? true : false}
                                    >
                                        {categories.map((categorie, index) => (
                                            <MenuItem key={index} value={categorie.id}>{categorie.nom}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth size="small" sx={{ mb: 1.5 }}>
                                    <InputLabel filled id="demo-simple-select-autowidth-label">Ile</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        name='idIle'
                                        value={activite.idIle !== 0 ? activite.idIle : ''}
                                        onChange={(e) => handleChange(e)}
                                        autoWidth
                                        label="Ile"
                                    // readOnly={selectedPiece ? true : false}
                                    >
                                        {iles.map((ile, index) => (
                                            <MenuItem key={index} value={ile.id}>{ile.nom}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    size='small'
                                    name="email"
                                    type="email"
                                    label="Email"
                                    value={activite.email}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                    {...inputErrors.email && "error"}
                                    helperText={inputErrors.email}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    name="siteweb"
                                    type="text"
                                    label="Site web"
                                    value={activite.siteweb}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                    {...inputErrors.siteweb && "error"}
                                    helperText={inputErrors.siteweb}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    name="lienyoutube"
                                    type="text"
                                    label="Lien Youtube"
                                    value={activite.lienyoutube}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                    {...inputErrors.prix && "error"}
                                    helperText={inputErrors.lienyoutube}
                                />

                                {activite?.contacts?.map((value, index) => (
                                    <FormControl fullWidth variant="outlined" key={index} sx={{ mb: 2 }}>
                                        <InputLabel htmlFor={`contact-input-${index}`}>Contact</InputLabel>
                                        <OutlinedInput
                                            id={`contact-input-${index}`}
                                            size="small"
                                            type="tel"
                                            name="contact"
                                            label="Contact"
                                            value={value.contact}
                                            onChange={(e) => handleContactChange(e, index)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => removeContact(index)}
                                                        edge="end"
                                                    >
                                                        <Icon>clear</Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                ))}
                                <Fab onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                                    <Icon>phone</Icon>
                                </Fab>
                                <br />
                                <br />

                            </SimpleCard>
                        </Grid>

                        <Grid item sm={12}>
                            <SimpleCard>
                                <AccordionRoot>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<Icon>expand_more</Icon>}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                            <Box className="column">
                                                <h3>Photo couverture</h3>
                                                {/* <Typography className="heading">Liste des réparations</Typography> */}
                                            </Box>

                                            <Box className="column">
                                                {/* <h3>Photo couverture</h3> */}
                                                {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                            </Box>
                                        </AccordionSummary>
                                        {/* <Typography variant="h6" >
                                    Photos de couverture
                                </Typography> */}
                                        <div
                                            {...getRootPropsOnDropPhotoCouverture()}
                                            style={{
                                                width: '100%',
                                                minHeight: '200px',
                                                height: '200px',
                                                padding: '20px',
                                                border: '2px dashed #cccccc',
                                                textAlign: 'center',
                                                borderRadius: '5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                backgroundColor: 'lightgray',
                                            }}
                                            className="dropzone"
                                        >
                                            <input {...getInputPropsOnDropPhotoCouverture()} />
                                            <Icon fontSize="large">photo_camera</Icon>
                                            <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                                Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                            </Typography>
                                        </div>

                                        {photoCouverture64 &&
                                            <>
                                                <Swiper
                                                    spaceBetween={1}
                                                    slidesPerView={1}
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                    navigation
                                                    pagination={{ clickable: true }}
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    {/* {activite.photos.map((img, index) => ( */}
                                                    <SwiperSlide >
                                                        <center>
                                                            <Badge
                                                                sx={{
                                                                    mt: 3,
                                                                }}
                                                                badgeContent={
                                                                    <>
                                                                        <IconButton
                                                                            onClick={() => handleFileRemovePhoto()}
                                                                            color='error'>
                                                                            <Icon>close</Icon>
                                                                        </IconButton>
                                                                    </>}>

                                                                <div
                                                                    style={{
                                                                        width: '100%',  // Largeur 100% de la diapositive
                                                                        height: '200px',  // Hauteur fixe
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={photoCouverture64}
                                                                        style={{
                                                                            width: 'auto',
                                                                            height: 'auto',
                                                                            maxWidth: '100%',
                                                                            maxHeight: '100%' // Empêche le débordement horizontal
                                                                        }}
                                                                        alt={`photo couverture img `} />
                                                                </div>
                                                            </Badge>
                                                        </center>

                                                    </SwiperSlide>

                                                </Swiper>
                                            </>
                                        }
                                    </Accordion>
                                </AccordionRoot>

                                <Box sx={{ py: '6px' }} />

                                <AccordionRoot>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<Icon>expand_more</Icon>}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                            <Box className="column">
                                                <h3>Photo</h3>
                                                {/* <Typography className="heading">Liste des réparations</Typography> */}
                                            </Box>

                                            <Box className="column">
                                                {/* <h3>Photos</h3> */}
                                                {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                            </Box>
                                        </AccordionSummary>
                                        {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                        <div
                                            {...getRootPropsOnDrop()}
                                            style={{
                                                width: '100%',
                                                minHeight: '200px',
                                                height: '200px',
                                                padding: '20px',
                                                border: '2px dashed #cccccc',
                                                textAlign: 'center',
                                                borderRadius: '5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                backgroundColor: 'lightgray',
                                            }}
                                            className="dropzone"
                                        >
                                            <input {...getInputPropsOnDrop()} />
                                            <Icon fontSize="large">photo_camera</Icon>
                                            <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                                Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                            </Typography>
                                        </div>

                                        {photos64.length > 0 &&
                                            <>
                                                <Swiper
                                                    spaceBetween={1}
                                                    slidesPerView={1}
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                    navigation
                                                    pagination={{ clickable: true }}
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    {photos64.map((img, index) => (
                                                        <SwiperSlide key={index}>
                                                            <center>
                                                                <Badge
                                                                    sx={{
                                                                        mt: 3,
                                                                    }}
                                                                    badgeContent={
                                                                        <>
                                                                            <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                                <Icon>close</Icon>
                                                                            </IconButton>
                                                                        </>}>

                                                                    <div
                                                                        style={{
                                                                            width: '100%',  // Largeur 100% de la diapositive
                                                                            height: '200px',  // Hauteur fixe
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={img}
                                                                            style={{
                                                                                width: 'auto',
                                                                                height: 'auto',
                                                                                maxWidth: '100%',
                                                                                maxHeight: '100%' // Empêche le débordement horizontal
                                                                            }}
                                                                            alt={`Pièce img ${index}`} />
                                                                    </div>
                                                                </Badge>
                                                            </center>

                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </>
                                        }
                                    </Accordion>
                                </AccordionRoot>

                                <Box sx={{ py: '6px' }} />

                                <AccordionRoot>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<Icon>expand_more</Icon>}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                            <Box className="column">
                                                <h3>Horaire d'ouverture</h3>
                                                {/* <Typography className="heading">Liste des réparations</Typography> */}
                                            </Box>

                                            <Box className="column">
                                                {/* <h3>Horaire d'ouverture</h3> */}
                                                {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                            </Box>
                                        </AccordionSummary>
                                        {/* <h3>Horaire d'ouverture</h3> */}
                                        <table>
                                            {horaireOuverture?.map((horaire, index) => (
                                                <tr key={index}>
                                                    {horaire.horaireOuverture.map((h, ind) => (
                                                        <td key={ind}>
                                                            <p>{horaire.typeJoursSTR} (format hh:mm/hh:mm)</p>
                                                            {/* <input placeholder="horaire" onChange={ }></input> */}
                                                            <TextField
                                                                fullWidth
                                                                size='small'
                                                                name="horaire"
                                                                type="text"
                                                                label="Horaire "
                                                                value={h}
                                                                onChange={(e) => handleChangeHoraire(e, ind, horaire.typeJoursSTR)}
                                                                variant="outlined"
                                                                placeholder="horaire"
                                                                sx={{ mt: 1.5, mb: 2 }}
                                                                {...inputErrors.prix && "error"}
                                                                helperText={inputErrors.prix}
                                                            />
                                                        </td>
                                                    ))}

                                                </tr>
                                            ))}

                                        </table>
                                    </Accordion>
                                </AccordionRoot>

                                <h1>Carte</h1>
                                <CarteMap apiKey={apiKey} setLongitude={setLng} setLatitude={setLat} setMereObject={setObjectMere} lati={Number(activite.latitude)} longi={Number(activite.longitude)} />
                                <StyledButton
                                    // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                                    onClick={selectedActivite ? UpdateActivite : saveActivite}
                                    fullWidth
                                    variant="contained"
                                    component="span"
                                >
                                    Enregistrer
                                </StyledButton>
                            </SimpleCard>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>
            )}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {messageSnak}
                </Alert>
            </Snackbar>
        </>
    );
}

export default FormActivite;