import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Breadcrumb } from "app/components";
import SousCategorieComponent from "app/myComponents/VentePiece/Admin/SousCategorieComponent";

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const SousCategorie = () => {
    document.title = "Sous-Categorie";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Vente Piece' },
                        { name: 'Sous-Categorie' }
                    ]}
                />
            </Box>

            <SousCategorieComponent />

        </AppButtonRoot>
    )
}
export default SousCategorie;