import { Card, Box, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Icon } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';





const ModalDelete = ({ children, vehicule }) => {
    const [openModalFiche, setOpenModalFiche] = useState(false);
    const Open = () => setOpenModalFiche(true);
    const Close = () => {
        setOpenModalFiche(false)
    };
    // if (show) {
    //     Open();
    // } else {

    // }
    return (
        <>
            {/* <Button onClick={Open}>AFFICHE</Button> */}
            <IconButton onClick={() => Open(vehicule)} >
                <Icon color="error">delete</Icon>
            </IconButton>
            <Dialog onClose={Close} aria-labelledby="customized-dialog-title" open={openModalFiche}>
                <DialogTitle id="customized-dialog-title" onClose={Close}>

                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>

                <DialogActions>
                    <Button onClick={Close}>
                        Fermer
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default ModalDelete;
