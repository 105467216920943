import { Box, Accordion, AccordionSummary, AccordionActions, AccordionDetails, Alert, Badge, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Snackbar, Tab, Tabs, TextField, Typography, styled, Divider } from "@mui/material";
import { SimpleCard } from "app/components";
import { getHebergementPrimaryRessources } from "app/data/nosybe/hebergement";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "./CarteMap";
import { SaveRestaurantAPI, getEquipement } from "../Equipement/Api-Equipement";
import { UpdateRestaurantAPI, getCategorieAPI } from "./API-Restaurant";
import { useNavigate } from "react-router-dom";
import url from "app/UrlConfig";
import { getAdminConnected } from "app/data/nosybe/admin";

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

const FormRestaurant = ({ selectedRestaurant, setrestaurant, closeForm }) => {
    console.log('============Update========================');
    // console.log(selectedRestaurant);
    console.log('====================================');

    const navigate = useNavigate();

    const [types, setTypes] = useState([]);
    const [equipements, setEquipements] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);



    const [horaireOuverture, setHoraireOuverture] = useState(selectedRestaurant ? selectedRestaurant.horaireOuverture : [
        { "typeJours": 0, "typeJoursSTR": "Lundi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 1, "typeJoursSTR": "Mardi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 2, "typeJoursSTR": "Mercredi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 3, "typeJoursSTR": "Jeudi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 4, "typeJoursSTR": "Vendredi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 5, "typeJoursSTR": "Samedi", "horaireOuverture": ["", "", "", ""] },
        { "typeJours": 6, "typeJoursSTR": "Dimanche", "horaireOuverture": ["", "", "", ""] }
    ]);
    const originalRestaurant = {
        name: 'n',
        description: 'p',
        logo: 'logo',
        email: '',
        siteweb: '',
        latitude: lat,
        longitude: lng,
        contact: '0346660745',
        contact_secours: '0330255888',
        contacts: [],
        photos: [],
        photosFile: [],
        photos64: [],
        types: [],
        equipements: [],
        carteMenu: [],
        carteMenuFile: [],
        carteMenu64: [],
        lienYoutube: '',
        photoCouverture: '',
        photoCouvertureFile: '',
        photoCouverture64: '',
        categorie: [],
        horaireOuverture,
        nom: { "fr": "", "eng": "", "ita": "" },
        descriptionsMulti: { "fr": "", "eng": "", "ita": "" },
        idAdmin: getAdminConnected().id
    }



    // console.log(selectedRestaurant, " RESTAURANT")

    const [restaurant, setRestaurant] = useState(selectedRestaurant ? { ...selectedRestaurant, photosFile: [] } : { ...originalRestaurant });
    const [inputErrors, setInputErrors] = useState({});

    const setObjectMere = (lat, lng) => {
        setRestaurant({ ...restaurant, latitude: lat, longitude: lng });
    }

    const handleChange = (e) => {
        setRestaurant({
            ...restaurant,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeEquipements = (event) => {
        // console.log(restaurant)
        if (selectedRestaurant === undefined) {
            const {
                target: { value },
            } = event;
            // console.log(value);

            setRestaurant({ ...restaurant, equipements: value })
            // console.log(restaurant)
        } else {
            const testExist = event.target.value.some(item => item.idEquipement === event.target.value[event.target.value.length - 1])
            if (testExist) {
                setRestaurant({ ...restaurant, equipements: [...restaurant.equipements.filter(equipement => equipement.idEquipement !== event.target.value[event.target.value.length - 1])] })
            } else {
                const val = {
                    id: 0,
                    "idEquipement": event.target.value[event.target.value.length - 1],
                    "idRestaurant": restaurant.id,
                    "name": "c",
                    "etat": 10
                }
                setRestaurant({ ...restaurant, equipements: [...restaurant.equipements, val] })
            }
            // console.log({ ...restaurant, equipements: [...restaurant.equipements, val] }, " <<< === VALUE ")
        }
    };

    const handleChangeCategorie = (event) => {
        // console.log(restaurant)
        if (selectedRestaurant === undefined) {
            const {
                target: { value },
            } = event;
            setRestaurant({ ...restaurant, categorie: value })
            // console.log(restaurant)
        } else {
            const testExist = event.target.value.some(item => item.idCategorie === event.target.value[event.target.value.length - 1])
            if (testExist) {
                setRestaurant({ ...restaurant, categorie: [...restaurant.categorie.filter(categ => categ.idCategorie !== event.target.value[event.target.value.length - 1])] });
            }
            else {
                const val = {
                    id: 0,
                    "idCategorie": event.target.value[event.target.value.length - 1],
                    "idRestaurant": restaurant.id,
                    "etat": 10
                }
                setRestaurant({ ...restaurant, categorie: [...restaurant.categorie, val] })

            }
        }
    }

    //CONTACT
    const addContact = () => {
        console.log("addContact");
        const contactData = [...restaurant.contacts];
        // contactData.push('+261');
        contactData.push({ 'contact': '+261' });

        setRestaurant({ ...restaurant, contacts: contactData });
    }
    const handleContactChange = (e, index) => {
        const contactData = [...restaurant.contacts];
        contactData[index].contact = e.target.value;
        setRestaurant({ ...restaurant, contacts: contactData });
    }
    const removeContact = (index) => {
        const contactData = restaurant.contacts.filter((_, i) => i !== index);
        setRestaurant({ ...restaurant, contacts: contactData });
    }

    //IMAGES
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                const tabImage = restaurant.photos64 ? [...restaurant.photos64] : [];
                const tabImage2 = [...restaurant.photosFile];
                tabImage.push({ photo: base64 });
                tabImage2.push(file);
                setRestaurant({ ...restaurant, photos64: tabImage, photosFile: tabImage2 });
            };
            reader.readAsDataURL(file);
        }
    }
    const onDropCarte = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                const tabImage = restaurant.carteMenu64 ? [...restaurant.carteMenu64] : [];
                const tabImage2 = [...restaurant.carteMenu];
                tabImage.push({ menu: base64, description: "PLATS" });
                tabImage2.push(file);
                setRestaurant({ ...restaurant, carteMenu64: tabImage, carteMenuFile: tabImage2 });
            };
            reader.readAsDataURL(file);
        }
    }

    const onDropPhotoCouverture = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;

                setRestaurant({ ...restaurant, photoCouverture64: base64, photoCouvertureFile: file });
            };
            reader.readAsDataURL(file);
        }
    }

    const handleFileRemove = (selectedIndex) => {
        const tabImage = restaurant.photosFile.filter((_, index) => index !== selectedIndex);
        const tabImage2 = restaurant.photos64.filter((_, index) => index !== selectedIndex);
        setRestaurant({ ...restaurant, photos64: tabImage2, photosFile: tabImage });
    }

    const handleFileRemoveCarte = (selectedIndex) => {
        const tabImage = restaurant.carteMenuFile.filter((_, index) => index !== selectedIndex);
        const tabImage2 = restaurant.carteMenu64.filter((_, index) => index !== selectedIndex);
        setRestaurant({ ...restaurant, carteMenu64: tabImage2, carteMenuFile: tabImage });
    }

    const handleFileRemovePhoto = () => {
        setRestaurant({ ...restaurant, photoCouverture64: undefined, photoCouvertureFile: "" })
    }

    const { getRootProps: getRootPropsOnDrop, getInputProps: getInputPropsOnDrop } = useDropzone({ onDrop });

    // Utilisez useDropzone avec onDropCarte
    const { getRootProps: getRootPropsOnDropCarte, getInputProps: getInputPropsOnDropCarte } = useDropzone({ onDrop: onDropCarte });

    const { getRootProps: getRootPropsOnDropPhotoCouverture, getInputProps: getInputPropsOnDropPhotoCouverture } = useDropzone({ onDrop: onDropPhotoCouverture });
    const [numberE, setNumberE] = useState(0);
    const [pageE, setPageE] = useState(0);



    const handleChangeHoraire = (event, ind, typeJours) => {
        // console.log(horaireOuverture)
        const j = horaireOuverture.filter(jour => jour.typeJoursSTR === typeJours);
        // console.log(ind, 'GO ', typeJours, " == ", event.target.value, " = ", j)
        // setHoraireOuverture({})
        let nouvelHoraire = [...horaireOuverture];
        const indexJ = nouvelHoraire.findIndex(jour => jour.typeJoursSTR === typeJours);
        let horaireOUV = [...nouvelHoraire[indexJ].horaireOuverture]
        horaireOUV[ind] = event.target.value;
        if (indexJ !== -1) {
            const nouvellesHeures = horaireOUV;
            // nouvelHoraire[indexMardi].horaireOuverture = event.target.value
            nouvelHoraire[indexJ].horaireOuverture = nouvellesHeures;
            setHoraireOuverture(nouvelHoraire);
        }
        // console.log(nouvelHoraire)
        setRestaurant({ ...restaurant, horaireOuverture: nouvelHoraire })
        // for (let i = 0; i < horaireOuverture.length; i + 1) {

        // }
    }


    const [tabNameValue, setTabNameValue] = useState(0);
    const [languages, setLanguages] = useState([{ "name": "eng", "abbrev": "MAL" }, { "name": "fr", "abbrev": "FRANCAIS" }, { "name": "ita", "abbrev": "MAL" }]);
    const [languageChoose, setLanguageChoose] = useState("eng");
    const [tabDescValue, setTabDescValue] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("eng");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [messageSnak, setMessageSnak] = useState("");

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeTabNameValue = (event, newValue) => {
        // console.log(newValue, " language ", languages[newValue].name, " SHOW == ", restaurant.nom[languages[newValue]?.name])

        setTabNameValue(newValue);
        // console.log(restaurant)
        setLanguageChoose(languages[newValue]?.name);
    };


    const handleChangeTabDescValue = (event, newValue) => {
        setTabDescValue(newValue);
        setLanguageDescChoose(languages[newValue]?.name);
    }

    const handleChangeNoms = (event) => {
        // console.log(languageChoose);
        setRestaurant({ ...restaurant, nom: { ...restaurant.nom, [languageChoose]: event.target.value } })
        // console.log(restaurant)
    }

    const handleChangeDesc = (event) => {
        // console.log(languageDescChoose);
        setRestaurant({ ...restaurant, descriptionsMulti: { ...restaurant.descriptionsMulti, [languageDescChoose]: event.target.value } })
        // console.log(restaurant)
    }

    const apiKey = url.key_map;


    const createFormData = (formData, key, data) => {
        if (!(data instanceof File) && data === Object(data) || Array.isArray(data)) {
            for (var i in data) {
                createFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }
    const saveRestaurant = async () => {
        console.log("restorant", restaurant);
        const data = new FormData();
        for (const key in restaurant) {
            if (key != "photos" && key != "photosFile" && key != "photos64" && key != "carteMenu" && key != "carteMenuFile" && key != "carteMenu64" && key != "photoCouverture" && key != "photoCouvertureFile" && key != "photoCouverture64") {
                createFormData(data, key, restaurant[key]);
            }
        }
        createFormData(data, "photos", restaurant.photosFile);
        createFormData(data, "carteMenu", restaurant.carteMenuFile);
        createFormData(data, "photoCouverture", restaurant.photoCouvertureFile);
        await SaveRestaurantAPI(data, setMessageSnak, setOpenSnackbar, setSeverity);
        if (severity === "success") {
            navigate("/entreprise/restaurant/list");
        }

    }

    const UpdateRestaurant = async () => {
        console.log("restorant-update", restaurant);
        const data = new FormData();
        for (const key in restaurant) {
            if (key != "photos" && key != "photosFile" && key != "photos64" && key != "carteMenu" && key != "carteMenuFile" && key != "carteMenu64" && key != "photoCouverture" && key != "photoCouvertureFile" && key != "photoCouverture64") {
                createFormData(data, key, restaurant[key]);
            }
        }
        createFormData(data, "photos", restaurant.photosFile);
        createFormData(data, "carteMenu", restaurant.carteMenuFile);
        createFormData(data, "photoCouverture", restaurant.photoCouvertureFile);
        await UpdateRestaurantAPI(data, restaurant.id, setrestaurant);
        // setrestaurant(true)
        closeForm();
    }


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    useEffect(() => {
        console.log("==>> USER : ", getAdminConnected().id);
        // getHebergementPrimaryRessources(setEquipements, setTypes);
        getEquipement(0, 100, 'none', setEquipements, setNumberE)
        getCategorieAPI(setTypes)
    }, [])

    // useEffect(() => {
    // }, [])


    return (
        <>
            {/* <TestButton toLog={hebergement} /> */}
            <Typography variant="h5">
                {selectedRestaurant ? 'Modifier un restaurant' : 'Ajouter un restaurant'}
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={8}>
                    <SimpleCard>
                        <Tabs
                            value={tabNameValue}
                            onChange={handleChangeTabNameValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='small'
                            name="noms"
                            type="text"
                            // label="Noms"
                            value={restaurant.nom[languageChoose] ? restaurant.nom[languageChoose] : ''}
                            onChange={(e) => handleChangeNoms(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.titre && "error"}
                            helperText={inputErrors.titre}
                        />
                        {/* 
                        <TextField
                            fullWidth
                            size='small'
                            name="name"
                            type="text"
                            label="Nom"
                            value={restaurant.name}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.titre && "error"}
                            helperText={inputErrors.titre}
                        />
                             */}

                        <Tabs
                            value={tabDescValue}
                            onChange={handleChangeTabDescValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='small'
                            name="descriptions"
                            label="Descriptions"
                            value={restaurant.descriptionsMulti[languageDescChoose] ? restaurant.descriptionsMulti[languageDescChoose] : ''}
                            onChange={(e) => handleChangeDesc(e)}
                            multiline
                            maxRows={4}
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        />

                        {/* <TextField
                            fullWidth
                            size='small'
                            name="description"
                            label="Description"
                            value={restaurant.description}
                            onChange={(e) => handleChange(e)}
                            multiline
                            maxRows={4}
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        /> */}

                        <TextField
                            fullWidth
                            size='small'
                            name="email"
                            type="email"
                            label="Email"
                            value={restaurant.email}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.email && "error"}
                            helperText={inputErrors.email}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="siteweb"
                            type="text"
                            label="Site web"
                            value={restaurant.siteweb}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.siteweb && "error"}
                            helperText={inputErrors.siteweb}
                        />

                        {/* <TextField
                            fullWidth
                            size='small'
                            name="etoile"
                            type="number"
                            label="Etoile"
                            value={restaurant.etoile}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.etoile && "error"}
                            helperText={inputErrors.etoile}
                        /> */}

                        <TextField
                            fullWidth
                            size='small'
                            name="lienYoutube"
                            type="text"
                            label="Lien Youtube"
                            value={restaurant.lienYoutube}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.prix && "error"}
                            helperText={inputErrors.prix}
                        />
                        {/* <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor={`contact-input`}>Contact</InputLabel>
                            <OutlinedInput
                                id={`contact-input`}
                                size="small"
                                type="tel"
                                name="contact"
                                label="Contact"
                                value={restaurant.contact}
                                onChange={(e) => handleChange(e)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            // onClick={() => removeContact(index)}
                                            edge="end"
                                        >
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor={`contact-input`}>Contact</InputLabel>
                            <OutlinedInput
                                id={`contact-input`}
                                size="small"
                                type="tel"
                                name="contact_secours"
                                label="Contact Secours"
                                value={restaurant.contact_secours}
                                onChange={(e) => handleChange(e)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            // onClick={() => removeContact(index)}
                                            edge="end"
                                        >
                                            <Icon>clear</Icon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl> */}

                        {restaurant?.contacts?.map((value, index) => (
                            <div key={index}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor={`contact-input-${index}`}>Contact</InputLabel>
                                    <OutlinedInput
                                        id={`contact-input-${index}`}
                                        size="small"
                                        type="tel"
                                        name="contact"
                                        label="Contact"
                                        value={value.contact}
                                        onChange={(e) => handleContactChange(e, index)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => removeContact(index)}
                                                    edge="end"
                                                >
                                                    <Icon>clear</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        ))}
                        <Fab onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                            <Icon>phone</Icon>
                        </Fab>
                        <br />
                        <br />



                    </SimpleCard>
                </Grid>

                <Grid item sm={4}>
                    <SimpleCard>

                        <div>
                            <FormControl sx={{ m: 1, width: 250 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Equipements</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={restaurant.equipements}
                                    onChange={handleChangeEquipements}
                                    input={<OutlinedInput label="Tag" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {equipements.map((name, index) => (
                                        <MenuItem key={index} value={name.id}>
                                            <Checkbox checked={selectedRestaurant ? restaurant.equipements.some(item => item.idEquipement === name.id) : restaurant.equipements.indexOf(name.id) > -1} />
                                            <ListItemText primary={name.names} />
                                        </MenuItem>
                                    ))}


                                </Select>
                            </FormControl>

                        </div>

                        <div>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Categorie</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={restaurant.categorie}
                                    onChange={handleChangeCategorie}
                                    input={<OutlinedInput label="Tag" />}
                                    // renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {types.map((name, index) => (
                                        <MenuItem key={index} value={name.id}>
                                            <Checkbox checked={selectedRestaurant ? restaurant.categorie.some(item => item.idCategorie === name.id) : restaurant.categorie.indexOf(name.id) > -1} />
                                            <ListItemText primary={name.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                    </SimpleCard>
                </Grid>
                <Grid item sm={10}>

                    <SimpleCard>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Photo couverture</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photo couverture</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos de couverture
                                </Typography> */}
                                <div
                                    {...getRootPropsOnDropPhotoCouverture()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDropPhotoCouverture()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>

                                {(restaurant?.photoCouverture64 || restaurant.photoCouverture) &&
                                    <>
                                        <Swiper
                                            spaceBetween={1}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                        >
                                            {/* {restaurant.photos.map((img, index) => ( */}
                                            <SwiperSlide >
                                                <center>
                                                    <Badge
                                                        sx={{
                                                            mt: 3,
                                                        }}
                                                        badgeContent={
                                                            <>
                                                                <IconButton
                                                                    onClick={() => handleFileRemovePhoto()}
                                                                    color='error'>
                                                                    <Icon>close</Icon>
                                                                </IconButton>
                                                            </>}>

                                                        <div
                                                            style={{
                                                                width: '100%',  // Largeur 100% de la diapositive
                                                                height: '200px',  // Hauteur fixe
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                            }}
                                                        >
                                                            <img
                                                                src={restaurant.photoCouverture64 ? restaurant.photoCouverture64 : url["url-api"] + "restaurant-image/" + restaurant.photoCouverture}
                                                                style={{
                                                                    width: 'auto',
                                                                    height: 'auto',
                                                                    maxWidth: '100%',
                                                                    maxHeight: '100%' // Empêche le débordement horizontal
                                                                }}
                                                                alt={`photo couverture img `} />
                                                        </div>
                                                    </Badge>
                                                </center>

                                            </SwiperSlide>

                                        </Swiper>
                                    </>
                                }

                            </Accordion>
                        </AccordionRoot>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Photo</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootPropsOnDrop()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDrop()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>

                                {(restaurant?.photos64 || restaurant?.photos) &&
                                    <>
                                        <Swiper
                                            spaceBetween={1}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                        >
                                            {restaurant?.photos64?.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <center>
                                                        <Badge
                                                            sx={{
                                                                mt: 3,
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </>}>

                                                            <div
                                                                style={{
                                                                    width: '100%',  // Largeur 100% de la diapositive
                                                                    height: '200px',  // Hauteur fixe
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                }}
                                                            >
                                                                <img
                                                                    src={img.photo}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%' // Empêche le débordement horizontal
                                                                    }}
                                                                    alt={`Pièce img ${index}`} />
                                                            </div>
                                                        </Badge>
                                                    </center>

                                                </SwiperSlide>
                                            ))}
                                            {restaurant?.photos?.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <center>
                                                        <Badge
                                                            sx={{
                                                                mt: 3,
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </>}>

                                                            <div
                                                                style={{
                                                                    width: '100%',  // Largeur 100% de la diapositive
                                                                    height: '200px',  // Hauteur fixe
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                }}
                                                            >
                                                                <img
                                                                    src={url["url-api"] + "restaurant-image/" + img.photo}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%' // Empêche le débordement horizontal
                                                                    }}
                                                                    alt={`Pièce img ${index}`} />
                                                            </div>
                                                        </Badge>
                                                    </center>

                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                }
                            </Accordion>
                        </AccordionRoot>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Carte menu</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Carte Menu
                                </Typography> */}
                                <div
                                    {...getRootPropsOnDropCarte()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDropCarte()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                {(restaurant?.carteMenu64 || restaurant?.carteMenu) &&
                                    <>
                                        <Swiper
                                            spaceBetween={1}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                        >
                                            {restaurant?.carteMenu64?.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <center>
                                                        <Badge
                                                            sx={{
                                                                mt: 3,
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    <IconButton onClick={() => handleFileRemoveCarte(index)} color='error'>
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </>}>

                                                            <div
                                                                style={{
                                                                    width: '100%',  // Largeur 100% de la diapositive
                                                                    height: '200px',  // Hauteur fixe
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                }}
                                                            >
                                                                <img
                                                                    src={img.menu}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%' // Empêche le débordement horizontal
                                                                    }}
                                                                    alt={`Pièce img ${index}`} />
                                                            </div>
                                                        </Badge>
                                                    </center>

                                                </SwiperSlide>
                                            ))}
                                            {restaurant?.carteMenu?.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <center>
                                                        <Badge
                                                            sx={{
                                                                mt: 3,
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    <IconButton onClick={() => handleFileRemoveCarte(index)} color='error'>
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </>}>

                                                            <div
                                                                style={{
                                                                    width: '100%',  // Largeur 100% de la diapositive
                                                                    height: '200px',  // Hauteur fixe
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                }}
                                                            >
                                                                <img
                                                                    src={url["url-api"] + "restaurant-image/" + img.menu}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%' // Empêche le débordement horizontal
                                                                    }}
                                                                    alt={`Pièce img ${index}`} />
                                                            </div>
                                                        </Badge>
                                                    </center>

                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                }
                            </Accordion>
                        </AccordionRoot>

                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Horaire d'ouverture</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Horaire d'ouverture</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <h3>Horaire d'ouverture</h3> */}
                                <table>
                                    {horaireOuverture?.map((horaire, index) => (
                                        <tr key={index}>
                                            {horaire.horaireOuverture.map((h, ind) => (
                                                <td>
                                                    <p>{horaire.typeJoursSTR} (format hh:mm/hh:mm)</p>
                                                    {/* <input placeholder="horaire" onChange={ }></input> */}
                                                    <TextField
                                                        fullWidth
                                                        size='small'
                                                        name="horaire"
                                                        type="text"
                                                        label="Horaire "
                                                        value={h}
                                                        onChange={(e) => handleChangeHoraire(e, ind, horaire.typeJoursSTR)}
                                                        variant="outlined"
                                                        placeholder="horaire"
                                                        sx={{ mt: 1.5, mb: 2 }}
                                                        {...inputErrors.prix && "error"}
                                                        helperText={inputErrors.prix}
                                                    />
                                                </td>
                                            ))}

                                        </tr>
                                    ))}

                                </table>
                            </Accordion>
                        </AccordionRoot>

                        {/* <table>

                            <tr>
                                <td>
                                    <p>Lundi (format hh:mm/hh:mm)</p>
                                    <input placeholder="horaire"></input>
                                </td>
                                <td>
                                    <h3> Horaire 1 </h3>
                                    <input placeholder="horaire"></input>
                                </td>
                                <td>
                                    <h3>Horaire 2</h3>
                                    <input placeholder="horaire"></input>
                                </td>
                                {/* <td>

                                    <input placeholder="horaire"></input>
                                </td> */}
                        {/* </tr>
                </table> */}
                        <h1>Carte</h1>
                        <CarteMap apiKey={apiKey} setLongitude={setLng} setLatitude={setLat} setMereObject={setObjectMere} lati={restaurant.latitude} longi={restaurant.longitude} />
                        <StyledButton
                            // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                            onClick={selectedRestaurant ? UpdateRestaurant : saveRestaurant}
                            fullWidth
                            variant="contained"
                            component="span"
                        >
                            Enregistrer
                        </StyledButton>
                    </SimpleCard>
                </Grid >
            </Grid >
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {messageSnak}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormRestaurant;