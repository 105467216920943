import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, DialogActions, Fab, Grid, Icon, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import url from "app/UrlConfig";
import { SimpleCard } from "app/components";
import { useEffect } from "react";
import { useState } from "react";
import ModalGenerer from "../utils/ModalGenerer";



// const demandeMarque = [
//     {
//         "id": 7,
//         "idmarque": 0,
//         "marque": "Audi",
//         "etat": 11,
//         "identreprise": 1,
//         "entreprise": {
//             "id": 1,
//             "nom": "Entreprise 1",
//             "email": "entreprise1@email.com",
//             "password": "password1",
//             "longitude": "12.345",
//             "latitude": "67.89",
//             "etat": "21",
//             "datevalidation": "2023-09-15 12:00:00",
//             "description": "Description 1",
//             "logo": "https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg",
//             "datecreation": "2023-10-10 00:00:00",
//             "datederniereactivite": "2023-10-10 10:50:32.755769"
//         },
//         "date_demande": "2023-10-13 11:40:49.512769",
//     }
// ]

const ListeDemandeComponent = () => {


    const [marqueUpdate, setMarqueUpdate] = useState();
    const [marqueDelete, setMarqueDelete] = useState();
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [recherche, setRecherche] = useState('');
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const [demandeMarque, setDemandeMarque] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [relance, setRelance] = useState(0);
    const [marqueAjout, setMarqueAjout] = useState({
        id: 0,
        marque: 'marque'
    });
    const handleChange = (e) => {
        setMarqueAjout({ ...marqueAjout, [e.target.name]: e.target.value })
    }

    const handleChangeUpdate = (e) => {
        setMarqueUpdate({ ...marqueUpdate, [e.target.name]: e.target.value })
    }

    const getDemandeMarque = () => {
        fetch(url["url-api"] + "admin/demande-marque", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setDemandeMarque(data.data);
                }

            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDemandeMarque()
    }, [relance])

    const validation = () => {

    }

    const valider = (demande) => {
        console.log(JSON.stringify(demande))
        fetch(url["url-api"] + "admin/demande-marque", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(demande)
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data.data)
                if (data.status == 200) {
                    setSeverity('success');
                    setMessage('Validation Demande Succé');
                    setRelance(relance + 1)
                    setOpenSnackbar(true)

                } else {
                    setSeverity('error')
                    setMessage('Une Erreur est survenue lors de validation');
                    setRelance(relance + 1)
                    setOpenSnackbar(true)
                }
            })
            .catch(error => console.log(error))
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    const [typeAction, setTypeAction] = useState("Ajout");

    const OpenModalAjout = () => {
        setShowModalUpdate(true);
    }

    const CloseModalAjout = () => {
        setShowModalUpdate(false)
    }

    const OpenModalUpdateDelete = (marque, typeAction) => {
        setTypeAction(typeAction);
        // console.log(marque);
        if (typeAction === "DELETE") {
            setMarqueDelete(marque);
        } if (typeAction === "UPDATE") {
            setMarqueUpdate(marque);
        }
        OpenModalAjout();

    }

    const AjoutMarque = () => {
        console.log(marqueAjout);
        fetch(url["url-api"] + "admin/marque", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(marqueAjout)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setMessage('Ajoute un nouveau marque est envoyé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)

                } else {
                    setSeverity('error');
                    setMessage('Marque déjat existé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                }
            })
            .catch(error => console.log(error))
    }

    const UpdateMarque = () => {
        setMarqueAjout(marqueUpdate)
        AjoutMarque();
        valider(marqueUpdate);
    }





    return (
        <div>
            <SimpleCard>
                <Fab onClick={() => OpenModalUpdateDelete(marqueAjout, "Ajout")} color="primary" aria-label="Add" className="button">
                    <Icon>add</Icon>
                </Fab>
                <Box sx={{ py: '12px' }} />
                Ajout Nouveau Marque


                <Box sx={{ py: '12px' }} />
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
            </SimpleCard>
            <Box width={'100%'} sx={{ mt: 3 }}>
                <Grid container spacing={3}>

                    {demandeMarque
                        .filter((marquedemande) =>
                            marquedemande.entreprise.nom.toLowerCase().includes(recherche) ||
                            marquedemande.marque.toLowerCase().includes(recherche)
                        )
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((marquedemande, index) =>
                            <Grid item lg={4} md={4} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar src={marquedemande.entreprise.logo} alt='' />
                                        }
                                        title={`${marquedemande.entreprise.nom}`}
                                        subheader={`${marquedemande.entreprise.email}`}
                                    />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Marque :</Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='body1'>{marquedemande.marque}</Typography>
                                                <Typography gutterBottom variant='body1'>Le : {marquedemande.date_demande}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardActions sx={{ justifyContent: 'right' }}>

                                        <IconButton onClick={() => OpenModalUpdateDelete(marquedemande, "UPDATE")} >
                                            <Icon color="success">edit</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => OpenModalUpdateDelete(marquedemande, "DELETE")} >
                                            <Icon color="error">delete</Icon>
                                        </IconButton>

                                        <Button onClick={() => valider(marquedemande)}>Valider</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>
            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                {typeAction === "Ajout" && (
                    <SimpleCard title={'Ajout d\'une nouvelle Marque'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="marque"
                            type="text"
                            label="Marque"
                            value={marqueAjout.marque}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={AjoutMarque}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "UPDATE" && (
                    <SimpleCard title={'Modifier d\'une Marque'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="marque"
                            type="text"
                            label="Marque"
                            value={marqueUpdate.marque}
                            onChange={handleChangeUpdate}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={UpdateMarque}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "DELETE" && (
                    <div>
                        <h1>Confirmation suppression</h1>
                        <h3>
                            Vous etes sure de Supprimer le marque {marqueDelete.marque}
                        </h3>
                        <DialogActions>
                            <Button onClick={AjoutMarque}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </div>
                )}

            </ModalGenerer>
            {/* MODAL UPDATE */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>

    );


}

export default ListeDemandeComponent;