import { connexionAdmin } from "app/data/nosybe/admin";
import { manageError } from "app/data/piece/dispatch";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Redirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const password = searchParams.get('password');

    // const { email, password } = useParams();

    const loginAdmin = () => {
        connexionAdmin({ email, password })
            .then(response => {
                localStorage.setItem("token", response.data.tokenAdmin)
                localStorage.setItem("type", 1);
                localStorage.setItem("user", "entreprise");
                localStorage.setItem("admin", JSON.stringify(response.data.admin));
                navigate("/entreprise/profile");
            })
            .catch(error => {
                manageError(error, "connexionAdmin: ")
            })
    }

    useEffect(() => {
        loginAdmin();
    }, []);

    return (
        <>
        </>
    )
}

export default Redirect;