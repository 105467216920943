import React from 'react';
import { Alert, Autocomplete, Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, Snackbar, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import ModalGenerer from '../utils/ModalGenerer';

import ModalDelete from '../utils/ModalDelete';
import ModalDetail from '../utils/ModalDetail';
import url from 'app/UrlConfig';
import ComponentFiltre from '../Vehicule/ComponentFiltre';
// import ComponentFiltre from './ComponentFiltre';
import chauffeur from './chauffeurData';
import DetailChauffeur from './DetailChauffeur';
import UpdateChauffeurComponent from './UpdateChauffeur';

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const AutoComplete = styled(Autocomplete)(() => ({
    width: 300
}));

const typeLocations = [
    { Etattype: 10, "type": "Hors Carburant" }, { Etattype: 20, "type": "Avec Carburant" }
];

const ListeChauffeurComponent = () => {
    /*  SNACKNAR * */
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }
    /*  SNACKNAR * */

    const [rechercheValue, setRechercheValue] = useState(
        {
            agemin: '',
            agemax: '',
            recherche: ''
        }
    )

    const styleImage = {
        width: "50px",
        heigth: "50px"
    }


    {/** MODAL DELETE */ }

    const [showModalDelete, setModalDelete] = useState(false);
    const CloseModalDelete = () => {
        setModalDelete(false);
    }

    const OpenDelete = (chauffeur) => {
        setModalDelete(true);
    }
    {/*  * */ }
    const [relance, setRelance] = useState();
    const [chauffeurUpdate, setChauffeurUpdate] = useState();
    const [chauffeurDelete, setChauffeurDelete] = useState();
    const [chauffeurs, setChauffeurs] = useState([]);
    const [chauffeur, setChauffeur] = useState([]);
    const [typeAction, setTypeAction] = useState('');
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [token, setToken] = useState(localStorage.getItem("token"))
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleChange(e) {
        if (typeAction !== "Update") {
            setRechercheValue({ ...rechercheValue, [e.target.name]: e.target.value });
        }
        else {
            setChauffeurUpdate({ ...chauffeurUpdate, [e.target.name]: e.target.value });
        }

    }

    const getChauffeur = () => {

        fetch(url['url-api'] + "admin/chauffeurs/1  ", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.data)
                setChauffeurs(data.data);
            })
            .catch(error => console.log(error))
    }

    const getRecherche = () => {
        setExpan(false)
        console.log(JSON.stringify(rechercheValue));
        fetch(url['url-api'] + "admin/recherche/chauffeurs", {
            method: 'POST',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rechercheValue)
        })
            .then(response => response.json())
            .then(data => { setChauffeurs(data.data) })
            .catch(error => { console.log(error) })
    }

    const getInitiale = () => {
        /// setRelance(relance + 1);
        const initial = {
            agemin: '',
            agemax: '',
            recherche: ''
        }
        setRechercheValue(initial);
        getRecherche()
    }

    const Delete = (id) => {
        console.log(url['url-api'] + "admin/chauffeurs/" + id)
        fetch(url['url-api'] + "admin/chauffeurs/" + id, {
            method: 'DELETE',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.etat == 200) {
                    setShowModalUpdate(false)
                    setMessage("Suppression Chauffeur réussi");
                    setOpenSnackbar(true);
                    setTypeAction('');
                    setRelance(relance + 1)
                } else {
                    setMessage("Une Erreur est Survenue lors de suppression");
                    setSeverity("error");
                    setOpenSnackbar(true)
                    // setRelance(relance + 1)
                    setShowModalUpdate(false);
                    setTypeAction('');
                }
            })
            .catch(error => { console.log(error) })
        // window.location.href = '/entreprise/location/chauffeur/liste';
    }

    const UpdateChauffeur = () => {
        console.log(JSON.stringify(chauffeurUpdate))
        fetch(url['url-api'] + "admin/chauffeur", {
            method: 'PUT',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(chauffeurUpdate)
        })
            .then(response => response.json())
            .then(data => {
                if (data.etat == 200) {
                    setShowModalUpdate(false)
                    setRelance(relance + 1)
                    setMessage("Modification Chauffeur réussi");
                    setOpenSnackbar(true);
                    setTypeAction('');

                } else {
                    setMessage("Une Erreur est Survenue lors du demande");
                    setSeverity("error");
                    setOpenSnackbar(true)
                    // setRelance(relance + 1)
                    setShowModalUpdate(false);
                    setTypeAction('');
                }
            })
            .catch(error => { console.log("ERROR ::: >>> ", error) })
        //navigate('/entreprise/location/chauffeur/liste')

    }

    useEffect(() => {
        console.log(chauffeur);
        getChauffeur();
        setChauffeurs(chauffeur);
        getInitiale();
    }, [relance])


    const OpenUpdate = (chauffeur, type) => {
        setTypeAction(type);
        if (type === "Update") {
            setChauffeurUpdate(chauffeur);
        } if (type === "DELETE") {
            setChauffeurDelete(chauffeur);
        }
        OpenModalUpdate();
    }
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const OpenModalUpdate = (chauffeur) => {
        setShowModalUpdate(true)

    }
    const CloseModalUpdate = () => {
        setShowModalUpdate(false)
    }

    const [expan, setExpan] = useState();

    return (
        <>
            <Grid container spacing={1}>
                <Box sx={{ py: '12px' }} />


                <ComponentFiltre expan={expan}>
                    <Grid container spacing={6}>

                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1 }}>

                        </Grid >

                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1 }}>
                            <TextField
                                size="small"
                                type="number"
                                name="agemin"
                                label="age minimun"
                                value={rechercheValue.agemin}
                                onChange={handleChange}
                            />
                            <Box sx={{ py: '12px' }} />
                            <TextField
                                size="small"
                                type="number"
                                name="agemax"
                                label="age maximum"
                                value={rechercheValue.agemax}
                                onChange={handleChange}
                            />

                            <Box sx={{ py: '12px' }} />
                            <Box sx={{ py: '12px' }} />
                            <TextField
                                size="small"
                                type="text"
                                name="recherche"
                                label="recherche"
                                value={rechercheValue.recherche}
                                onChange={handleChange}
                            />
                            <Box sx={{ py: '12px' }} />
                            <Button sx={{ ml: 2 }} variant="contained" size="small" color="success" onClick={getInitiale}>Reinitialiser</Button>
                            <Button sx={{ ml: 2 }} variant="contained" size="small" color="primary" onClick={getRecherche}>Valider</Button>

                        </Grid>


                    </Grid>
                </ComponentFiltre>
                <Box sx={{ py: '12px' }} />
                <SimpleCard title={'Liste des Vehicules'}>
                    <Box width="100%" overflow="auto">
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Photo</TableCell>
                                    <TableCell align="center">Nom</TableCell>
                                    <TableCell align="center">Prenom</TableCell>
                                    <TableCell align="center">Contact</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Date naissance</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {chauffeurs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((chauffeur, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell><Avatar style={styleImage} src={chauffeur.photo}></Avatar></TableCell>
                                            <TableCell align="center">{chauffeur.nom}</TableCell>
                                            <TableCell align="center">{chauffeur.prenom}</TableCell>
                                            <TableCell align="center">{chauffeur.contact}</TableCell>
                                            <TableCell align="center">{chauffeur.email}</TableCell>
                                            <TableCell align="center">{chauffeur.date_naissance}</TableCell>

                                            <TableCell align="center">
                                                <ModalDetail typeModal={''}>
                                                    <DetailChauffeur chauffeurArg={chauffeur}></DetailChauffeur>
                                                </ModalDetail>
                                                {/* <Link to={''} state={{ personnels: vehicule.personnels }}>
                                                        <IconButton >
                                                            <Icon color="info">group</Icon>
                                                        </IconButton>
                                                    </Link> */}
                                                <IconButton onClick={() => OpenUpdate(chauffeur, "Update")} >
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => OpenUpdate(chauffeur, "DELETE")} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                                <IconButton >
                                                    <Icon color="primary">money</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </StyledTable>

                        <TablePagination
                            sx={{ px: 2 }}
                            page={page}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            count={chauffeurs.length}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[5, 10, 25]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                        />
                    </Box>
                </SimpleCard>

            </Grid >
            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                {typeAction === "Update" && (
                    <Grid item lg={4}>
                        <SimpleCard title={'Update d\'une Chauffeur'}>
                            {/* <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}> */}

                            {/* </FormControl> */}
                            <img style={styleImage} src={chauffeurUpdate.photo}></img>
                            <TextField
                                fullWidth
                                size='small'
                                name="nom"
                                type="text"
                                label="Nom"
                                aria-readonly
                                value={chauffeurUpdate.nom}
                                onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                size='small'
                                name="prenom"
                                type="text"
                                label="Prenom"
                                aria-readonly
                                value={chauffeurUpdate.prenom}
                                onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                size='small'
                                name="contact"
                                type="text"
                                label="Contact"
                                value={chauffeurUpdate.contact}
                                onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                size='small'
                                name="email"
                                type="text"
                                label="Email"
                                defaultValue={chauffeurUpdate.email}
                                onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                size='small'
                                name="date_naissance"
                                type="date"
                                label="Date"
                                defaultValue={chauffeurUpdate.date_naissance}
                                onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                            {/* <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" /> */}

                            <StyledButton onClick={UpdateChauffeur} fullWidth variant="contained" component="span">
                                Modifier
                            </StyledButton>
                        </SimpleCard>
                    </Grid>
                )}
                {typeAction === "DELETE" && (
                    <div>
                        <h1>Confirmation suppression</h1>
                        <h3>
                            Vous etes sure de Supprimer le Chauffeur {chauffeurDelete.nom} {chauffeurDelete.prenom}{" "}
                            de numero {chauffeurDelete.contact}
                        </h3>
                        <Button onClick={(id) => Delete(chauffeurDelete.id)}>Valider</Button>
                    </div>
                )}

                <DialogActions>
                    <Button onClick={CloseModalUpdate}>
                        fermer
                    </Button>
                </DialogActions>
            </ModalGenerer>
            {/* MODAL UPDATE */}

            {/** MODAL DELETE */}


            {/* * */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ListeChauffeurComponent; 