import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';
import ListeEtapeComponent from '../Etapes/ListeEtapeComponent';
// 

const AjoutReparationEtapeComponent = (entretien) => {

    const navigate = useNavigate();

    const reparation = entretien.entretien;

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // modal
    const [etape, setEtape] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const displayModal = (etapeReparation) => {
        setEtape(etapeReparation);
        setOpenModal(true);
    }

    function ajouterEtape() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/etapes`,
            method: 'POST',
            data: {
                idreparation: reparation.id,
                id: etape.id,
                updateEtape: true
            }
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                navigate('/entreprise/reparation/liste');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
                setMessage(response.data.message);
                setOpen(true);
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e);
            setOpen(true);
        })
    }

    return (
        <>

            <SimpleCard title={reparation.nom}>
                <Grid container spacing={3}>
                    <Grid item lg={6} md={6}>
                        <Swiper
                            spaceBetween={1}
                            slidesPerView={1}
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            {reparation.images.map((reparationimg, index) => (
                                <SwiperSlide key={index}>
                                    <div
                                        style={divStyle}
                                    >
                                        <img
                                            src={reparationimg.img}
                                            style={imgStyle}
                                            alt="" />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Typography gutterBottom variant='subtitle1'>
                            {reparation.description}
                        </Typography>
                        <br />
                        <Typography gutterBottom variant='h6'>
                            Les étapes de {reparation.nom}
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <Stepper alternativeLabel>
                                {reparation.etapes && reparation.etapes.map((etape, index) => (
                                    <Step key={index}>
                                        <StepLabel>
                                            {etape.nom}
                                            {etape.image && <img width={'50px'} height={'50px'} src={etape.image} alt="" />}
                                        </StepLabel>
                                    </Step>
                                ))}

                            </Stepper>
                        </Box>
                    </Grid>

                </Grid>
            </SimpleCard>

            <br />

            <ListeEtapeComponent ajoutEtapeReparation={displayModal}></ListeEtapeComponent>

            {etape &&
                <>
                    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                            Affectation
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Affecter <b>{etape.nom}</b> à <b>{reparation.nom}</b>?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ajouterEtape}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AjoutReparationEtapeComponent;