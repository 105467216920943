import { Accordion, AccordionSummary, Alert, Badge, Box, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { getHebergementPrimaryRessources } from "app/data/nosybe/hebergement";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "../Restaurant/CarteMap";
import url from "app/UrlConfig";
import { SaveHebergement, SaveHebergementAPI, UpdateHebergementAPI, getCategorie, getEquipementAPI, getScrappingBooking } from "./API-Hebergement";
import { useNavigate } from "react-router-dom";
import { getAdminConnected } from "app/data/nosybe/admin";
import { element } from "prop-types";

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const FormHebergement = ({ selectedHebergement, setHebergements, closeForm }) => {

    const navigate = useNavigate();
    // console.log(selectedHebergement, '  <<<< ======= slected')

    const [types, setTypes] = useState([]);
    const [equipements, setEquipements] = useState([]);
    const [lat, setLat] = useState(-13.330993056594197);
    const [lng, setLng] = useState(48.256898613913386);


    const [tabNameValue, setTabNameValue] = useState(0);
    const [inputErrors, setInputErrors] = useState({});


    const setObjectMere = (lat, lng) => {
        setHebergement({ ...hebergement, latitude: lat, longitude: lng });
    }

    const handleChange = (e) => {
        // console.log([e.target.name] + ":" + e.target.value)
        setHebergement({
            ...hebergement,
            [e.target.name]: e.target.value
        });
    }

    //CONTACT
    const addContact = () => {
        // console.log("addContact");
        const contactData = [...hebergement.contacts];
        contactData.push({ 'contact': '+261' });
        setHebergement({ ...hebergement, contacts: contactData });
    }
    const handleContactChange = (e, index) => {
        const contactData = [...hebergement.contacts];
        contactData[index] = { 'contact': e.target.value };
        setHebergement({ ...hebergement, contacts: contactData });
    }
    const removeContact = (index) => {
        const contactData = hebergement.contacts.filter((_, i) => i !== index);
        setHebergement({ ...hebergement, contacts: contactData });
    }

    //IMAGES
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                const tabImage = [...hebergement.photos];
                const tabImages = [...hebergement.images];
                // if (selectedHebergement !== undefined) {
                tabImage.unshift({ photo: base64, base: 64 });
                tabImages.push(file);
                setHebergement({ ...hebergement, photos: tabImage, images: tabImages });

            };
            reader.readAsDataURL(file);
        }
    }

    const onDropPhotoCouverture = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                // const tabImage = [...hebergement.photos];
                // tabImage.push({ image: base64 });
                setHebergement({ ...hebergement, photoCouverture: file, photoCouverture64: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleFileRemove = (selectedIndex) => {
        const tabImage = hebergement.photos.filter((_, index) => index !== selectedIndex);
        setHebergement({ ...hebergement, photos: tabImage });
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { getRootProps: getRootPropsOnDropPhotoCouverture, getInputProps: getInputPropsOnDropPhotoCouverture } = useDropzone({ onDrop: onDropPhotoCouverture });


    const [languageChoose, setLanguageChoose] = useState("titre_eng");
    const [languages, setLanguages] = useState([{ "name": "eng", "abbrev": "MAL" }, { "name": "fr", "abbrev": "FRANCAIS" }, { "name": "ita", "abbrev": "MAL" }]);
    const [tabDescValue, setTabDescValue] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("description_eng");
    const [lienBooking, setLienBooking] = useState("");
    const [lienAiolia, setLienAiolia] = useState("");
    const [lienexpedia, setLienExpedia] = useState("");
    const [hebergementScrapp, setHebergementScrapp] = useState();
    // const [equipements, setEquipements] = useState([]);

    const originalHebergement = {
        titre_fr: '',
        titre_eng: '',
        titre_ita: '',
        description_fr: '',
        description_eng: '',
        description_ita: '',
        email: '',
        siteweb: '',
        etoile: 0,
        latitude: lat,
        longitude: lng,
        contacts: [],
        photos: [],
        images: [],
        types: [],
        equipements: [],
        photoCouverture: '',
        photoCouverture64: '',
        lienyoutube: '',
        idAdmin: getAdminConnected().id,
        lien_booking: lienBooking,
        lien_aiolia: lienAiolia,
        lien_expedia: lienexpedia,
        note: 0,
        avis: 0,
        isScrapping: true
    }
    const [hebergement, setHebergement] = useState(selectedHebergement ? { ...selectedHebergement, images: [], photoCouverture64: '', photoCouverture: '' } : { ...originalHebergement });


    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState('success');
    const [visible, setVisible] = useState(false);
    const [dataScrapping, setDataScrapping] = useState({
        url: lienBooking,
        justprice: false
    });

    const setLienBookingData = async (e) => {
        // console.log('ddf', e)
        setLienBooking(e);
        setHebergement({ ...hebergement, lien_booking: e })
        setDataScrapping({ ...dataScrapping, url: e });
    }

    const getIdType = (data) => {
        // const typeTrouve = hebergement.types.find(element => element.nom === type.nom);
        let val = [];
        for (let i = 0; i < data.length; i++) {
            for (let n = 0; n < types.length; n++) {
                if (data[i].nom === types[n].type_etablissement) {
                    val.push({ id: types[n].id, nom: types[n].type_etablissement });
                    continue;
                }
            }
        }
        return val;
    }

    const getIdEquipement = (data) => {
        let val = [];
        for (let i = 0; i < data.length; i++) {
            for (let n = 0; n < equipements.length; n++) {
                if (data[i].title === equipements[n].title) {
                    val.push({ idequipement: equipements[n].id, title: equipements[n].title })
                    continue;
                }
            }
        }
        return val;
    }

    const Scrapping = async () => {
        // console.log(' ggh ', hebergement.lien_booking)
        let data = null;
        if (dataScrapping.url !== '' && !selectedHebergement) {
            console.log(' ==>>> CONSOLE 1', dataScrapping)
            data = await getScrappingBooking(dataScrapping, setHebergementScrapp);
        } else if (selectedHebergement) {
            const dataSend = {
                url: hebergement.lien_booking,
                justprice: false
            }
            console.log(' ==>>> CONSOLE 2', dataSend)
            data = await getScrappingBooking(dataSend, setHebergementScrapp);
        } else {
            console.log(" ENTRE")
            return;
        }
        // console.log(data.location[0])
        setLat(data.location[0]);
        setLng(data.location[1]);
        const dataScrapp = {
            ...hebergement,
            photos: data.albums,
            titre_fr: data.title.replaceAll("\'", ' '),
            titre_eng: data.title.replaceAll("\'", ' '),
            titre_ita: data.title.replaceAll("\'", ' '),
            description_fr: data.description.replaceAll("\'", " "),
            description_eng: data.description.replaceAll("\'", " "),
            description_ita: data.description.replaceAll("\'", " "),
            latitude: data.location[0],
            longitude: data.location[1],
            note: data.note,
            avis: data.avis,
            photoCouverture: data.albums !== undefined && data.albums.length > 0 ? data.albums[0].photo : [],
            isScrapping: false,
            // equipements: data.equis,
            equipements: getIdEquipement(data.equis),
            lien_booking: selectedHebergement ? hebergement.lien_booking : dataScrapping.url,
            lien_aiolia: selectedHebergement ? hebergement.lien_aiolia : lienAiolia,
            // lien_aiolia: dataScrapping.url,
            lien_expedia: selectedHebergement ? hebergement.lien_expedia : lienexpedia,
            // lien_expedia: dataScrapping.url,
            etoile: data.etoile,
            types: getIdType(data.type)
            // types: []
        }
        // console.log('DATA:', data.avis.split(" · ")[1])
        // console.log(dataScrapp)
        setHebergement(dataScrapp);
    }



    const handleChangeTabNameValue = (event, newValue) => {
        setTabNameValue(newValue);
        setLanguageChoose("titre_" + languages[newValue]?.name);
    };

    const handleChangeTabDescValue = (event, newValue) => {
        setTabDescValue(newValue);
        setLanguageDescChoose("description_" + languages[newValue]?.name);
    }

    const handleFileRemoveCouverture = () => {
        setHebergement({ ...hebergement, photoCouverture64: undefined, photoCouverture: '' });
    }


    useEffect(() => {
        getEquipement();
        getHebergementPrimaryRessources(setEquipements, setTypes);
        // handleTranslate();
        // getCategorie(setTypes);
    }, [])

    const exclu64 = (photos = []) => {
        let ex = photos.filter(val => val?.base != 64);
        return ex;
    }

    const createFormData = (formData, key, data) => {
        if (!(data instanceof File) && data === Object(data) || Array.isArray(data)) {
            for (var i in data) {
                createFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }
    const saveHebergement = async () => {
        console.log(hebergement, " Hebergement")
        const photos = exclu64(hebergement.photos)
        const data = new FormData();
        for (let key in hebergement) {
            if (key != "photos" && key != "photoCouverture64")
                createFormData(data, key, hebergement[key]);
        }
        console.log("photos", photos);

        createFormData(data, 'photos', photos);
        await SaveHebergementAPI(data, setMessage, setVisible, setSeverity);
        /* if (severity !== 'success') {
            navigate("/entreprise/hebergement/list");
        } */
    }
    const UpdateHebergement = async () => {
        console.log("update hebergement", hebergement);
        const photos = exclu64(hebergement.photos)
        const data = new FormData();
        for (let key in hebergement) {
            if (key != "photos" && key != "photoCouverture64")
                createFormData(data, key, hebergement[key]);
        }
        createFormData(data, 'photos', photos);
        await UpdateHebergementAPI(data, hebergement.id, setMessage, setVisible, setSeverity);
        closeForm();
    }

    const getEquipement = async () => {
        await getEquipementAPI(setEquipements);
    }

    const handleCloseSnackbar = () => {
        setVisible(false);
    }
    const handleChangeEquipements = (id) => {
        // console.log(id)
        // console.log(hebergement, "ENTRE ICI", id)
        const isExist = hebergement.equipements.some(element => element.idequipement === id)
        const equi = equipements.filter(element => element.id === id)[0];
        // console.log('heb : ', equi)
        console.log(isExist);
        if (isExist) {
            // if (selectedHebergement) {
            for (let i = 0; i < hebergement.equipements.length; i++) {
                // console.log(hebergement.equipements[i].id, " hh")
                if (hebergement.equipements[i].idequipement === id && hebergement.equipements[i].id === 0) {
                    hebergement.equipements.splice(i, 1);
                    setHebergement({ ...hebergement, equipements: hebergement.equipements })
                    break;
                }
                if (hebergement.equipements[i].idequipement === id && hebergement.equipements[i].id !== 0) {
                    hebergement.equipements[i].etat = 0;
                    hebergement.equipements.splice(i, 1);
                    setHebergement({ ...hebergement, equipements: hebergement.equipements })
                    break;
                }
            }
            // console.log({ ...hebergement, equipements: hebergement.equipements })
            setHebergement({ ...hebergement, equipements: hebergement.equipements })
        } else {
            hebergement.equipements.push({ id: 0, idequipement: id, etat: 10, title: equi.title });
            setHebergement({ ...hebergement, equipements: hebergement.equipements })
        }
    }

    const HandleChangeType = (id) => {
        // console.log(types, " === ", hebergement.types, " === ", id)
        const isExist = hebergement.types.some(element => element.idType === id)
        // console.log(isExist)
        if (isExist) {
            for (let i = 0; i < hebergement.types.length; i++) {
                if (hebergement.types[i].idtype === id && hebergement.types[i].id === 0) {
                    hebergement.types.splice(i, 1);
                    setHebergement({ ...hebergement, types: hebergement.types })
                    break;
                }
                if (hebergement.types[i].idtype === id && hebergement.types[i].id !== 0) {
                    hebergement.types[i].etat = 0;
                    hebergement.types.splice(i, 1);
                    setHebergement({ ...hebergement, types: hebergement.types })
                    break;
                }
            }
        } else {
            // console.log(id)
            hebergement.types.push({ id: 0, idtype: id, nom: getNameTypesByID(id) });
            // console.log({ id: 0, idType: id, nom: getNameTypesByID(id) })
            // console.log({ ...hebergement, types: hebergement.types })
            setHebergement({ ...hebergement, types: hebergement.types })
        }
    }

    const getNameTypesByID = (id) => {
        // console.log(types)
        for (let i = 0; i < types.length; i++) {
            if (types[i].id === id) return types[i].type_etablissement;
        }
    }


    const [text, setText] = useState('');
    const [translations, setTranslations] = useState({ en: '', fr: '', it: '' });

    const apiKey = "sk-proj-WKEK7SSte42p18xkjsyDT3BlbkFJuCsJ9xphhYtfgAbgOBJl";


    // sk-proj-WKEK7SSte42p18xkjsyDT3BlbkFJuCsJ9xphhYtfgAbgOBJl

    const translateText = async (text, targetLanguage) => {
        const prompt = `Translate the following text to ${targetLanguage}:\n${text}`;
        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [{ role: 'system', content: `You are a translator.` }, { role: 'user', content: prompt }],
                    max_tokens: 60
                })
            });

            if (!response.ok) {
                const errorData = await response.text();
                console.error('Error Data:', errorData);
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const translatedText = data.choices[0].message.content.trim();
            return translatedText;
        } catch (error) {
            console.error("Error translating text: ", error);
            return '';
        }
    };


    const handleTranslate = async () => {
        const en = await translateText("Bonjour", 'en');
        const fr = await translateText("Bonjour", 'fr');
        const it = await translateText("Bonjour", 'it');
        // console.log(en + " =  en =  fr = " + fr + " = ita = " + it);
    };


    // const handleChangeEquipements = (event) => {
    //     // console.log(restaurant)
    //     if (selectedHebergement === undefined) {
    //         const {
    //             target: { value },
    //         } = event;
    //         console.log(value);

    //         setHebergement({ ...hebergement, equipements: value })
    //         console.log(hebergement)
    //     } else {
    //         const testExist = event.target.value.some(item => item.idEquipement === event.target.value[event.target.value.length - 1])
    //         if (testExist) {
    //             setRestaurant({ ...restaurant, equipements: [...restaurant.equipements.filter(equipement => equipement.idEquipement !== event.target.value[event.target.value.length - 1])] })
    //         } else {
    //             const val = {
    //                 id: 0,
    //                 "idEquipement": event.target.value[event.target.value.length - 1],
    //                 "idRestaurant": restaurant.id,
    //                 "name": "c",
    //                 "etat": 10
    //             }
    //             setRestaurant({ ...restaurant, equipements: [...restaurant.equipements, val] })
    //         }
    //         // console.log({ ...restaurant, equipements: [...restaurant.equipements, val] }, " <<< === VALUE ")
    //     }
    // };


    return (
        <>
            {/* <TestButton toLog={hebergement} /> */}
            <Typography variant="h5">
                {selectedHebergement ? 'Modifier un hébergement' : 'Ajouter un hébergement'}
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={8}>
                    <StyledButton
                        // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                        onClick={Scrapping}
                        fullWidth
                        variant="contained"
                        component="span"
                    >
                        Scrapping
                    </StyledButton>
                    <SimpleCard>
                        <TextField
                            fullWidth
                            size='small'
                            name="lien_booking"
                            type="text"
                            label="Lien Booking"
                            value={hebergement.lien_booking}
                            onChange={(e) => { handleChange(e); setLienBookingData(e.target.value) }}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienBooking && "error"}
                            helperText={inputErrors.lienBooking}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="lien_aiolia"
                            type="text"
                            label="Lien Aiolia"
                            value={hebergement.lien_aiolia}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienBooking && "error"}
                            helperText={inputErrors.lienAiolia}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="lien_expedia"
                            type="text"
                            label="Lien Expédia"
                            value={hebergement.lien_expedia}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienBooking && "error"}
                            helperText={inputErrors.lienexpedia}
                        />
                        <Tabs
                            value={tabNameValue}
                            onChange={handleChangeTabNameValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>

                        <TextField
                            fullWidth
                            size='small'
                            name={languageChoose}
                            type="text"
                            label="Titre"
                            value={hebergement[languageChoose]}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.titre && "error"}
                            helperText={inputErrors.titre}
                        />

                        <Tabs
                            value={tabDescValue}
                            onChange={handleChangeTabDescValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='small'
                            name={languageDescChoose}
                            label="Description"
                            value={hebergement[languageDescChoose]}
                            onChange={(e) => handleChange(e)}
                            multiline
                            maxRows={4}
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="email"
                            type="email"
                            label="Email"
                            value={hebergement.email}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.email && "error"}
                            helperText={inputErrors.email}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="siteweb"
                            type="text"
                            label="Site web"
                            value={hebergement.siteweb}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.siteweb && "error"}
                            helperText={inputErrors.siteweb}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="etoile"
                            type="number"
                            label="Etoile"
                            value={hebergement.etoile}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.etoile && "error"}
                            helperText={inputErrors.etoile}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="note"
                            type="number"
                            label="Note"
                            value={hebergement.note}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.note && "error"}
                            helperText={inputErrors.note}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button variant="contained"  >
                                            sync
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="avis"
                            type="text"
                            label="Avis"
                            value={hebergement.avis}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.avis && "error"}
                            helperText={inputErrors.avis}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button variant="contained"  >
                                            sync
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="lienyoutube"
                            type="text"
                            label="Lien Youtube"
                            value={hebergement.lienyoutube}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienyoutube && "error"}
                            helperText={inputErrors.lienyoutube}
                        />

                        {hebergement.contacts.map((value, index) => (
                            <div key={index}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor={`contact-input-${index}`}>Contact</InputLabel>
                                    <OutlinedInput
                                        id={`contact-input-${index}`}
                                        size="small"
                                        type="tel"
                                        name="contact"
                                        label="Contact"
                                        value={value.contact}
                                        onChange={(e) => handleContactChange(e, index)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => removeContact(index)}
                                                    edge="end"
                                                >
                                                    <Icon>clear</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        ))}
                        <Fab onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                            <Icon>phone</Icon>
                        </Fab>
                        <br />
                        <br />
                    </SimpleCard>
                </Grid>
                <Grid item sm={3}>

                    <SimpleCard>

                        {/* {hebergement.equipements.length > 0 && */}

                        <>
                            <Typography variant="h6" >
                                Equipements
                            </Typography>
                            {/* {selectedHebergement && ( */}
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                {/* <Grid container spacing={4}> */}
                                {equipements.map((equipement, index) => {
                                    // console.log("equipements: ", equipement, hebergement, hebergement.equipements.some(element => element.id === equipement.id));
                                    return (
                                        <Grid >
                                            {/* <svg
                                                    xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128"
                                                >{equipement.icon}</svg> */}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        // checked={true}
                                                        checked={hebergement?.equipements[0]?.title ? hebergement?.equipements?.some(element => element.title === equipement.title && equipement?.etat !== 0) : hebergement?.equipements?.some(element => element.idequipement === equipement.id && equipement?.etat !== 0)}
                                                    />
                                                }
                                                label={equipement.title}
                                                onChange={(id) => handleChangeEquipements(equipement ? equipement.id : 0)}
                                            />
                                        </Grid>

                                    )
                                }
                                )}
                                {/* </Grid> */}

                            </div>
                            {/* )} */}
                            {/* {selectedHebergement === undefined && (
                                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        {hebergement.equipements.map((equipement, index) => {
                                            // console.log("equipements: ", equipement, hebergement, hebergement.equipements.some(element => element.id === equipement.id));
                                            return (
                                                <Grid >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={true}
                                                            // checked={hebergement.equipements.some(element => element.id === equipements.id)}
                                                            />
                                                        }
                                                        label={equipement.title}
                                                        onChange={() => { }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        )}
                                    </div>
                                )} */}
                        </>

                        {/* } */}


                        {/* {equipements.length > 0 &&
                            <>
                                <Typography variant="h6" >
                                    Equipements
                                </Typography>
                                <Grid container spacing={3}>
                                    {equipements.map((equipement, index) => {
                                        // console.log("equipements: ", equipement, hebergement, hebergement.equipements.some(element => element.id === equipement.id));
                                        return (
                                            <Grid item sm={4} key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={hebergement.equipements.some(element => element.id === equipement.id)}
                                                        />
                                                    }
                                                    label={equipement.nom}
                                                    onChange={() => { }}
                                                />
                                            </Grid>

                                        )
                                    }
                                    )}
                                </Grid>
                            </>
                        } */}


                        {types.length > 0 &&
                            <>
                                <Typography variant="h6" >
                                    Types
                                </Typography>
                                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                    {/* <Grid container spacing={4}> */}
                                    {types.map((type, index) =>
                                        // <Grid item sm={4} key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedHebergement ? hebergement.types.some(element => element.idtype === type.id) : hebergement.types.some(element => element.nom === type.type_etablissement)}
                                                />
                                            }
                                            label={type.type_etablissement}
                                            onChange={(id) => HandleChangeType(type.id)}
                                        />
                                        // </Grid>
                                    )}
                                    {/* </Grid> */}


                                </div>
                            </>

                        }
                    </SimpleCard>

                </Grid>
                <Grid item sm={8}>
                    <SimpleCard>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Gallerie</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootProps()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputProps()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                {hebergement?.photos?.length > 0 &&
                                    <>
                                        <Swiper
                                            spaceBetween={1}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                        >
                                            {hebergement?.photos.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <center>
                                                        <Badge
                                                            sx={{
                                                                mt: 3,
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </>}>

                                                            <div
                                                                style={{
                                                                    width: '100%',  // Largeur 100% de la diapositive
                                                                    height: '200px',  // Hauteur fixe
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                }}
                                                            >
                                                                <img
                                                                    src={img.photo}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%' // Empêche le débordement horizontal
                                                                    }}
                                                                    alt={`Pièce img ${index}`} />
                                                            </div>
                                                        </Badge>
                                                    </center>

                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                }
                            </Accordion>
                        </AccordionRoot>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Photo Couverture</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootPropsOnDropPhotoCouverture()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDropPhotoCouverture()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                <>
                                    <Swiper
                                        spaceBetween={1}
                                        slidesPerView={1}
                                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                                        navigation
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                    >

                                        <SwiperSlide >
                                            <center>
                                                <Badge
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                    badgeContent={
                                                        <>
                                                            <IconButton onClick={() => handleFileRemoveCouverture()} color='error'>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>}>

                                                    <div
                                                        style={{
                                                            width: '100%',  // Largeur 100% de la diapositive
                                                            height: '200px',  // Hauteur fixe
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                        }}
                                                    >
                                                        <img
                                                            src={!hebergement.photoCouverture64 ? hebergement.photocouverture : hebergement.photoCouverture64}
                                                            style={{
                                                                width: 'auto',
                                                                height: 'auto',
                                                                maxWidth: '100%',
                                                                maxHeight: '100%' // Empêche le débordement horizontal
                                                            }}
                                                            alt={`Photo couverture`} />
                                                    </div>
                                                </Badge>
                                            </center>

                                        </SwiperSlide>

                                    </Swiper>
                                </>

                            </Accordion>
                        </AccordionRoot>
                        <h1>Carte</h1>
                        <CarteMap apiKey={url.key_map} setLongitude={setLng} setLatitude={setLat} setMereObject={setObjectMere} lati={lat} longi={lng} />
                        <StyledButton
                            // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                            onClick={selectedHebergement ? UpdateHebergement : saveHebergement}
                            fullWidth
                            variant="contained"
                            component="span"
                        >
                            Enregistrer
                        </StyledButton>
                    </SimpleCard>

                </Grid>
            </Grid >
            <Snackbar
                open={visible}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormHebergement;