import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { useState } from "react";
import ModalUpdateActivite from "./ModalUpdateActivite";
import ModalDeleteActivite from "./ModalDeleteActivite";
import { useEffect } from "react";
import { SimpleCard } from "app/components";
import { getActivite } from "app/data/nosybe/activite";
import { FunctionBundle, manageError, useManageError } from "app/data/piece/dispatch";
import TestButton from "app/myComponents/TestButton";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListActivite = () => {
    // const manageError = useManageError();
    // const bundle = new FunctionBundle();

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [activites, setActivites] = useState([]);
    const [selectedActivite, setSelectedActivite] = useState();
    const [activiteTotal, setActiviteTotal] = useState([]);

    //PAGINATION AND SEARCH
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    // const [rowsPerPage, setRowsPerPage] = useState(2);

    const handleChangeSearch = (newSearch) => {
        console.log("handleChangeSearch");
        getActiviteListe(0, newSearch);
        setSearch(newSearch);
    }

    const handleChangePage = async (_, newPage) => {
        console.log("handleChangePage");
        getActiviteListe(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        console.log("handleChangeRowsPerPage");
        getActiviteListe(0, +event.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedActivite(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (activite) => {
        setSelectedActivite(activite);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedActivite(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (activite) => {
        setSelectedActivite(activite);
        setOpenModalEdit(true);
    }

    const getActiviteListe = async (currentPage = 0, row = 25, newSearch = '') => {
        // const getActiviteListe = async (currentPage = 0, row = 2, newSearch = '') => {
        const admin = JSON.parse(localStorage.getItem('admin'));
        await getActivite({
            page: currentPage,
            search: newSearch,
            rowsPerPage: row,
            idAdmin: admin.id,
        })
            .then(response => {
                // console.log("getActiviteListe: ", response.data.activites);
                setActivites(response.data.activites);
                setActiviteTotal(response.data.count);
                setPage(currentPage);
            })
            .catch(error => manageError(error, "getActivite: "));
    }

    const handleListChange = (list) => {
        console.log("handleListChange: ", list);
        setActivites(list);
    }

    useEffect(() => {
        console.log("effect");
        getActiviteListe()

        // }, [selectedActivite]);
    }, []);

    useEffect(() => {
        console.log("effect: ", activites);
    }, [activites])

    return (
        <>
            {/* <TestButton toLog={activites} /> */}
            <SimpleCard title={'Liste des Activité'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={search}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {activites.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom </TableCell>
                                        <TableCell align="center">lien</TableCell>
                                        <TableCell align="center">email</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {activites.map((activite, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{activite.nom_fr}</TableCell>
                                            <TableCell align="center"><a href={activite.siteweb}>{activite.siteweb}</a></TableCell>
                                            <TableCell align="center">{activite.email}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(activite)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(activite)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={activiteTotal}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[25, 50, 100]}
                                // rowsPerPageOptions={[2, 5, 10]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard >
            {selectedActivite &&
                <>
                    <ModalUpdateActivite
                        activite={selectedActivite}
                        setActivite={handleListChange}
                        activites={activites}
                        setActivites={setActivites}
                        open={openModalEdit}
                        handleClose={handleCloseModalEdit}
                    />

                    <ModalDeleteActivite
                        activite={selectedActivite}
                        activites={activites}
                        setActivites={setActivites}
                        open={openModalDelete}
                        handleClose={handleCloseModalDelete}
                    />
                </>
            }
        </>
    );
}

export default ListActivite;