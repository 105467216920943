import { Autocomplete, Button, DialogActions, Fab, FormControl, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutocompleteModel from './AutoCompleteModel';
import { Fragment } from 'react';
import { useEffect } from 'react';
import ModalGenerer from '../utils/ModalGenerer';
import url from 'app/UrlConfig';


const AutoComplete = styled(Autocomplete)(() => ({
    width: 300,
    marginBottom: '16px',
}));

const typeLocations = [
    { Etattype: 10, "type": "Hors Carburant" }, { Etattype: 20, "type": "Avec Carburant" }
];


const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const UpdateVehiculeComponent = ({ vehicule }) => {

    const navigate = useNavigate();
    const [indice, setIndice] = useState();

    const [veh, setVehicule] = useState(vehicule);

    function handleChange(e) {
        setVehicule({ ...veh, [e.target.name]: e.target.value });
    }

    function handleFileChange(event, ind) {
        // console.log("index: ", indice);
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;

                const tabImage = [...veh.image_vehicule];

                console.log(tabImage);
                tabImage[indice].image = base64;
                setVehicule({ ...veh, image_vehicule: tabImage });

            };
            reader.readAsDataURL(file);
        }
    }

    const UpdateVEH = async () => {
        console.log(JSON.stringify(veh))
        fetch(url['url-api'] + "admin/vehicule", {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(veh)
        })
            .then(response => response.json())
            .then(data => { console.log("DATA ::: >>> ", data) })
            .catch(error => { console.log("ERROR ::: >>> ", error) })
        window.location.href = '/entreprise/locations/liste';
    }
    const changeIndice = (ind) => {
        setIndice(ind)
        console.log(ind)
    }



    return (
        // {vehicule!=undefined && (

        // )}
        <div>
            <Grid item lg={4}>
                <SimpleCard title={'Update d\'une Vehicule'}>
                    {/* <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}> */}

                    {/* </FormControl> */}
                    <TextField
                        fullWidth
                        size='small'
                        name="marque"
                        type="text"
                        label="marque"
                        aria-readonly
                        value={veh.model_vehicule.marquevoiture.marque}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="model"
                        type="text"
                        label="Model"
                        aria-readonly
                        value={veh.model_vehicule.model}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="categorie"
                        type="text"
                        label="categorie"
                        aria-readonly
                        value={veh.model_vehicule.categorie_voiture.categorie_voiture}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="immatriculation"
                        type="text"
                        label="Immatriculation"
                        defaultValue={veh.immatriculation}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <InputLabel filled id="demo-simple-select-autowidth-label">Type de Location</InputLabel>
                    <Select
                        size="small"
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name='type_location'
                        onChange={handleChange}
                        defaultValue={veh.type_location}
                        autoWidth
                        label="Categorie Vehicules"
                    >
                        {typeLocations.map((typeLocation, index) => (
                            <MenuItem key={index} value={typeLocation.Etattype}>{typeLocation.type}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        fullWidth
                        size='small'
                        name="prix_par_type_location"
                        type="number"
                        label="Prix"
                        defaultValue={veh.prix_par_type_location}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    {/* <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" /> */}

                    {veh.image_vehicule.length !== 0 &&
                        <>
                            <ImageList sx={{ width: '100%', height: 200 }} cols={3} rowHeight={164}>
                                {veh.image_vehicule.map((img, ind) => {
                                    // console.log("loop: ", img, index);
                                    return (

                                        <div key={ind}>
                                            <ImageListItem>
                                                <img
                                                    src={`${img.image}`}
                                                    alt={`Reparation img ${ind}`}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>

                                            <label htmlFor="contained-button-file">
                                                <Fab onClick={() => changeIndice(ind)} component="span" style={{ zIndex: '0' }} size="small" color="primary">
                                                    <Icon>photo</Icon>
                                                </Fab>
                                            </label>
                                            {/* <button > */}
                                            <input onChange={(e) => handleFileChange(e, ind)} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />
                                            {/* </button> */}
                                        </div>
                                    )
                                })}
                            </ImageList>
                        </>
                    }
                    <StyledButton onClick={UpdateVEH} fullWidth variant="contained" component="span">
                        Modifier
                    </StyledButton>
                </SimpleCard>
            </Grid>
        </div>
    );
};

export default UpdateVehiculeComponent;