import { Alert, Box, Button, DialogActions, Fab, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { useEffect, useState } from "react";
import ModalGenerer from "../utils/ModalGenerer";
import url from "app/UrlConfig";
import { Link } from "react-router-dom";





const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));




const ListeMarqueComponent = () => {

    const [rechercheEntreprise, setRechercheEntreprise] = useState({
        recherche: '',
        etat: 11
    });


    const [message, setMessage] = useState('');
    const [marqueUpdate, setMarqueUpdate] = useState();
    const [marqueDelete, setMarqueDelete] = useState();
    const [typeAction, setTypeAction] = useState("Ajout");
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("token"))
    const [marqueAjout, setMarqueAjout] = useState({
        id: 0,
        marque: 'marque'
    });
    const [marques, setMarques] = useState([]);


    const [page, setPage] = useState(0);
    const [recherche, setRecherche] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const OpenModalAjout = () => {
        setShowModalUpdate(true);
    }

    const CloseModalAjout = () => {
        setShowModalUpdate(false)
    }

    const handleChange = (e) => {
        setMarqueAjout({ ...marqueAjout, [e.target.name]: e.target.value })
    }

    const handleChangeUpdate = (e) => {
        setMarqueUpdate({ ...marqueUpdate, [e.target.name]: e.target.value })
    }

    const AjoutMarque = () => {
        console.log(marqueAjout);
        fetch(url["url-api"] + "admin/demande/marque", {
            method: 'POST',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(marqueAjout)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setMessage('Votre demande d\'ajouter un marque est envoyé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)

                } else {
                    setSeverity('error');
                    setMessage('Marque déjat existé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                }
            })
            .catch(error => console.log(error))
    }

    const UpdateMarque = () => {
        // const token = localStorage.getItem("token");
        fetch(url["url-api"] + "admin/demande/marque", {
            method: "PUT",
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(marqueUpdate)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setMessage('Votre demande de modification d\'un marque est envoyé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)

                } else {
                    setSeverity('error');
                    setMessage('Marque déjat existé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                }
            })
            .catch(error => console.log(error))
    }



    const OpenModalUpdateDelete = (marque, typeAction) => {
        setTypeAction(typeAction);
        // console.log(marque);
        if (typeAction === "DELETE") {
            setMarqueDelete(marque);
        } if (typeAction === "UPDATE") {
            setMarqueUpdate(marque);
        }
        OpenModalAjout();

    }

    const getMarquesActif = () => {
        // const token = localStorage.getItem("token");
        console.log(token)
        fetch(url["url-api"] + "admin/marqueActif", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data)
                setMarques(data.data)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getMarquesActif();
    }, [])

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }




    return (

        <div>
            <SimpleCard>

                <Link to={'/entreprise/locations/marque-entreprise'}>
                    <Button>Votre Marque</Button>
                </Link>
                <Box sx={{ py: '12px' }} />
                <Fab onClick={() => OpenModalUpdateDelete(marqueAjout, "Ajout")} color="primary" aria-label="Add" className="button">
                    <Icon>add</Icon>
                </Fab>
                <Box sx={{ py: '12px' }} />
                Ajout Nouveau Marque


                <Box sx={{ py: '12px' }} />
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={rechercheEntreprise.recherche}
                    onChange={(e) => setRechercheEntreprise({ ...rechercheEntreprise, recherche: e.target.value.toLowerCase() })}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
            </SimpleCard>
            <Box sx={{ py: '12px' }} />
            <SimpleCard title={'Liste des Marques'}>
                <Box width="100%" overflow="auto">
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="center">Marque</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {marques
                                .filter((marque) => marque.marque.toLowerCase().includes(rechercheEntreprise.recherche))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((marque, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{marque.id}</TableCell>
                                        <TableCell align="center">{marque.marque}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => OpenModalUpdateDelete(marque, "UPDATE")} >
                                                <Icon color="success">edit</Icon>
                                            </IconButton>
                                            <IconButton onClick={() => OpenModalUpdateDelete(marque, "DELETE")} >
                                                <Icon color="error">delete</Icon>
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </StyledTable>

                    <TablePagination
                        sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={marques.length}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </Box>
            </SimpleCard>
            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                {typeAction === "Ajout" && (
                    <SimpleCard title={'Ajout d\'une nouvelle Marque'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="marque"
                            type="text"
                            label="Marque"
                            value={marqueAjout.marque}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={AjoutMarque}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "UPDATE" && (
                    <SimpleCard title={'Modifier d\'une Marque'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="marque"
                            type="text"
                            label="Marque"
                            value={marqueUpdate.marque}
                            onChange={handleChangeUpdate}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={UpdateMarque}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "DELETE" && (
                    <div>
                        <h1>Confirmation suppression</h1>
                        <h3>
                            Vous etes sure de Supprimer le marque {marqueDelete.marque}
                        </h3>
                        <DialogActions>
                            <Button onClick={AjoutMarque}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </div>
                )}

            </ModalGenerer>
            {/* MODAL UPDATE */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );

}

export default ListeMarqueComponent;