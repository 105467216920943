import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment'
import { useState } from 'react';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import 'moment/locale/fr'; // Importez la localisation française

// Configurez moment pour utiliser la localisation française
moment.locale('fr');

const localizer = momentLocalizer(moment);

const PlanningComponent = () => {

    const [events, setEvents] = useState(null);

    function getHistorique() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/client/demande?isEntreprise=true&isHistorique=true`,
            method: 'GET'
        };

        axios(requestOptions).then((response) => {

            const listEvent = [];

            const historiques = response.data;

            historiques.forEach(historique => {
                const startDate = moment(historique.datedebut, 'YYYY-MM-DD HH:mm:ss').toDate();
                const dureeReparation = historique.reparation_entrepise.duree;
                const endDate = moment(startDate).add(moment.duration(dureeReparation)).toDate();
                listEvent.push(
                    {
                        title: `${historique.client.nom} ${historique.client.prenom} - ${historique.reparation_entrepise.nom}`,
                        start: startDate,
                        end: endDate,
                        color: historique.reparation_entrepise.codecouleur
                    }
                );
            });

            setEvents(listEvent);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getHistorique();
    }, []);

    const eventStyleGetter = (event) => {
        return {
            style: {
                backgroundColor: event.color,
                opacity: 0.8,
                color: 'white',
                border: '1px solid black',
                display: 'block',
                borderRadius: '5px'
            },
        };
    };

    return (
        <div>
            {events ?
                <>
                    <Calendar
                        culture="fr"
                        messages={{
                            allDay: "Tous les jours",
                            previous: "Précédent",
                            next: "Suivant",
                            today: "Aujourd'hui",
                            month: "Mois",
                            week: "Semaine",
                            day: "Jour",
                            date: "Date",
                            time: "Heure",
                            event: "Evenement",
                        }}
                        onSelectSlot={(slotInfo) => {
                            console.log(slotInfo)
                        }}
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        eventPropGetter={eventStyleGetter} // Utilisez eventStyleGetter pour définir le style des événements
                    />
                </>
                :
                <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>
            }

        </div>
    );
};

export default PlanningComponent;