import DemandeClient from "./views/myViews/Reparation/Clients/DemandeClient";
import HistoriqueClient from "./views/myViews/Reparation/Clients/HistoriqueClient";
import AffectationEtape from "./views/myViews/Reparation/Etapes/AffectationEtape";
import AjoutEtape from "./views/myViews/Reparation/Etapes/AjoutEtape";
import ListeEtape from "./views/myViews/Reparation/Etapes/ListeEtape";
import AffectationPersonnel from "./views/myViews/Reparation/Personnels/AffectationPersonnel";
import AjoutPersonnel from "./views/myViews/Reparation/Personnels/AjoutPersonnel";
import ListPersonnel from "./views/myViews/Reparation/Personnels/ListPersonnel";
import Profil from "./views/myViews/Reparation/Profil/Profil";
import Actualite from "./views/myViews/Reparation/Publications/Actualite";
import ActualiteReponse from "./views/myViews/Reparation/Publications/ActualiteReponse";
import Suggestions from "./views/myViews/Reparation/Publications/Suggestions";
import SuggestionsModif from "./views/myViews/Reparation/Publications/SuggestionsModif";
import AjoutReparation from "./views/myViews/Reparation/Reparations/AjoutReparation";
import AjoutReparationEtape from "./views/myViews/Reparation/Reparations/AjoutReparationEtape";
import ListeReparation from "./views/myViews/Reparation/Reparations/ListeReparation";
import ListReparationPersonnel from "./views/myViews/Reparation/Reparations/ListeReparationPersonnel";
import ListeTypeReparation from "./views/myViews/Reparation/Reparations/Types/ListeTypeReparation";
import ListeTypeReparationEntreprise from "./views/myViews/Reparation/Reparations/Types/ListeTypeReparationEntreprise";
import AjoutTarif from "./views/myViews/Reparation/Tarifs/AjoutTarif";
import ListeTarif from "./views/myViews/Reparation/Tarifs/ListeTarif";
import Planning from "./views/myViews/Reparation/dashboard/Planning";
import RecetteDepense from "./views/myViews/Reparation/dashboard/RecetteDepense";
import ReparationEvolution from "./views/myViews/Reparation/dashboard/ReparationEvolution";
import ReparationPieChart from "./views/myViews/Reparation/dashboard/ReparationPieChart";

const routesRepair = [

    {
        path: '/entreprise/reparation/dashboard/planning',
        element: <Planning></Planning>
    },
    {
        path: '/entreprise/reparation/dashboard/reparations',
        element: <ReparationPieChart></ReparationPieChart>
    },
    {
        path: '/entreprise/reparation/dashboard/evolution',
        element: <ReparationEvolution></ReparationEvolution>
    },
    {
        path: '/entreprise/reparation/dashboard/recettes_depenses',
        element: <RecetteDepense></RecetteDepense>
    },

    // publications
    {
        path: '/entreprise/reparation/publications/actualites',
        element: <Actualite></Actualite>
    },
    {
        path: '/entreprise/reparation/publications/actualites/reponse',
        element: <ActualiteReponse></ActualiteReponse>
    },
    {
        path: '/entreprise/reparation/publications/suggestions',
        element: <Suggestions></Suggestions>
    },
    {
        path: '/entreprise/reparation/publications/suggestions/modif',
        element: <SuggestionsModif />
    },

    // client
    {
        path: '/entreprise/reparation/clients/demandes',
        element: <DemandeClient></DemandeClient>
    },
    {
        path: '/entreprise/reparation/clients/historique',
        element: <HistoriqueClient></HistoriqueClient>
    },

    // types
    {
        path: '/entreprise/reparation/types/liste',
        element: <ListeTypeReparationEntreprise></ListeTypeReparationEntreprise>,
    },
    {
        path: '/entreprise/reparation/types/innocar/liste',
        element: <ListeTypeReparation />,
    },

    // reparations
    {
        path: '/entreprise/reparation/liste',
        element: <ListeReparation></ListeReparation>,
    },
    {
        path: '/entreprise/reparation/liste/personnel',
        element: <ListReparationPersonnel></ListReparationPersonnel>
    },
    {
        path: '/entreprise/reparation/ajout',
        element: <AjoutReparation></AjoutReparation>
    },
    {
        path: '/entreprise/reparation/ajout/etapes',
        element: <AjoutReparationEtape></AjoutReparationEtape>
    },

    // etapes
    {
        path: '/entreprise/reparation/etapes/liste',
        element: <ListeEtape></ListeEtape>,
    },
    {
        path: '/entreprise/reparation/etapes/ajout',
        element: <AjoutEtape></AjoutEtape>
    },
    {
        path: '/entreprise/reparation/etapes/ajout/affectation',
        element: <AffectationEtape></AffectationEtape>
    },

    // tarifs
    {
        path: '/entreprise/reparation/tarifs/ajout',
        element: <AjoutTarif />
    },
    {
        path: '/entreprise/reparation/tarifs/liste',
        element: <ListeTarif />
    },

    // personnels
    {
        path: '/entreprise/reparation/personnels/liste',
        element: <ListPersonnel></ListPersonnel>
    },
    {
        path: '/entreprise/reparation/personnels/ajout',
        element: <AjoutPersonnel></AjoutPersonnel>
    },
    {
        path: '/entreprise/reparation/personnels/ajout/affectation',
        element: <AffectationPersonnel></AffectationPersonnel>
    },

    // profile
    {
        path: '/entreprise/reparation/profil',
        element: <Profil></Profil>
    },
]

export default routesRepair;