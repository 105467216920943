import { Box, styled } from "@mui/material";
import { Breadcrumb } from "app/components";
import AccueilComponent from "../../../../myComponents/VentePiece/Accueil/AccueilComponent";

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const Accueil = () => {
    document.title = "Accueil";
    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Accueil' }]}
                />
            </Box>
            <AccueilComponent />
        </AppButtonRoot>
    );
}
export default Accueil;