import url from "app/UrlConfig"


export const CreateCondition = (condition, setMessage, setOpen, setType, closeModal) => {
    const token = localStorage.getItem('token');
    fetch(url["url-api"] + "admin/condition", {
        method: 'POST',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(condition)
    })
        .then(reponse => reponse.json())
        .then(data => {
            if (data.status === 200) {
                setMessage("Creation Condition Avec Succés");
                setOpen(true);
                setType("success");
            } else {
                setMessage("Creation Condition Avec Erreur");
                setOpen(true);
                setType("error");
                closeModal(false);
            }
        })
        .catch(error => {
            setMessage("Creation Condition Avec Erreur " + error);
            setOpen(true);
            setType("error");
        })
}


export const getContrat = (setContrat) => {
    const token = localStorage.getItem('token');
    fetch(url["url-api"] + "admin/condition", {
        method: "GET",
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        }
    })
        .then(reponse => reponse.json())
        .then(data => {
            console.log(data.data)
            if (data.status === 200) {
                setContrat(data.data);
            }
        })
        .catch(error => console.log(error))
}