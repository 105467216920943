const categorieChangements = [
    {
        id: 1,
        nom: 'Pneus',
        date: '10/10/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
    },
    {
        id: 2,
        nom: 'Freinage',
        date: '10/10/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
        categorie: {
            id: 1,
            nom: "Freins",
        },
    },
]

const sousCategorieChangements = [
    {
        id: 1,
        nom: 'Flexible de frein',
        categorie: {
            id: 1,
            nom: "Freins",
        },
        date: '10/10/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
    },
    {
        id: 2,
        nom: 'Jeux de plaquette',
        categorie: {
            id: 1,
            nom: "Freins",
        },
        date: '10/10/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
        souscategorie: {
            id: 1,
            nom: "Plaquettes de frein",
            categorie: {
                id: 1,
                nom: "Freins",
            },
        }
    },
]

const marqueChangements = [
    {
        id: 1,
        idsouscategorie: 1,
        nom: 'DELPHI',
        souscategorie: {
            id: 1,
            nom: "Plaquettes de frein",
        },
        date: '10/10/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
    },
    {
        id: 1,
        nom: 'BREMBO',
        souscategorie: {
            id: 2,
            nom: "Disques de frein",
        },
        date: '10/10/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
        marque: {
            id: 3,
            nom: 'MAPCO',
            souscategorie: {
                id: 2,
                nom: "Disques de frein",
            },
        }
    }
]

export const getCategoriesChangements = () => categorieChangements;

export const getSousCategoriesChangements = () => sousCategorieChangements;

export const getMarquesChangements = () => marqueChangements;