import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListeReparationComponent from '../Reparation/ListeReparationComponent';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const AffectationPersonnelComponent = (employe) => {

    const navigate = useNavigate();
    const [personnel, setPersonnel] = useState(employe.employe);
    const contacts = [];
    if (personnel.contact) {
        for (let index = 0; index < personnel.contact.length; index++) {
            const element = personnel.contact[index];
            contacts.push(
                <span key={index}>{element}<br /></span>
            );
        }
    } else {
        personnel.contactpersonnels.forEach((contactpersonnel, index) => {
            contacts.push(
                <span key={index}>{contactpersonnel.contact}<br /></span>
            );
        });
    }


    // Modal
    const [modalReparation, setModalReparation] = useState(null);

    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setPersonnel({ ...personnel, idreparation: 0 });
        setOpenModal(false);
    };

    const affecter = (reparation) => {
        setPersonnel({ ...personnel, idreparation: reparation.id });
        setModalReparation(reparation);
        setOpenModal(true);
    }

    const terminerAffectation = () => {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/personnels`,
            method: 'POST',
            data: personnel
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                navigate('/entreprise/reparation/personnels/liste');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
                setMessage(response.data.message);
                setOpen(true);
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        if (openModal) {
            setOpenModal(false);
        }
    }

    return (
        <>
            <SimpleCard title={`Personnel`}>
                <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Card sx={{ width: '100%' }}>
                            {personnel.img ?
                                <Card sx={{ width: '100%' }}>
                                    <div style={divStyle}>
                                        <img src={personnel.img} alt="Votre profil" style={imgStyle} />
                                    </div>
                                </Card>
                                :
                                <>
                                    <Typography variant='h5'>
                                        Aucune image sélectionnée
                                    </Typography>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Typography gutterBottom variant="h5" component="div">
                            {personnel.nom} {personnel.prenom}
                        </Typography>
                        <Typography variant="body2">
                            Date de naissance : {personnel.datenaissance}<br />
                            Email : {personnel.email}<br />
                            Contact : <br />{contacts}
                        </Typography>
                        <br />
                        <TextField
                            variant='outlined'
                            size='small'
                            fullWidth
                            type='number'
                            label='Salaire'
                            name='salaire'
                            value={personnel.salaire}
                            onChange={(e) => setPersonnel({ ...personnel, salaire: e.target.value })}
                        ></TextField>
                        {personnel.idreparation === 0 &&
                            <Button
                                onClick={terminerAffectation}
                                variant='contained'
                                fullWidth
                                sx={{ mt: 2, mb: 2 }}
                                color='success'
                            >
                                <Icon>check</Icon>
                                Terminer
                            </Button>
                        }
                    </Grid>
                </Grid>
            </SimpleCard>

            <br />

            <ListeReparationComponent affectation={affecter}></ListeReparationComponent>

            {/* modal affectation */}
            {modalReparation &&
                <>
                    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                            Affectation
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Affecter <b>{personnel.nom} {personnel.prenom}</b> à <b>{modalReparation.nom}</b>?
                            </Typography>
                            <Typography gutterBottom>
                                Salaire : Ar <b>{personnel.salaire}</b>
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModal}>
                                Fermer
                            </Button>
                            <Button color='success' onClick={terminerAffectation}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AffectationPersonnelComponent;