import { Card, Box, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Icon } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import './modalDetail.css';



const ModalDetail = ({ children, vehicule, typeModal }) => {
    const [openModalFiche, setOpenModalFiche] = useState(false);
    const Open = () => setOpenModalFiche(true);
    const Close = () => {
        setOpenModalFiche(false)
    };
    // if (show) {
    //     Open();
    // } else {

    // }

    return (
        <>
            {/* <Button onClick={Open}>AFFICHE</Button> */}
            <IconButton onClick={() => Open(vehicule)} >
                <Icon color="info">info</Icon>
            </IconButton>
            <Dialog
                // maxWidth={typeModal}
                onClose={Close} aria-labelledby="customized-dialog-title" open={openModalFiche}>
                <DialogTitle id="customized-dialog-title" onClose={Close}>
                    <Button onClick={Close}>
                        X
                    </Button>
                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>

                <DialogActions>
                    <Button onClick={Close}>
                        Fermer
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default ModalDetail;
