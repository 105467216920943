import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import ListeDemandeModelComponent from 'app/myComponents/Location/Vehicule/ListeDemandeModel';
import ListeMarqueComponent from 'app/myComponents/Location/Vehicule/ListeMarqueComponent';
import TypeDemandeComponent from 'app/myComponents/Repair/Admin/Reparation/TypeDemandeComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));


const ListeDemandeModel = () => {
    document.title = "TYPE-DEMANDE";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Reparations' }, { name: 'TypeDemande' }]}
                />
            </Box>

            <ListeDemandeModelComponent></ListeDemandeModelComponent>

        </AppButtonRoot>
    );
};

export default ListeDemandeModel;