import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { getListeHebergement } from "app/data/nosybe/hebergement";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { getEquipement } from "./Api-Equipement";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListEquipementComponent = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [hebergements, setHebergements] = useState([]);
    const [selectedHebergement, setSelectedHebergement] = useState(undefined);
    const [hebergementTotal, setHebergementTotal] = useState(0);
    const [search, setSearch] = useState("none");
    const [equipements, setEquipements] = useState([]);
    const [equipementTotal, setEquipementTotal] = useState(0);

    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const handleChangePage = async (_, newPage) => {
        setPage(newPage);
        await getEquipement(page, rowsPerPage, search, setEquipements, setEquipementTotal);
        // getListeHebergement(setHebergements, setHebergementTotal, newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        await getEquipement(page, +event.target.value, search, setEquipements, setEquipementTotal);
        // getListeHebergement(setHebergements, setHebergementTotal, 0, +event.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedHebergement(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (hebergement) => {
        setSelectedHebergement(hebergement);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedHebergement(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (hebergement) => {
        setSelectedHebergement(hebergement);
        setOpenModalEdit(true);
    }

    const getEquipementsListe = async () => {
        const equipement = await getEquipement(page, rowsPerPage, search, setEquipements, setEquipementTotal);
        // console.log(equipement)
        setEquipements(equipement);
    }

    useEffect(() => {
        getEquipementsListe();
    }, [search]);

    return (
        <>
            {/* <TestButton toLog={hebergements} /> */}
            <SimpleCard title={'Liste des equipement'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {equipements.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom</TableCell>
                                        <TableCell align="center">Description</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {equipements.map((equipement, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{equipement.names}</TableCell>
                                            <TableCell align="center">{equipement.description}</TableCell>
                                            <TableCell align="center">
                                                <IconButton >
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton  >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={equipementTotal}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[2, 5, 10]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard >
            {selectedHebergement &&
                <>
                </>
            }
        </>
    )
}

export default ListEquipementComponent;