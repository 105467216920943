import React from 'react';
import { Box, styled } from "@mui/material";
import { Breadcrumb } from "app/components";
import FeedbackListComponent from 'app/myComponents/Nosybe/Feedback/FeedbackListComponent';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const FeedbackListe = () => {
    document.title = "Feedback clients";
    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Feedback' }
                    ]}
                />
            </Box>
            <FeedbackListComponent />
        </AppButtonRoot>
    )
};

export default FeedbackListe;