import url from "app/UrlConfig";
import axios from "axios";
import { displayToast } from "app/data/piece/dispatch";


export const SaveIlesAPI = async (Iles, setMessage, setVisible, setSeverity) => {
    await axios.post(url["url-api"] + "iles/add", Iles)
        //.then(response => response.json())
        .then(data => {
            // console.log(data)
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("request avec success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage(data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            setSeverity('error');
            setMessage(error)
            setVisible(true);
        })
}

export const getIlesListAPI = async (data, setIles, setLigneNumber) => {
    await axios.get(url["url-api"] + "iles")
        //.then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setIles(data.data);
                setLigneNumber(data.LigneNumber);
                // setIless(data.data.ori);
            }
        })
        .catch((error) => {
            console.log(error);
        })
}

export const getListeIlesDetail = async (idIles, setIles) => {
    new Promise((resolve, reject) => {
        fetch(url["url-api"] + "Iles-get/" + idIles, {
            method: 'GET',
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // console.log(data.data)
                    setIles(data.data[0]);
                    if (data.data.length > 0) {

                        resolve(data.data[0]);

                        return data.data[0];
                    }
                }
            })
            .catch((error) => {
                reject(error);
            })
    })
}


export const UpdateIlesAPI = async (Iles, setIles, setMessage, setVisible, setSeverity) => {

    //console.log(JSON.stringify(Iles))
    await axios.post(url["url-api"] + "iles/update", Iles)
        //.then(response => response.json())
        .then(data => {
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("Modification avec Success");
                setVisible(true);
                displayToast("Modification avec Success", true);
            } else {
                setSeverity('error');
                setMessage("Error " + data.message)
                setVisible(true);
                displayToast("Error " + data.message, false);
            }
        })
        .catch((error) => {
            console.log(error);
            setSeverity('error');
            setMessage("Error " + error);
            setVisible(true);
            displayToast("Error " + error, false);
        });
}

export const DeleteIles = async (idIles, setIles, setMessage, setVisible, setSeverity) => {
    await axios.delete(url["url-api"] + "iles/delete/" + idIles)

        //.then(response => response.json())
        .then(data => {
            if (data.data.status === 200) {
                // setSeverity('success');
                // setMessage("Suppression avec Success")
                // setVisible(true);
            } else {
                // setSeverity('error');
                // setMessage("Error " + data.message)
                // setVisible(true);
            }
        })
        .catch((error) => {
            setSeverity('error');
            setMessage("Error " + error)
            setVisible(true);
        })


}