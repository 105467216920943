import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Icon, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { getFeedback } from 'app/api/feedback/feedback';
import { SimpleCard } from 'app/components';
import { dateToYYYYMMDD } from 'app/config/formatDate';
import { formatDate } from 'app/data/piece/stat';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0 } },
    },
}));

const FeedbackListComponent = () => {

    const [feedbacks, setFeedback] = useState(null);
    const [filter, setFilter] = useState({
        recherche: "",
        datedebut: dateToYYYYMMDD(new Date()),
        datefin: dateToYYYYMMDD(new Date())
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeFilter = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    }

    const getListFeedback = (payload) => {
        getFeedback(payload).then((result) => {
            // console.log('result : ', result);
            setFeedback(result.data.feedbacks);
        }).catch((e) => {
            // console.log('error : ', e);
            setFeedback([]);
        });
    }

    const filtrerFeedback = () => {
        getListFeedback(filter);
    }

    useEffect(() => {
        console.log('filter : ', filter);
    }, [filter]);

    useEffect(() => {
        getListFeedback();
    }, []);

    return (
        <SimpleCard title={'Feedbacks venant des clients'}>

            <Box width="100%" overflow="auto">
                <AccordionRoot>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<Icon>expand_more</Icon>}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <Box className="column">
                                <Typography className="heading">Liste des feedbacks</Typography>
                            </Box>

                            <Box className="column">
                                <Typography className="secondaryHeading">Filtrer</Typography>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails className="details">
                            <Box className="column helper">
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            name="recherche"
                                            type="text"
                                            label="Recherche"
                                            variant="outlined"
                                            value={filter.recherche}
                                            onChange={handleChangeFilter}
                                        />
                                        <TablePagination
                                            sx={{ px: 2 }}
                                            page={page}
                                            component="div"
                                            rowsPerPage={rowsPerPage}
                                            count={feedbacks ? feedbacks.length : 0}
                                            onPageChange={handleChangePage}
                                            rowsPerPageOptions={[4, 8, 12]}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    name="datedebut"
                                                    type="date"
                                                    label="Date debut"
                                                    variant="outlined"
                                                    value={filter.datedebut}
                                                    onChange={handleChangeFilter}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    name="datefin"
                                                    type="date"
                                                    label="Date Fin"
                                                    variant="outlined"
                                                    value={filter.datefin}
                                                    onChange={handleChangeFilter}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Box>
                        </AccordionDetails>

                        <Divider />

                        <AccordionActions>
                            <Button onClick={() => filtrerFeedback()} variant="contained" size="small">
                                Filtrer
                            </Button>
                        </AccordionActions>
                    </Accordion>
                </AccordionRoot>
                <br />
                {feedbacks && feedbacks.length > 0 ?
                    <>
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Client</TableCell>
                                    <TableCell align="center">Date du message</TableCell>
                                    <TableCell align="center">Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feedbacks
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((feedback, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                <p>{feedback.nom} {feedback.prenom}</p>
                                                <p>{feedback.email}</p>
                                            </TableCell>
                                            <TableCell align="center">{formatDate(feedback.datemessage)}</TableCell>
                                            <TableCell align="center">{feedback.message}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </StyledTable>
                    </>
                    :
                    <h5>Pas de résultat</h5>
                }
            </Box>
        </SimpleCard >
    );
};

export default FeedbackListComponent;