import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { displayToast } from "app/data/piece/dispatch";
import TestButton from "app/myComponents/TestButton";
import { DeleteHebergement } from "./API-Hebergement";

const ModalDeleteHebergement = ({ hebergement, setHebergements, open, handleClose }) => {
    const supprimerHebergement = async () => {
        await DeleteHebergement(hebergement.id);
        handleClose();
        displayToast("Succes de la suppression", true);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Retirer {hebergement.titre_fr}
            </DialogTitle>

            <DialogContent dividers>
                {/* <TestButton toLog={hebergement} /> */}
                <Typography gutterBottom>
                    Vous en êtes sur?
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={supprimerHebergement} style={{ color: 'red' }}>
                    Confirmer
                </Button>
                <Button onClick={handleClose} style={{ color: 'red' }}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteHebergement;