export const navigationsLocation = [
  {
    name: 'Publications',
    icon: 'forum',
    children: [
      { name: 'Actualités', iconText: '', path: '/#' },
      { name: 'Suggestions', iconText: '', path: '/#' }
    ]
  },
  { name: 'Dashboard', path: '/entreprise/locations/dashboard', icon: 'dashboard' },
  {
    name: 'Mes Vehicules',
    icon: 'build',
    children: [
      { name: 'Ajout', iconText: 'SI', path: '/entreprise/locations/ajout' },
      { name: 'Liste', iconText: 'SU', path: '/entreprise/locations/liste' },
      { name: 'Marque Voiture', iconText: 'SU', path: '/entreprise/locations/marque' },
      { name: 'Categorie Voiture', iconText: 'SU', path: '/entreprise/locations/categorie' },
      { name: 'Model Voiture', iconText: 'SU', path: '/entreprise/locations/modele' }
    ]
  },
  {
    name: 'Mes clients',
    icon: 'person',
    children: [
      { name: 'Demandes Reservations', iconText: 'SI', path: '/entreprise/locations/clients/demandes-reservation' },
      { name: 'Publications', iconText: 'SU', path: '/entreprise/location/clients/publications' },
      { name: 'Historique', iconText: 'SU', path: '/entreprise/location/clients/historique' }
    ]
  },
  {
    name: 'Calendrier',
    icon: 'construction',
    path: '/entreprise/locations/calendrier'
  },
  {
    name: 'Liste Planning',
    icon: 'construction',
    path: '/entreprise/locations/planning'
  },
  {
    name: 'Locations',
    icon: 'build',
    children: [
      { name: 'Locations Proches', iconText: 'SI', path: '/entreprise/locations/locations-proches' },
      { name: 'Historique', iconText: 'SU', path: '/entreprise/locations/historiques-locations' },
    ]
  },
  {
    name: 'Itineraire',
    icon: 'map',
    path: '/entreprise/locations/suivi'
  },
  {
    name: 'Statistiques & Analyses',
    icon: '%',
    children: [
      { name: 'Liste', iconText: 'SU', path: '/entreprise/locations/tarifs/liste' },
    ]
  },
  {
    name: 'Mes personnels',
    icon: 'group',
    children: [
      { name: 'Ajout Chauffeur', iconText: 'SI', path: '/entreprise/location/chauffeur/ajout' },
      { name: 'Liste Chauffeur', iconText: 'SU', path: '/entreprise/location/chauffeur/liste' },
    ]
  },
  {
    name: 'Caratcéristique Vehicule',
    icon: 'settings',
    children: [
      { name: 'Ajout Model Vehicule', iconText: 'SI', path: '/entreprise/location/chauffeur/ajout' },
      { name: 'Liste Model', iconText: 'SU', path: '/entreprise/location/chauffeur/liste' },
    ]
  },

  // 
  {
    name: 'Parametres',
    icon: 'settings',
    children: [
      { name: 'A propos', path: '#' },
      { name: 'Contrat', path: '/entreprise/location/contrat' }
    ]
  },
];

