import routesPieceAdmin from "./routesPieceAdmin";
import ListeDemandeComponent from "./myComponents/Location/Vehicule/ListeDemandeMarqueComponent";
import ListeMarqueComponent from "./myComponents/Location/Vehicule/ListeMarqueComponent";
import routesRepairAdmin from "./routesRepairAdmin";
import AdminClient from "./views/myViews/Admin/AdminClient";
import AdminEntreprise from "./views/myViews/Admin/AdminEntreprise";
import ListeDemandeCategoriePage from "./views/myViews/Admin/Location/ListeDemandeCategoriePage";
import ListeDemandeModel from "./views/myViews/Admin/Location/ListeDemandeModelPage";
import Accueil from "./views/myViews/VentePiece/Publication/Accueil";
import Attribution from "./views/myViews/Admin/Attribution";
import AjoutIles from "./views/myViews/Nosybe/Iles/AjoutIles";
import ListIles from "./views/myViews/Nosybe/Iles/ListIles";
import FeedbackListe from "./views/myViews/Nosybe/Feedback/FeedbackListe";

const routesAdmin = [
    //feedback
    {
        path: '/admin/feedback-client',
        element: <FeedbackListe />
    },
    //iles
    {
        path: '/admin/iles/ajout',
        element: <AjoutIles />
    },
    {
        path: '/admin/iles/list',
        element: <ListIles />
    },
    //attribution centre interet
    {
        path: '/admin/attribution',
        element: <Attribution />
    },
    //accueil
    {
        path: '/nosybe/accueil',
        element: <Accueil />
    },
    {
        path: '/nosybe',
        element: <Accueil />
    },
    //liste utilisateur
    {
        path: '/admin/entreprises',
        element: <AdminEntreprise></AdminEntreprise>
    },

    //other
    {
        path: '/admin/clients',
        element: <AdminClient></AdminClient>
    },
    {
        path: 'admin/locations/marques',
        element: <ListeMarqueComponent></ListeMarqueComponent>
    },
    {
        path: 'admin/locations/marques/changement',
        element: <ListeDemandeComponent></ListeDemandeComponent>
    },
    {
        path: 'admin/locations/demande/categories',
        element: <ListeDemandeCategoriePage></ListeDemandeCategoriePage>
    },
    {
        path: 'admin/locations/demande/models',
        element: <ListeDemandeModel></ListeDemandeModel>
    },
    // {
    //     path: '/admin/entreprises',
    //     element: <AdminClient></AdminClient>
    // },

    // reparation
    ...routesRepairAdmin,

    //piece
    ...routesPieceAdmin,
]

export default routesAdmin;
