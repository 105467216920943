import React from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useState } from 'react';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListeTypeReparationEntrepriseComponent = () => {

    const token = localStorage.getItem("token");

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [typereparations, setTypeReparation] = useState(null);

    function getTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTypeReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTypeReparation();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modals
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [modifTypeReparation, setModifTypeReparation] = useState(null);
    const [modifNomTypeReparation, setModifNomTypeReparation] = useState('');

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => {
        setOpenModalDelete(false);
    }

    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
    }

    function displayModalDelete(typereparation) {
        setModifTypeReparation(typereparation);
        setOpenModalDelete(true);
    }

    function supprimerTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations/${modifTypeReparation.id}`,
            method: 'DELETE'
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getTypeReparation();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalDelete();
    }

    function displayModalEdit(typereparation) {
        setModifTypeReparation(typereparation);
        setModifNomTypeReparation(typereparation.typereparation.nom);
        setOpenModalEdit(true);
    }

    function modifierTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations/${modifTypeReparation.idtypereparation}`,
            method: 'PUT',
            data: {
                changement: modifNomTypeReparation
            }
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getTypeReparation();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalEdit();
    }

    return (
        <>
            <SimpleCard title={'Vos types de reparation'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {
                        typereparations ?
                            <>
                                <StyledTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Nom</TableCell>
                                            <TableCell align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {typereparations
                                            .filter((type) => type.typereparation.nom.toLowerCase().includes(recherche))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((type, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Icon>settings</Icon>
                                                        {type.typereparation.nom}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton onClick={() => displayModalEdit(type)} color='success'>
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                        <IconButton onClick={() => displayModalDelete(type)} color='error'>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </StyledTable>
                            </>
                            :
                            <>
                                <center>
                                    <CircularProgress></CircularProgress>
                                </center>
                            </>
                    }


                    <TablePagination
                        sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={typereparations ? typereparations.length : 0}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[2, 5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </Box>
            </SimpleCard>

            {/* modal edit */}
            {modifTypeReparation &&
                <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                        Modifier {modifTypeReparation.typereparation.nom}
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography variant='subtitle1' gutterBottom>
                            Votre demande de changement va être envoyée aux administrateurs.
                        </Typography>

                        <TextField
                            value={modifNomTypeReparation}
                            onChange={(e) => setModifNomTypeReparation(e.target.value)}
                            name='nom'
                            size='small'
                            sx={{ mt: 2, mb: 2 }}
                            fullWidth
                            label='Nom'
                            variant='outlined'
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={modifierTypeReparation} color='success'>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}

            {/* modal delete */}
            {modifTypeReparation &&
                <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                        Retirer {modifTypeReparation.typereparation.nom}
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Vous en etes sur?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={supprimerTypeReparation} color='error'>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}

            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ListeTypeReparationEntrepriseComponent;