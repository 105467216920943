import { Alert, Autocomplete, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, DialogActions, Fab, Grid, Icon, IconButton, Snackbar, TextField, Typography, styled } from "@mui/material";
import url from "app/UrlConfig";
import { SimpleCard } from "app/components";
import { useEffect } from "react";
import { useState } from "react";
import ModalGenerer from "../utils/ModalGenerer";
import { Fragment } from "react";


const AutoComplete = styled(Autocomplete)(() => ({
    width: 300
}));

const ListeModelVoiture = () => {

    const [recherche, setRecherche] = useState('');


    /*  SNACKNAR * */
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("entreprise")))

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    /*  SNACKNAR * */
    const [modelUpdate, setModelUpdate] = useState();
    const [modelDelete, setModelDelete] = useState();
    const [relance, setRelance] = useState(0);
    const [marques, setMarques] = useState([]);
    const [categories, setCategories] = useState([]);
    const [demandeModel, setDemandeModel] = useState([]);
    const [modelAjout, setModelAjout] = useState({
        id: 0,
        idmarque: 0,
        id_categorie_vehicule: 0,
        model: '',
        places: 0,
        consommation: 0,
        consommation_ville: 0
    });
    const [typeAction, setTypeAction] = useState('Ajout');
    const [showModalUpdate, setShowModalUpdate] = useState(false);

    const handleChange = (e) => {
        if (typeAction === "Ajout") {
            setModelAjout({ ...modelAjout, [e.target.name]: e.target.value });
        } if (typeAction === "UPDATE") {
            setModelUpdate({ ...modelUpdate, [e.target.name]: e.target.value });
        }
    }

    /*const handleChange = (e) => {
        setModelAjout({ ...modelAjout, [e.target.name]: e.target.value });
    }*/

    const getDemandeModel = () => {
        const token = localStorage.getItem("token");
        fetch(url["url-api"] + "admin/model-vehicule", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data)
                setDemandeModel(data);
            })
            .catch(error => console.log(error))
    }

    const getMarque = () => {

        const token = localStorage.getItem("token");
        console.log(token)
        fetch(url["url-api"] + "admin/marqueActif", {
            method: "GET",
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    console.log(data.data)
                    setMarques(data.data);
                }
            })
            .catch(error => console.log(error))
    }

    const getCategorie = () => {
        fetch(url["url-api"] + "admin/categories-vehicules", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(reponse => reponse.json())
            .then(data => {
                setCategories(data);
            })
            .catch(error => console.log(error))
    }

    const OpenModalAjout = () => {
        setShowModalUpdate(true);
    }

    const CloseModalAjout = () => {
        setShowModalUpdate(false)
    }

    const OpenModalUpdateDelete = (model, type) => {
        console.log(model);
        setTypeAction(type);
        if (type === "UPDATE") {
            setModelUpdate(model);
        } if (type === "DELETE") {
            setModelDelete(model);
        }
        OpenModalAjout();
    }


    const UpdateModel = () => {
        // console.log(modelUpdate)
        // setModelAjout(modelUpdate);
        fetch(url["url-api"] + "admin/demande-model", {
            method: 'POST',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(modelUpdate)
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data);
                if (data.status == 200) {
                    setMessage("Votre demande de modification du Model est bien envoyé");
                    setSeverity("success");
                    setOpenSnackbar(true)
                    setRelance(relance + 1)
                    CloseModalAjout();
                } else {
                    setMessage("Une Erreur est Survenue lors du demande");
                    setSeverity("error");
                    setOpenSnackbar(true)
                    setRelance(relance + 1)
                    CloseModalAjout();
                }

            })
            .catch(error => console.log(error))

    }

    const AjoutModel = () => {
        console.log(modelAjout);
        fetch(url["url-api"] + "admin/demande-model", {
            method: 'POST',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(modelAjout)
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data);
                if (data.status == 200) {
                    setMessage("Votre demande d'ajout une nouvelle Model est bien envoyé");
                    setSeverity("success");
                    setOpenSnackbar(true)
                    setRelance(relance + 1)
                    CloseModalAjout();
                } else {
                    setMessage("Une Erreur est Survenue lors du demande");
                    setSeverity("error");
                    setOpenSnackbar(true)
                    setRelance(relance + 1)
                    CloseModalAjout();
                }

            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDemandeModel(11);
        getMarque();
        getCategorie();
    }, [relance])


    const handleAutocompleteChange = (event, newValue, type) => {
        console.log(newValue);
        console.log(type)
        if (newValue !== null) {
            if (typeAction === "Ajout") {
                if (type === 'marque') {
                    setModelAjout({ ...modelAjout, ['idmarque']: newValue.id });
                } else {
                    setModelAjout({ ...modelAjout, ['id_categorie_vehicule']: newValue.id });
                }
            }
            if (typeAction === "UPDATE") {
                if (type === 'marque') {
                    setModelUpdate({ ...modelUpdate, ['idmarque']: newValue.id });
                } else {
                    setModelUpdate({ ...modelUpdate, ['id_categorie_vehicule']: newValue.id });
                }
            }
        }
    };

    const getMarquesComplete = () => {
        getMarque();
    }




    return (
        <div>
            <SimpleCard>
                <Fab onClick={() => OpenModalUpdateDelete(modelAjout, "Ajout")} color="primary" aria-label="Add" className="button">
                    <Icon>add</Icon>
                </Fab>
                <Box sx={{ py: '12px' }} />
                Ajout Nouveau Model
                <Box sx={{ py: '12px' }} />
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
            </SimpleCard>
            <Box width={'100%'} sx={{ py: '12px' }}>
                <Grid container spacing={3}>

                    {demandeModel
                        // .filter((model) =>
                        //     model.marque_model.toLowerCase().includes(recherche)
                        //     // || model.marque_model.toLowerCase().includes(recherche)
                        // )
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((model, index) =>
                            <Grid item lg={4} md={4} key={index}>
                                <Card>
                                    {/* <CardHeader
                                        avatar={
                                            <Avatar src={model.entreprise.logo} alt='' />
                                        }
                                        title={`${model.entreprise.nom}`}
                                        subheader={`${model.entreprise.email}`}
                                    /> */}
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Model :</Typography>
                                                <Typography gutterBottom variant='subtitle1'>Marque :</Typography>
                                                <Typography gutterBottom variant='subtitle1'>Categorie :</Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='body1'>{model.model}</Typography>
                                                <Typography gutterBottom variant='body1'>{model.marquevoiture.marque}</Typography>
                                                <Typography gutterBottom variant='body1'>{model.categorie_voiture.categorie_voiture}</Typography>

                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardActions sx={{ justifyContent: 'right' }}>
                                        <IconButton onClick={() => OpenModalUpdateDelete(model, "UPDATE")} >
                                            <Icon color="success">edit</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => OpenModalUpdateDelete(model, "DELETE")} >
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>
            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                {typeAction === "Ajout" && (
                    <SimpleCard title={'Ajout d\'une nouvelle Model'}>
                        <Fragment>
                            <AutoComplete
                                options={marques}
                                getOptionLabel={(option) => option.marque}
                                onClick={getMarquesComplete}
                                onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'marque')}
                                size="small"
                                renderInput={(params) => (
                                    <TextField {...params} label="marque" variant="outlined" fullWidth />
                                )}
                            />
                        </Fragment>
                        <Box sx={{ py: '12px' }} />
                        <Fragment>
                            <AutoComplete
                                options={categories}
                                getOptionLabel={(option) => option.categorie_voiture}
                                onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'categorie')}
                                size="small"
                                width="50px"
                                renderInput={(params) => (
                                    <TextField {...params} label="categorie" variant="outlined" fullWidth />
                                )}
                            />
                        </Fragment>
                        <Box sx={{ py: '12px' }} />
                        <TextField
                            fullWidth
                            size='small'
                            name="model"
                            type="text"
                            label="Model"
                            value={modelAjout.model}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="places"
                            type="text"
                            label="Places"
                            value={modelAjout.places}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="consommation"
                            type="text"
                            label="Consommation"
                            value={modelAjout.consommation}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="consommation_ville"
                            type="text"
                            label="Consommation Ville"
                            value={modelAjout.consommation_ville}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={AjoutModel}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "UPDATE" && (
                    <SimpleCard title={'Modifier d\'une Model'}>
                        <Fragment>
                            <AutoComplete
                                options={marques}
                                getOptionLabel={(option) => option.marque}
                                onClick={getMarquesComplete}
                                defaultValue={modelUpdate.marquevoiture}

                                onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'marque')}
                                size="small"
                                renderInput={(params) => (
                                    <TextField {...params} label="marque" variant="outlined" fullWidth />
                                )}
                            />

                        </Fragment>
                        <Box sx={{ py: '12px' }} />
                        <Fragment>
                            <AutoComplete
                                options={categories}
                                getOptionLabel={(option) => option.categorie_voiture}
                                onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'categorie')}
                                defaultValue={modelUpdate.categorie_voiture}
                                size="small"
                                width="50px"
                                renderInput={(params) => (
                                    <TextField {...params} label="categorie" variant="outlined" fullWidth />
                                )}
                            />
                        </Fragment>
                        <Box sx={{ py: '12px' }} />
                        <TextField
                            fullWidth
                            size='small'
                            name="model"
                            type="text"
                            label="Model"
                            value={modelUpdate.model}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="places"
                            type="text"
                            label="Places"
                            value={modelUpdate.places}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="consommation"
                            type="text"
                            label="Consommation"
                            value={modelUpdate.consommation}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="consommation_ville"
                            type="text"
                            label="Consommation Ville"
                            value={modelUpdate.consommation_ville}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={UpdateModel}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "DELETE" && (
                    <div>
                        <h1>Confirmation suppression</h1>
                        <h3>
                            Vous etes sure de Supprimer le model
                        </h3>
                        <DialogActions>
                            <Button onClick={CloseModalAjout}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </div>
                )}

            </ModalGenerer>
            {/* MODAL UPDATE */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>

    );


}

export default ListeModelVoiture;