import url from "app/UrlConfig"
import myConfig from "app/config/api-config"
import axios from "axios"

export const changeAdminPassword = (payload) => {
    return axios.put(`${myConfig.host}/api/admin/changePassword`, payload, {

    })
}

export const updateAdminInfo = (payload) => {
    return axios.put(`${myConfig.host}/api/admin/updateInfo`, payload, {

    })
}

export const connexionAdmin = (payload) => {
    return axios.post(`${myConfig.host}/api/admin/loginJWT`, payload, {

    })
}

export const inscriptionAdmin = (payload) => {
    return axios.post(`${url["url-api"]}admin/inscription`, payload, {

    })
}

export const changeAdminState = (payload) => {
    return axios.post(`${myConfig.host}/api/superadmin/admin/changeState`, payload, {

    })
}

export const getListAdmin = () => {
    return axios.get(`${myConfig.host}/api/superadmin/admin`, {

    })
}

export const getAdminConnected = () => {
    return JSON.parse(localStorage.getItem('admin'));
}