import React from 'react';
import { Breadcrumb, SimpleCard } from 'app/components';
import {
    Box,
    Button,
    Card,
    Grid,
    Icon,
    TextField,
    Typography,
    styled
} from "@mui/material";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import divStyle from 'app/myComponents/Repair/divStyle';
import imgStyle from 'app/myComponents/Repair/imgStyle';
import { useDropzone } from 'react-dropzone';

const StyledButton = styled(Button)(({ theme }) => ({
    // margin: theme.spacing(1),
}));

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AjoutEtape = () => {
    document.title = "AJOUT-ETAPE";

    const [formData, setFormData] = useState({
        nom: 'Nouvelle Etape',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi debitis ipsum fugiat deserunt facere doloremque cupiditate aspernatur libero molestiae aut itaque eaque nisi inventore asperiores voluptates nihil, vitae ea nam, voluptatem aperiam ratione facilis. Voluptatum enim, sapiente assumenda necessitatibus impedit temporibus qui adipisci nam accusantium, quisquam quidem voluptate consequuntur provident?',
        image: '',
        idreparation: 0
    });

    function handleChange(e) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    // drop zone
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setFormData({ ...formData, image: base64 });
            };
            reader.readAsDataURL(file);
        }

    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    // 

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Etapes', path: '/entreprise/reparation/etapes/liste' }, { name: 'Creation' }]}
                />
            </Box>

            <SimpleCard title={'Creation d\'une nouvelle etape'}>
                <Box width="100%" overflow="auto">

                    <TextField
                        fullWidth
                        size='small'
                        name="nom"
                        type="text"
                        label="Nom"
                        value={formData.nom}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='medium'
                        name="description"
                        type="text"
                        label="Description"
                        value={formData.description}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                        multiline
                    />

                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div
                                {...getRootProps()}
                                style={{
                                    width: '100%',
                                    minHeight: '200px',
                                    padding: '20px',
                                    border: '2px dashed #cccccc',
                                    textAlign: 'center',
                                    // margin: '20px 0',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: 'lightgray',
                                }}
                                className="dropzone"
                            >
                                <input {...getInputProps()} />
                                <Icon fontSize="large">cloud_upload</Icon>
                                <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                    Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {formData.image &&
                                <>
                                    <Card sx={{ width: '100%' }}>
                                        <div style={{
                                            ...divStyle
                                        }}>
                                            <img style={imgStyle} src={formData.image} alt="" />
                                        </div>
                                    </Card>
                                    <br />

                                </>
                            }
                        </Grid>
                    </Grid>

                    <br />

                    {formData.nom !== '' &&
                        <>
                            <Link to={'/entreprise/reparation/etapes/ajout/affectation'} state={{ etape: formData }}>
                                <StyledButton fullWidth variant="contained" component="span">
                                    Suivant
                                </StyledButton>
                            </Link>
                        </>
                    }
                </Box>
            </SimpleCard >
        </AppButtonRoot>
    );
};

export default AjoutEtape;