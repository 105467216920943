import { Box, styled } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import DemandeLocationComponent from "app/myComponents/Location/Locations/DemandeLocation";
import ListeVehiculeComponent from "app/myComponents/Location/Vehicule/ListeVehicule";
import CalendrierAgenda from "app/myComponents/Location/utils/CalendrierAgenda";

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const PageCalendrier = () => {
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Table" }]} />
            </Box>
            {/* <SimpleCard title="Liste Vehicules"> */}
            <CalendrierAgenda></CalendrierAgenda>
            {/* </SimpleCard> */}
        </Container>
    );
};

export default PageCalendrier;
