import React from 'react';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Fragment } from 'react';
import CardMobile from './CardMobile';
import publications from './Publication'
import PublicationClient from './PublicationClient';
import { getPublications } from './ClientApropos';


const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const AutoComplete = styled(Autocomplete)(() => ({
    width: 300
    // marginBottom: '16px',
}));

const suggestions = [
    { id: 1, marque: "Toyota" },
    { id: 3, marque: "Renault" },
    { id: 4, marque: "Mercedes" },
    { id: 5, marque: "Ford" },
    { id: 6, marque: "BMW" },
    { id: 8, marque: "ISUZU" }
];

const ListePublicationClientComponent = () => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [publication, setPublication] = useState([]);
    const [publications, setPublications] = useState([]);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [reparation, setReparation] = useState({});
    function handleChange(e) {
        setReparation({ ...reparation, [e.target.name]: e.target.value });
    }


    const getPublicationEntreprise = () => {
        getPublications(setPublications)
    }


    // modal fiche



    const [modeleVs, setModels] = useState([]);

    const categories = [
        { "id": 1, "categorie": "4x4" },
        { "id": 2, "categorie": "Plaisir" },
        { "id": 3, "categorie": "Familial" }
    ]

    useEffect(() => {
        setModels(suggestions)
        setPublication(publications)
        getPublicationEntreprise();
    }, [])

    const handleAutocompleteChange = (event, newValue) => {
        // setSelectedOption(newValue);
        // alert(newValue.id)
        // veh.id_model = newValue.id;
    };





    return (

        <Grid container spacing={1}>
            <center>
                <h1>Suggestion pour vous</h1>
            </center>
            <Box sx={{ py: '12px' }} />
            <Grid item lg={12} md={12}>
                <SimpleCard title={'Suggestion pour vous'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Categorie</TableCell>
                            <TableCell align="center">Date Min</TableCell>
                            <TableCell align="center">Date Max</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover>

                            <TableCell align="center">
                                <Select
                                    size="small"
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    name='id'
                                    value={reparation.idtypereparation !== 0 ? reparation.idtypereparation : ''}
                                    onChange={handleChange}
                                    autoWidth
                                    label="Categorie Vehicules"
                                >
                                    {categories.map((categorie, index) => (
                                        <MenuItem key={index} value={categorie.id}>{categorie.categorie}</MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    size="small"
                                    type="date"
                                    name="dateMin"

                                />

                            </TableCell>
                            <TableCell align="center">
                                <TextField
                                    size="small"
                                    type="date"
                                    name="dateMax"

                                />

                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <Box sx={{ py: '12px' }} />
                    <Box width="100%" overflow="auto">
                        {publications
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((publication, index) => (
                                // <TableRow hover key={index}>
                                <div key={index}>
                                    <PublicationClient publication={publication}></PublicationClient>
                                    <Box sx={{ py: '12px' }} />
                                </div>

                            ))}
                        <TablePagination
                            sx={{ px: 2 }}
                            page={page}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            count={publication.length}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[5, 10, 25]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                        />
                    </Box>
                </SimpleCard>
            </Grid>
        </Grid >


    );
};

export default ListePublicationClientComponent;