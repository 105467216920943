import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TablePagination, TextField, Typography } from '@mui/material';
import { SimpleCard } from 'app/components';
import { getSousCategoriesChangements } from 'app/data/piece/changement';
import { useEffect } from 'react';
import { useState } from 'react';

const SousCategorieChangementComponent = () => {
    const [sousCategorieChangements, setSousCategorieChangements] = useState([]);

    const [changement, setChangement] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalMock, setOpenModalMock] = useState(false);

    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    //page handling
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //mock
    const handleCloseModalMock = () => setOpenModalMock(false);
    const displayModalMock = () => {
        setOpenModalMock(true);
    }

    // modal
    const handleCloseModal = () => setOpenModal(false);
    const displayModal = (changement) => {
        setChangement(changement);
        setOpenModal(true);
    }
    const validerChangement = () => {
        handleCloseModal();
    }


    useEffect(() => {
        setSousCategorieChangements(getSousCategoriesChangements());
    }, [])

    return (
        <>
            <SimpleCard title={'Les Changements de categorie par les entreprises'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />

                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={sousCategorieChangements.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box width={'100%'} sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                    {sousCategorieChangements
                        .filter((sousCategorieChangement) =>
                            sousCategorieChangement.entreprise.nom.toLowerCase().includes(recherche) ||
                            sousCategorieChangement.nom.toLowerCase().includes(recherche)
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((sousCategorieChangement, index) =>
                            <Grid item lg={4} md={4} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar src={sousCategorieChangement.entreprise.logo} alt='' />
                                        }
                                        title={`${sousCategorieChangement.entreprise.nom}`}
                                        subheader={`${sousCategorieChangement.entreprise.email}`}
                                    />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Actuel :</Typography>
                                                {sousCategorieChangement.souscategorie &&
                                                    <>
                                                        <Typography variant='body1'>{sousCategorieChangement.souscategorie.categorie.nom}</Typography>
                                                        <Typography variant='body1'>{sousCategorieChangement.souscategorie.nom}</Typography>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Changement :</Typography>
                                                <Typography gutterBottom variant='body1'>{sousCategorieChangement.categorie.nom}</Typography>
                                                <Typography gutterBottom variant='body1'>{sousCategorieChangement.nom}</Typography>
                                                <Typography gutterBottom variant='body1'>Le : {sousCategorieChangement.date}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardActions sx={{ justifyContent: 'right' }}>
                                        <IconButton onClick={() => displayModalMock()}>
                                            <Icon color='primary'>info</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>

            {openModalMock &&
                <Dialog fullWidth={true} maxWidth='md' onClose={handleCloseModalMock} aria-labelledby="customized-dialog-title" open={openModalMock}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalMock}>
                        Details de changement
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid container spacing={3} sx={{
                            maxHeight: '600px',
                            overflowY: 'auto'
                        }}>
                            <Grid item sm={6}>
                                <Typography variant='h5'>Actuel</Typography>
                                <Typography variant='h6'>Plaquettes de frein</Typography>
                                <div>
                                    Caracteristiques:
                                    <ul>
                                        <li>Epaisseur (nombre)</li>
                                        <li>Longueur [mm] (nombre)</li>
                                        <li>Cote d'assemblage (choix)
                                            <ul>
                                                <li>Essieu avant</li>
                                                <li>Essieu arriere</li>
                                            </ul>
                                        </li>
                                        <li>Etat (choix)
                                            <ul>
                                                <li>Neuf</li>
                                                <li>Occasion</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant='h5'>Changements</Typography>
                                <Typography variant='h6'>Jeux de Plaquettes</Typography>
                                <div>
                                    Caracteristiques:
                                    <ul>
                                        <li>Epaisseur (nombre)</li>
                                        <li>Longueur [mm] (nombre)</li>
                                        <li><b>Diametre [mm] (nombre)</b></li>
                                        <li>Cote d'assemblage (choix)
                                            <ul>
                                                <li>Essieu avant</li>
                                                <li>Essieu arriere</li>
                                                <li><b>Avant/Arriere</b></li>
                                            </ul>
                                        </li>
                                        <li>Etat (choix)
                                            <ul>
                                                <li>Neuf</li>
                                                <li>Occasion</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button color='error'>
                            Rejeter
                        </Button>
                        <Button color='success'>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }

            {changement &&
                <>
                    {/* modal delete */}
                    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                            Validation de changement
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur de valider le changement de {changement.entreprise.nom} le {changement.date}?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={validerChangement} color='success'>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}
                </>
            }
        </>
    )
}
export default SousCategorieChangementComponent;