import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, styled } from '@mui/material';
import { red } from '@mui/material/colors';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ListeReparationComponent from '../Reparation/ListeReparationComponent';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';
import getPrixRepair from 'app/config/Repair/getPrixRepair';
// 

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: 2
}));

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ActualiteReponseComponent = (publication) => {

    const token = localStorage.getItem("token");

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const navigate = useNavigate();

    const actualite = publication.publication;

    // tarifs
    const [tarifs, setTarif] = useState(null);

    function getTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTarif(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTarif();
    }, []);

    const [reponse, setReponse] = useState({
        idPublication: actualite.id,
        description: 'Bonjour, suite a votre publication nous avons le plaisir de vous offrir nos services avec notamment les reparations suivantes',
        reparations: []
    });

    function handleChange(e) {
        setReponse({ ...reponse, [e.target.name]: e.target.value });
    }

    const handleReparationChange = (reparation) => {
        const tabReparation = [...reponse.reparations];

        const isReparationAlreadyInArray = tabReparation.some(item => item.id === reparation.id);

        if (!isReparationAlreadyInArray) {
            tabReparation.push(reparation);
        }

        setReponse({ ...reponse, reparations: tabReparation });
    }

    function removeReparation(reparation) {
        const tabReparation = [...reponse.reparations];

        const updatedTabReparation = tabReparation.filter(item => item.id !== reparation.id);

        setReponse({ ...reponse, reparations: updatedTabReparation });
    }

    function calculPrixTotal() {
        let result = 0;

        for (let index = 0; index < reponse.reparations.length; index++) {
            const element = reponse.reparations[index];
            result += parseInt(element.prix);
        }

        return result;
    }

    const total = useMemo(() =>
        calculPrixTotal()
        , [reponse.reparations])


    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // envoyer reponse
    function envoyerReponse() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/publications/envoiSuggestion`,
            method: 'POST',
            data: reponse
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                navigate('/entreprise/reparation/publications/suggestions');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
    }

    return (
        <>
            <SimpleCard title={'Reponse'}>

                <Grid container spacing={3}>

                    <Grid item lg={6} md={6} xs={12}>
                        <Box width="100%" overflow="auto">

                            <Card sx={{ mb: 3 }}>
                                <CardHeader
                                    avatar={
                                        <>
                                            {actualite.client.photoprofil ?
                                                <>
                                                    <Avatar src={actualite.client.photoprofil} />
                                                </>
                                                :
                                                <>
                                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                        {actualite.client.nom.charAt(0)}{actualite.client.prenom.charAt(0)}
                                                    </Avatar>
                                                </>
                                            }
                                        </>
                                    }
                                    title={`${actualite.client.nom} ${actualite.client.prenom}`}
                                    subheader={`${actualite.date}`}
                                />
                                <Swiper
                                    spaceBetween={1}
                                    slidesPerView={1}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                >
                                    {actualite.publicationimages.map((publicationimg, index) => (
                                        <SwiperSlide key={index}>
                                            <div
                                                style={divStyle}
                                            >
                                                <img
                                                    src={publicationimg.img}
                                                    style={imgStyle}
                                                    alt="" />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                                <CardContent>
                                    <Typography variant="body2">
                                        {actualite.description}
                                    </Typography>
                                </CardContent>
                            </Card>

                        </Box>

                    </Grid>

                    <Grid item lg={6} md={6} xs={12}>
                        <TextField
                            fullWidth
                            size="medium"
                            name="description"
                            type="text"
                            label="Description"
                            variant="outlined"
                            value={reponse.description}
                            onChange={handleChange}
                            sx={{ mt: 1.5, mb: 2 }}
                            multiline
                        />

                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">Nom</TableCell>
                                    <TableCell align="center">Prix</TableCell>
                                    <TableCell align="center">Duree</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reponse.reparations
                                    .map((reparation, index) => (
                                        <TableRow hover key={index}>

                                            <TableCell align='center'>
                                                {reparation.images &&
                                                    reparation.images.length !== 0 &&
                                                    <center>
                                                        <Avatar src={reparation.images[0].img} />
                                                    </center>
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2">
                                                    {reparation.nom}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">Ar {reparation.prix}</TableCell>
                                            <TableCell align="center">{reparation.duree}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => removeReparation(reparation)} color='error'>
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {(tarifs && tarifs.length !== 0) &&
                                    <>
                                        {tarifs.map((tarif, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell align="center">TOTAL</TableCell>
                                                <TableCell align="center">Marge</TableCell>
                                                <TableCell align="center">{tarif.typedemande.nom}</TableCell>
                                                <TableCell align="center" >{tarif.valeur} %</TableCell>
                                                {/* <TableCell align="right" >Ar {total * ((parseInt(tarif.valeur)) / 100)}</TableCell> */}
                                                <TableCell align="right" >Ar {getPrixRepair(total, tarif.valeur)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                }
                            </TableBody>
                        </StyledTable>

                        {(tarifs) ?
                            <>
                                {tarifs.length === 0 &&
                                    <>
                                        <center>
                                            <Link to={'/entreprise/reparation/tarifs/ajout'}>
                                                <Button color='primary'>
                                                    Ajouter des nouveaux tarifs
                                                </Button>
                                            </Link>
                                        </center>
                                    </>
                                }
                            </>
                            :
                            <>
                                <center>
                                    <CircularProgress></CircularProgress>
                                </center>
                            </>
                        }

                        {
                            reponse.reparations.length !== 0 && tarifs.length !== 0 &&
                            <>
                                <StyledButton onClick={envoyerReponse} fullWidth color='success' variant="outlined">
                                    <Icon>telegram</Icon> Envoyer
                                </StyledButton>
                            </>
                        }

                    </Grid>

                </Grid>

            </SimpleCard>

            <br />

            <ListeReparationComponent proposition={handleReparationChange}></ListeReparationComponent>

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}

        </>

    );
};

export default ActualiteReponseComponent;