import url from "app/UrlConfig"
import { useState } from "react"
// const [token, setToken] = useState(JSON.parse(localStorage.getItem("entreprise")))
export const getReservation = (identreprise, setReservation) => {
    const token = localStorage.getItem("token")
    fetch(url["url-api"] + "admin/reservations/" + identreprise, {
        method: 'GET',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        }
    })
        .then(reponse => reponse.json())
        .then(data => {
            if (data.status === 200) {
                console.log(data)
                setReservation(data.data);
            }
        })
        .catch(error => console.log(error))
}

export const getReservationListe = (setReservation) => {
    const token = localStorage.getItem("token")
    fetch(url["url-api"] + "admin/reservations-liste", {
        method: 'GET',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        }
    })
        .then(reponse => reponse.json())
        .then(data => {
            if (data.status === 200) {
                console.log(data)
                setReservation(data.data);
            }
        })
        .catch(error => console.log(error))
}

export const ValiderReservation = async (data, etat) => {
    const token = localStorage.getItem("token")
    console.log(JSON.stringify(data))
    console.log(url["url-api"] + "admin/validation-reservation/" + etat)
    fetch(url["url-api"] + "admin/validation-reservation/" + etat, {
        method: 'POST',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(reponse => reponse.json())
        .then(dat => {
            console.log(dat)
            return dat;
        })
        .catch(error => console.log(error))
}

export const getChauffeur = (identreprise, setChauffeur) => {
    const token = localStorage.getItem("token")
    fetch(url["url-api"] + "admin/chauffeurs/1", {
        method: 'GET',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        }
    })
        .then(reponse => reponse.json())
        .then(data => {
            if (data.status === 200) {
                setChauffeur(data.data);
            }
        })
        .catch(error => console.log(error))
}

export const getPublications = (setPublication) => {
    const token = localStorage.getItem("token")
    console.log(url["url-api"] + "admin/publications")
    fetch(url["url-api"] + "admin/publications", {
        method: 'GET',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        }
    })
        .then(reponse => reponse.json())
        .then(data => {
            console.log(data)
            if (data.status === 200) {
                setPublication(data.data);
            }
        })
        .catch(error => console.log(error))
}

export const getVehiculeProposer = (data, setVehiculeProposer, identreprise) => {
    const token = localStorage.getItem("token")
    console.log(url["url-api"] + "admin/vehicule-proposer/" + identreprise, JSON.stringify(data))
    fetch(url["url-api"] + "admin/vehicule-proposer/" + identreprise, {
        method: 'POST',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(reponse => reponse.json())
        .then(data => {
            console.log(data);
            if (data.status === 200) {
                setVehiculeProposer(data.data);
            }
        })
        .catch(error => console.log(error))
}

export const SaveReponse = (data, identreprise, setShowModal, setMessage, setSevelity) => {
    const token = localStorage.getItem("token");
    fetch(url["url-api"] + "admin/repondre/" + identreprise, {
        method: 'POST',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(reponse => reponse.json())
        .then(data => {
            console.log(data)
            if (data.status === 200) {
                setShowModal(true)
                setMessage("Reponse bien envoyé")
                setSevelity("success");
            } else {
                setShowModal(true)
                setMessage("Erreur survenue")
                setSevelity("error");
            }
        })
        .catch(error => console.log(error))
}