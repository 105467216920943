import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Snackbar, Typography, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import myConfig from 'app/config/api-config';
import divStyle from 'app/myComponents/Repair/divStyle';
import imgStyle from 'app/myComponents/Repair/imgStyle';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListeReparationComponent from 'app/myComponents/Repair/Reparation/ListeReparationComponent';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AffectationEtape = () => {

    document.title = "AFFECTATION-ETAPE";

    const location = useLocation();
    const [etape, setEtape] = useState(location.state.etape);
    const navigate = useNavigate();

    const [modalReparation, setModalReparation] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        if (!etape.updateEtape) {
            setEtape({ ...etape, idreparation: 0 });
        }
        setOpenModal(false);
    };

    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    function affecter(reparation) {
        setEtape({ ...etape, idreparation: reparation.id });
        setModalReparation(reparation);
        setOpenModal(true);
    }

    function terminerEtapeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/etapes`,
            method: 'POST',
            data: etape
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                navigate('/entreprise/reparation/etapes/liste');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
                setMessage(response.data.message);
                setOpen(true);
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        if (openModal) {
            setOpenModal(false);
        }
    }

    // expand

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // 

    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Etapes', path: '/entreprise/reparation/etapes/liste' }, { name: 'Creation', path: '/entreprise/reparation/etapes/ajout' }, { name: 'Affectation' }]}
                    />
                </Box>

                <Card sx={{ width: '100%' }}>
                    {(etape.image || etape.img) &&
                        <>
                            <div style={{
                                ...divStyle,
                                height: 200
                            }}>
                                <img style={imgStyle} src={etape.image || etape.img} alt="" />
                            </div>
                        </>
                    }
                    <CardContent>

                        <Typography gutterBottom variant='h5'>
                            {etape.nom}
                            <br />
                            {etape.reparation &&
                                <>
                                    <span style={{ display: 'flex' }}>
                                        <Avatar alt={''} src={etape.reparation.images ? etape.reparation.images[0].img : ''} />
                                        {etape.reparation.nom}
                                    </span>

                                    <span>Ordre : {etape.ordre}</span>
                                </>
                            }
                        </Typography>

                    </CardContent>

                    <CardActions>
                        {etape.idreparation === 0 &&
                            <Button
                                onClick={terminerEtapeReparation}
                                variant='contained'
                                fullWidth
                                color='success'
                                sx={{ mt: 2, mb: 2 }}
                            >
                                <Icon>check</Icon>
                                Terminer
                            </Button>
                        }
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </CardActions>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>Description:</Typography>
                            <Typography gutterBottom variant='subtitle1'>
                                {etape.description}
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>

                <br />

                <ListeReparationComponent affectation={affecter}></ListeReparationComponent>

            </AppButtonRoot>

            {/* modal delete */}
            {modalReparation &&
                <>
                    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                            Affectation
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Affecter <b>{etape.nom}</b> à <b>{modalReparation.nom}</b>?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={terminerEtapeReparation}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
            {/*  */}

            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>

        </>
    );
};

export default AffectationEtape;