export const listEntreprise = [
    {
        id: 1,
        email: 'nosybelodge@yopmail.com',
        nom: 'NOSYBE-LODGE',
        nomRepresentant: 'Rakoto Jean',
        nif: 'xxxx',
        stat: 'xxxx',
        license: 'xxxx',
        adresse: 'lot 123 hellville',
        telephone: '+261341111111',
        logo: '/assets/images/Nosybe/hebergement_logo.png',
        etat: 0,
    },
    {
        id: 2,
        email: 'nosybefood@yopmail.com',
        nom: 'NOSYBE-FOOD',
        nomRepresentant: 'Rajean Koto',
        nif: 'xxxx',
        stat: 'xxxx',
        license: 'xxxx',
        adresse: 'lot 234 hellville',
        telephone: '+261342222222',
        logo: '/assets/images/Nosybe/restaurant_logo.webp',
        etat: 11,
    },
    {
        id: 3,
        email: 'nosybefun@yopmail.com',
        nom: 'NOSYBE-FUN',
        nomRepresentant: 'Rakoto Naivo',
        nif: 'xxxx',
        stat: 'xxxx',
        license: 'xxxx',
        adresse: 'lot 345 hellville',
        telephone: '+261343333333',
        logo: '/assets/images/Nosybe/loisirs_logo.jpg',
        etat: 21,
    },
];