import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const StyledButton = styled(Button)(({ theme }) => ({
    // margin: theme.spacing(1),
}));

const AjoutTarifComponent = () => {

    const [typeDemandes, setTypeDemande] = useState(null);

    function getTypeDemande() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typedemandes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTypeDemande(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTypeDemande();
    }, []);

    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [formData, setFormData] = useState({
        idtypedemande: 0,
        tarif: 10
    });

    function handleChange(e) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    function addTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes`,
            method: 'POST',
            data: formData
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 500) {
                setColorMessage('error');
                setMessage(response.data.message);
                setOpen(true);
            }
            if (response.data.code === 200) {
                setColorMessage('success');
                setMessage(response.data.message);
                setOpen(true);
                // navigate('/entreprise/reparation/tarifs/liste');
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
    }

    // modal
    const [tarif, setTarif] = useState({
        nomTarif: 'Province'
    });

    const [displayModal, setDisplayModal] = useState(false);
    const handleCloseModal = () => setDisplayModal(false);

    function handleTarifChange(e) {
        setTarif({ ...tarif, [e.target.name]: e.target.value });
    }

    function creerTarif() {

        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes/envoyer`,
            method: 'POST',
            data: tarif
        };
        console.log(requestOptions);
        axios(requestOptions).then((response) => {
            if (response.data.code === 500) {
                setColorMessage('error');
                setMessage(response.data.message);
                setOpen(true);
            }
            if (response.data.code === 200) {
                setColorMessage('success');
                setMessage(response.data.message);
                setOpen(true);
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })

        setDisplayModal(false);
    }

    return (
        <>
            <SimpleCard title={'Ajout de nouveau tarif'}>
                {
                    typeDemandes ?
                        <>
                            <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                                <InputLabel filled id="demo-simple-select-autowidth-label">Demande</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    name='idtypedemande'
                                    value={formData.idtypedemande !== 0 ? formData.idtypedemande : ''}
                                    onChange={handleChange}
                                    // autoWidth
                                    label="Demande"
                                >
                                    {typeDemandes.map((typeDemande, index) => (
                                        <MenuItem key={index} value={typeDemande.id}>{typeDemande.nom}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </> :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                }

                <TextField
                    fullWidth
                    size='small'
                    name="tarif"
                    type="number"
                    label="Tarif"
                    value={formData.tarif}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
                <Button sx={{ mb: 2 }} onClick={() => setDisplayModal(true)} >Creer un nouveau tarif</Button>
                {formData.idtypedemande !== 0 && formData.tarif >= 0 &&
                    <StyledButton onClick={addTarif} fullWidth variant="contained" component="span">
                        Ajout
                    </StyledButton>
                }

            </SimpleCard>

            {/* modal edit */}
            <Dialog maxWidth='md' onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={displayModal}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                    Creer
                </DialogTitle>

                <DialogContent dividers>
                    <TextField
                        fullWidth
                        size='small'
                        name="nomTarif"
                        type="text"
                        label="Nom"
                        value={tarif.nomTarif}
                        onChange={handleTarifChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                </DialogContent >

                <DialogActions>
                    <Button onClick={creerTarif}>
                        Envoyer demande
                    </Button>
                </DialogActions>
            </Dialog >
            {/*  */}

            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AjoutTarifComponent;
