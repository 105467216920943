import React from 'react';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Fragment } from 'react';
import ModalDetail from '../utils/ModalDetail';
import DetailVehicule from '../Vehicule/DetailVehicule';
import ModalDelete from '../utils/ModalDelete';
import CardMobile from './CardMobile';
import DetailDemande from './DetailDemande';
import ModalUpdate from '../utils/ModalUpdate';
import UpdateLocation from './UpdateLocation';
import ComponentFiltre from '../Vehicule/ComponentFiltre';
import { getReservation } from './ClientApropos';


const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const AutoComplete = styled(Autocomplete)(() => ({
    width: 300
    // marginBottom: '16px',
}));

const suggestions = [
    { id: 1, marque: "Toyota" },
    { id: 3, marque: "Renault" },
    { id: 4, marque: "Mercedes" },
    { id: 5, marque: "Ford" },
    { id: 6, marque: "BMW" },
    { id: 8, marque: "ISUZU" }
];

const DemandeLocationComponent = () => {

    const [reservations, setReservations] = useState([]);


    const getReservationAll = () => {
        getReservation(1, setReservations);
    }
    //     {
    //         id: 1,
    //         marque: "Toyota",
    //         model: "LC 300",
    //         categorie: "4x4",
    //         description: "Les disques de frein doivent être changés lorsque l’épaisseur minimale d’usure est atteinte, s’ils présentent des anomalies telles que des rayures ou des fissures, ou sur conseil de votre garagiste. Il est conseillé de faire contrôler les disques tous les 20 000 à 30 000 kilomètres selon votre véhicule.",
    //         prix: 10000000,
    //         immatriculation: "5425 TBC",
    //         places: 7,
    //         images: [
    //             'https://www.top-garage.fr/app/uploads/iStock-124202360.jpg',
    //             'https://d3t0tbmlie281e.cloudfront.net/igi/oscaro/jv3HIXLPTBOPbVSe.medium',
    //             'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHh4hCmABAevWMxubkOeUB9DPNxKEWCAboKut2U1QbpGkbhpdJXUwUxbNrEHiLjBhj5qM&usqp=CAU'
    //         ],
    //         Etattype: 10,
    //         "type": "Hors Carburant",
    //         datedebut: "02/05/2023",
    //         datefin: "03/05/2023",
    //         nombrejours: 2,
    //         client: {
    //             contact: "+261 34 66 607 45",
    //             nom: "RAKOTO1"
    //         },
    //         montant_total: 20000000
    //     },
    //     {
    //         id: 2,
    //         marque: "Renault",
    //         model: "Kangoo",
    //         categorie: "Plaisir",
    //         prix: 100000,
    //         immatriculation: "5425 TBN",
    //         places: 5,
    //         images: [
    //             'https://www.top-garage.fr/app/uploads/iStock-124202360.jpg',
    //             'https://d3t0tbmlie281e.cloudfront.net/igi/oscaro/jv3HIXLPTBOPbVSe.medium',
    //             'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHh4hCmABAevWMxubkOeUB9DPNxKEWCAboKut2U1QbpGkbhpdJXUwUxbNrEHiLjBhj5qM&usqp=CAU'
    //         ],
    //         description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum dolor, accusantium velit obcaecati fugiat, vel nam tempora vero ut fugit recusandae placeat aut sed quia quibusdam laborum aliquam temporibus laboriosam?",
    //         Etattype: 20,
    //         "type": "Avec Carburant",
    //         datedebut: "30/06/2023",
    //         datefin: "30/06/2023",
    //         nombrejours: 1,
    //         client: {
    //             contact: "+261 34 66 607 45",
    //             nom: "RAKOTO1"
    //         },
    //         montant_total: 20000000
    //     },
    //     {
    //         id: 3,
    //         marque: "Mercedes",
    //         model: "Sprinter",
    //         categorie: "Familial",
    //         prix: 100000,
    //         immatriculation: "0025 TBC",
    //         places: 32,
    //         images: [
    //             'https://www.top-garage.fr/app/uploads/iStock-124202360.jpg',
    //             'https://d3t0tbmlie281e.cloudfront.net/igi/oscaro/jv3HIXLPTBOPbVSe.medium',
    //             'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHh4hCmABAevWMxubkOeUB9DPNxKEWCAboKut2U1QbpGkbhpdJXUwUxbNrEHiLjBhj5qM&usqp=CAU'
    //         ],
    //         Etattype: 10,
    //         "type": "Hors Carburant",
    //         datedebut: "30/06/2023",
    //         datefin: "30/06/2023",
    //         nombrejours: 1,
    //         client: {
    //             contact: "+261 34 66 607 45",
    //             nom: "RAKOTO2"
    //         },
    //         montant_total: 20000000
    //     },
    //     {
    //         id: 4,
    //         marque: "Toyota",
    //         model: "RAV4",
    //         categorie: "4x4",
    //         prix: 200000,
    //         immatriculation: "85696 WWT",
    //         places: 6,
    //         images: [
    //             'https://www.top-garage.fr/app/uploads/iStock-124202360.jpg',
    //             'https://d3t0tbmlie281e.cloudfront.net/igi/oscaro/jv3HIXLPTBOPbVSe.medium',
    //             'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHh4hCmABAevWMxubkOeUB9DPNxKEWCAboKut2U1QbpGkbhpdJXUwUxbNrEHiLjBhj5qM&usqp=CAU'
    //         ],
    //         Etattype: 20,
    //         "type": "Avec Carburant",
    //         datedebut: "03/06/2023",
    //         datefin: "09/0/2023",
    //         nombrejours: 6,
    //         client: {
    //             contact: "+261 34 66 607 45",
    //             nom: "RAKOTO4"
    //         },
    //         montant_total: 20000000
    //     },
    //     {
    //         id: 5,
    //         marque: "ISUZU",
    //         model: "SUV",
    //         categorie: "BUS",
    //         prix: 300000,
    //         immatriculation: "5563 TBC",
    //         places: 42,
    //         images: [
    //             'https://www.top-garage.fr/app/uploads/iStock-124202360.jpg',
    //             'https://d3t0tbmlie281e.cloudfront.net/igi/oscaro/jv3HIXLPTBOPbVSe.medium',
    //             'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHh4hCmABAevWMxubkOeUB9DPNxKEWCAboKut2U1QbpGkbhpdJXUwUxbNrEHiLjBhj5qM&usqp=CAU'
    //         ],
    //         Etattype: 10,
    //         "type": "Hors Carburant",
    //         datedebut: "2023-02-03",
    //         datefin: "2023-02-06",
    //         nombrejours: 6,
    //         client: {
    //             contact: "+261 34 66 607 45",
    //             nom: "RAKOTO3"
    //         },
    //         montant_total: 20000000
    //     },
    // ];

    const [vehiculeUpdate, setVehiculeUpdate] = useState();

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [reparation, setReparation] = useState({});
    function handleChange(e) {
        setReparation({ ...reparation, [e.target.name]: e.target.value });
    }

    // modal delete
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    function displayDeleteModal(reparation) {
        setReparation(reparation);
        setOpenModalDelete(true);
    }

    function supprimerReparation() {
        handleCloseModalDelete();
    }

    // modal fiche
    const [openModalFiche, setOpenModalFiche] = useState(false);
    const handleCloseModalFiche = () => setOpenModalFiche(false);

    function displayFicheModal(reparation) {
        setReparation(reparation);
        setOpenModalFiche(true);
    }

    // modal edit
    const [openModalEdit, setOpenModalEdit] = useState(false);




    const [modeleVs, setModels] = useState([]);

    const categories = [
        { "id": 1, "categorie": "4x4" },
        { "id": 2, "categorie": "Plaisir" },
        { "id": 3, "categorie": "Familial" }
    ]

    const [relance, setRelance] = useState(0);

    useEffect(() => {
        setModels(suggestions)
        getReservationAll();
    }, [relance])

    const handleAutocompleteChange = (event, newValue, type) => {
        console.log(newValue);
        console.log(type)
        if (newValue !== null) {
            if (type === 'marque') {
                // setRechercheValue({ ...rechercheValue, ['marque']: newValue.id });
            } else {
                // setRechercheValue({ ...rechercheValue, ['categorie']: newValue.id });
            }
        }
        else {
            if (type === 'marque') {
                // setRechercheValue({ ...rechercheValue, ['marque']: 0 });
            } else {
                // setRechercheValue({ ...rechercheValue, ['categorie']: 0 });
            }

        }
    };

    return (

        <Grid container spacing={1}>

            <Box sx={{ py: '12px' }} />
            <Grid item lg={12} md={12}>
                <SimpleCard title={'Liste des Demandes Reservations'}>

                    <ComponentFiltre >
                        <Grid container spacing={6}>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }}>
                                <Fragment>
                                    <AutoComplete
                                        options={suggestions}
                                        getOptionLabel={(option) => option.marque}
                                        onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'marque')}
                                        size="small"
                                        renderInput={(params) => (
                                            <TextField {...params} label="marque" variant="outlined" fullWidth />
                                        )}
                                    />
                                </Fragment>
                                <Box sx={{ py: '12px' }} />


                                <Fragment>
                                    <AutoComplete
                                        options={categories}
                                        getOptionLabel={(option) => option.categorie}
                                        onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'categorie')}
                                        size="small"
                                        renderInput={(params) => (
                                            <TextField {...params} label="categorie" variant="outlined" fullWidth />
                                        )}
                                    />
                                </Fragment>
                                <Box sx={{ py: '12px' }} />
                                <TextField
                                    size="small"
                                    type="text"
                                    name="immatriculation"
                                    label="Immatriculation"
                                />
                                <Box sx={{ py: '12px' }} />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }}>
                                <InputLabel filled id="demo-simple-select-autowidth-label">Date debut</InputLabel>
                                <TextField
                                    size="small"
                                    type="date"
                                    name="dateMin"

                                />

                                <Box sx={{ py: '12px' }} />
                                <InputLabel filled id="demo-simple-select-autowidth-label">Date fin</InputLabel>
                                <TextField
                                    size="small"
                                    type="date"
                                    name="dateMax"

                                />
                                <Box sx={{ py: '12px' }} />
                                <Button sx={{ ml: 2 }} variant="contained" size="small" color="success" >Reinitialiser</Button>
                                <Button sx={{ ml: 2 }} variant="contained" size="small" color="primary" >Valider</Button>
                            </Grid>

                        </Grid>
                    </ComponentFiltre>

                    <Box sx={{ py: '12px' }} />
                    <Box width="100%" overflow="auto">
                        {reservations
                            // .filter((reservation) => reservation.categorie.toLowerCase().includes(recherche))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((reservation, index) => (
                                // <TableRow hover key={index}>
                                <div key={index}>
                                    <CardMobile vehicule={reservation} setRelance={setRelance} relance={relance}></CardMobile>
                                    <Box sx={{ py: '12px' }} />
                                </div>

                            ))}
                        {/* </TableBody>
                            </StyledTable> */}

                        <TablePagination
                            sx={{ px: 2 }}
                            page={page}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            count={reservations.length}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[5, 10, 25]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                        />
                    </Box>

                </SimpleCard>
            </Grid>
        </Grid >
    );
};

export default DemandeLocationComponent;