import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import CategorieChangementComponent from 'app/myComponents/VentePiece/Admin/CategorieChangementComponent';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const CategorieChangement = () => {
    document.title = "Changement Categorie";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Vente Piece' },
                        { name: 'Categories', path: '/admin/pieces/categories' },
                        { name: 'Changement' }
                    ]}
                />
            </Box>

            <CategorieChangementComponent />

        </AppButtonRoot>
    )
}
export default CategorieChangement;