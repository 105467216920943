import { Card, CardContent, CardHeader, Tab, Tabs, TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const AProposComponent = ({
    languages,
    accueil,
    setAccueil,
    loadedData
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [tabNameValue, setTabNameValue] = useState(0);
    const [languageChoose, setLanguageChoose] = useState("eng");

    const [sections, setSection] = useState({
        histoire: {
            fr: "",
            eng: "",
            ita: ""
        },
        mission1: {
            fr: "",
            eng: "",
            ita: ""
        },
        mission2: {
            fr: "",
            eng: "",
            ita: ""
        }
    });
    const sectionKeys = Object.keys(sections);

    const handleChangeTabNameValue = (event, newValue) => {
        // console.log(newValue, " language ", languages[newValue].name, " SHOW == ", presentation.description[languages[newValue]?.name])
        setTabNameValue(newValue);
        setLanguageChoose(languages[newValue]?.name);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeDescription = (event) => {
        // console.log(languageChoose);
        setAccueil({ ...accueil, apropos: { ...accueil.apropos, [languageChoose]: event.target.value } })
    }

    const handleChangeDetail = (event, sectionKey) => {
        // console.log(languageChoose);
        let txt = handleChangeSection(event, sectionKey);
        // setAccueil({ ...accueil, apropos_detail: { ...accueil.apropos_detail, [languageChoose]: event.target.value } })
        console.log('txt to put : ', txt);
        setAccueil({ ...accueil, apropos_detail: { ...accueil.apropos_detail, [languageChoose]: txt } })
    }

    const handleChangeSection = (event, sectionKey) => {
        let result = "";
        // console.log('sectionKey : ', sectionKey);
        // console.log('event.target.value : ', event.target.value);
        // console.log('languageChoose : ', languageChoose);
        setSection({ ...sections, [sectionKey]: { ...sections[sectionKey], [languageChoose]: event.target.value } });

        result = getFullText();

        return result;
    }

    const getFullText = () => {
        let result = "";

        let keys = Object.keys(sections);
        keys.forEach(key => {
            result += sections[key][languageChoose] + "_";
        });

        return result;
    }

    const initSections = () => {
        let sectionsTmp = { ...sections };
        let keys = Object.keys(sectionsTmp);
        // console.log('languages : ', languages);
        let txt = '';
        let index = 0;
        if (loadedData) {
            // console.log('apropos_detail : ', accueil.apropos_detail);
            languages.forEach(language => {
                txt = accueil.apropos_detail[language.name];
                txt = txt.split("_");
                // console.log(`txt in ${language.name} : `, txt);
                index = 0;
                keys.forEach(key => {
                    sectionsTmp[key][language.name] = txt[index] ? txt[index] : '';
                    index++;
                });
            });
            setIsLoaded(true);
        }
        setSection(sectionsTmp);
    }

    useEffect(() => {
        console.log('sections : ', sections);
    }, [sections]);

    useEffect(() => {
        if (isLoaded === false) {
            initSections();
        }
    }, [accueil.apropos_detail]);
    return (
        <Card sx={{ m: 3 }}>
            <CardHeader title={`A propos`} />
            <CardContent>
                <Tabs
                    value={tabNameValue}
                    onChange={handleChangeTabNameValue}
                    aria-label="basic tabs example"
                >
                    {languages?.map((language, index) =>
                        <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                    )}
                </Tabs>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="description"
                    type="text"
                    label="Description"
                    value={accueil.apropos[languageChoose] ? accueil.apropos[languageChoose] : ''}
                    onChange={(e) => handleChangeDescription(e)}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
                {/* <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="description"
                    type="text"
                    label="Details"
                    value={accueil.apropos_detail[languageChoose] ? accueil.apropos_detail[languageChoose] : ''}
                    onChange={(e) => handleChangeDetail(e)}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                /> */}
                {
                    sectionKeys.map((sectionKey, index) =>
                        <TextField
                            key={index}
                            multiline
                            fullWidth
                            size='small'
                            name="description"
                            type="text"
                            label={`Section ${sectionKey.toUpperCase()}`}
                            value={sections[sectionKey][languageChoose]}
                            onChange={(e) => handleChangeDetail(e, sectionKey)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                    )
                }

            </CardContent>
        </Card>
    );
};

export default AProposComponent;