import { Box, styled } from "@mui/material";
import { Breadcrumb } from "app/components";
import ListArticleComponent from "app/myComponents/Nosybe/Article/ListArticleComponent";

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const ListArticle = () => {
    document.title = "Article";
    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Article' },
                        { name: 'Liste' }
                    ]}
                />
            </Box>
            <ListArticleComponent />
        </AppButtonRoot>
    )
}
export default ListArticle;