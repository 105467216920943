import { Alert, Autocomplete, Badge, Button, CircularProgress, Grid, Icon, IconButton, Snackbar, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// dropzone
import { useDropzone } from 'react-dropzone';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// 

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const AjoutReparationComponent = () => {

    const navigate = useNavigate();

    const [reparation, setReparation] = useState(
        {
            idtypereparation: 0,
            nom: "Changement Pare-brise",
            description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque voluptas reprehenderit possimus quidem voluptatum. Quam ipsum consequuntur mollitia nostrum deserunt. Quia nulla dolore accusantium voluptatum incidunt ut fugiat libero quod?",
            duration: "01:00",
            prix: 200000,
            codecouleur: '#000000',
            images: []
        }
    );

    const handleAutocompleteChange = (event, newValue) => {
        if (newValue) {
            setReparation({ ...reparation, idtypereparation: newValue.id });
        } else {
            setReparation({ ...reparation, idtypereparation: 0 });
        }
    };

    // drop zone
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                const tabImage = [...reparation.images];
                tabImage.push(base64);
                setReparation({ ...reparation, images: tabImage });
            };
            reader.readAsDataURL(file);
        }

    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    // 

    function handleChange(e) {
        setReparation({ ...reparation, [e.target.name]: e.target.value });
    }

    function removeImgReparation(index) {
        const tabImg = [...reparation.images];
        tabImg.splice(index, 1);
        setReparation({ ...reparation, images: tabImg });
    }

    const [typereparations, setTypeReparation] = useState(null);

    function getTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            const tab = [];
            const listTypeReparations = response.data;
            listTypeReparations.forEach(typeReparation => {
                tab.push({
                    id: typeReparation.idtypereparation,
                    label: typeReparation.typereparation.nom
                });
            });
            setTypeReparation(tab);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTypeReparation();
    }, []);


    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    function ajouterReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/reparations`,
            method: 'POST',
            data: reparation
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                navigate('/entreprise/reparation/liste');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
                setMessage(response.data.message);
                setOpen(true);
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
    }

    return (
        <div>
            <SimpleCard title={'Ajout d\'une nouvelle reparation'}>
                {
                    typereparations ?

                        typereparations.length !== 0 ?
                            <>
                                <Autocomplete
                                    sx={{ mb: 3 }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={typereparations}
                                    size='small'
                                    fullWidth
                                    onChange={handleAutocompleteChange}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params} label="Type de la réparation" />
                                    }
                                />
                            </>
                            :
                            <>
                                <Link to={'/entreprise/reparation/types/innocar/liste'}>
                                    <center>
                                        <Button>
                                            Ajouter des nouveaux types de réparation
                                        </Button>
                                    </center>
                                </Link>
                            </>

                        :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                }

                <TextField
                    required
                    fullWidth
                    size='small'
                    name="nom"
                    type="text"
                    label="Nom"
                    value={reparation.nom}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
                <TextField
                    fullWidth
                    size='small'
                    name="description"
                    type="text"
                    label="Description"
                    value={reparation.description}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                    multiline
                />
                <TextField
                    required
                    fullWidth
                    size='small'
                    name="duration"
                    type="time"
                    label="Duree"
                    value={reparation.duration}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
                <TextField
                    required
                    fullWidth
                    size='small'
                    name="prix"
                    type="number"
                    label="Prix"
                    value={reparation.prix}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />

                <TextField
                    required
                    fullWidth
                    size='small'
                    name="codecouleur"
                    type="color"
                    label="Couleur"
                    variant="outlined"
                    value={reparation.codecouleur}
                    onChange={handleChange}
                    sx={{ mt: 1.5, mb: 2 }}
                />

                <Grid container spacing={3}>

                    <Grid item lg={6} md={6} xs={12}>
                        <div
                            {...getRootProps()}
                            style={{
                                width: '100%',
                                minHeight: '200px',
                                padding: '20px',
                                border: '2px dashed #cccccc',
                                textAlign: 'center',
                                margin: '20px 0',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                backgroundColor: 'lightgray',
                            }}
                            className="dropzone"
                        >
                            <input {...getInputProps()} />
                            <Icon fontSize="large">cloud_upload</Icon>
                            <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        {reparation.images.length !== 0 ?
                            <>
                                <Swiper
                                    spaceBetween={1}
                                    slidesPerView={1}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                >
                                    {reparation.images.map((img, index) => (
                                        <SwiperSlide key={index}>
                                            <center>
                                                <Badge
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                    badgeContent={
                                                        <>
                                                            <IconButton onClick={() => removeImgReparation(index)} color='error'>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>}>

                                                    <div
                                                        style={{
                                                            width: '100%',  // Largeur 100% de la diapositive
                                                            height: '200px',  // Hauteur fixe
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                        }}
                                                    >
                                                        <img
                                                            src={img}
                                                            style={{
                                                                width: 'auto',
                                                                height: 'auto',
                                                                maxWidth: '100%',
                                                                maxHeight: '100%' // Empêche le débordement horizontal
                                                            }}
                                                            alt={`Publication img ${index}`} />
                                                    </div>
                                                </Badge>
                                            </center>

                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                            :
                            <>
                                <Typography>
                                    Aucune image de votre réparation
                                </Typography>
                            </>
                        }
                    </Grid>

                </Grid>



                {reparation.idtypereparation !== 0 &&
                    <StyledButton onClick={ajouterReparation} fullWidth variant="contained" component="span">
                        Ajouter
                    </StyledButton>
                }


            </SimpleCard>

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AjoutReparationComponent;