import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import ProfilComponent from 'app/myComponents/Repair/Profil/ProfilComponent';
import React from 'react';
import MyAccount from '../../SignIn/MyAccount';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const Profil = () => {
    document.title = "Profil";
    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Entreprise' }, { name: 'Profil' }]}
                    />
                </Box>

                <MyAccount />
                {/* <ProfilComponent></ProfilComponent> */}

            </AppButtonRoot>
        </>
    );
};

export default Profil;