import React from 'react';
import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    Snackbar,
    styled,
    TablePagination,
    TextField,
    Typography,
} from "@mui/material";
import { SimpleCard } from 'app/components';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import divStyle from 'app/myComponents/Repair/divStyle';
import imgStyle from 'app/myComponents/Repair/imgStyle';

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));
const StyledButton = styled(Button)(({ theme }) => ({
    // margin: theme.spacing(1),
}));

const ListeEtapeComponent = ({ ajoutEtapeReparation }) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    document.title = "ETAPE-REPARATION";

    const [etapeReparations, setEtapeReparation] = useState(null);
    function getEtapeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/etapes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setEtapeReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }
    useEffect(() => {
        getEtapeReparation();
    }, []);


    const [deleteEtape, setDeleteEtape] = useState({});
    const [editEtape, setEditEtape] = useState({});

    // table
    const [recherche, setRecherche] = useState({
        affect: true,
        label: ''
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // 

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // retirer etapereparation
    const [retirerEtapeReparation, setRetirerEtapeReparation] = useState(false);

    // modals
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => {
        setRetirerEtapeReparation(false);
        setOpenModalEdit(false);
    };

    // functions
    function displayEditModal(etape) {
        setEditEtape(etape);
        setOpenModalEdit(true);
    }

    function handleEditEtape(e) {
        setEditEtape({ ...editEtape, [e.target.name]: e.target.value });
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setEditEtape({ ...editEtape, img: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    function modifierEtape() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/etapes/${editEtape.id}`,
            method: 'PUT',
            data: {
                ...editEtape,
                retirerEtapeReparation: retirerEtapeReparation
            }
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getEtapeReparation();
                handleCloseModalEdit();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        setEtapeReparation(null);
        getEtapeReparation();
    }

    function displayDeleteModal(etape) {
        setDeleteEtape(etape);
        setOpenModalDelete(true);
    }

    function supprimerEtape() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/etapes/${deleteEtape.id}`,
            method: 'DELETE'
        };
        axios(requestOptions).then((response) => {
            console.log(response.data);
            if (response.data.code === 200) {
                setColorMessage('success');
                getEtapeReparation();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalDelete();
    }
    return (
        <>
            <SimpleCard title={'Etapes de reparation'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche.label}
                        onChange={(e) => setRecherche({ ...recherche, label: e.target.value })}
                        sx={{ mt: 1.5 }}
                    />
                    <FormControlLabel onChange={() => setRecherche({ ...recherche, affect: !recherche.affect })}
                        control={<Checkbox />} label="Non affecté" />
                    <TablePagination
                        sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={etapeReparations ? etapeReparations.length : 0}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[4, 8, 12]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </Box>
            </SimpleCard>

            <br />

            {etapeReparations ?
                <>

                    {
                        etapeReparations.length !== 0 ?
                            <>
                                <Grid container spacing={3}>
                                    {etapeReparations
                                        .filter((data) =>
                                            (recherche.affect === true ? data.idreparation !== 0 : data.idreparation === 0) &&
                                            (
                                                data.nom.toLowerCase().includes(recherche.label) ||
                                                (data.idreparation !== 0 && data.reparation.nom.toLowerCase().includes(recherche.label)) ||
                                                data.description.toLowerCase().includes(recherche.label)
                                            )
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((etapeReparation, index) => (
                                            <Grid item key={index} lg={3} md={3} sm={6} xs={12}>
                                                <Card sx={{ width: '100%', height: '100%' }}>
                                                    {etapeReparation.img &&
                                                        <>
                                                            <div style={{ ...divStyle }}>
                                                                <img src={etapeReparation.img} alt="" style={imgStyle} />
                                                            </div>
                                                            <center>
                                                            </center>
                                                        </>
                                                    }
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            {etapeReparation.nom}
                                                        </Typography>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            Affecter à :
                                                        </Typography>
                                                        {
                                                            etapeReparation.idreparation === 0 ?
                                                                <>
                                                                    <Typography gutterBottom variant="h6" component="div">
                                                                        Aucune réparation
                                                                    </Typography>
                                                                </>
                                                                :
                                                                <>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                                                            <center>
                                                                                <Avatar alt={''} src={etapeReparation.reparation.images.length !== 0 ? etapeReparation.reparation.images[0].img : ''} />
                                                                            </center>
                                                                        </Grid>
                                                                        <Grid item lg={9} md={9} sm={9} xs={9}>
                                                                            <Typography variant="h6" component="div">
                                                                                {etapeReparation.reparation.nom}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Typography gutterBottom variant="h6" component="div">
                                                                        Ordre : {etapeReparation.ordre}
                                                                    </Typography>
                                                                </>

                                                        }

                                                        <AccordionRoot>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<Icon>expand_more</Icon>}
                                                                    aria-controls="panel1c-content"
                                                                    id="panel1c-header"
                                                                >
                                                                    <Box className="column">
                                                                        <Typography className="heading">Description</Typography>
                                                                    </Box>
                                                                </AccordionSummary>

                                                                <AccordionDetails className="details">
                                                                    <Box className="column helper">
                                                                        <Typography variant="subtitle1" color="text.secondary">
                                                                            {etapeReparation.description}
                                                                        </Typography>
                                                                    </Box>
                                                                </AccordionDetails>

                                                            </Accordion>
                                                        </AccordionRoot>

                                                    </CardContent>
                                                    <CardActions>
                                                        {
                                                            ajoutEtapeReparation ?
                                                                etapeReparation.idreparation === 0 &&
                                                                <>
                                                                    <Button color="primary" onClick={() => ajoutEtapeReparation(etapeReparation)}>
                                                                        <Icon>add</Icon>
                                                                        Ajouter
                                                                    </Button>
                                                                </>
                                                                :
                                                                <>
                                                                    <IconButton onClick={() => displayEditModal(etapeReparation)}>
                                                                        <Icon color="success">edit</Icon>
                                                                    </IconButton>
                                                                    <IconButton onClick={() => displayDeleteModal(etapeReparation)}>
                                                                        <Icon color="error">delete</Icon>
                                                                    </IconButton>
                                                                </>
                                                        }
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                            :
                            <>
                                <center>
                                    <Link to={'/entreprise/reparation/etapes/ajout'}>
                                        <Button>
                                            Ajouter des étapes à vos réparations
                                        </Button>
                                    </Link>
                                </center>
                            </>
                    }

                </>
                :
                <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>
            }

            {/* modal edit */}
            <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                    Modifer
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <label htmlFor="contained-button-file">
                                <StyledButton fullWidth sx={{ mb: 1 }} variant="outlined" component="span">
                                    <Icon>photo_camera</Icon>Modifier votre image
                                </StyledButton>
                            </label>
                            {editEtape.img &&
                                <>
                                    <Button onClick={() => setEditEtape({ ...editEtape, img: '' })} color='error' fullWidth variant='outlined'>
                                        <Icon>close</Icon>
                                        Retirer l'image
                                    </Button>
                                </>
                            }
                            <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            {editEtape.img &&
                                <>
                                    <Card sx={{ width: '100%' }}>
                                        <div style={divStyle}>
                                            <img src={editEtape.img} style={imgStyle} alt="" />
                                        </div>
                                    </Card>

                                    <br />
                                </>
                            }
                        </Grid>
                    </Grid>

                    <br />
                    <TextField
                        fullWidth
                        size='small'
                        name="nom"
                        type="text"
                        label="Nom"
                        value={editEtape.nom}
                        onChange={handleEditEtape}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="description"
                        type="text"
                        label="Description"
                        value={editEtape.description}
                        onChange={handleEditEtape}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                        multiline
                    />
                    {editEtape.idreparation !== 0 &&
                        <TextField
                            fullWidth
                            size='small'
                            name="ordre"
                            type="number"
                            label="Ordre"
                            value={editEtape.ordre}
                            onChange={handleEditEtape}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                    }

                    <Link to={'/entreprise/reparation/etapes/ajout/affectation'} state={{ etape: { ...editEtape, updateEtape: true } }}>
                        <Button>
                            Affecter à une {editEtape.reparation && <>autre</>} réparation
                        </Button>
                    </Link>
                    <br />
                    {
                        editEtape.reparation &&
                        <FormControlLabel sx={{ color: 'red' }} onClick={() => setRetirerEtapeReparation(true)} control={<Checkbox />} label={`Retirer de ${editEtape.reparation.nom}`} />
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalEdit}>
                        Fermer
                    </Button>
                    <Button onClick={modifierEtape} color='success'>
                        Appliquer
                    </Button>
                </DialogActions>
            </Dialog>
            {/*  */}

            {/* modal delete */}
            <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                    Retirer {deleteEtape.nom}
                </DialogTitle>

                <DialogContent dividers>
                    <Typography gutterBottom>
                        Vous en etes sur?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalDelete}>
                        Fermer
                    </Button>
                    <Button onClick={supprimerEtape} style={{ color: 'red' }}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
            {/*  */}

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}
        </>
    );
};

export default ListeEtapeComponent;