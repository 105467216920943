import { Box, Container } from "@mui/material";
import { Breadcrumb } from "app/components";
import CategorieVoiture from "app/myComponents/Location/Vehicule/CategorieVoiture";





const ListeCategoriePage = () => {
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Table" }]} />
            </Box>
            {/* <SimpleCard title="Liste Vehicules"> */}
            <CategorieVoiture></CategorieVoiture>
            {/* </SimpleCard> */}
        </Container>
    );
}

export default ListeCategoriePage;