import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Snackbar, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const ListeReparationPersonnelComponent = ({ reparation }) => {

    const navigate = useNavigate();

    const personnelReparation = reparation.personnels;

    // modal
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [personne, setPersonne] = useState(null);

    function displayModalDelete(personnel) {
        setPersonne(personnel);
        setOpenModalDelete(true);
    }

    function retirerPersonnel() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/reparations/personnel`,
            method: 'DELETE',
            data: personne
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                navigate('/entreprise/reparation/liste');
            }
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
    }

    return (
        <>
            <SimpleCard title={'Liste de ses personnels'}>
                <Grid container spacing={3}>
                    {personnelReparation.length !== 0 ?
                        personnelReparation.map((personnel, index) => (
                            <Grid item lg={3} md={3} key={index}>
                                <Card sx={{ width: '100%', height: '100%' }}>
                                    {personnel.img &&
                                        <>
                                            <div style={divStyle}>
                                                <img src={personnel.img} style={imgStyle} alt={`${personnel.nom} ${personnel.prenom}`} />
                                            </div>
                                        </>
                                    }
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {personnel.nom} {personnel.prenom}
                                        </Typography>

                                        <Typography gutterBottom>
                                            Salaire : Ar {personnel.salaire}
                                        </Typography>

                                        <AccordionRoot>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<Icon>expand_more</Icon>}
                                                    aria-controls="panel1c-content"
                                                    id="panel1c-header"
                                                >
                                                    <Box className="column">
                                                        <Typography className="heading">Détails</Typography>
                                                    </Box>
                                                </AccordionSummary>

                                                <AccordionDetails className="details">
                                                    <Box className="column helper">
                                                        <Typography variant="subtitle1" color="text.secondary">
                                                            Date de naissance : {personnel.dtn}<br />
                                                            Email : {personnel.email}<br />
                                                        </Typography>
                                                    </Box>
                                                </AccordionDetails>

                                            </Accordion>
                                        </AccordionRoot>

                                    </CardContent>
                                    <CardActions style={{ justifyContent: 'right' }}>
                                        <Button onClick={() => displayModalDelete(personnel)} variant='contained' size="small" color="error">
                                            <Icon>close</Icon>
                                            Retirer
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>

                        )

                        )
                        :
                        <>
                            <Link to={'/entreprise/reparation/personnels/liste'} state={{ ajoutPersonnel: true, reparation: reparation }}>
                                <Button sx={{ m: 3 }} color='success'>
                                    Ajouter de nouveaux personnels
                                </Button>
                            </Link>
                        </>
                    }
                </Grid>
            </SimpleCard>

            {/* modal delete */}
            {personne &&
                <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                        Retirer
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Vous êtes sur de retirer <b>{personne.nom} {personne.prenom}</b> de la réparation?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={retirerPersonnel} color='error' >
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}
        </>
    );
};

export default ListeReparationPersonnelComponent;
