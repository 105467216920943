import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const GeneralDialog = () => {
    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [success, setSuccess] = useState(true);

    const closeSnackBar = () => setIsOpen(false);
    const displayToast = (text, success = true) => {
        setMessage(text);
        setSuccess(success)
        setIsOpen(true);
    }

    const toastAction = (event) => {
        const detail = event.detail;
        displayToast(
            detail.message,
            detail.success
        )
    };

    const unauthorizedAction = () => {
        let currentUser = "entreprise";
        let localUser = localStorage.getItem('user');
        if (localUser) currentUser = localUser;
        navigate(`/${currentUser}/connexion`);
        // displayToast(`Session expiré. Veuillez vous reconnecter`, false)
    }

    useEffect(() => {
        window.addEventListener('displayToast', toastAction);
        window.addEventListener('unauthorizedUser', unauthorizedAction);
    }, [])

    return (
        <>
            {isOpen &&
                <Snackbar
                    open={isOpen}
                    onClose={closeSnackBar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    autoHideDuration={3000}
                >
                    <Alert
                        severity={success ? 'success' : 'error'}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            }
        </>
    )
}
export default GeneralDialog;