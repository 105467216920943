
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Importez les styles de Leaflet
import { SimpleCard } from 'app/components';

const TrajetLocation = ({ longitudeDepart, latitudeDepart, longitudeArrive, latitudeArrive }) => {

    return (
        <div>
            <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '1200px', width: '1200%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[51.505, -0.09]}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>

    );

}

export default TrajetLocation;