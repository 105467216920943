import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Button, Card, CardActions, CardContent, CardMedia, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, Radio, RadioGroup, Snackbar, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
import {
    Box,
    Dialog,
    Icon,
    IconButton,
    TablePagination,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    // margin: theme.spacing(1),
}));

const ListePersonnelComponent = () => {

    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const location = useLocation();
    const ajoutPersonnel = location.state ? location.state.ajoutPersonnel : false;
    const reparation = location.state ? location.state.reparation : null;

    const [genres, setGenre] = useState([]);

    function getGenre() {
        const requestOptions = {
            url: `${myConfig.host}/api/genres`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setGenre(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getGenre();
    }, []);

    const [personnels, setPersonnel] = useState(null);

    function getPersonnel() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/personnels`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setPersonnel(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getPersonnel();
    }, []);

    // modal personne
    const [personne, setPersonne] = useState(null);
    // retirer etapereparation
    const [retirerPersonnelReparation, setRetirerPersonnelReparation] = useState(false);

    // table
    const [recherche, setRecherche] = useState('');

    const [affectation, setAffectation] = useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setPersonne({ ...personne, img: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    function handlePersonnelChange(e) {
        setPersonne({ ...personne, [e.target.name]: e.target.value });
    }

    // modal
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    const [openModalAjout, setOpenModalAjout] = useState(false);
    const handleCloseModalAjout = () => setOpenModalAjout(false);

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    function displayDeleteModal(personnel) {
        setPersonne(personnel);
        setOpenModalDelete(true);
    }

    function supprimerPersonnel() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/personnels/${personne.id}`,
            method: 'DELETE'
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getPersonnel();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalDelete();
    }

    function displayEditModal(personnel) {
        setPersonne(personnel);
        setOpenModalEdit(true);
    }

    function handleChangeContact(e, index) {
        const contactpersonnel = [...personne.contactpersonnels];
        contactpersonnel[index].contact = e.target.value;
        setPersonne({ ...personne, contactpersonnels: contactpersonnel });
    }

    function addContact() {
        const contactpersonnels = [...personne.contactpersonnels];
        contactpersonnels.push({
            id: 0,
            idpersonnel: personne.id,
            contact: '+261',
            etat: 0
        });
        setPersonne({ ...personne, contactpersonnels: contactpersonnels });
    }

    function modifierPersonnel() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/personnels/${personne.id}`,
            method: 'PUT',
            data: {
                ...personne,
                retirerPersonnelReparation: retirerPersonnelReparation
            }
        };
        // console.log(requestOptions);
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getPersonnel();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalEdit();
    }

    function displayAjoutModal(personne) {
        setPersonne(personne);
        setOpenModalAjout(true);
    }

    function ajouterPersonnelReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/reparations/personnel`,
            method: 'PUT',
            data: {
                idpersonnel: personne.id,
                idreparation: reparation.id
            }
        };
        axios(requestOptions).then((response) => {
            console.log(response.data);
            if (response.data.code === 200) {
                setColorMessage('success');
                getPersonnel();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalAjout();
    }

    return (
        <>
            <SimpleCard title={'Liste du personnel'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />

                    <FormControlLabel onClick={() => setAffectation(!affectation)} control={<Checkbox />} label={`Non affectés`} />

                    <TablePagination
                        sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={personnels ? personnels.length : 0}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[4, 8, 12]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </Box>
            </SimpleCard>

            <br />

            {personnels ?
                <>
                    <Grid container spacing={3}>
                        {personnels
                            .filter((data) =>
                                (affectation ? data.idreparation !== 0 : data.idreparation === 0) && (
                                    data.nom.toLowerCase().includes(recherche.toLowerCase()) ||
                                    data.prenom.toLowerCase().includes(recherche.toLowerCase()) ||
                                    (data.reparation && data.reparation.nom.toLowerCase().includes(recherche.toLowerCase())
                                    )
                                )
                            )
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((personnel, index) => (
                                <Grid item key={index} lg={3} md={3} sm={6} xs={12}>
                                    <Card sx={{ width: '100%', height: '100%' }}>
                                        <div style={divStyle}>
                                            <img style={imgStyle} src={personnel.img ? personnel.img : ''} alt={`${personnel.nom} ${personnel.prenom}`} />
                                        </div>

                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {personnel.nom} {personnel.prenom}
                                            </Typography>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Affecter à :
                                            </Typography>
                                            {
                                                personnel.idreparation === 0 ?
                                                    <>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            Aucune réparation
                                                        </Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                                                <center>
                                                                    <Avatar alt={''} src={personnel.reparation.images[0].img} />
                                                                </center>
                                                            </Grid>
                                                            <Grid item lg={9} md={9} sm={9} xs={9}>
                                                                <Typography variant="h6" component="div">
                                                                    {personnel.reparation.nom}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>

                                            }
                                            <AccordionRoot>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<Icon>expand_more</Icon>}
                                                        aria-controls="panel1c-content"
                                                        id="panel1c-header"
                                                    >
                                                        <Box className="column">
                                                            <Typography className="heading">Voir Plus</Typography>
                                                        </Box>
                                                    </AccordionSummary>

                                                    <AccordionDetails className="details">
                                                        <Box className="column helper">
                                                            <Typography variant="subtitle1" color="text.secondary">
                                                                Salaire : <br />
                                                                Ar {personnel.salaire} <br />
                                                                Genre : <br />
                                                                {personnel.genre.nom} <br />
                                                                Date de naissance : <br />
                                                                {personnel.dtn}<br />
                                                                Email : <br />
                                                                {personnel.email}<br />
                                                                Contact : <br />
                                                                {
                                                                    personnel.contactpersonnels.map((contactpersonnel, i) =>
                                                                        <span key={i}>
                                                                            {contactpersonnel.contact}<br />
                                                                        </span>
                                                                    )
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </AccordionDetails>

                                                </Accordion>
                                            </AccordionRoot>

                                        </CardContent>
                                        <CardActions sx={{ justifyContent: 'right' }}>
                                            {ajoutPersonnel === false ?
                                                <>
                                                    <IconButton onClick={() => displayEditModal(personnel)}>
                                                        <Icon color="success">edit</Icon>
                                                    </IconButton>
                                                    <IconButton onClick={() => displayDeleteModal(personnel)}>
                                                        <Icon color="error">delete</Icon>
                                                    </IconButton>
                                                </>
                                                :
                                                <>
                                                    {personnel.idreparation === 0 &&
                                                        <IconButton onClick={() => displayAjoutModal(personnel)}>
                                                            <Icon color="primary">add</Icon>
                                                        </IconButton>
                                                    }

                                                </>
                                            }

                                        </CardActions>
                                    </Card>

                                </Grid>
                            ))}
                    </Grid>
                </>
                : <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>}



            {/* modal ajout */}
            {personne && reparation &&
                <Dialog onClose={handleCloseModalAjout} aria-labelledby="customized-dialog-title" open={openModalAjout}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalAjout}>
                        Ajout
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Ajouter <b>{personne.nom} {personne.prenom}</b> à la réparation <b>{reparation.nom}</b>?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ajouterPersonnelReparation} color='success'>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }

            {/* modal edit */}
            {personne &&
                <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                        Modifer
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <label htmlFor="contained-button-file">
                                    <Button fullWidth sx={{ mb: 1 }} variant="outlined" component="span">
                                        <Icon>photo_camera</Icon>Modifier votre image
                                    </Button>
                                </label>

                                <Button fullWidth variant="outlined" onClick={() => setPersonne({ ...personne, img: '' })} color='error'>
                                    <Icon>close</Icon>
                                    Retirer l'image
                                </Button>
                                <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div style={{ ...divStyle, borderRadius: '10px' }}>
                                    <img src={personne.img ? personne.img : ''} style={imgStyle} alt="Profil" />
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="nom"
                            label="Votre nom"
                            variant="outlined"
                            value={personne.nom}
                            onChange={handlePersonnelChange}
                            sx={{ mb: 3 }}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="prenom"
                            label="Votre prenom"
                            variant="outlined"
                            value={personne.prenom}
                            onChange={handlePersonnelChange}
                            sx={{ mb: 3 }}
                        />

                        <Box sx={{ mb: 3 }}>
                            <Typography>Genre : {personne.genre.nom}</Typography>
                            <RadioGroup
                                className="group"
                            >
                                {genres.map((genre, index) => (
                                    <FormControlLabel key={index} name='idgenre' onChange={handlePersonnelChange} value={genre.id} control={< Radio />} label={genre.nom} />
                                ))}
                            </RadioGroup>
                        </Box>

                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            name="dtn"
                            label="Votre date de naissance"
                            variant="outlined"
                            value={personne.dtn}
                            onChange={handlePersonnelChange}
                            sx={{ mb: 3 }}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            type="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            value={personne.email}
                            onChange={handlePersonnelChange}
                            sx={{ mb: 3 }}
                        />

                        {personne.contactpersonnels && personne.contactpersonnels.map((contactpersonnel, index) => (
                            <TextField
                                key={index}
                                fullWidth
                                size="small"
                                type="tel"
                                name="contact"
                                label="Contact"
                                variant="outlined"
                                value={contactpersonnel.contact}
                                sx={{ mb: 1 }}
                                onChange={(e) => handleChangeContact(e, index)}
                            />
                        ))}

                        <Fab onClick={addContact} style={{ zIndex: '0' }} sx={{ mb: 1.5 }} size="small" color="primary" aria-label="Add" className="button">
                            <Icon>phone</Icon>
                        </Fab>

                        <br />

                        <TextField
                            fullWidth
                            size="small"
                            name="salaire"
                            type="number"
                            label="Salaire du personnel"
                            variant="outlined"
                            value={personne.salaire}
                            onChange={handlePersonnelChange}
                            sx={{ mt: 1.5 }}
                        />

                        <br />

                        {
                            personne.reparation &&
                            <FormControlLabel onClick={() => setRetirerPersonnelReparation(true)} control={<Checkbox />} label={`Retirer de ${personne.reparation.nom}`} />
                        }

                        <Link to={'/entreprise/reparation/personnels/ajout/affectation'} state={{ personnel: { ...personne, updateReparation: true } }}>
                            <Button>
                                Affecter à une {personne.reparation && <>autre</>} réparation
                            </Button>
                        </Link>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseModalEdit}>
                            Fermer
                        </Button>
                        <Button onClick={modifierPersonnel} color='success'>
                            Modifier
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}

            {/* modal delete */}
            {personne &&
                <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                        Retirer {personne.nom} {personne.prenom}
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Vous en etes sur?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={supprimerPersonnel} color='error' >
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}
        </>
    );
};

export default ListePersonnelComponent;