import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import ActualiteReponseComponent from 'app/myComponents/Repair/Publications/ActualiteReponseComponent';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const ActualiteReponse = () => {
    document.title = "REPONSE-ACTUALITE"
    const location = useLocation();
    const actualite = location.state.actualite;

    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Publications', path: '/entreprise/reparation/publications/actualites' }, { name: 'Actualites', path: '/entreprise/reparation/publications/actualites' }, { name: 'Reponse' }]}
                    />
                </Box>

                <ActualiteReponseComponent publication={actualite}></ActualiteReponseComponent>

            </AppButtonRoot>
        </>
    );
};

export default ActualiteReponse;
