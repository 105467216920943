import { Box, styled } from "@mui/material"
import { Breadcrumb } from "app/components";
import FormEquipement from "app/myComponents/Nosybe/Equipement/FormEquipement";
// import FormHebergement from "app/myComponents/Nosybe/Hebergement/FormHebergement";

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AjoutEquipement = () => {
    document.title = "Ajout Equipement";
    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Equipement' },
                        { name: 'Ajout' }
                    ]}
                />
            </Box>
            <FormEquipement />
        </AppButtonRoot>
    )
}

export default AjoutEquipement;