export const navigationsRepair = [
  {
    name: 'Publications',
    icon: 'forum',
    children: [
      { name: 'Actualités', icon: 'bookmark', path: '/entreprise/reparation/publications/actualites' },
      { name: 'Suggestions', icon: 'add_to_photos', path: '/entreprise/reparation/publications/suggestions' }
    ]
  },
  {
    name: 'Dashboard',
    icon: 'dashboard',
    children: [
      { name: 'Reparations Demandées', icon: 'pie_chart', path: '/entreprise/reparation/dashboard/reparations' },
      { name: 'Evolution Prix', icon: 'show_chart', path: '/entreprise/reparation/dashboard/evolution' },
      { name: 'Recettes & Dépenses', icon: 'insert_chart', path: '/entreprise/reparation/dashboard/recettes_depenses' },
      { name: 'Planning Reparation', icon: 'event_note', path: '/entreprise/reparation/dashboard/planning' },
    ]
  },
  {
    name: 'Mes clients',
    icon: 'person',
    children: [
      { name: 'Demandes', icon: 'textsms', path: '/entreprise/reparation/clients/demandes' },
      { name: 'Historique', icon: 'restore', path: '/entreprise/reparation/clients/historique' },
    ]
  },
  { label: 'GESTIONS', type: 'label' },
  {
    name: 'Types de reparation',
    icon: 'view_stream',
    children: [
      { name: 'Mes Types', icon: 'beenhere', path: '/entreprise/reparation/types/liste' },
      { name: 'Liste', icon: 'list', path: '/entreprise/reparation/types/innocar/liste' },
    ]
  },
  {
    name: 'Reparations',
    icon: 'construction',
    children: [
      { name: 'Ajout', icon: 'add', path: '/entreprise/reparation/ajout' },
      { name: 'Liste', icon: 'list', path: '/entreprise/reparation/liste' },
    ]
  },
  {
    name: 'Etapes de reparation',
    icon: 'build',
    children: [
      { name: 'Ajout', icon: 'add', path: '/entreprise/reparation/etapes/ajout' },
      { name: 'Liste', icon: 'list', path: '/entreprise/reparation/etapes/liste' },
    ]
  },
  {
    name: 'Tarifs',
    icon: '%',
    children: [
      { name: 'Ajout', icon: 'add', path: '/entreprise/reparation/tarifs/ajout' },
      { name: 'Liste', icon: 'list', path: '/entreprise/reparation/tarifs/liste' },
    ]
  },
  {
    name: 'Mes personnels',
    icon: 'group',
    children: [
      { name: 'Ajout', icon: 'add', path: '/entreprise/reparation/personnels/ajout' },
      { name: 'Liste', icon: 'list', path: '/entreprise/reparation/personnels/liste' },
    ]
  }
];

