import { Accordion, AccordionSummary, AccordionActions, AccordionDetails, Alert, Badge, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Snackbar, Tab, Tabs, TextField, Typography, styled, Box } from "@mui/material";
import { SimpleCard } from "app/components";
import { getHebergementPrimaryRessources } from "app/data/nosybe/hebergement";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "../Restaurant/CarteMap";
import { SaveRestaurantAPI } from "../Equipement/Api-Equipement";
import { UpdateRestaurantAPI } from "../Restaurant/API-Restaurant";
import { SaveEvenement, UpdateEvenementAPI } from "./API-Evenement";
import { useNavigate } from "react-router-dom";
import { getAdminConnected } from "app/data/nosybe/admin";

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),

}));

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

const FormEvenement = ({ selectedEvenement, setevenement, closeForm }) => {


    const navigate = useNavigate();


    const [types, setTypes] = useState([]);
    const [equipements, setEquipements] = useState([]);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    console.log(selectedEvenement)

    const originalEvenement = {
        name: 'n',
        description: 'p',
        logo: 'logo',
        email: '',
        siteweb: '',
        latitude: lat,
        longitude: lng,
        contact: '0346660745',
        contact_secours: '0330255888',
        contacts: [],
        photos: [],
        lienYoutube: '',
        photoCouverture: '',
        nom: { "fr": "", "eng": "", "ita": "" },
        descriptionsMulti: { "fr": "", "eng": "", "ita": "" },
        dateEvenement: '',
        prix: 0,
        idAdmin: getAdminConnected().id
    }



    // console.log(selectedRestaurant, " RESTAURANT")

    const [restaurant, setRestaurant] = useState(selectedEvenement ? selectedEvenement : { ...originalEvenement });
    // const [restaurant, setRestaurant] = useState(selectedEvenement ? selectedEvenement : { ...originalEvenement });

    const [evenement, setEvenement] = useState(selectedEvenement ? selectedEvenement : { ...originalEvenement })
    const [inputErrors, setInputErrors] = useState({});

    const setObjectMere = (lat, lng) => {
        setEvenement({ ...evenement, latitude: lat, longitude: lng });
    }

    const handleChange = (e) => {
        setEvenement({
            ...evenement,
            [e.target.name]: e.target.value
        });
    }
    //CONTACT
    const addContact = () => {
        if (selectedEvenement === undefined) {
            console.log("addContact");
            const contactData = [...evenement.contacts];
            // contactData.push('+261');
            contactData.push({ 'contact': '+261' });
            setEvenement({ ...evenement, contacts: contactData });
        } else {
            const contactData = [...evenement.contacts];
            contactData.push({ "contact": '+261', "etat": 10, "idEvenement": selectedEvenement.id, "id": 0 });
            setEvenement({ ...evenement, contacts: contactData });
        }
    }
    const handleContactChange = (e, index) => {
        if (selectedEvenement === undefined) {
            const contactData = [...evenement.contacts];
            // contactData[index] = e.target.value;
            contactData[index] = { 'contact': e.target.value };
            setEvenement({ ...evenement, contacts: contactData });
        } else {
            const contactData = [...evenement.contacts];
            // const contact = { "contact": e.target.value, "etat": 10, "id": 0 };
            contactData[index] = { "contact": e.target.value, "etat": 10, "id": contactData[index].id, "idEvenement": selectedEvenement.id };
            setEvenement({ ...evenement, contacts: contactData });
        }
    }
    const removeContact = (index) => {
        const contactData = evenement.contacts.filter((_, i) => i !== index);
        setEvenement({ ...evenement, contacts: contactData });
    }

    //IMAGES
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (selectedEvenement !== undefined) {
                    const base64 = e.target.result;
                    const tabImage = [...evenement.photos];
                    tabImage.push({ "photo": "base64", "idEvenement": selectedEvenement.id, "etat": 10, "id": 0 });
                    setEvenement({ ...evenement, photos: tabImage });
                } else {
                    const base64 = e.target.result;
                    const tabImage = [...evenement.photos];
                    tabImage.push({ "photo": base64 });
                    setEvenement({ ...evenement, photos: tabImage });
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const onDropPhotoCouverture = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;

                setEvenement({ ...evenement, photoCouverture: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    const handleFileRemove = (selectedIndex) => {
        const tabImage = evenement.photos.filter((_, index) => index !== selectedIndex);
        setEvenement({ ...evenement, photos: tabImage });
    }



    const handleFileRemovePhoto = () => {
        setEvenement({ ...evenement, photoCouverture: undefined })
    }

    const { getRootProps: getRootPropsOnDrop, getInputProps: getInputPropsOnDrop } = useDropzone({ onDrop });

    // Utilisez useDropzone avec onDropCarte
    // const { getRootProps: getRootPropsOnDropCarte, getInputProps: getInputPropsOnDropCarte } = useDropzone({ onDrop: onDropCarte });

    const { getRootProps: getRootPropsOnDropPhotoCouverture, getInputProps: getInputPropsOnDropPhotoCouverture } = useDropzone({ onDrop: onDropPhotoCouverture });

    useEffect(() => {
        getHebergementPrimaryRessources(setEquipements, setTypes);
    }, [])





    const [tabNameValue, setTabNameValue] = useState(0);
    const [languages, setLanguages] = useState([{ "name": "eng", "abbrev": "MAL" }, { "name": "fr", "abbrev": "FRANCAIS" }, { "name": "ita", "abbrev": "MAL" }]);
    const [languageChoose, setLanguageChoose] = useState("eng");
    const [tabDescValue, setTabDescValue] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("eng");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [messageSnak, setMessageSnak] = useState("");

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeTabNameValue = (event, newValue) => {
        console.log(newValue, " language ", languages[newValue].name, " SHOW == ", evenement.nom[languages[newValue]?.name])

        setTabNameValue(newValue);
        // console.log(restaurant)
        setLanguageChoose(languages[newValue]?.name);


        // setChoosedNameLanguageAbbrev(Object.keys(roomType.names)[newValue]);
    };


    const handleChangeTabDescValue = (event, newValue) => {
        setTabDescValue(newValue);
        setLanguageDescChoose(languages[newValue]?.name);
    }

    const handleChangeNoms = (event) => {
        console.log(languageChoose);
        setEvenement({ ...evenement, nom: { ...evenement.nom, [languageChoose]: event.target.value } })
        console.log(restaurant)
    }

    const handleChangeDesc = (event) => {
        console.log(languageDescChoose);
        setEvenement({ ...evenement, descriptionsMulti: { ...evenement.descriptionsMulti, [languageDescChoose]: event.target.value } })
        console.log(evenement)
    }

    const apiKey = "AIzaSyBC2AAgF-VErgsY-8u_bDQEkH1PvqbsOS8";

    const saveEvenement = async () => {
        console.log(evenement);
        await SaveEvenement(evenement, setEvenement, setMessageSnak, setOpenSnackbar, setSeverity);
        navigate("/entreprise/evenement/list");

    }

    const UpdateEvenement = async () => {
        console.log(evenement)
        await UpdateEvenementAPI(evenement, setEvenement)
        closeForm();
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }



    return (
        <>
            <Typography variant="h5">
                {selectedEvenement ? 'Modifier un Evenement' : 'Ajouter un Evenement'}
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <SimpleCard>

                        <Tabs
                            value={tabNameValue}
                            onChange={handleChangeTabNameValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='small'
                            name="noms"
                            type="text"
                            // label="Noms"
                            value={evenement.nom[languageChoose] ? evenement.nom[languageChoose] : ''}
                            onChange={(e) => handleChangeNoms(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.titre && "error"}
                            helperText={inputErrors.titre}
                        />

                        <Tabs
                            value={tabDescValue}
                            onChange={handleChangeTabDescValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='small'
                            name="descriptions"
                            label="Descriptions"
                            value={evenement.descriptionsMulti[languageDescChoose] ? evenement.descriptionsMulti[languageDescChoose] : ''}
                            onChange={(e) => handleChangeDesc(e)}
                            multiline
                            maxRows={4}
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="dateEvenement"
                            type="date"
                            label=""
                            value={evenement.dateEvenement}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.dateEvenement && "error"}
                            helperText={inputErrors.dateEvenement}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="email"
                            type="email"
                            label="Email"
                            value={evenement.email}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.email && "error"}
                            helperText={inputErrors.email}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="siteweb"
                            type="text"
                            label="Site web"
                            value={evenement.siteweb}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.siteweb && "error"}
                            helperText={inputErrors.siteweb}
                        />

                        {/* <TextField
                            fullWidth
                            size='small'
                            name="etoile"
                            type="number"
                            label="Etoile"
                            value={evenement.etoile}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.etoile && "error"}
                            helperText={inputErrors.etoile}
                        /> */}

                        <TextField
                            fullWidth
                            size='small'
                            name="prix"
                            type="number"
                            label="Prix"
                            value={evenement.prix}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.prix && "error"}
                            helperText={inputErrors.prix}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="lienYoutube"
                            type="text"
                            label="Lien Youtube"
                            value={evenement.lienYoutube}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.prix && "error"}
                            helperText={inputErrors.prix}
                        />

                        {evenement?.contacts?.map((value, index) => (
                            <div key={index}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor={`contact-input-${index}`}>Contact</InputLabel>
                                    <OutlinedInput
                                        id={`contact-input-${index}`}
                                        size="small"
                                        type="tel"
                                        name="contact"
                                        label="Contact"
                                        value={value.contact}
                                        onChange={(e) => handleContactChange(e, index)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => removeContact(index)}
                                                    edge="end"
                                                >
                                                    <Icon>clear</Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        ))}
                        <Fab onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                            <Icon>phone</Icon>
                        </Fab>
                        <br />
                        <br />

                    </SimpleCard>
                </Grid>

                <Grid item sm={12}>
                    <SimpleCard>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Photo</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photo couverture</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                <Typography variant="h6" >
                                    Photos de couverture
                                </Typography>
                                <div
                                    {...getRootPropsOnDropPhotoCouverture()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDropPhotoCouverture()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>

                                {evenement.photoCouverture &&
                                    <>
                                        <Swiper
                                            spaceBetween={1}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                        >
                                            {/* {restaurant.photos.map((img, index) => ( */}
                                            <SwiperSlide >
                                                <center>
                                                    <Badge
                                                        sx={{
                                                            mt: 3,
                                                        }}
                                                        badgeContent={
                                                            <>
                                                                <IconButton
                                                                    onClick={() => handleFileRemovePhoto()}
                                                                    color='error'>
                                                                    <Icon>close</Icon>
                                                                </IconButton>
                                                            </>}>

                                                        <div
                                                            style={{
                                                                width: '100%',  // Largeur 100% de la diapositive
                                                                height: '200px',  // Hauteur fixe
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                            }}
                                                        >
                                                            <img
                                                                src={evenement.photoCouverture}
                                                                style={{
                                                                    width: 'auto',
                                                                    height: 'auto',
                                                                    maxWidth: '100%',
                                                                    maxHeight: '100%' // Empêche le débordement horizontal
                                                                }}
                                                                alt={`photo couverture img `} />
                                                        </div>
                                                    </Badge>
                                                </center>

                                            </SwiperSlide>

                                        </Swiper>
                                    </>
                                }
                                <Typography variant="h6" >
                                    Photos
                                </Typography>
                                <div
                                    {...getRootPropsOnDrop()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDrop()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>

                                {evenement.photos.length > 0 &&
                                    <>
                                        <Swiper
                                            spaceBetween={1}
                                            slidesPerView={1}
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            navigation
                                            pagination={{ clickable: true }}
                                            scrollbar={{ draggable: true }}
                                        >
                                            {evenement.photos.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <center>
                                                        <Badge
                                                            sx={{
                                                                mt: 3,
                                                            }}
                                                            badgeContent={
                                                                <>
                                                                    <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                        <Icon>close</Icon>
                                                                    </IconButton>
                                                                </>}>

                                                            <div
                                                                style={{
                                                                    width: '100%',  // Largeur 100% de la diapositive
                                                                    height: '200px',  // Hauteur fixe
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                                }}
                                                            >
                                                                <img
                                                                    src={selectedEvenement ? img.photo : img.photo}
                                                                    style={{
                                                                        width: 'auto',
                                                                        height: 'auto',
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%' // Empêche le débordement horizontal
                                                                    }}
                                                                    alt={`Pièce img ${index}`} />
                                                            </div>
                                                        </Badge>
                                                    </center>

                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                }
                            </Accordion>
                        </AccordionRoot>
                        <h1>Carte </h1>
                        <CarteMap apiKey={apiKey} setLongitude={setLng} setLatitude={setLat} setMereObject={setObjectMere} lati={evenement.latitude} longi={evenement.longitude} />

                        <StyledButton
                            // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                            onClick={selectedEvenement ? UpdateEvenement : saveEvenement}
                            fullWidth
                            variant="contained"
                            component="span"
                        >
                            Enregistrer
                        </StyledButton>

                    </SimpleCard >
                </Grid >
            </Grid >
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {messageSnak}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormEvenement;