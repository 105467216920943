import React from 'react';
import { useState } from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Box, Button, CircularProgress, Divider, FormControl, Grid, Icon, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { red } from '@mui/material/colors';
import getPrixRepair from 'app/config/Repair/getPrixRepair';


const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const HistoriqueClientComponent = () => {

    const [historiques, setHistorique] = useState(null);

    function getHistorique() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/client/demande?isEntreprise=true&isHistorique=true`,
            method: 'GET'
        };

        if (filter.month !== 0) {
            setHistorique(null);
            requestOptions.url += `&month=${filter.month}&year=${filter.year}`;
        }

        axios(requestOptions).then((response) => {
            setHistorique(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    const [mois, setMois] = useState(null);
    function getMois() {
        const requestOptions = {
            url: `${myConfig.host}/api/mois`,
            method: 'GET',
        };
        axios(requestOptions).then((response) => {
            setMois(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getMois();
        getHistorique();
    }, []);

    // filtre
    const [filter, setFilter] = useState({
        month: 0,
        year: new Date().getFullYear()
    });

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            {mois && historiques ?
                <>
                    <AccordionRoot>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <Box className="column">
                                    <Typography className="heading">Historique des clients</Typography>
                                </Box>

                                <Box className="column">
                                    <Typography className="secondaryHeading">Filtrer</Typography>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails className="details">
                                <Box className="column helper">
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} md={6}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="recherche"
                                                type="text"
                                                label="Recherche"
                                                variant="outlined"
                                                value={recherche}
                                                onChange={(e) => setRecherche(e.target.value)}
                                                sx={{ mt: 1.5 }}
                                            />
                                            <TablePagination
                                                sx={{ px: 2 }}
                                                page={page}
                                                component="div"
                                                rowsPerPage={rowsPerPage}
                                                count={historiques.length}
                                                onPageChange={handleChangePage}
                                                rowsPerPageOptions={[4, 8, 12]}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel filled id="demo-simple-select-autowidth-label">Mois</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    name='month'
                                                    value={filter.month === 0 ? '' : filter.month}
                                                    onChange={(e) => setFilter({ ...filter, month: e.target.value })}
                                                    // autoWidth
                                                    label="Mois"
                                                >

                                                    {mois.map((mois, index) => (
                                                        <MenuItem key={index} value={mois.id}>{mois.nom}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="year"
                                                type="number"
                                                label="Année"
                                                variant="outlined"
                                                value={filter.year}
                                                onChange={(e) => setFilter({ ...filter, year: e.target.value })}
                                                sx={{ mt: 3 }}
                                            />

                                        </Grid>
                                    </Grid>

                                </Box>
                            </AccordionDetails>

                            <Divider />

                            <AccordionActions>
                                <Button onClick={getHistorique} size="small" color="primary">
                                    Filtrer
                                </Button>
                            </AccordionActions>
                        </Accordion>
                    </AccordionRoot>

                    <br />

                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Reparation</TableCell>
                                <TableCell align="center">Client</TableCell>
                                <TableCell align="center">Demande</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historiques
                                .filter((historique) =>
                                    historique.client.nom.toLowerCase().includes(recherche) ||
                                    historique.client.prenom.toLowerCase().includes(recherche) ||
                                    historique.reparation_entrepise.nom.toLowerCase().includes(recherche) ||
                                    historique.entreprisetypedemande.typedemande.nom.toLowerCase().includes(recherche)
                                )
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((historique, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell align="center">
                                            <span style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {historique.reparation_entrepise.images ?
                                                    <>
                                                        <Avatar src={historique.reparation_entrepise.images[0].img} />
                                                    </>
                                                    :
                                                    <>
                                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                            {historique.reparation_entrepise.nom.charAt(0)}
                                                        </Avatar>
                                                    </>
                                                }

                                                {historique.reparation_entrepise.nom}
                                            </span>
                                        </TableCell>

                                        <TableCell align="center">
                                            <span style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {historique.client.photoprofil ?
                                                    <>
                                                        <Avatar src={historique.client.photoprofil} />
                                                    </>
                                                    :
                                                    <>
                                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                            {historique.client.nom.charAt(0)}{historique.client.prenom.charAt(0)}
                                                        </Avatar>
                                                    </>
                                                }
                                                {historique.client.nom} {historique.client.prenom}
                                            </span>

                                        </TableCell>
                                        <TableCell align="center">
                                            {historique.entreprisetypedemande.typedemande.nom} <br />
                                            Marge: {historique.entreprisetypedemande.valeur}%
                                        </TableCell>
                                        <TableCell align="center">
                                            {historique.datedebut}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* Ar {parseInt(historique.reparation_entrepise.prix) * (parseInt(historique.entreprisetypedemande.valeur) / 100)} */}
                                            Ar {getPrixRepair(historique.reparation_entrepise.prix, historique.entreprisetypedemande.valeur)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </StyledTable>


                </>
                :
                <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>
            }
        </>
    );
};

export default HistoriqueClientComponent;