import { Box, Container, Grid, TextField, useTheme, Select, MenuItem } from "@mui/material";
import DashBordSuivi from "./DashBordSuivi";
import { SimpleCard } from "app/components";
import { useRef } from "react";
import BarChart from "./Statistique/VoitureLoue";
import ComparisonChart from "app/views/charts/echarts/ComparisonChart";
import BarVoiture from "./Statistique/ComparaisonChartGenerer";
import LineChart from "app/views/charts/echarts/LineChart";
import { useState } from "react";
import { useEffect } from "react";
import { getStatistiqueCategorie, getStatistiqueFulle } from "./ControllerAPI/API-DemandeMarque";
import LineChartGenerer from "./Statistique/LineChartGenerer";
import ComparaisonChartGenerer from "./Statistique/ComparaisonChartGenerer";
import DoughnutChart from "app/views/charts/echarts/Doughnut";
import DonutState from "./Statistique/DonutState";
import BarStateCategorie from "./Statistique/BarStateCategorie";




const AllDashBordSuivi = () => {





    let MoisData = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"]
    const [anneActus, setAnneActus] = useState(new Date().getFullYear());
    const [annePrecedent, setAnnePrecedent] = useState(new Date().getFullYear() - 1);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [dataState, setDataState] = useState();
    const [categorie, setCategorie] = useState(2);
    const [dataActus, setDataActus] = useState();


    // const dataDashBord = [
    //     { "categorie": "4x4", "nombre": 12, "date": "" },
    //     { "categorie": "Plaisir", "nombre": 4, "date": "" },
    //     { "categorie": "Familial", "nombre": 15, "date": "" },
    //     { "categorie": "vehicules louées", "nombre": 20, "date": "" },
    //     { "categorie": "vehicules disponibles", "nombre": 11, "date": "" },
    //     { "categorie": "vehicules", "nombre": 31, "date": "" }
    // ]

    const date = useRef();
    const theme = useTheme();
    ;



    const [filtre, setFiltre] = useState({
        annePrec: annePrecedent,
        mois: month
    })


    const [moisSelect, setMoisSelect] = useState("");

    const setMoisSelectString = () => {
        let dataMois = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"]
        for (let i = 0; i < dataMois.length; i++) {
            if (i + 1 == month) {
                setMoisSelect(dataMois[i]);
                return;
            }
        }
    }
    // "Novembre", "Decembre"
    const handleChange = (e) => {
        setFiltre({ ...filtre, [e.target.name]: e.target.value });
        let dataMois = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"]
        console.log(e.target.name)
        if (e.target.name === "annePrec") {
            setAnnePrecedent(e.target.value);
        } if (e.target.name === "mois") {
            setMoisSelect(dataMois[(e.target.value) - 1]);
            setMonth(e.target.value);
        }
    }

    // const 



    useEffect(() => {
        setMoisSelectString();
        getStatistiqueCategorie(annePrecedent, categorie, setDataActus);
        getStatistiqueFulle(anneActus, annePrecedent, month, setDataState);
    }, [annePrecedent, month])

    const filtreDate = () => {

    }

    return (
        <Container>
            <Box sx={{ py: '12px' }} />
            <SimpleCard title={'TABLEAU DE BORD'}>
                <TextField
                    type="date"
                    name="date_filtre"
                    ref={date}
                    // label="Date"
                    onChange={filtreDate}
                />

            </SimpleCard>
            <Box sx={{ py: '12px' }} />
            {dataState !== undefined && (
                <Grid container spacing={3}>

                    {dataState.categorieCount.map((detail, index) => (
                        <Grid item lg={4} md={4} key={index}>
                            <DashBordSuivi categorie={detail.categorie_voiture} nombre={detail.nombre}></DashBordSuivi>
                        </Grid>
                    ))}

                </Grid>
            )}

            {dataState !== undefined && (
                <>
                    <SimpleCard>
                        <center>
                            <Select
                                size="small"
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                name='annePrec'
                                onChange={handleChange}
                                value={filtre.annePrec}
                                autoWidth
                                label="Année Précedent"
                            >
                                {dataState.annefiltre.map((anne, index) => (
                                    <MenuItem key={index} value={(anne.anne - 1)}>{(anne.anne) - 1}</MenuItem>
                                ))}

                            </Select>

                            <Select
                                size="small"
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                name='mois'
                                onChange={handleChange}
                                value={filtre.mois}
                                autoWidth
                                label="Mois"
                            >


                                {dataState.month.map((mois, index) => (
                                    <MenuItem key={index} value={mois.id}>{mois.mois}</MenuItem>
                                ))}

                            </Select>
                        </center>
                    </SimpleCard>
                    <Grid container spacing={6}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                            <SimpleCard title="Top 5 Chiffre d'affaire par Voiture ">
                                <center><h1>{moisSelect}</h1></center>

                                <DonutState
                                    height="350px"
                                    color={[
                                        theme.palette.primary.dark,
                                        theme.palette.primary.main,
                                        theme.palette.primary.light
                                    ]}
                                    dataSet={dataState.VehiculeTo5Chiffre}
                                />

                            </SimpleCard>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                            <SimpleCard title="Top 5 Voiture les plus loué">
                                <center><h1>{moisSelect}</h1></center>

                                <DonutState
                                    height="350px"
                                    color={[
                                        theme.palette.primary.dark,
                                        theme.palette.primary.main,
                                        theme.palette.primary.light
                                    ]}
                                    dataSet={dataState.VehiculeTop5}
                                />

                            </SimpleCard>
                        </Grid>
                    </Grid>

                    <SimpleCard title="Voiture loué">

                        <SimpleCard>


                            <h1> Année Actuelle : {anneActus} comparer par l'année {annePrecedent} le mois {moisSelect}</h1>

                            {/* <ComparaisonChartGenerer
                            height="350px"
                            color={[theme.palette.primary.dark, theme.palette.primary.light]}
                            labelsAffiche={dataState.labels}
                            dataActus={dataState.budjetActus}
                            dataPrecedent={dataDashBord.budjetPrecedent}
                        /> */}

                            <LineChartGenerer
                                height="350px"
                                color={[theme.palette.primary.main, theme.palette.primary.light]}
                                labelsAffiche={dataState.labels}
                                dataActus={dataState.budjetActus}
                                dataPrecedent={dataState.budjetPrecedent}
                                anneActu={anneActus}
                                annePrecedent={annePrecedent}
                            ></LineChartGenerer>
                        </SimpleCard>

                        <BarStateCategorie
                            height="350px"
                            color={[theme.palette.primary.main, theme.palette.primary.light]}
                            anne={anneActus}
                            categorie={categorie}
                            labelsAffiche={MoisData}

                        >
                        </BarStateCategorie>
                        {/* <ComparisonChart
                            height="350px"
                            color={[theme.palette.primary.main, theme.palette.primary.light]}
                        ></ComparisonChart> */}


                        {/* <BarVoiture></BarVoiture> */}
                        {/* <BarChart></BarChart> */}
                    </SimpleCard>
                </>
            )}

        </Container>
    );
};

export default AllDashBordSuivi;
