import url from "app/UrlConfig";
import axios from "axios";

export const getRestaurant = async (dataSend, setRestaurant, setRestaurantTotal) => {
    // console.log(dataSend)
    fetch(url["url-api"] + "restaurant-get", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataSend)
    })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            if (data.status === 200 || data.message === "request avec succes") {
                setRestaurant(data.data)
                console.log(" G")
                setRestaurantTotal(data.LigneNumber);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getRestaurantById = async (idRestaurant, setRestaurant) => {
    // console.log(url["url-api"] + "restaurantById/" + idRestaurant)
    fetch(url["url-api"] + "restaurantById/" + idRestaurant, {
        method: "GET",
        headers: { 'Content-Type': 'application/json' }
    })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            if (data.status === 200) {
                setRestaurant(data.data);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const UpdateRestaurantAPI = async (Restaurant, idRestaurant, setRestaurant) => {
    // console.log(url["url-api"] + "restaurant/" + idRestaurant)

    await axios.post(url["url-api"] + "restaurant/" + idRestaurant, Restaurant)
        .then(resp => {
            // console.log("reponse:", resp.data)
            if (resp.data.status === 200) {
                console.log("==> ", resp.data)
                setRestaurant(resp.data);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const DeleteRestaurant = async (idRestaurant, setRestaurant, setVisible) => {
    // console.log(url["url-api"] + "restaurant-delete/" + idRestaurant)
    fetch(url["url-api"] + "restaurant-delete/" + idRestaurant, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            if (data.status === 200) {
                // setVisible()
                // setRestaurant();
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getCategorieAPI = (setCategorie) => {
    fetch(url["url-api"] + "categorie", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setCategorie(data.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
}