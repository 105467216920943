import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Snackbar, TablePagination, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { dispatchUpdate } from 'app/data/piece/users';
import { activateAdmin, changeAdminState, desactivateAdmin, getListAdmin } from 'app/data/nosybe/admin';
import axios from 'axios';
import { listEntreprise } from 'fake-db/listEntreprise';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AdminEntreprise = () => {
    document.title = "ADMIN-ENTREPRISE";
    const navigate = useNavigate();

    const [rechercheEntreprise, setRechercheEntreprise] = useState({
        recherche: '',
        etat: 21
    });

    const etats = [
        {
            nom: 'Bloqué',
            value: 0
        },
        {
            nom: 'Demande',
            value: 11
        },
        {
            nom: 'Inscrit',
            value: 21
        }
    ];

    const [entreprises, setEntreprise] = useState([]);

    function getAdmin() {
        getListAdmin()
            .then((result) => {
                setEntreprise(result.data.admins);
            })
        // setEntreprise(listEntreprise);
    }
    const clearSelectedAdmin = () => {
        localStorage.setItem('user', 'admin');
        localStorage.removeItem('type');
        localStorage.removeItem('admin');
        dispatchUpdate();
    }

    useEffect(() => {
        clearSelectedAdmin();
        getAdmin();
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modal
    const [entrepriseModal, setEntrepriseModal] = useState(null);
    const [typeModal, setTypeModal] = useState();

    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const [severity, setSeverity] = useState('info');
    const [message, setMessage] = useState('');

    function displayModal(entreprise, type) {
        setEntrepriseModal(entreprise);
        setOpenModalEdit(true);
        setTypeModal(type);
    }

    function validerModifEntreprise() {
        const payload = {
            idAdmin: entrepriseModal.id
        }
        changeAdminState(payload)
            .then(result => {
                setEntreprise(result.data.admins);
                setMessage(result.data.message);
                setSeverity('success');
            })
            .catch(error => {
                console.log("validerModifEntreprise error: ", error);
                // setMessage(error.response.data.message);
                setSeverity('error');
            })
            .finally(() => {
                handleCloseModalEdit();
                setOpenSnackbar(true);
            })
    }

    const goToManagement = (entreprise) => {
        localStorage.setItem('user', 'entreprise');
        localStorage.setItem('type', 0);
        localStorage.setItem('admin', JSON.stringify(entreprise));
        dispatchUpdate();
        navigate('/entreprise/profile');
    }

    return (
        <>
            <AppButtonRoot>
                <SimpleCard title={'Liste des entreprises'}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='recherche'
                                value={rechercheEntreprise.recherche}
                                onChange={(e) => setRechercheEntreprise({ ...rechercheEntreprise, recherche: e.target.value.toLowerCase() })}
                                label='Recherche'
                                sx={{ mt: 2 }}
                            />
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={entreprises.length}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[25, 50, 100]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </Grid>
                        <Grid item lg={6} md={6}>
                            <RadioGroup
                                className="group"
                            >
                                <Typography gutterBottom>
                                    Etat des comptes :
                                </Typography>
                                {etats.map((etat, index) => (
                                    <FormControlLabel key={index} name='etat' value={etat.value} onChange={(e) => setRechercheEntreprise({ ...rechercheEntreprise, etat: parseInt(e.target.value) })} control={< Radio />} label={etat.nom} />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </SimpleCard>

                <Box sx={{ mt: 3 }} width={'100%'}>
                    <Grid container spacing={3}>
                        {entreprises
                            .filter((entreprise) =>
                                entreprise.etat == rechercheEntreprise.etat &&
                                entreprise.nom.toLowerCase().includes(rechercheEntreprise.recherche)
                            )
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((entreprise, index) =>
                                <Grid key={index} item lg={4} md={4}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={entreprise.images}
                                            title={entreprise.nom}
                                        />
                                        <CardContent>

                                            <Typography gutterBottom variant="h5" component="div">
                                                {entreprise.nom}
                                            </Typography>

                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {entreprise.email}
                                            </Typography>

                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                Représentant : {entreprise.nomRepresentant}<br />
                                            </Typography>

                                            <Typography variant="body2" color="text.secondary">
                                                {entreprise.description}
                                            </Typography>

                                        </CardContent>

                                        <CardActions>
                                            {entreprise.etat == 0 &&
                                                <Button onClick={() => displayModal(entreprise)} color='primary' size="small">Re activer?</Button>
                                            }
                                            {entreprise.etat == 11 &&
                                                <Button onClick={() => displayModal(entreprise)} color='success' size="small">Valider?</Button>
                                            }
                                            {entreprise.etat == 21 &&
                                                <Button onClick={() => displayModal(entreprise)} color='error' size="small">Bloquer?</Button>
                                            }
                                            <Button onClick={() => goToManagement(entreprise)} color='success' size="small">Acceder gestion</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                    </Grid>
                </Box>

            </AppButtonRoot>

            {/* modal fiche */}
            {entrepriseModal &&
                <>
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            {
                                entrepriseModal.etat === 0 ? 'Réactivation'
                                    : entrepriseModal.etat === 11 ? 'Validation'
                                        : entrepriseModal.etat === 21 ? 'Désactivation'
                                            : ''} de {entrepriseModal.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography sx={{ textAlign: 'center' }} gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>

                            <Button onClick={validerModifEntreprise} sx={entrepriseModal.etat == 21 ? { color: 'red' } : entrepriseModal.etat == 0 ? { color: 'blue' } : { color: 'green' }}>
                                Valider
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={openSnackbar}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={3000}
                        anchorOrigin={
                            {
                                "horizontal": "right",
                                "vertical": "top"
                            }
                        }
                    >
                        <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                            {message}
                        </Alert>
                    </Snackbar>
                </>
            }

            {/* */}

        </>
    );
};

export default AdminEntreprise;