import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
//import { getListeArticle } from "app/data/nosybe/Article";
// import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import ModalDeleteArticle from "./ModalDeleteArticle";
import ModalEditArticle from "./ModalEditArticle";
import { getArticleListAPI, getListeArticleDetail, getArticleList } from "./API-Article";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListArticleComponent = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [Article, setArticle] = useState([]);
    const [selectedArticle, setSelectedArticle] = useState(undefined);
    const [Total, setArticleTotal] = useState(0);

    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
        //getListeArticle(setArticles, setArticleTotal, newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        //getListeArticle(setArticles, setArticleTotal, 0, +Article.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedArticle(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (Article) => {
        setSelectedArticle(Article);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedArticle(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (Article) => {
        console.log(Article)
        setSelectedArticle(Article);
        setOpenModalEdit(true);
    }

    const [dataSend, setDataSend] = useState({
        "offset": page,
        "limit": rowsPerPage,
        "search": recherche
    });


    const getArticleListe = async () => {
        await getArticleList(setArticle, setArticleTotal, rowsPerPage, page);
    }

    useEffect(() => {
        // getListeArticle(setArticles, setArticleTotal);
        getArticleListe();
        // console.log("Article", Article);
    }, [selectedArticle, page, rowsPerPage]);

    return (
        <>
            {/* <TestButton toLog={Articles} /> */}
            <SimpleCard title={'Liste des Articles'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {Article.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom</TableCell>

                                        {/* <TableCell align="center">Prix</TableCell> */}
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Article.filter(val => (val.titre.toLowerCase().match(recherche))).map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{item.titre}</TableCell>
                                            {/* <TableCell align="center">{Article.prix}</TableCell> */}
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(item)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(item)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                    }
                                </TableBody>
                            </StyledTable>
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={Total}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[15, 30, 45]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard>
            {selectedArticle &&
                <>
                    <ModalDeleteArticle Article={selectedArticle} setArticle={setArticle} open={openModalDelete} handleClose={handleCloseModalDelete} />

                    <ModalEditArticle Article={selectedArticle} setArticle={setArticle} open={openModalEdit} handleClose={handleCloseModalEdit} />
                </>
            }
        </>
    )
}

export default ListArticleComponent;