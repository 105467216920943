import myConfig from "app/config/api-config";
import axios from "axios";
import { displayToast } from "./dispatch";

export const dispatchUpdate = () => {
    const updateEvent = new Event('userUpdated');
    window.dispatchEvent(updateEvent);
}

export const dispatchAdminUpdate = () => {
    const updateEvent = new Event('adminUpdated');
    window.dispatchEvent(updateEvent);
}

export const selectType = (type) => {
    localStorage.setItem('type', type);
    dispatchUpdate();
}

export const getCurrentEntreprise = (setEntreprise) => {
    // const message = ('Veuillez vous connecter');
    // const errorData = { message: message };
    const requestOptions = {
        url: `${myConfig.host}/api/entreprise/profileJWT`,
        method: 'GET'
    };
    axios(requestOptions).then((response) => {
        setEntreprise(response.data.entreprise);
    }).catch((e) => {
        console.error(e);
        displayToast(e.response.data.message, false);
    })
}