import { styled } from '@mui/material';
import RecetteDepenseComponent from 'app/myComponents/Repair/Dashboard/RecetteDepenseComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const RecetteDepense = () => {
    document.title = "RECETTE-DEPENSE";

    return (
        <>
            <AppButtonRoot>
                <RecetteDepenseComponent />
            </AppButtonRoot>
        </>
    );
};

export default RecetteDepense;
