import { Box, Button, Card, CardMedia, Fab, FormControlLabel, Grid, Icon, Radio, RadioGroup, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// dropzone
import { useDropzone } from 'react-dropzone';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const AjoutPersonnelComponent = () => {

    const [genres, setGenre] = useState([]);

    function getGenre() {
        const requestOptions = {
            url: `${myConfig.host}/api/genres`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setGenre(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getGenre();
    }, []);

    const [initialValues, setInitialValue] = useState(
        {
            nom: 'RAKOTO',
            prenom: 'Dadatoa',
            datenaissance: '1997-01-01',
            idgenre: 0,
            img: '',
            email: 'dadatoa@gmail.com',
            contact: [],
            idreparation: 0,
            salaire: 200000
        }
    );

    function changeInitialValue(e) {
        setInitialValue({ ...initialValues, [e.target.name]: e.target.value });
    }

    function addContact() {
        const contactData = [...initialValues.contact];
        contactData.push('+261');
        setInitialValue({ ...initialValues, contact: contactData });
    }

    function handleContactChange(e, index) {
        const contactData = [...initialValues.contact];
        contactData[index] = e.target.value;
        setInitialValue({ ...initialValues, contact: contactData });
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setInitialValue({ ...initialValues, img: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    // drop zone
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setInitialValue({ ...initialValues, img: base64 });
            };
            reader.readAsDataURL(file);
        }

    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    //

    return (
        <>
            <SimpleCard title={'Ajout d\'un nouveau personnel'}>

                <Grid container spacing={3}>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div
                            {...getRootProps()}
                            style={{
                                width: '100%',
                                minHeight: '200px',
                                height: '300px',
                                padding: '20px',
                                border: '2px dashed #cccccc',
                                textAlign: 'center',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                backgroundColor: 'lightgray',
                            }}
                            className="dropzone"
                        >
                            <input {...getInputProps()} />
                            <Icon fontSize="large">photo_camera</Icon>
                            <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {initialValues.img ?
                            <>
                                <Card sx={{ width: '100%' }}>
                                    <div style={divStyle}>
                                        <img src={initialValues.img} alt="Votre profil" style={imgStyle} />
                                    </div>
                                </Card>

                                <br />
                            </> :
                            <>
                                <Typography variant='h5'>
                                    Aucune image sélectionnée
                                </Typography>
                            </>
                        }
                    </Grid>

                </Grid>

                <br />

                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    name="nom"
                    label="Votre nom"
                    variant="outlined"
                    value={initialValues.nom}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    name="prenom"
                    label="Votre prenom"
                    variant="outlined"
                    value={initialValues.prenom}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                <Box sx={{ mb: 3 }}>
                    <Typography>Genre</Typography>
                    <RadioGroup
                        className="group"
                        row
                    >
                        {genres.map((genre, index) => (
                            <FormControlLabel key={index} name='idgenre' onChange={changeInitialValue} value={genre.id} control={< Radio />} label={genre.nom} />
                        ))}
                    </RadioGroup>
                </Box>

                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    name="datenaissance"
                    label="Votre date de naissance"
                    variant="outlined"
                    value={initialValues.datenaissance}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                    required
                />

                <TextField
                    fullWidth
                    size="small"
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    value={initialValues.email}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                {initialValues.contact.map((value, index) => (
                    <div key={index}>
                        <TextField
                            fullWidth
                            size="small"
                            type="tel"
                            name="contact"
                            label="Contact"
                            variant="outlined"
                            value={value}
                            sx={{ mb: 1 }}
                            onChange={(e) => handleContactChange(e, index)}
                        /></div>
                ))}

                <Fab style={{ zIndex: '0' }} sx={{ mb: 1.5 }} onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                    <Icon>phone</Icon>
                </Fab>

                {initialValues.idgenre !== 0 && initialValues.datenaissance &&
                    <Link to={'/entreprise/reparation/personnels/ajout/affectation'} state={{ personnel: initialValues }}>
                        <StyledButton fullWidth variant="contained" component="span">
                            Suivant <ChevronRightIcon />
                        </StyledButton>
                    </Link>
                }

            </SimpleCard>
        </>
    );
};

export default AjoutPersonnelComponent;