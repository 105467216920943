import { Card, Box, styled, Grid, Button, Avatar } from '@mui/material';
import { SimpleCard } from 'app/components';
import ModalDetail from '../utils/ModalDetail';
import DetailVehicule from '../Vehicule/DetailVehicule';
import DetailDemande from './DetailDemande';
import ModalUpdate from '../utils/ModalUpdate';
import UpdateLocation from './UpdateLocation';
import ModalDelete from '../utils/ModalDelete';
import { Link } from 'react-router-dom';
import { divStyle, imgStyle } from '../utils/Style';



const CardRoot = styled(Card)({
    height: '250px',
    width: '200px',
    padding: '20px 24px',
});

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && '16px'
}));

const PublicationClient = ({ publication }) => {
    const styleImage = {
        width: "100px",
        heigth: "80px"
    }




    const styleP = {
        fontSize: "10px"
    }

    // const cardStyle
    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={4} md={4}>
                    {/* <CardRoot elevation={12}> */}
                    <SimpleCard>
                        <Box width="100%" overflow="auto">
                            {/* <div style={divStyle}>
                                <img style={imgStyle} src={publication.client.photo ? publication.client.photo : ''} alt={`${publication.client.nom} ${publication.client.nom}`} />
                            </div> */}
                            <>
                                <Avatar src={publication.client.photo} />
                            </>
                            {/* :
                            <>
                                <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                                    {publication.client.nom.charAt(0)}{publication.client.nom.charAt(0)}
                                </Avatar>
                            </> */}
                            {/* <img style={styleImage} src={publication.client.photo}></img> */}
                            <p style={styleP}>Nom Client : {publication.client.nom}</p>
                            {/* <p>{vehicule.categorie}</p> */}
                            <p style={styleP}>Contact : {publication.client.contact}</p>
                            <p style={styleP}>Email : {publication.client.email}</p>
                            <ModalDetail>

                            </ModalDetail>
                        </Box>
                    </SimpleCard>

                    {/* </CardRoot> */}
                </Grid>
                <Grid item lg={4} md={4}>
                    <div>

                        <p>Date Location:{publication.date_debut} jusqu'au {publication.date_debut}</p>
                        <p>Nombre Personne : {publication.nombre_places} (personnes)</p>
                        <p>Categorie Voiture du CLient sont :</p>
                        {publication.categorie_vehicule.map((categorie, index) => (
                            <p key={index}>Categorie : {categorie.categorie.categorie_voiture}</p>
                        ))}
                        <p>Prix entre {publication.budjet_min} Ar et {publication.budjet_max} Ar</p>
                    </div>
                </Grid>
                <Grid item lg={4} md={4}>
                    <ModalDetail></ModalDetail>
                    {publication.etat_publication !== 0 && (
                        <Link to={'/entreprise/locations/clients/publications/repondre-publication'} state={{ publication: publication }}>
                            <Button>Repondre</Button>
                        </Link>
                    )}
                    {publication.etat_publication === 0 && (
                        <p>Publication Desactiver</p>
                    )}
                </Grid>
            </Grid >
        </>

    );
};

export default PublicationClient;
