import myConfig from "app/config/api-config";
import axios from "axios"

const listCategories = [
    {
        id: 1,
        nom: 'Motorisé',
    },
    {
        id: 2,
        nom: 'Nautique',
    },
    {
        id: 3,
        nom: 'Terrestre',
    },
    {
        id: 4,
        nom: 'Visite',
    },
    {
        id: 5,
        nom: 'Aérienne',
    },
]

// export const getActivitePrimaryRessources = () => {
//     return listCategories
// }

export const getActiviteImage = (path) => {
    return axios.get(`${myConfig.host}${path}`, {
        responseType: 'blob',
    })
}

export const createFormData = (formData, key, data) => {
    if (!(data instanceof File) && data === Object(data) || Array.isArray(data)) {
        for (var i in data) {
            createFormData(formData, key + '[' + i + ']', data[i]);
        }
    } else {
        formData.append(key, data);
    }
}

export const saveActiviteAPI = (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
        createFormData(formData, key, value);
    });
    return axios.post(`${myConfig.host}/api/admin/activite/`, formData, {

    });
}

export const UpdateActiviteAPI = (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
        createFormData(formData, key, value);
    });
    return axios.post(`${myConfig.host}/api/admin/activite/${payload.id}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export const getActivitePrimaryRessources = () => {
    return axios.get(`${myConfig.host}/api/admin/activite/primaryRessource`, {

    });
}

export const getActivite = (params) => {
    return axios.get(`${myConfig.host}/api/admin/activite`, {
        params
    })
}

export const getActiviteById = (id) => {
    return axios.get(`${myConfig.host}/api/admin/activite/${id}`, {

    });
}


export const DeleteActivite = (id) => {
    return axios.delete(`${myConfig.host}/api/admin/activite/${id}`, {

    });
}
