import myConfig from "app/config/api-config";
import axios from "axios"

export const getFeedback = (payload) => {
    let url = `${myConfig.host}/api/contactInformation?1=1`;
    if (payload) {
        let keys = Object.keys(payload);
        keys.forEach(key => {
            url += `&${key}=${payload[key]}`;
        });
    }
    // console.log('url : ', url);
    return axios.get(url);
}