export const listChangementTypeReparations = [
    {
        id: 1,
        changement: 'Batterie',
        date: '11/11/2023',
        entreprise: {
            id: 1,
            nom: 'E-CAR',
            email: 'ecar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
        typereparation: {
            id: 1,
            nom: 'Alternateur et demarreur'
        }
    },
    {
        id: 2,
        changement: 'Kit de Distribution',
        date: '11/11/2023',
        entreprise: {
            id: 2,
            nom: 'E-RENTCAR',
            email: 'erentacar@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',
        },
        typereparation: {
            id: 2,
            nom: 'Distribution'
        }
    },
    {
        id: 3,
        changement: 'Frein',
        date: '11/11/2023',
        entreprise: {
            id: 3,
            nom: 'E-REPAIR',
            email: 'erepair@gmail.com',
            logo: 'https://img.freepik.com/vecteurs-premium/conception-logo-voiture-concept-simple-moderne-vecteur-eps-10_567423-203.jpg',  
        },
        typereparation: {
            id: 3,
            nom: 'Freinage'
        }
    },
];