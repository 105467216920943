import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
//import { getListeExcursion } from "app/data/nosybe/Excursion";
// import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import ModalDeleteExcursion from "./ModalDeleteExcursion";
import ModalEditExcursion from "./ModalEditExcursion";
import { getExcursionListAPI, getListeExcursionDetail } from "./API-Excursion";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListExcursionComponent = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [Excursion, setExcursion] = useState([]);
    const [selectedExcursion, setSelectedExcursion] = useState(undefined);
    const [ExcursionTotal, setExcursionTotal] = useState(0);

    const Admin = JSON.parse(localStorage.getItem("admin") || null);

    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
        //getListeExcursion(setExcursions, setExcursionTotal, newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        //getListeExcursion(setExcursions, setExcursionTotal, 0, +event.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedExcursion(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (Excursion) => {
        setSelectedExcursion(Excursion);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedExcursion(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (Excursion) => {
        console.log(Excursion)
        setSelectedExcursion(Excursion);
        setOpenModalEdit(true);
    }

    const [dataSend, setDataSend] = useState({
        "offset": page,
        "limit": rowsPerPage,
        "search": recherche
    });


    const getExcursionListe = async () => {
        await getExcursionListAPI(Admin.id, setExcursion, setExcursionTotal);
    }

    useEffect(() => {
        // getListeExcursion(setExcursions, setExcursionTotal);
        getExcursionListe();
        console.log("Excursion", Excursion);
    }, [selectedExcursion]);

    return (
        <>
            {/* <TestButton toLog={Excursions} /> */}
            <SimpleCard title={'Liste des Excursion'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {Excursion.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom</TableCell>

                                        {/* <TableCell align="center">Prix</TableCell> */}
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Excursion.filter(val => (val.nom.toLowerCase().match(recherche))).map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{item.nom}</TableCell>
                                            {/* <TableCell align="center">{Excursion.prix}</TableCell> */}
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(item)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(item)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                    }
                                </TableBody>
                            </StyledTable>

                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard>
            {selectedExcursion &&
                <>
                    <ModalDeleteExcursion Excursion={selectedExcursion} setExcursion={setExcursion} open={openModalDelete} handleClose={handleCloseModalDelete} />

                    <ModalEditExcursion Excursion={selectedExcursion} setExcursion={setExcursion} open={openModalEdit} handleClose={handleCloseModalEdit} />
                </>
            }
        </>
    )
}

export default ListExcursionComponent;