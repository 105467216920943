import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
import {
    Alert,
    CircularProgress,
    Dialog,
    Icon,
    IconButton,
    Snackbar,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import axios from 'axios';
import myConfig from 'app/config/api-config';

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListeTarifComponent = () => {

    const [tarifs, setTarif] = useState(null);

    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);


    function getTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTarif(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTarif();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [deleteTarif, setDeleteTarif] = useState(null);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => {
        setOpenModalDelete(false);
    }

    useEffect(() => {
        if (deleteTarif) {
            setOpenModalDelete(true);
        }
    }, [deleteTarif]);

    function supprimerTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes/${deleteTarif.id}`,
            method: 'DELETE',
            data: deleteTarif
        }
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getTarif();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })

        setOpenModalDelete(false);
    }

    const [editTarif, setEditTarif] = useState(null);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    useEffect(() => {
        if (editTarif) {
            setOpenModalEdit(true);
        }
    }, [editTarif]);

    function modifierTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes/${editTarif.id}`,
            method: 'PUT',
            data: editTarif
        }
        console.log(requestOptions);
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getTarif();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        setOpenModalEdit(false);
    }

    return (
        <>
            <SimpleCard title={'Liste des tarifs'}>
                <TextField
                    fullWidth
                    size="small"
                    name="recherche"
                    type="text"
                    label="Recherche"
                    variant="outlined"
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value)}
                    sx={{ mt: 1.5 }}
                />
                {tarifs ? <>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Nom</TableCell>
                                <TableCell align="center">Tarifs</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tarifs
                                    .filter((data) =>
                                        data.typedemande.nom.toLowerCase().includes(recherche)
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((tarif, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{tarif.typedemande.nom}</TableCell>
                                            <TableCell align="center">{tarif.valeur}%</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => setEditTarif(tarif)} >
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => setDeleteTarif(tarif)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                    </StyledTable>
                </> : <>
                    <center><CircularProgress></CircularProgress></center>
                </>}



                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={tarifs ? tarifs.length : 0}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[4, 8, 12]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />

            </SimpleCard>

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}

            {/* modal edit */}
            {editTarif &&
                <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                        Modifer {editTarif.typedemande.nom}
                    </DialogTitle>

                    <DialogContent dividers>
                        <TextField
                            fullWidth
                            size='medium'
                            name="tarif"
                            type="number"
                            label="Tarif"
                            value={editTarif.valeur}
                            onChange={(e) => setEditTarif({ ...editTarif, valeur: e.target.value })}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={modifierTarif} color='success'>
                            Appliquer
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}

            {/* modal delete */}
            {deleteTarif &&
                <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                        Retirer {deleteTarif.typedemande.nom}
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Vous en etes sur?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={supprimerTarif} color='error'>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}
        </>
    );
};

export default ListeTarifComponent;