import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FormArticle from './FormArticle';
import { useEffect } from "react";
import { useState } from "react";
import { getListeArticleDetail } from "./API-Article";


const ModalEditArticle = ({ Article, setArticle, open, handleClose }) => {

    console.log(Article, " Article")

    const [ArticleUpdate, setArticleUpdate] = useState(Article);


    const getArticleDetailFX = async () => {
        await getListeArticleDetail(Article.id, setArticleUpdate);
    }

    useEffect(() => {
        //getArticleDetailFX();
    }, [])

    return (
        <Dialog maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modification
            </DialogTitle>

            <DialogContent dividers>
                {ArticleUpdate && (
                    <FormArticle selectedArticle={ArticleUpdate} setArticles={setArticle} closeForm={handleClose} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalEditArticle;