import { LoadingButton } from '@mui/lab';
import { Alert, Card, CircularProgress, Grid, Snackbar, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/material';
import { Paragraph } from 'app/components/Typography';
import { Formik } from 'formik';
import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import innoCarLogo from '../../../../logo/tiny_logo.png';
import { useEffect } from 'react';
import url from 'app/UrlConfig';
import { dispatchUpdate } from 'app/data/piece/users';
import { listEntreprise } from 'fake-db/listEntreprise';
import { connexionAdmin } from 'app/data/nosybe/admin';
import TestButton from 'app/myComponents/TestButton';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)'
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: '#1A2038',
  minHeight: '100% !important',
  '& .card': {
    maxWidth: 800,
    minHeight: 400,
    margin: '1rem',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center'
  }
}));

// inital login credentials

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'password must be 6 character length')
    .required('password is required!'),
  email: Yup.string().email('Invalid Email address').required('Email is required!')
});

const MyLogin = () => {
  const location = useLocation();
  const errorData = location.state;

  document.title = "Connexion";

  const theme = useTheme();
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [initialValues, setInitialValue] = useState({
    email: '',
    password: ''
  });
  function handleChangeInitialValues(e) {
    // console.log("handleChangeInitialValues: ", e.target.name, e.target.value);
    setInitialValue({ ...initialValues, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (errorData) {
      setOpen(true);
      setMessage(errorData.message);
    }
  }, [errorData]);

  const [displayCircular, setDisplayCircular] = useState(false);

  const handleFormSubmit = async () => {
    console.log("handleFormSubmit");
    connexionAdmin(initialValues)
      .then(response => {
        localStorage.setItem("token", response.data.tokenAdmin)
        localStorage.setItem("type", 1);
        localStorage.setItem("user", "entreprise");
        localStorage.setItem("admin", JSON.stringify(response.data.admin));
        navigate("/entreprise/profile");
      })
      .catch(error => {
        console.error(error);
        setMessage(error.response.data.message);
        setOpen(true);
      })
      .finally(() => {
        setDisplayCircular(false);
      })
  }

  return (
    <JWTRoot>
      {/* <TestButton toLog={initialValues} /> */}
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <JustifyBox p={4} height="100%" sx={{ minWidth: 320 }}>
              <img
                src={innoCarLogo}
                width="100%"
                alt="" />
            </JustifyBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ContentBox>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ errors, touched, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <h2>NOSYBE-CONNEXION</h2>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={initialValues.email}
                      onChange={handleChangeInitialValues}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      value={initialValues.password}
                      onChange={handleChangeInitialValues}
                      // onBlur={handleBlur}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{ mb: 1.5 }}
                    />

                    <LoadingButton
                      onClick={handleFormSubmit}
                      fullWidth
                      type="button"
                      color="primary"
                      variant="contained"
                      sx={{ my: 2 }}
                      disabled={displayCircular === true}
                    >
                      {displayCircular ?
                        <>
                          <CircularProgress></CircularProgress>
                        </>
                        :
                        <>
                          Connexion
                        </>
                      }
                    </LoadingButton>

                    <Paragraph>
                      Pas de compte?
                      <NavLink
                        to="/entreprise/inscription"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                      >
                        Créer un
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>

      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
      >
        <Alert
          severity='error'
        >
          {message}
        </Alert>
      </Snackbar>

    </JWTRoot>
  );
};

export default MyLogin;
