import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Card, CardMedia, CircularProgress, Fab, FormControl, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, styled, useTheme } from "@mui/material";
import { SimpleCard } from "app/components";
import myConfig from "app/config/api-config";
import { changeAdminPassword, updateAdminInfo } from "app/data/nosybe/admin";
import { displayToast, manageError } from "app/data/piece/dispatch";
import { dispatchAdminUpdate } from "app/data/piece/users";
import TestButton from "app/myComponents/TestButton";
import axios from "axios";
import { Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import * as Yup from 'yup';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Password must be 6 character length')
        .required('Password is required!'),
    email: Yup.string().email('Invalid Email address').required('Email is required!')
});

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const MyAccount = () => {
    document.title = "Profile";

    const [originalAdmin, setOriginalAdmin] = useState();
    const [admin, setAdmin] = useState();
    const [password, setPassword] = useState({
        oldPassword: '',
        newPassword: '',
        confirmationPassword: '',
    });

    const changeAdmin = (e) => {
        setAdmin({ ...admin, [e.target.name]: e.target.value });
    }

    const updateInfo = () => {
        updateAdminInfo(admin)
            .then(response => {
                localStorage.setItem('admin', JSON.stringify(response.data.admin));
                displayToast("Mise a jour avec succés");
                dispatchAdminUpdate();
            })
            .catch(error => manageError(error, "updateAdminInfo:"))
    }

    const cancel = () => {
        setAdmin({ ...originalAdmin });
    }

    const handleChangePassword = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    }

    const changePassword = () => {
        changeAdminPassword(password)
            .then(response => {
                displayToast("Mot de passe changé avec succés");
            })
            .catch(error => manageError(error, "changeAdminPassword:"))
    }

    function addContact() {
        const contactData = [...admin.contact];
        contactData.push('+261');
        setAdmin({ ...admin, contact: contactData });
    }

    const removeContact = (index) => {
        const contactData = admin.contact.filter((_, i) => i !== index);
        setAdmin({ ...admin, contact: contactData });
    }

    function handleContactChange(e, index) {
        const contactData = [...admin.contact];
        contactData[index] = e.target.value;
        setAdmin({ ...admin, contact: contactData });
    }

    const isValidImageType = (file) => {
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp', 'image/svg'];
        return allowedTypes.includes(file.type);
    };

    function handleFileChange(event) {
        const file = event.target.files[0];

        if (!file) {
            // console.log("not file");
            return; // Handle empty file selection gracefully
        }

        // Validate file type (optional)
        if (!isValidImageType(file)) {
            // console.log("image not valid");
            // Display error message or handle invalid type in a suitable way
            console.error('Invalid file type. Please select an image.');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const base64 = e.target.result;
            // const imagePath = `public/img/admin/${file.name}`;
            setAdmin({
                ...admin,
                images: base64,
                // imagesPath: imagePath,
            });
        };
        reader.readAsDataURL(file);
    }

    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const getProfil = () => {
        const admin = JSON.parse(localStorage.getItem('admin'));
        const requestOptions = {
            url: `${myConfig.host}/api/admin/profile/${admin.id}`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            let adminProfil = response.data.admin;
            const contact = [];
            if (adminProfil.contact) {
                adminProfil.contact.forEach(value => {
                    contact.push(value.contact);
                });
            }
            adminProfil.contact = contact
            setAdmin(adminProfil);
            setOriginalAdmin(adminProfil);
        }).catch((e) => {
            manageError(e, "getProgfile: ")
        })
    }

    useEffect(() => {
        getProfil();
    }, [])

    return (
        <>
            {/* <TestButton toLog={admin} /> */}
            <Typography variant="h5">Profile</Typography>
            {
                admin ? (
                    <>
                        <SimpleCard title={'Modifier les informations'}>
                            <Box p={4} height="100%">
                                <Formik
                                    onSubmit={updateInfo}
                                    initialValues={admin}
                                    validationSchema={validationSchema}
                                >
                                    {({ errors, touched, handleBlur, handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="container">
                                                {/* <label htmlFor="contained-button-file">
                                                    <StyledButton variant="contained" component="span">
                                                        Ajouter votre logo
                                                    </StyledButton>
                                                </label>
                                                <input
                                                    onChange={handleFileChange}
                                                    accept="image/*"
                                                    style={{ visibility: 'hidden' }}
                                                    className="input"
                                                    id="contained-button-file"
                                                    multiple type="file"
                                                />
                                                {admin.images &&
                                                    <>
                                                        <Card sx={{ maxWidth: 345 }}>
                                                            <CardMedia
                                                                sx={{ height: 140 }}
                                                                image={admin.images}
                                                                title="Your profil"
                                                            />
                                                        </Card>
                                                        <br />
                                                    </>
                                                } */}
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    name="nom"
                                                    label="Votre nom"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    value={admin.nom}
                                                    onChange={changeAdmin}
                                                    helperText={touched.nom && errors.nom}
                                                    error={Boolean(errors.nom && touched.nom)}
                                                    sx={{ mb: 3 }}
                                                />

                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    name="nomRepresentant"
                                                    label="Nom du représentant"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    value={admin.nomRepresentant}
                                                    onChange={changeAdmin}
                                                    helperText={touched.nomRepresentant && errors.nomRepresentant}
                                                    error={Boolean(errors.nomRepresentant && touched.nomRepresentant)}
                                                    sx={{ mb: 3 }}
                                                />
                                            </div>

                                            {admin.contact.map((value, index) => (
                                                <FormControl fullWidth variant="outlined" key={index} sx={{ mb: 2 }}>
                                                    <InputLabel htmlFor={`contact-input-${index}`}>Contact</InputLabel>
                                                    <OutlinedInput
                                                        id={`contact-input-${index}`}
                                                        size="small"
                                                        type="tel"
                                                        name="contact"
                                                        label="Contact"
                                                        value={value}
                                                        onChange={(e) => handleContactChange(e, index)}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => removeContact(index)}
                                                                    edge="end"
                                                                >
                                                                    <Icon>clear</Icon>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            )
                                            )}

                                            <Fab onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                                                <Icon>phone</Icon>
                                            </Fab>
                                            <br />
                                            <br />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="adresse"
                                                label="Adresse"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={admin.adresse}
                                                onChange={changeAdmin}
                                                helperText={touched.adresse && errors.adresse}
                                                error={Boolean(errors.adresse && touched.adresse)}
                                                sx={{ mb: 3 }}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="nif"
                                                label="NIF"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={admin.nif}
                                                onChange={changeAdmin}
                                                helperText={touched.nif && errors.nif}
                                                error={Boolean(errors.nif && touched.nif)}
                                                sx={{ mb: 3 }}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="stat"
                                                label="STAT"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={admin.stat}
                                                onChange={changeAdmin}
                                                helperText={touched.stat && errors.stat}
                                                error={Boolean(errors.stat && touched.stat)}
                                                sx={{ mb: 3 }}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="license"
                                                label="License"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={admin.license}
                                                onChange={changeAdmin}
                                                helperText={touched.license && errors.license}
                                                error={Boolean(errors.license && touched.license)}
                                                sx={{ mb: 3 }}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="email"
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={admin.email}
                                                onChange={changeAdmin}
                                                sx={{ mb: 3 }}
                                            />

                                            <LoadingButton
                                                onClick={updateInfo}
                                                fullWidth
                                                type="button"
                                                color="primary"
                                                variant="contained"
                                                sx={{ mb: 2, mt: 3 }}
                                            >
                                                Enregistrer
                                            </LoadingButton>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </SimpleCard>
                        <Box sx={{ py: '12px' }} />
                        <SimpleCard title={'Changer de mot de passe'}>
                            <Box p={4} height="100%">
                                <Formik
                                    onSubmit={changePassword}
                                    initialValues={password}
                                    validationSchema={validationSchema}
                                >
                                    {({ errors, touched, handleBlur, handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="oldPassword"
                                                type="password"
                                                label="Ancient Mot de passe"
                                                id="idoldmdp"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={password.oldPassword}
                                                onChange={handleChangePassword}
                                                helperText={touched.oldPassword && errors.oldPassword}
                                                error={Boolean(errors.oldPassword && touched.oldPassword)}
                                                sx={{ mb: 2 }}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="newPassword"
                                                type="password"
                                                label="Mot de passe"
                                                id="idmdp"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={password.newPassword}
                                                onChange={handleChangePassword}
                                                helperText={touched.newPassword && errors.newPassword}
                                                error={Boolean(errors.newPassword && touched.newPassword)}
                                                sx={{ mb: 2 }}
                                            />

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="confirmationPassword"
                                                type="password"
                                                label="Confirmation de mot de passe"
                                                variant="outlined"
                                                id='confirmationmdpid'
                                                value={password.confirmationPassword}
                                                onChange={handleChangePassword}
                                                sx={{ mb: 2 }}
                                            />

                                            {
                                                password.newPassword === password.confirmationPassword ?
                                                    <>
                                                        <LoadingButton
                                                            onClick={changePassword}
                                                            fullWidth
                                                            type="submit"
                                                            color="primary"
                                                            variant="contained"
                                                            sx={{ mb: 2, mt: 3 }}
                                                        >
                                                            Enregistrer
                                                        </LoadingButton>
                                                    </>
                                                    :
                                                    <>
                                                        <Alert severity="error">Confirmer votre mot de passe!</Alert>
                                                    </>
                                            }
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </SimpleCard>
                    </>
                ) : (
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                )
            }
        </>
    )
}

export default MyAccount;