import { Alert, Snackbar, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, CircularProgress, Divider, Grid, Icon, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

// Line Chart

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// 

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const ReparationEvolutionComponent = ({ height }) => {

    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // datas

    const [init, setInit] = useState(true);

    const [reparations, setListReparation] = useState(null);

    const getListReparation = () => {
        let myUrl = `${myConfig.host}/api/reparation/entreprise/reparations`;
        const requestOptions = {
            url: myUrl,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            const datas = (response.data);
            const tab = [];
            datas.forEach(reparation => {
                tab.push({
                    id: reparation.id,
                    label: reparation.nom,
                    color: reparation.codecouleur
                });
            });
            setListReparation(tab);
        }).catch((e) => {
            console.log(e);
        })
    };

    useEffect(() => {
        getListReparation();
    }, []);

    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedOption, setSelectedOption] = useState(null);
    const handleAutocompleteChange = (event, newValue) => {
        console.log(newValue);
        setSelectedOption(newValue);
    };

    const getHistoriquePrix = () => {
        setInit(false);

        if (selectedOption) {
            let myUrl = `${myConfig.host}/api/reparation/entreprise/stat/reparationHistoriquePrix/${selectedOption.id}?year=${year}`;
            const requestOptions = {
                url: myUrl,
                method: 'GET'
            };
            axios(requestOptions).then((response) => {
                const datas = response.data.datas;
                const optionMonth = [];
                for (let index = 0; index < datas.months.length; index++) {
                    const element = datas.months[index].nom;
                    optionMonth.push(element);
                }

                const lineChartOptions = {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: `${selectedOption.label}`,
                        },
                    },
                };

                setLineChartOptions(lineChartOptions);

                const labels = optionMonth;

                const lineChartData = {
                    labels,
                    datasets: [
                        {
                            label: `${selectedOption.label} ${year}`,
                            data: datas.prices,
                            borderColor: selectedOption.color,
                        },
                        {
                            label: `${selectedOption.label} ${year - 1}`,
                            data: datas.pricesAvant,
                            borderColor: 'rgb(148,88,5)',
                        },
                    ],
                };

                setLineChartData(lineChartData);
                setColorMessage('success');
                setMessage(`Les prix de ${selectedOption.label} en  ${year}`);
                setOpen(true);

            }).catch((e) => {
                console.log(e);
            })

        } else {
            setInit(true);
            setColorMessage('error');
            setMessage('Réparation introuvable');
            setOpen(true);
        }
    }
    //

    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // Line Chart

    const [lineChartOptions, setLineChartOptions] = useState(null);
    const [lineChartData, setLineChartData] = useState(null);

    // 

    return (
        <>
            <AccordionRoot>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Box className="column">
                            <Typography className="heading">TABLEAU DE BORD</Typography>
                        </Box>

                        <Box className="column">
                            <Typography className="secondaryHeading">Filtrer</Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails className="details">
                        <Box className="column helper">

                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6} xs={12} sm={12}>
                                    {
                                        reparations ?
                                            <>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={reparations}
                                                    size='small'
                                                    fullWidth
                                                    onChange={handleAutocompleteChange}
                                                    renderInput={
                                                        (params) =>
                                                            <TextField {...params} label="Nom de la réparation" />
                                                    }
                                                />
                                            </>
                                            :
                                            <>
                                                <center>
                                                    <CircularProgress></CircularProgress>
                                                </center>
                                            </>
                                    }
                                </Grid>
                                <Grid item lg={6} md={6} xs={12} sm={12}>
                                    <TextField
                                        fullWidth
                                        label='Année'
                                        size='small'
                                        type='number'
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                        </Box>
                    </AccordionDetails>

                    <Divider />

                    <AccordionActions>
                        <Button onClick={getHistoriquePrix} size="small" variant='contained' color="primary">
                            Filtrer
                        </Button>
                    </AccordionActions>
                </Accordion>
            </AccordionRoot>

            <br />

            <SimpleCard title="Evolution de prix">
                {
                    init === false ?
                        <>
                            {
                                (lineChartOptions !== null && lineChartData !== null) ?
                                    <>
                                        <Line options={lineChartOptions} data={lineChartData} />;
                                    </>
                                    :
                                    <>
                                        <center>
                                            <CircularProgress></CircularProgress>
                                        </center>
                                    </>
                            }
                        </>
                        :
                        <></>
                }
            </SimpleCard>

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}
        </>
    );
};

export default ReparationEvolutionComponent;
