import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FormHebergement from './FormHebergement';
import { useEffect } from "react";
import { useState } from "react";
import { getListeHebergementDetail } from "./API-Hebergement";

const ModalEditHebergement = ({ hebergement, setHebergements, open, handleClose }) => {

    console.log(hebergement, " HEBERGEMENBT")

    const [hebergementUpdate, setHebergementUpdate] = useState(undefined);


    const getHebergementDetailFX = async () => {
        await getListeHebergementDetail(hebergement.id, setHebergementUpdate);
    }

    useEffect(() => {
        getHebergementDetailFX();
    }, [])

    return (
        <Dialog maxWidth='xl' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modifier {hebergement.titre_fr}
            </DialogTitle>

            <DialogContent dividers>
                {hebergementUpdate && (
                    <FormHebergement selectedHebergement={hebergementUpdate} setHebergements={setHebergements} closeForm={handleClose} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalEditHebergement;