export const listClient = [
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Jean',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotojean@gmail.com',
        etat: 21,
        dateCreation: '01/11/2023',
        dateDerniereActivite: '12/12/2023'
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Ando',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoAndo@gmail.com',
        etat: 0,
        dateCreation: '11/01/2023',
        dateDerniereActivite: '02/12/2023'
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Doda',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoDoda@gmail.com',
        etat: 11,
        dateCreation: '11/03/2023',
        dateDerniereActivite: ''
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Rasoa',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoRasoa@gmail.com',
        etat: 21,
        dateCreation: '11/04/2023',
        dateDerniereActivite: '12/12/2023'
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Haja',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoHaja@gmail.com',
        etat: 0,
        dateCreation: '11/05/2023',
        dateDerniereActivite: '12/12/2023'
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Anja',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoAnja@gmail.com',
        etat: 11,
        dateCreation: '11/06/2023',
        dateDerniereActivite: ''
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Ravo',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoravo@gmail.com',
        etat: 0,
        dateCreation: '11/07/2023',
        dateDerniereActivite: '12/11/2023'
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Eric',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotoeric@gmail.com',
        etat: 11,
        dateCreation: '11/09/2023',
        dateDerniereActivite: ''
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Paul',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotopaul@gmail.com',
        etat: 11,
        dateCreation: '11/10/2023',
        dateDerniereActivite: ''
    },
    {
        id: 1,
        nom: 'RAKOTO',
        prenom: 'Jeanne',
        profil: 'https://thumbs.dreamstime.com/b/vecteur-d-icône-homme-affaires-image-masculine-de-profil-avatar-mâle-182095609.jpg',
        email: 'rakotojeanne@gmail.com',
        etat: 21,
        dateCreation: '11/11/2023',
        dateDerniereActivite: '12/12/2023'
    },
];