import { Card, Box, styled } from '@mui/material';



const CardRoot = styled(Card)({
    height: '150px',
    padding: '20px 24px',
});

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && '16px'
}));

const DashBordSuivi = ({ categorie, nombre }) => {
    return (
        <CardRoot elevation={6}>
            <CardTitle subtitle={categorie}><h3>Voiture {categorie}</h3>   <h1>Nombre  {nombre}</h1></CardTitle>
        </CardRoot>


    );
};

export default DashBordSuivi;
