import { useState } from "react";
import { useEffect } from "react";
import { SimpleCard } from "app/components";
import { Box, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select, TablePagination, TextField, Typography, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const SousCategorieComponent = () => {
    const [categories, setCategories] = useState([]);
    const [sousCategories, setSousCategories] = useState([]);
    const [sousCategorieEdit, setSousCategorieEdit] = useState(null);
    const [sousCategorieCreate, setSousCategorieCreate] = useState({
        idcategorie: 0,
        nom: '',
    });

    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openMockEdit, setOpenMockEdit] = useState(false);

    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    //mock modal
    const handleCloseMockEdit = () => setOpenMockEdit(false);
    const displayEditMock = () => {
        setOpenMockEdit(true);
    }

    //page handling
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //create modal
    const handleCloseModalCreate = () => setOpenModalCreate(false);
    const handleCreateChange = (event) => {
        setSousCategorieCreate({
            ...sousCategorieCreate,
            [event.target.name]: event.target.value,
        })
    }
    const createSousCategorie = () => {
        handleCloseModalCreate();
    }

    //edit modal
    const handleCloseModalEdit = () => setOpenModalEdit(false);
    const displayEditModal = (sousCategorie) => {
        setSousCategorieEdit(sousCategorie);
        setOpenModalEdit(true);
    }
    const handleEditChange = (event) => {
        setSousCategorieEdit({
            ...sousCategorieEdit,
            [event.target.name]: event.target.value,
        })
    }
    const editSousCategorie = () => {
        handleCloseModalEdit();
    }

    //delete modal
    const handleCloseModalDelete = () => setOpenModalDelete(false);
    const displayDeleteModal = (sousCategorie) => {
        setSousCategorieEdit(sousCategorie);
        setOpenModalDelete(true);
    }
    const deleteSousCategorie = () => {
        handleCloseModalDelete();
    }

    const test = () => {
        console.log("create: ", sousCategorieCreate);
    }


    useEffect(() => {

    }, [])

    return (
        <>
            <SimpleCard title={'Sous-Categorie'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
                <IconButton onClick={() => setOpenModalCreate(true)}>
                    <Icon color='primary'>add</Icon>
                </IconButton>
                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={sousCategories.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box sx={{ mt: 3 }} width={'100%'}>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={3}>
                        <Card sx={{ maxWidth: 345 }}>

                            <CardContent>
                                <Typography gutterBottom sx={{ textAlign: 'center' }} variant="h5" component="div">
                                    Plaquettes de frein
                                </Typography>
                            </CardContent>

                            <CardActions>
                                <IconButton onClick={() => displayEditMock()}>
                                    <Icon color='success'>edit</Icon>
                                </IconButton>
                                <IconButton>
                                    <Icon color='error'>delete</Icon>
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    {sousCategories
                        .filter((sousCategorie) =>
                            sousCategorie.nom.toLowerCase().includes(recherche)
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((sousCategorie, index) =>
                            <Grid key={index} item lg={3} md={3}>
                                <Card sx={{ maxWidth: 345 }}>

                                    <CardContent>
                                        <Typography gutterBottom sx={{ textAlign: 'center' }} variant="h5" component="div">
                                            {sousCategorie.nom}
                                        </Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton onClick={() => displayEditModal(sousCategorie)}>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => displayDeleteModal(sousCategorie)}>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>

            {/* modal create start */}
            <Dialog onClose={handleCloseModalCreate} aria-labelledby="customized-dialog-title" open={openModalCreate}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalCreate}>
                    Ajout de nouvelle sous-categorie
                </DialogTitle>

                <DialogContent dividers>
                    {/* <button onClick={test}>test</button> */}
                    <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                        <InputLabel filled id="demo-simple-select-autowidth-label">Categorie</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name='idcategorie'
                            value={categories.length !== 0 && sousCategorieCreate.idcategorie && sousCategorieCreate.idcategorie !== 0 ? sousCategorieCreate.idcategorie : ''}
                            onChange={handleCreateChange}
                            autoWidth
                            label="Sous-Categorie"
                        >
                            {categories.map((categorie, index) => (
                                <MenuItem key={index} value={categorie.id}>{categorie.nom}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        name='nom'
                        value={sousCategorieCreate.nom}
                        onChange={handleCreateChange}
                        label='Nom'
                        sx={{ mt: 2 }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalCreate}>
                        Fermer
                    </Button>
                    <Button color='success' onClick={createSousCategorie}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            {/* create end */}

            {openMockEdit &&
                <Dialog open={openMockEdit} fullWidth={true} maxWidth="lg" onClose={handleCloseMockEdit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseMockEdit}>
                        Modifier Plaquettes de frein
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3} sx={{
                            height: '600px',
                        }}>
                            <Grid item sm={4}>
                                <h3>Sous-Catégorie</h3>

                                <Typography variant="body1">Catégorie: Freins</Typography>

                                <TextField
                                    fullWidth
                                    size='small'
                                    type="text"
                                    label="Nom"
                                    value="Plaquettes de frein"
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    type="color"
                                    label="Couleur"
                                    value="#ff0000"
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                />

                                <Button fullWidth variant="contained" component="span">
                                    Modifier
                                </Button>
                            </Grid>
                            <Grid item sm={4} sx={{
                                height: '100%',
                                overflowY: 'auto',
                            }}>
                                <h3>Caractéristiques</h3>

                                <TextField
                                    fullWidth
                                    size='small'
                                    type="text"
                                    label="Nom"
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                />

                                <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                                    <InputLabel filled id="demo-simple-select-autowidth-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        autoWidth
                                        label="Sous-Categorie"
                                    >
                                    </Select>
                                </FormControl>

                                <Button fullWidth variant="contained" component="span">
                                    Ajouter
                                </Button>

                                <Box sx={{ py: '6px' }} />


                                <Card sx={{
                                    maxWidth: 345,
                                    background: "rgb(155,255,255)",
                                }}>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="text"
                                            label="Nom"
                                            value="Cote d'assemblage"
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                        <Typography variant="body1">Type: Choix</Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>

                                <Box sx={{ py: '6px' }} />

                                <Card sx={{ maxWidth: 345 }}>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="text"
                                            label="Nom"
                                            value="Epaisseur"
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                        <Typography variant="body1">Type: Numerique</Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>

                                <Box sx={{ py: '6px' }} />

                                <Card sx={{ maxWidth: 345 }}>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="text"
                                            label="Nom"
                                            value="Etat"
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                        <Typography variant="body1">Type: Choix</Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>

                                <Box sx={{ py: '6px' }} />

                                <Card sx={{ maxWidth: 345 }}>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="text"
                                            label="Nom"
                                            value="Longueur [mm]"
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                        <Typography variant="body1">Type: Numerique</Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>

                                <Box sx={{ py: '6px' }} />

                            </Grid>
                            <Grid item sm={4}>
                                <h3>Valeurs</h3>

                                <TextField
                                    fullWidth
                                    size='small'
                                    type="text"
                                    label="Valeur"
                                    variant="outlined"
                                    sx={{ mt: 1.5, mb: 2 }}
                                />

                                <Button fullWidth variant="contained" component="span">
                                    Ajouter
                                </Button>

                                <Box sx={{ py: '6px' }} />

                                <Card sx={{ maxWidth: 345 }}>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="text"
                                            label="Valeur"
                                            value="Essieu arriere"
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                    </CardContent>

                                    <CardActions>
                                        <IconButton>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>

                                <Box sx={{ py: '6px' }} />

                                <Card sx={{ maxWidth: 345 }}>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="text"
                                            label="Valeur"
                                            value="Essieu avant"
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                    </CardContent>

                                    <CardActions>
                                        <IconButton>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>

                                <Box sx={{ py: '6px' }} />

                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

            {sousCategorieEdit &&
                <>
                    {/* modal edit start */}
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier {sousCategorieEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                                <InputLabel filled id="demo-simple-select-autowidth-label">Categorie</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    name='idcategorie'
                                    value={categories.length !== 0 && sousCategorieEdit.idcategorie && sousCategorieEdit.idcategorie !== 0 ? sousCategorieEdit.idcategorie : ''}
                                    onChange={handleEditChange}
                                    autoWidth
                                    label="Sous-Categorie"
                                >
                                    {categories.map((categorie, index) => (
                                        <MenuItem key={index} value={categorie.id}>{categorie.nom}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='nom'
                                value={sousCategorieEdit.nom}
                                onChange={handleEditChange}
                                label='Nom'
                                sx={{ mt: 2 }}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>
                            <Button color='success' onClick={editSousCategorie}>
                                Modifier
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* edit end */}

                    {/* modal delete start */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            Retirer {sousCategorieEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={deleteSousCategorie} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* delete end */}
                </>
            }
        </>
    )
}
export default SousCategorieComponent;