export const dateToYYYYMMDD = (date) => {
    let result = '';
    try {
        result = `${date.getFullYear()}-${formatDateNumber(date.getMonth() + 1)}-${formatDateNumber(date.getDate())}`;
    } catch (error) {
        console.log('error dateToYYYYMMDD : ', error);
        result = '';
    }
    return result;
}

const formatDateNumber = (number) => {
    return number < 10 ? '0' + number : number;
}