import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import SousCategorieChangementComponent from 'app/myComponents/VentePiece/Admin/SousCategorieChangementComponent';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const SousCategorieChangement = () => {
    document.title = "Changement Sous-Categorie";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Vente Piece' },
                        { name: 'Sous-Categories', path: '/admin/pieces/souscategories' },
                        { name: 'Changement' }
                    ]}
                />
            </Box>

            <SousCategorieChangementComponent />

        </AppButtonRoot>
    )
}
export default SousCategorieChangement;