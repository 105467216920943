import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import { forwardRef, useState } from 'react';
import { useTheme } from '@mui/material';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { H6 } from 'app/components/Typography';
import { useEffect } from 'react';
import { SimpleCard } from 'app/components';
import ModalDetail from './ModalDetail';
import styled from '@emotion/styled';
import DetailVehicule from '../Vehicule/DetailVehicule';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));


const styleImage = {
    width: "100px",
    heigth: "80px"
}

const styleP = {
    fontSize: "10px"
}

const ModalFactureReponse = ({ reponsePublication }) => {

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleClickOpen = () => setOpen(true);
    const [nombreJours, setNombreJours] = useState();
    const [montantTotal, setMontantTotal] = useState();

    const getDiffJours = (date1, date2) => {
        const dateF = new Date(date1);
        const dateD = new Date(date2);
        const nbJours = dateF.getTime() - dateD.getTime();
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const differenceInDays = Math.floor(nbJours / millisecondsPerDay);
        return differenceInDays
    }

    const getTotal = (data) => {
        let somme = 0;
        const dataVEH = data.vehicules;
        console.log(dataVEH)
        for (let i = 0; i < dataVEH.length; i++) {
            somme += (dataVEH[i].prix_par_type_location * nombreJours);
            console.log(dataVEH[i].prix_par_type_location * nombreJours)
        }
        return somme;
    }

    useEffect(() => {
        setNombreJours(getDiffJours(reponsePublication.date_fin, reponsePublication.date_debut));
        setMontantTotal(getTotal(reponsePublication));
        console.log(reponsePublication.vehicules);
    }, [open])

    return (
        <Box>
            <Button fullWidth variant="contained" onClick={handleClickOpen} size="small" color="primary">Voir Facture Reponse</Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>

                        <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }}>Quitter</H6>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={6}>

                    {/* <CardRoot elevation={12}> */}
                    <Grid item lg={4} md={2} sm={12} xs={12} sx={{ mt: 2 }}>
                        <SimpleCard>
                            <Box width="100%" overflow="auto">
                                <img style={styleImage} src={reponsePublication.client.photo}></img>
                                <p style={styleP}>Nom Client : {reponsePublication.client.nom}</p>
                                {/* <p>{vehicule.categorie}</p> */}
                                <p style={styleP}>Contact : {reponsePublication.client.contact}</p>
                                <p style={styleP}>Email : {reponsePublication.client.email}</p>
                                <ModalDetail>
                                </ModalDetail>
                            </Box>

                        </SimpleCard>

                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: 2 }}>
                        <div>
                            <h1>Facture </h1>
                            <p>Date Location:{reponsePublication.date_debut} jusqu'au {reponsePublication.date_fin}</p>
                            <p>Nombre du jours : {nombreJours} jours</p>
                            <p>Nombre Personne : {reponsePublication.nombre_place} (personnes)</p>
                            <p>Categorie Voiture demandé par le client sont :</p>
                            {reponsePublication.categorie_vehicule.map((categorie, index) => (
                                <p key={index}>Categorie : {categorie.categorie.categorie_voiture}</p>
                            ))}
                        </div>
                        {/* <SimpleCard overflow="scroll"> */}
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Categorie</TableCell>
                                    <TableCell align="center">Prix/jours</TableCell>
                                    {/* <TableCell align="center">Type Location</TableCell> */}
                                    <TableCell align="center">Immat°</TableCell>
                                    <TableCell align="center">Places</TableCell>
                                    <TableCell align="center">Nombre jours</TableCell>
                                    <TableCell align="center">montant total</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reponsePublication.vehicules.map((vehicule, index) => (
                                    <TableRow hover key={index}>
                                        {/* <TableCell><SimpleCard><img style={styleImage} src={vehicule.images[0]}></img></SimpleCard></TableCell> */}
                                        <TableCell align="center">{vehicule.model_vehicule.categorie_voiture.categorie_voiture}</TableCell>
                                        <TableCell align="center">Ar {vehicule.prix_par_type_location}</TableCell>
                                        {/* <TableCell align="center">{vehicule.type}</TableCell> */}
                                        <TableCell align="center">{vehicule.immatriculation}</TableCell>
                                        <TableCell align="center">{vehicule.model_vehicule.places}</TableCell>
                                        <TableCell align="center">{nombreJours}</TableCell>
                                        <TableCell align="center">{nombreJours * vehicule.prix_par_type_location}</TableCell>
                                        <TableCell align="center">
                                            <ModalDetail typeModal={''}>
                                                <DetailVehicule vehicule={vehicule}></DetailVehicule>
                                            </ModalDetail>
                                        </TableCell >

                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    {/* <TableCell align="center">Type Location</TableCell> */}
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">Montant Total : </TableCell>
                                    <TableCell align="center">{montantTotal} Ar</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableBody>

                        </StyledTable>
                        <Button sx={{ mb: 1, mt: 1 }} fullWidth variant="contained" size="small" color="primary">Envoyer le reponse</Button>
                        <Button fullWidth variant="contained" size="small" onClick={handleClose} color="primary">Veuillez repondre le demande</Button>
                        {/* </SimpleCard> */}

                    </Grid>
                </Grid>

            </Dialog>
        </Box>
    );

}

export default ModalFactureReponse;

