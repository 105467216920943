import { Button, Tab, Tabs, TextField } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
import url from "app/UrlConfig";
import axios from "axios";
import { useEffect } from 'react';
import { displayToast } from "app/data/piece/dispatch";

const ActivityDescriptionComponent = ({
    activite,
    languages,
    keyActivites,
    setActivite
}) => {

    const [tabNameValue, setTabNameValue] = useState(0);
    const [languageChoose, setLanguageChoose] = useState("eng");

    const ActDesc = {
        id: null,
        etat: 1,
        motorise: {
            fr: "",
            eng: "",
            ita: ""
        },
        nautique: {
            fr: "",
            eng: "",
            ita: ""
        },
        terreste: {
            fr: "",
            eng: "",
            ita: ""
        },
        visite: {
            fr: "",
            eng: "",
            ita: ""
        },
        aerienne: {
            fr: "",
            eng: "",
            ita: ""
        }

    }
    const [activity, setActivity] = useState(ActDesc);
    const [mod, setMod] = useState(-1);

    const handleChangeTabNameValue = (event, newValue) => {
        // console.log(newValue, " language ", languages[newValue].name, " SHOW == ", presentation.description[languages[newValue]?.name])
        setTabNameValue(newValue);
        setLanguageChoose(languages[newValue]?.name);

    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeDescription = (event) => {
        console.log("activite", activity);
        setActivity({ ...activity, [event.target.name]: { ...activity[event.target.name], [languageChoose]: event.target.value } })
    }
    const Read = async () => {
        await axios.get(url["url-api"] + "description-activite")
            .then(res => {
                if (res.data.length > 0) {
                    setMod(1);
                    setActivity(res.data[0]);
                } else {
                    setMod(0);
                }
            })
            .catch(err => {
                setMod(-1);
            });
    }
    const Save = async () => {
        await axios.post(url["url-api"] + "description-activite/add", activity)
            .then(res => {
                if (res.data.data.length > 0) {
                    setMod(1);
                    setActivity(res.data.data[0]);
                } else {
                    setMod(0);
                }
                displayToast("Enregistrer avec success", true);
            })
            .catch(err => {
                setMod(-1);
                displayToast("Error d'enregistrement", false);
            });
    }
    const Update = async () => {
        await axios.put(url["url-api"] + "description-activite/update", activity)
            .then(res => {
                if (res.data.data.length > 0) {
                    setActivity(res.data.data[0]);
                }
                setMod(1);
                displayToast("Modification avec success", true);

            })
            .catch(err => {
                setMod(1);
                displayToast("error" + err.message)
            });
    }
    const onsubmit = () => {
        if (mod == 0) {
            Save()
        } else if (mod == 1) {
            Update()
        }
        else {
            alert('error');
        }
    }
    useEffect(() => {
        Read();
    }, []);
    return (
        <SimpleCard title={`Description des activités`}>
            <Tabs
                sx={{ mt: 1.5, mb: 2 }}
                value={tabNameValue}
                onChange={handleChangeTabNameValue}
                aria-label="basic tabs example"
            >
                {languages?.map((language, index) =>
                    <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                )}
            </Tabs>
            <div>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name='motorise'
                    type="text"
                    label="Motorise"
                    value={activity?.motorise[languageChoose] || ''}
                    onChange={handleChangeDescription}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
            </div>
            <div>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="nautique"
                    type="text"
                    label="Nautique"
                    value={activity?.nautique[languageChoose] || ''}
                    onChange={handleChangeDescription}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
            </div>
            <div>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="terreste"
                    type="text"
                    label="Terreste"
                    value={activity?.terreste[languageChoose] || ''}
                    onChange={handleChangeDescription}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
            </div>
            <div>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="visite"
                    type="text"
                    label="Visite"
                    value={activity?.visite[languageChoose] || ''}
                    onChange={handleChangeDescription}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
            </div>
            <div>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="aerienne"
                    type="text"
                    label="Aerienne"
                    value={activity?.aerienne[languageChoose] || ''}
                    onChange={handleChangeDescription}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
            </div>

            <br />
            <Button fullWidth disabled={mod == -1} variant='contained' onClick={() => onsubmit()}>
                Valider
            </Button>
        </SimpleCard>
    );
};

export default ActivityDescriptionComponent;