import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import AjoutChauffeurComponent from 'app/myComponents/Location/Chauffeur/AjoutChauffeur';
import AjoutPersonnelComponent from 'app/myComponents/Repair/Personnel/AjoutPersonnelComponent';
import React from 'react';


const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));
const PageAjoutChauffeur = () => {
    document.title = "AJOUT-PERSONNEL";

    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Personnels', path: '/entreprise/reparation/personnels/liste' }, { name: 'Ajout' }]}
                    />
                </Box>

                <AjoutChauffeurComponent></AjoutChauffeurComponent>

            </AppButtonRoot>
        </>
    );
};

export default PageAjoutChauffeur;