import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FormActivite from "./FormActivite";
import { useEffect } from "react";
import { getActiviteById } from "app/data/nosybe/activite";

const ModalUpdateActivite = ({ activite, setActivite, activites, setActivites, open, handleClose }) => {

    const getActiviteByIdDetail = async () => {
        // await getActiviteById(activite.id)
        //     .then(response => {
        //         setActivite(response.data.activite);
        //     })
        //     .catch(error => console.error("getActiviteByIdDetail: ", error));
    }

    const formatedActivite = () => {
        let horaireOuverture = [
            { "typeJours": 0, "typeJoursSTR": "Lundi", "horaireOuverture": ["", "", "", ""] },
            { "typeJours": 1, "typeJoursSTR": "Mardi", "horaireOuverture": ["", "", "", ""] },
            { "typeJours": 2, "typeJoursSTR": "Mercredi", "horaireOuverture": ["", "", "", ""] },
            { "typeJours": 3, "typeJoursSTR": "Jeudi", "horaireOuverture": ["", "", "", ""] },
            { "typeJours": 4, "typeJoursSTR": "Vendredi", "horaireOuverture": ["", "", "", ""] },
            { "typeJours": 5, "typeJoursSTR": "Samedi", "horaireOuverture": ["", "", "", ""] },
            { "typeJours": 6, "typeJoursSTR": "Dimanche", "horaireOuverture": ["", "", "", ""] }
        ];
        activite.horaires.forEach(horaire => {
            let jourIndex = horaireOuverture.findIndex(jour => jour.typeJours === horaire.typeJours);
            let ouvertures = [...horaireOuverture[jourIndex].horaireOuverture];
            for (let i in ouvertures) {
                if (ouvertures[i] === '') {
                    ouvertures[i] = `${horaire.heureOuverture}/${horaire.heureFermer}`;
                    break;
                }
            }
            horaireOuverture[jourIndex].horaireOuverture = ouvertures;
        })
        return {
            ...activite,
            nom: {
                fr: activite.nom_fr,
                eng: activite.nom_eng,
                ita: activite.nom_fr,
            },
            descriptionsMulti: {
                fr: activite.description_fr,
                eng: activite.description_eng,
                ita: activite.description_ita,
            },
            horaireOuverture
        };
    }

    const activiteForm = formatedActivite();

    useEffect(() => {
        // getActiviteByIdDetail();
        // formatActiviteForm();
    }, [])


    return (
        <Dialog maxWidth='xl' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modifier {activite.nom_fr}
            </DialogTitle>

            <DialogContent dividers>
                {activite && (
                    <FormActivite selectedActivite={activiteForm} activites={activites} setActivites={setActivites} update={true} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalUpdateActivite;