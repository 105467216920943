export const navigationsPiece = [
    {
        label: 'HOME',
        type: 'label',
    },
    // {
    //     name: 'Accueil',
    //     icon: 'home',
    //     path: '/nosybe/accueil',
    // },
    {
        name: 'Profile',
        icon: 'account_circle',
        path: '/entreprise/profile',
    },
    {
        name: 'Description des activités',
        icon: 'rate_review',
        path: '/nosybe/description-activites'
    },
    {
        name: 'Description des opérateurs',
        icon: 'rate_review',
        path: '/nosybe/description-operateurs'
    },
    {
        label: 'GESTIONS',
        type: 'label',
    },
    // {
    //     name: 'Iles',
    //     icon: 'explore',
    //     children: [
    //         { name: 'Ajout', iconText: '', path: '/entreprise/Iles/ajout' },
    //         { name: 'Listes', iconText: '', path: '/entreprise/Iles/list' },
    //     ]
    // },
    {
        name: 'Excursion',
        icon: 'airplane',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/Excursion/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/Excursion/list' },
        ]
    },
    {
        name: 'Article',
        icon: 'article',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/article/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/article/list' },
        ]
    },
    {
        name: 'Hébergement',
        icon: 'hotel',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/hebergement/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/hebergement/list' },
        ]
    },
    {
        name: 'Equipement',
        icon: 'build',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/equipement/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/equipement/list' },
        ]
    },
    {
        name: 'Restaurant',
        icon: 'restaurant',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/restaurant/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/restaurant/list' },
        ]
    },
    {
        name: 'Activité',
        icon: 'local_activity',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/activity/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/activity/list' },
        ]
    },
    {
        name: 'Agence & Guide',
        icon: 'transfer_within_a_station',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/activity/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/activity/list' },
        ]
    },
    {
        name: 'Evenement',
        icon: 'settings',
        children: [
            { name: 'Ajout', iconText: '', path: '/entreprise/evenement/ajout' },
            { name: 'Listes', iconText: '', path: '/entreprise/evenement/list' },
        ]
    },
]