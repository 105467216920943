import myConfig from "app/config/api-config";
import axios from "axios"

export const getAccueil = () => {
    return axios.get(
        `${myConfig.host}/api/accueil`
    );
}

export const validateAccueil = (payload) => {
    // console.log('payload : ', payload);
    // console.log(`${myConfig.host}/api/accueil`);
    return axios.post(
        `${myConfig.host}/api/accueil`,
        payload
    );
}