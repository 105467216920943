import url from "app/UrlConfig"
import axios from "axios";
import React from "react";



/* export const SaveHebergementAPI = async (hebergement, setMessage, setVisible, setSeverity) => {
    fetch(url["url-api"] + "hebergement", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(hebergement)
    })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            if (data.status === 200) {
                setSeverity('success');
                setMessage("request avec success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage(data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            setSeverity('error');
            setMessage(error)
            setVisible(true);
        })
} */
export const SaveHebergementAPI = async (hebergement, setMessage, setVisible, setSeverity) => {
    await axios.post(url["url-api"] + "hebergement-post", hebergement)
        //.then(response => response.json())
        .then(res => {
            console.log("eror", res.data)
            if (res.data.status === 200) {
                setSeverity('success');
                setMessage("request avec success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage(res.data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            /* setSeverity('error');
            setMessage(error)
            setVisible(true); */
        })
}
export const getHebergementsListAPI = async (data, setHebergements, setLigneNumber) => {
    fetch(url["url-api"] + "hebergement-get", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setHebergements(data.data);
                setLigneNumber(data.LigneNumber);
                // setHebergements(data.data.ori);
            }
        })
        .catch((error) => {
            console.log(error);
        })
}

export const getListeHebergementDetail = async (idHebergement, setHebergement) => {
    new Promise((resolve, reject) => {
        fetch(url["url-api"] + "hebergement-get/" + idHebergement, {
            method: 'GET',
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // console.log(data.data)
                    setHebergement(data.data[0]);
                    if (data.data.length > 0) {

                        // console.log(" ===>>> : ", data.data[0])
                        resolve(data.data[0]);

                        return data.data[0];
                    }
                }
            })
            .catch((error) => {
                reject(error);
            })
    })
}


/* export const UpdateHebergementAPI = async (Hebergement, idHebergement, setMessage, setVisible, setSeverity) => {

    // console.log(JSON.stringify(Hebergement))
    await fetch(url["url-api"] + "hebergement-update/" + idHebergement, {
        method: "PUT",
        //headers: { "Content-Type": "application/json" },
        //body: JSON.stringify(Hebergement)
        body: Hebergement
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setSeverity('success');
                setMessage("Modification avec Success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage("Error " + data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            setSeverity('error');
            setMessage("Error " + error)
            setVisible(true);
        })
} */
export const UpdateHebergementAPI = async (Hebergement, idHebergement, setMessage, setVisible, setSeverity) => {

    console.log(JSON.stringify(Hebergement))
    await axios.post(url["url-api"] + "hebergement-update/" + idHebergement, Hebergement)
        //.then(response => response.json())
        .then(res => {
            //console.log("hebergement", res.data);

            if (res.data.status === 200) {
                setSeverity('success');
                setMessage("Modification avec Success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage("Error " + res.data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log("error", error)
            setSeverity('error');
            setMessage("Error " + error)
            setVisible(true);
        })
}

export const DeleteHebergement = async (idHebergement, setHebergement, setMessage, setVisible, setSeverity) => {
    fetch(url["url-api"] + "hebergement/" + idHebergement, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" }
    })

        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                // setSeverity('success');
                // setMessage("Suppression avec Success")
                // setVisible(true);
            } else {
                // setSeverity('error');
                // setMessage("Error " + data.message)
                // setVisible(true);
            }
        })
        .catch((error) => {
            setSeverity('error');
            setMessage("Error " + error)
            setVisible(true);
        })

}

export const getScrappingBooking = async (dataSend, setHebergement) => {
    // console.log(JSON.stringify(dataSend))
    return new Promise((resolve, reject) => {
        fetch(url.url_scrapping + "/booking-lower-price", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSend)
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                if (data.status === 200) {
                    // console.log(data.data)
                    setHebergement(data.data);
                    resolve(data.data)
                    return data.data;
                }
            })
            .catch((error) => {
                reject(error)
                console.log(error)
            })
    })
}

export const getEquipementAPI = async (setEquipement) => {
    fetch(url["url-api"] + "equipements/hebergement", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then(data => {
            // console.log(data.data)
            if (data.status === 200) {
                setEquipement(data.data);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getCategorie = (setCategorie) => {
    fetch(url["url-api"] + "typeetablissement", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setCategorie(data.data);
            }
        })
        .catch((error) => { console.log(error) })
}

export const getUserConnected = (setUSer, setToken) => {
    let localUser = localStorage.getItem("admin");
    let token = localStorage.getItem('token');
    if (localUser) {
        setUSer(JSON.parse(localUser));
    } if (token) {
        setToken(token)
    }
}

// mysqld --console --skip-grant-tables --skip-external-locking

