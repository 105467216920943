
import { Button, ImageList, ImageListItem, TextField } from '@mui/material';
import React from 'react';

const UpdateLocation = ({ demande }) => {

    return (
        <div>
            {demande.vehicule.image_vehicule.length !== 0 &&
                <>
                    <ImageList sx={{ width: '100%', height: 200 }} cols={3} rowHeight={164}>
                        {demande.vehicule.image_vehicule.map((img, ind) => {
                            // console.log("loop: ", img, index);
                            return (
                                <div key={ind}>

                                    <ImageListItem>
                                        <img
                                            src={`${img.image}`}
                                            alt={`Voiture img ${ind}`}
                                            loading="lazy"
                                        // onClick={() => ChangePhoto(img)}
                                        />
                                    </ImageListItem>

                                </div>
                            )
                        })}
                    </ImageList>
                </>
            }
            <table>
                <thead>
                    <tr>
                        <td>Date debut</td>
                        <td>Date fin</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <TextField
                                fullWidth
                                size='small'
                                name="datedebut"
                                type="date"
                                label=""
                                defaultValue={demande.datedebut}
                                // onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                        </td>
                        <td>
                            <TextField
                                fullWidth
                                size='small'
                                name="datefin"
                                type="date"
                                label=""
                                defaultValue={demande.datefin}
                                // onChange={handleChange}
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <p>Nom Client : {demande.client.nom}</p> */}
            <p>Contact Client : {demande.contact}</p>
            <p>Marque Voiture : {demande.vehicule.model_vehicule.marquevoiture.marque} {demande.vehicule.model_vehicule.model} {"("}{demande.vehicule.model_vehicule.places}{")"}</p>
            <p>Immatriculation : {demande.immatriculation} </p>
            <p>prix : Ar {demande.prix_par_type_location}  {"("}{demande.vehicule.location_type}{")"} </p>
            <Button>Reporter</Button>
        </div>
    );

}

export default UpdateLocation;