import { Container } from '@mui/material';
import ReparationPieChartComponent from 'app/myComponents/Repair/Dashboard/ReparationPieChartComponent';
import React from 'react';

const ReparationPieChart = () => {
    document.title = "DASHBOARD";
    return (
        <>
            <Container>
                <br />
                <ReparationPieChartComponent
                    height="350px"
                />
            </Container>
        </>
    );
};

export default ReparationPieChart;
