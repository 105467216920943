export const imgStyle = {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'  // Empêche le débordement horizontal
};

export const divStyle = {
    width: '100%',  // Largeur 100% de la diapositive
    height: '300px',  // Hauteur fixe
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'lightgrey',  // Couleur de fond noire
};