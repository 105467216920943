import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { DeleteActivite } from "app/data/nosybe/activite";
import { displayToast, manageError } from "app/data/piece/dispatch";

const ModalDeleteActivite = ({ activite, activites, setActivites, open, handleClose }) => {
    const supprimerActivite = async () => {
        await DeleteActivite(activite.id)
            .then(response => {
                setActivites(activites.filter(element => element.id !== activite.id));
                displayToast(response.data.message, true);
                handleClose();
            })
            .catch(error => manageError(error, "DeleteActivite: "))
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Retirer {activite.nom_fr}
            </DialogTitle>

            <DialogContent dividers>
                {/* <TestButton toLog={hebergement} /> */}
                <Typography gutterBottom>
                    Vous en êtes sur?
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={supprimerActivite} style={{ color: 'red' }}>
                    Confirmer
                </Button>
                <Button onClick={handleClose} style={{ color: 'red' }}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteActivite;