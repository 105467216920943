import React from 'react';
import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import OperateurComponent from 'app/myComponents/VentePiece/Accueil/OperateurComponent';
const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const OperateurDescription = () => {
    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Accueil' },
                        { name: 'Description des opérateurs' }
                    ]}
                />
            </Box>
            <OperateurComponent />
        </AppButtonRoot>
    );
};

export default OperateurDescription;