import React from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useState } from 'react';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListeTypeReparationComponent = () => {

    const token = localStorage.getItem("token");

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [typereparations, setTypeReparation] = useState(null);

    function getTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typereparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTypeReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTypeReparation();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modals
    const [ajoutTypeReparation, setAjoutTypeReparation] = useState(null);

    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    function displayModal(typereparation) {
        setAjoutTypeReparation(typereparation);
        setOpenModal(true);
    }

    function ajouterTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations`,
            method: 'POST',
            data: ajoutTypeReparation
        }
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModal();
    }

    const [nouveauTypeReparation, setNouveauTypeReparation] = useState({
        nom: 'Climatisation'
    });

    const [displayAjoutModal, setDisplayAjoutModal] = useState(false);
    const handleCloseAjoutModal = () => setDisplayAjoutModal(false);

    function envoyerDemande() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations/envoyer`,
            method: 'POST',
            data: nouveauTypeReparation
        }
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseAjoutModal();
    }

    return (
        <>
            <SimpleCard title={'Types de reparation de INNOCAR'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    <Button sx={{ mb: 2 }} onClick={() => setDisplayAjoutModal(true)} >Creer un nouveau type</Button>
                    {
                        typereparations ?
                            <>
                                <StyledTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Nom</TableCell>
                                            <TableCell align="center">Ajouter</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {typereparations
                                            .filter((type) => type.nom.toLowerCase().includes(recherche))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((typereparation, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Icon>settings</Icon>
                                                        {typereparation.nom}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton onClick={() => displayModal(typereparation)} color='primary'>
                                                            <Icon>add</Icon>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </StyledTable>

                            </>
                            :
                            <>
                                <center>
                                    <CircularProgress></CircularProgress>
                                </center>
                            </>
                    }

                    <TablePagination
                        sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={typereparations ? typereparations.length : 0}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[2, 5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </Box>
            </SimpleCard>

            {/* modal ajout */}
            <Dialog maxWidth='md' onClose={handleCloseAjoutModal} aria-labelledby="customized-dialog-title" open={displayAjoutModal}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseAjoutModal}>
                    Ajouter
                </DialogTitle>

                <DialogContent dividers>
                    <TextField
                        fullWidth
                        size='small'
                        name="nom"
                        type="text"
                        label="Nom"
                        value={nouveauTypeReparation.nom}
                        onChange={(e) => setNouveauTypeReparation({ ...nouveauTypeReparation, nom: e.target.value })}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                </DialogContent >

                <DialogActions>
                    <Button onClick={envoyerDemande}>
                        Envoyer demande
                    </Button>
                </DialogActions>
            </Dialog >
            {/*  */}

            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>

            {/* modal */}
            {ajoutTypeReparation &&
                <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                        Ajout {ajoutTypeReparation.nom}
                    </DialogTitle>

                    <DialogContent dividers>
                        <Typography gutterBottom>
                            Ajouter à votre catalogue?
                        </Typography>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ajouterTypeReparation} color='success'>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {/*  */}
        </>
    );
};

export default ListeTypeReparationComponent;