import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { useState } from 'react';

const LineChartGenerer = ({ height, color = [], labelsAffiche, dataActus, dataPrecedent, anneActu, annePrecedent }) => {
    const theme = useTheme();

    const option = {
        grid: { top: '10%', bottom: '10%', right: '5%' },
        legend: {
            itemGap: 20,
            icon: 'circle',
            textStyle: {
                fontSize: 13,
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily
            }
        },
        label: {
            fontSize: 10,
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily
        },
        xAxis: {
            type: 'category',
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            data: labelsAffiche,
            axisLine: { show: true },
            axisTick: { show: true },
            axisLabel: {
                fontSize: 14,
                fontFamily: 'roboto',
                color: theme.palette.text.secondary
            }
        },
        yAxis: {
            type: 'value',
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: {
                lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 }
            },
            axisLabel: { color: theme.palette.text.secondary, fontSize: 13, fontFamily: 'roboto' }
        },
        series: [
            {
                data: dataActus,
                type: 'bar',
                color: 'red',
                stack: anneActu,
                name: anneActu,
                smooth: true,
                symbolSize: 1,
                lineStyle: { width: 1 }
            },
            {
                data: dataPrecedent,
                type: 'bar',
                color: 'blue',
                stack: annePrecedent,
                name: annePrecedent,
                smooth: true,
                symbolSize: 1,
                lineStyle: { width: 1 }
            }
        ]
    };

    return <ReactEcharts style={{ height: height }} option={{ ...option, color: [...color] }} />;
};

export default LineChartGenerer;



