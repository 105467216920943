import { ImageList, ImageListItem, Accordion, AccordionSummary, Alert, Badge, Box, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import { SimpleCard } from "app/components";
//import { getIlesPrimaryRessources } from "app/data/nosybe/Iles";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "../Restaurant/CarteMap";
import url from "app/UrlConfig";
import { SaveIles, SaveIlesAPI, UpdateIlesAPI } from "./API-Iles";
import { useNavigate } from "react-router-dom";
import { forEach } from "lodash";
import { displayToast } from "app/data/piece/dispatch";

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const FormIles = ({ selectedIles, setIless, closeForm }) => {

    const navigate = useNavigate();
    // console.log(selectedIles, 'slected')

    const [Image, setimage] = useState({ id: null, photo: '', file: '' });
    const [lat, setLat] = useState(-13.330993056594197);
    const [lng, setLng] = useState(48.256898613913386);

    const originalIles = {
        id: null,
        nom: '',
        description: [

            {
                lang: 'eng',
                contenu: ''
            },
            {
                lang: 'fr',
                contenu: ''
            },
            {
                lang: 'ita',
                contenu: ''
            }
        ],
        latitude: lat,
        longitude: lng,
        etat: 1,
        imagesFile: [],
        images64: [],
        images: [],
        delete_images: []
    }

    const [Iles, setIles] = useState(selectedIles ? { ...originalIles, ...selectedIles } : { ...originalIles });
    const [inputErrors, setInputErrors] = useState({});

    const setObjectMere = (lat, lng) => {
        setIles({ ...Iles, latitude: lat, longitude: lng });
    }

    const handleChange = (e) => {
        if (e.target.name == 'nom')
            setIles({
                ...Iles,
                [e.target.name]: e.target.value
            });
        else
            setIles({
                ...Iles,
                [e.target.name]: [...Iles.description, Iles.description[tabDescValue] = { ...Iles.description[tabDescValue], 'contenu': e.target.value }]
            });
        console.log(Iles);
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    //IMAGES
    const onDrop = (files) => {
        const tabImage = Iles.imagesFile;
        const tabImage64 = Iles.images64;
        files.map((val) => {
            if (val) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64 = e.target.result;
                    setimage({ file: val, photo: base64 });
                    tabImage.push(val);
                    tabImage64.push(base64);
                };
                reader.readAsDataURL(val);
            }

        })
        setIles({ ...Iles, imagesFile: tabImage, images64: tabImage64 });

    }
    const handleFileRemove = (selectedIndex) => {
        const tabImage = Iles.imagesFile.filter((_, index) => index !== selectedIndex);
        const tabImage64 = Iles.images64.filter((_, index) => index !== selectedIndex);
        setIles({ ...Iles, imagesFile: tabImage, images64: tabImage64 });
    }

    const handleImageRemove = (selectedIndex) => {
        const delimage = Iles.images[selectedIndex];
        const tabImage = Iles.images.filter((_, index) => index !== selectedIndex);
        setIles({ ...Iles, images: tabImage, delete_images: [...Iles.delete_images, delimage] });
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const [languages, setLanguages] = useState([{ "name": "eng", "abbrev": "MAL" }, { "name": "fr", "abbrev": "FRANCAIS" }, { "name": "ita", "abbrev": "MAL" }]);
    const [tabDescValue, setTabDescValue] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("description_eng");
    const [lienexpedia, setLienExpedia] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState('success');
    const [visible, setVisible] = useState(false);


    const handleChangeTabDescValue = (event, newValue) => {
        setTabDescValue(newValue);
        setLanguageDescChoose("description_" + languages[newValue]?.name);
    }

    useEffect(() => {
        //getIlesPrimaryRessources(setEquipements, setTypes);
        if (selectedIles) {
            setLat(selectedIles.latitude);
            setLng(selectedIles.longitude);
        }
    }, [])

    const createFormData = (formData, key, data) => {
        if (!(data instanceof File) && data === Object(data) || Array.isArray(data)) {
            for (var i in data) {
                createFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }

    const saveIles = async () => {

        const data = new FormData();
        createFormData(data, "id", Iles.id);
        createFormData(data, "nom", Iles.nom);
        createFormData(data, "description", Iles.description);
        createFormData(data, "latitude", Iles.latitude);
        createFormData(data, "longitude", Iles.longitude);
        createFormData(data, "etat", Iles.etat);
        createFormData(data, "images", Iles.imagesFile);
        console.log(data, " Iles")
        await SaveIlesAPI(data, setMessage, setVisible, setSeverity);
        navigate("/entreprise/Iles/list");
    }

    const UpdateIles = async () => {
        console.log(Iles, "iles update");
        const data = new FormData();
        createFormData(data, "id", Iles.id);
        createFormData(data, "nom", Iles.nom);
        createFormData(data, "description", Iles.description);
        createFormData(data, "latitude", Iles.latitude);
        createFormData(data, "longitude", Iles.longitude);
        createFormData(data, "etat", Iles.etat);
        createFormData(data, "images", Iles.imagesFile);
        createFormData(data, "delete_images", Iles.delete_images);
        await UpdateIlesAPI(data, setIles, setMessage, setVisible, setSeverity);
        closeForm();

    }

    const handleCloseSnackbar = () => {
        setVisible(false);
    }


    return (
        <>
            {/* <TestButton toLog={Iles} /> */}
            <Typography variant="h5">
                {selectedIles ? 'Modifier un Iles' : 'Ajouter un Iles'}
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12} md={8}>
                    <SimpleCard>
                        <TextField
                            fullWidth
                            size='small'
                            name="nom"
                            type="text"
                            label="Nom"
                            value={Iles.nom}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienBooking && "error"}
                            helperText={inputErrors.lienexpedia}
                        />

                        <Tabs
                            value={tabDescValue}
                            onChange={handleChangeTabDescValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='large'
                            name={languageDescChoose}
                            value={Iles.description[tabDescValue]?.contenu}
                            label="Description"
                            onChange={handleChange}
                            multiline
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        />

                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Gallerie</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootProps()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputProps()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                {Iles.images64.length > 0 &&
                                    <ImageList cols={3} rowHeight={100}>
                                        {Iles.images64.map((item, index) => (
                                            <ImageListItem key={index}>
                                                <Badge
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                    badgeContent={
                                                        <>
                                                            <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>}></Badge>
                                                <img
                                                    src={item}
                                                    alt={"photo 0" + index}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))}

                                    </ImageList>
                                }

                            </Accordion>
                        </AccordionRoot>
                        <h1>Carte</h1>
                        <CarteMap apiKey={url.key_map} setLongitude={setLng} setLatitude={setLat} setMereObject={setObjectMere} lati={lat} longi={lng} />

                    </SimpleCard>

                </Grid>
                <Grid item sm={12} md={4}>

                    {Iles.images.length > 0 &&
                        <>
                            <h3>Photos dans serveur</h3>
                            <ImageList cols={4} rowHeight={150} rowWidth={100}>
                                {Iles.images.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <Badge
                                            sx={{
                                                mt: 3,
                                            }}
                                            badgeContent={
                                                <>
                                                    <IconButton onClick={() => handleImageRemove(index)} color='error'>
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                </>}></Badge>
                                        <img
                                            src={url["url-api"] + "iles-image/" + item.file}
                                            alt={"photo 0" + index}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </>
                    }
                </Grid>
                <Grid item sm={12} md={4}>
                    <br />
                    <br />
                    <StyledButton
                        // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                        onClick={selectedIles ? UpdateIles : saveIles}
                        fullWidth
                        variant="contained"
                        component="span"
                    >
                        Enregistrer
                    </StyledButton>
                </Grid>

            </Grid>
            <Snackbar
                open={visible}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormIles;