import myConfig from "app/config/api-config"
import axios from "axios";
import { displayToast } from "./dispatch";

// export const mois = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUI', 'JUL', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'];
export const mois = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
];

export const formatNumber = (num) => {
    const quant = typeof num === 'string' ? parseFloat(num) : num;
    const nombre = quant.toLocaleString('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    // console.log("formatNumber: ", nombre);
    return nombre
}
export const formatDate = (inputDate) => {
    if (!inputDate) return '';
    const date = new Date(inputDate);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };

    return new Intl.DateTimeFormat('fr-FR', options).format(date);
};

const buildData = (data, type) => {
    let val = {
        data: [],
        color: [],
    }
    data.forEach((element) => {
        val.data.push({
            ...element,
            type: type,
            symbolSize: 4,
            lineStyle: { width: 4 }
        })
        val.color.push(element.color)
    });
    return val;
}

const buildDoughnutData = (data) => {
    let val = {
        data: [],
        color: [],
    }
    data.forEach((element) => {
        val.data.push(element)
        val.color.push(element.color)
    })
    return val;
}

export const getGeneraleStat = (
    year,
    setYears,
    setChiffreData,
    setQuantityData,
    setBestChiffres,
    setBestQuantity
) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/generale';
    const requestOptions = {
        url: url,
        params: {
            year: year
        }
    }
    axios(requestOptions)
        .then((response) => {
            // console.log("initGeneraleStat: ", response);
            setYears(response.data.years)
            const chiffreSeries = buildData(response.data.chiffreSeries, 'bar');
            setChiffreData(chiffreSeries);
            const quantitySeries = buildData(response.data.quantitySeries, 'bar');
            setQuantityData(quantitySeries);
            const bestChiffres = buildDoughnutData(response.data.bestChiffres);
            setBestChiffres(bestChiffres)
            const bestQunatity = buildDoughnutData(response.data.bestQuantity);
            setBestQuantity(bestQunatity);
        })
        .catch((error) => {
            console.error("initGeneraleStat: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getChiffreMonth = (year, chiffreFiltre, setBestChiffres) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/generale/month/chiffre';
    const requestOptions = {
        url: url,
        params: {
            year: year,
            month: chiffreFiltre.mois + 1,
            significant: chiffreFiltre.significatif,
        }
    }
    axios(requestOptions)
        .then((response) => {
            // console.log("initGeneraleStat: ", response);
            const bestChiffres = buildDoughnutData(response.data.bestChiffres);
            setBestChiffres(bestChiffres);
        })
        .catch((error) => {
            console.error("initGeneraleStat: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getQuantityMonth = (year, quantityFiltre, setBestQuantity) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/generale/month/quantity';
    const requestOptions = {
        url: url,
        params: {
            year: year,
            month: quantityFiltre.mois + 1,
            significant: quantityFiltre.significatif,
        }
    }
    axios(requestOptions)
        .then((response) => {
            // console.log("initGeneraleStat: ", response);
            const bestQuantity = buildDoughnutData(response.data.bestQuantity);
            setBestQuantity(bestQuantity);
        })
        .catch((error) => {
            console.error("initGeneraleStat: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getCompareYearStat = (year1, year2, setYears, setChiffreData, setQuantityData,) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/compare';
    const requestOptions = {
        url: url,
        params: {
            year1: year1,
            year2: year2
        }
    }
    axios(requestOptions)
        .then((response) => {
            setYears(response.data.years);
            const chiffreSeries = buildData(response.data.chiffreSeries, 'bar');
            setChiffreData(chiffreSeries);
            const quantitySeries = buildData(response.data.quantitySeries, 'bar');
            setQuantityData(quantitySeries);
        })
        .catch((error) => {
            console.error("initGeneraleStat: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getInitData = (setYears) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/init';
    const requestOptions = {
        url: url,
    }
    axios(requestOptions)
        .then((response) => {
            setYears(response.data.years);
        })
        .catch((error) => {
            console.error("getInitData: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getModeValues = (mode, setValues) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/values';
    const requestOptions = {
        url: url,
        params: {
            mode: mode,
        }
    }
    axios(requestOptions)
        .then((response) => {
            // console.log("getModeValues: ", response);
            setValues(response.data.values);
        })
        .catch((error) => {
            console.error("getPrecisionValues: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getPrecisionStat = (
    selectedYear,
    mode,
    elements,
    setChiffreData,
    setQuantityData,
    setBestChiffres,
    setBestQuantity
) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/precision';
    const requestOptions = {
        url: url,
        params: {
            year: selectedYear,
            mode: mode,
            elements: elements,
        }
    }
    axios(requestOptions)
        .then((response) => {
            console.log("initGeneraleStat: ", response);
            const chiffreSeries = buildData(response.data.chiffreSeries, 'bar');
            setChiffreData(chiffreSeries);
            const quantitySeries = buildData(response.data.quantitySeries, 'bar');
            setQuantityData(quantitySeries);
            const bestChiffres = buildDoughnutData(response.data.bestChiffres);
            setBestChiffres(bestChiffres)
            const bestQunatity = buildDoughnutData(response.data.bestQuantity);
            setBestQuantity(bestQunatity);
        })
        .catch((error) => {
            console.error("initGeneraleStat: ", error);
            displayToast(error.response.data.message, false);
        })
}

export const getPrecisionMonth = (stat, year, mode, filtre, setBestChiffres, setBestQuantity) => {
    const url = myConfig.host + '/api/entreprise/piece/stat/precision/month';
    const requestOptions = {
        url: url,
        params: {
            stat: stat,
            year: year,
            mode: mode,
            month: filtre.mois + 1,
            significant: filtre.significatif,
        }
    }
    console.log("getPrecisionMonth: ", requestOptions);
    axios(requestOptions)
        .then((response) => {
            console.log("initGeneraleStat: ", response);
            if (stat === 'chiffre') {
                const bestChiffres = buildDoughnutData(response.data.values);
                setBestChiffres(bestChiffres);
            } else if (stat === 'quantite') {
                const bestQuantity = buildDoughnutData(response.data.values);
                setBestQuantity(bestQuantity);
            }
        })
        .catch((error) => {
            console.error("initGeneraleStat: ", error);
            displayToast(error.response.data.message, false);
        })
}