import React from 'react';
import { Autocomplete, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import ModalGenerer from '../utils/ModalGenerer';
import UpdateVehiculeComponent from './UpdateVehiculeComponent';
import ModalDelete from '../utils/ModalDelete';
import ModalDetail from '../utils/ModalDetail';
import DetailVehicule from './DetailVehicule';
import url from 'app/UrlConfig';
import ComponentFiltre from './ComponentFiltre';
import { divStyle, imgStyle } from '../utils/Style';


const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const AutoComplete = styled(Autocomplete)(() => ({
    width: 300
}));

const typeLocations = [
    { Etattype: 10, "type": "Hors Carburant" }, { Etattype: 20, "type": "Avec Carburant" }
];

const ListeVehiculeComponent = () => {

    const [rechercheValue, setRechercheValue] = useState({
        marque: 0,
        categorie: 0,
        immatriculation: '',
        prixMin: 0,
        prixMax: 0,
        type_location: 0
    })


    const [token, setToken] = useState(localStorage.getItem("token"));
    const [vehiculesV, setVehicule] = useState([]);
    const [categories, setCategories] = useState([]);
    const [vehiculeUpdate, setVehiculeUpdate] = useState();
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleChange(e) {
        setRechercheValue({ ...rechercheValue, [e.target.name]: e.target.value });
    }


    function displayFicheModal(reparation) {
    }


    const [modeleVs, setModels] = useState([]);
    const getMarques = () => {

        fetch(url['url-api'] + "admin/marques", {
            method: 'GET',
            headers:
            {
                'Authorization': 'Bearer' + token,
                'Content-Type': 'application/json'
            },

        })
            .then(response => response.json())
            .then(data => { setModels(data) })
            .catch(error => { console.log(error) })
    }

    const getVehicule = () => {
        const token = localStorage.getItem("token");
        console.log(token)
        fetch(url['url-api'] + "admin/vehicules", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setVehicule(data);
            })
            .catch(error => { console.log(error) })
    }

    const getCategories = () => {
        fetch(url['url-api'] + "admin/categories-vehicules", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => { setCategories(data) })
            .catch(error => { console.log(error) })
    }

    const getRecherche = () => {
        setExpan(false)
        console.log(JSON.stringify(rechercheValue));
        console.log(token)
        fetch(url['url-api'] + "admin/vehicule-recherche", {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rechercheValue)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setVehicule(data)
            })
            .catch(error => { console.log(error) })
    }

    const getInitiale = () => {
        const initial = {
            marque: 0,
            categorie: 0,
            immatriculation: '',
            prixMin: 0,
            prixMax: 0,
            type_location: 0
        }
        getVehicule();
        setRechercheValue(initial);
    }

    const Delete = (id) => {
        console.log(url['url-api'] + "admin/vehicules/" + id)
        fetch(url['url-api'] + "admin/vehicules/" + id, {
            method: 'DELETE',
            headers:
            {
                'Authorization': 'Bearer' + token,
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => { })
            .catch(error => { console.log(error) })
        window.location.href = '/entreprise/locations/liste';
    }

    useEffect(() => {
        getVehicule();
        getMarques();
        getCategories();
    }, [])

    const handleAutocompleteChange = (event, newValue, type) => {
        console.log(newValue);
        console.log(type)
        if (newValue !== null) {
            if (type === 'marque') {
                setRechercheValue({ ...rechercheValue, ['marque']: newValue.id });
            } else {
                setRechercheValue({ ...rechercheValue, ['categorie']: newValue.id });
            }
        }
        else {
            if (type === 'marque') {
                setRechercheValue({ ...rechercheValue, ['marque']: 0 });
            } else {
                setRechercheValue({ ...rechercheValue, ['categorie']: 0 });
            }

        }
    };


    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const OpenModalUpdate = (veh) => {
        console.log(veh)
        setShowModalUpdate(true)
        setVehiculeUpdate(veh)
    }
    const CloseModalUpdate = () => {
        setShowModalUpdate(false)
    }

    const [expan, setExpan] = useState();

    return (
        <>
            <Grid container spacing={1}>
                <Box sx={{ py: '12px' }} />


                <ComponentFiltre expan={expan}>
                    <Grid container spacing={6}>

                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }}>
                            <Fragment>
                                <AutoComplete
                                    options={modeleVs}
                                    getOptionLabel={(option) => option.marque}
                                    onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'marque')}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField {...params} label="marque" variant="outlined" fullWidth />
                                    )}
                                />
                            </Fragment>
                            <Box sx={{ py: '12px' }} />
                            <Fragment>
                                <AutoComplete
                                    options={categories}
                                    getOptionLabel={(option) => option.categorie_voiture}
                                    onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'categorie')}
                                    size="small"
                                    width="50px"
                                    // autowidth
                                    renderInput={(params) => (
                                        <TextField {...params} label="categorie" variant="outlined" fullWidth />
                                    )}
                                />
                            </Fragment>
                            <Box sx={{ py: '12px' }} />
                            <TextField
                                size="small"
                                type="text"
                                name="immatriculation"
                                label="Immatriculation"
                                value={rechercheValue.immatriculation}
                                onChange={handleChange}
                            />

                        </Grid >

                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }}>
                            <TextField
                                size="small"
                                type="number"
                                name="prixMin"
                                label="Prix Min"
                                value={rechercheValue.prixMin}
                                onChange={handleChange}
                            />
                            <Box sx={{ py: '12px' }} />
                            <TextField
                                size="small"
                                type="number"
                                name="prixMax"
                                label="Prix Max"
                                value={rechercheValue.prixMax}
                                onChange={handleChange}
                            />

                            <Box sx={{ py: '12px' }} />
                            <Select
                                size="small"
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                name='type_location'
                                onChange={handleChange}
                                value={rechercheValue.type_location}
                                // autoWidth
                                label="Categorie Vehicules"
                            >
                                <MenuItem key={-1} value={0}>tous</MenuItem>
                                {typeLocations.map((typeLocation, index) => (
                                    <MenuItem key={index} value={typeLocation.Etattype}>{typeLocation.type}</MenuItem>
                                ))}
                            </Select>
                            <Box sx={{ py: '12px' }} />
                            <Button sx={{ ml: 2 }} variant="contained" size="small" color="success" onClick={getInitiale}>Reinitialiser</Button>
                            <Button sx={{ ml: 2 }} variant="contained" size="small" color="primary" onClick={getRecherche}>Valider</Button>

                        </Grid>


                    </Grid>
                </ComponentFiltre>
                <Box sx={{ py: '12px' }} />
                <SimpleCard title={'Liste des Vehicules'}>
                    <Box width="100%" overflow="auto">
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Photo</TableCell>
                                    <TableCell align="center">Marque</TableCell>
                                    <TableCell align="center">Model</TableCell>
                                    <TableCell align="center">Categorie</TableCell>
                                    <TableCell align="center">Prix/jours</TableCell>
                                    <TableCell align="center">Type Location</TableCell>
                                    <TableCell align="center">Immatriculation</TableCell>
                                    <TableCell align="center">Places</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {vehiculesV
                                    .filter((vehicule) => vehicule.model_vehicule.categorie_voiture.categorie_voiture.toLowerCase().includes(recherche))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((vehicule, index) => (
                                        <TableRow hover key={index}>
                                            {vehicule.image_vehicule.length > 0 && (
                                                <TableCell>

                                                    <div style={divStyle}>
                                                        <img style={imgStyle} src={vehicule.image_vehicule[0].image}></img>
                                                    </div>

                                                </TableCell>
                                            )}
                                            {vehicule.image_vehicule.length == 0 && (
                                                <TableCell><SimpleCard></SimpleCard></TableCell>
                                            )}

                                            <TableCell align="center">{vehicule.model_vehicule.marquevoiture.marque}</TableCell>
                                            <TableCell align="center">{vehicule.model_vehicule.model}</TableCell>
                                            <TableCell align="center">{vehicule.model_vehicule.categorie_voiture.categorie_voiture}</TableCell>
                                            <TableCell align="center">Ar {vehicule.prix_par_type_location}</TableCell>
                                            <TableCell align="center">{vehicule.location_type}</TableCell>
                                            <TableCell align="center">{vehicule.immatriculation}</TableCell>
                                            <TableCell align="center">{vehicule.model_vehicule.places}</TableCell>
                                            <TableCell align="center">
                                                <ModalDetail typeModal={''}>
                                                    <DetailVehicule vehicule={vehicule}></DetailVehicule>
                                                </ModalDetail>
                                                <IconButton onClick={() => displayFicheModal(vehicule)} >
                                                    <Icon color="photo">photo</Icon>
                                                </IconButton>
                                                {/* <Link to={''} state={{ personnels: vehicule.personnels }}>
                                                        <IconButton >
                                                            <Icon color="info">group</Icon>
                                                        </IconButton>
                                                    </Link> */}
                                                <IconButton onClick={() => OpenModalUpdate(vehicule)} >
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <ModalDelete vehicule={vehicule}>
                                                    <h1>Confirmation suppression</h1>
                                                    <h3>
                                                        Vous etes sure de Supprimer cette Voiture {vehicule.model_vehicule.marquevoiture.marque} {vehicule.model_vehicule.model}{" "}
                                                        de numero {vehicule.immatriculation}
                                                    </h3>
                                                    <Button onClick={(id) => Delete(vehicule.id)}>Valider</Button>
                                                </ModalDelete>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </StyledTable>

                        <TablePagination
                            sx={{ px: 2 }}
                            page={page}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            count={vehiculesV.length}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[5, 10, 25]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                        />
                    </Box>
                </SimpleCard>

            </Grid >

            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                <UpdateVehiculeComponent vehicule={vehiculeUpdate}></UpdateVehiculeComponent>
                <DialogActions>
                    <Button onClick={CloseModalUpdate}>
                        fermer
                    </Button>
                </DialogActions>
            </ModalGenerer>
            {/* MODAL UPDATE */}
        </>
    );
};

export default ListeVehiculeComponent;