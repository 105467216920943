import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import vehs from '../Vehicule/VehiculeData';
import { Alert, Box, Button, Checkbox, Grid, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { SimpleCard } from "app/components";
import ModalDetail from "../utils/ModalDetail";
import DetailVehicule from "../Vehicule/DetailVehicule";
import ModalFactureReponse from "../utils/ModalFactureReponse";
import { SaveReponse, getVehiculeProposer } from "./ClientApropos";



const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));


// const styleImage = {
//     width: "50px",
//     heigth: "50px"
// }

const styleImage = {
    width: "100px",
    heigth: "80px"
}

const styleP = {
    fontSize: "10px"
}



const RepondrePublication = () => {

    const location = useLocation();
    const pub = location.state.publication
    const [vehiculePropose, setVehiculePropose] = useState([])
    const [message, setMessage] = useState();
    const [v, setV] = useState([]);

    const [reponsePub, setReponsePub] = useState({
        ...pub,
        message: message,
        vehicules: []
    })

    const ecrireMessage = (e) => {
        setReponsePub({ ...reponsePub, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        // console.log(pub);
        getVehiculeProposer(pub, setVehiculePropose, 1);
        // setVehiculePropose(vehs);
    }, [])

    const AddVehicule = (veh) => {
        const vehs = reponsePub.vehicules;
        for (let i = 0; i < vehs.length; i++) {
            if (vehs[i].id == veh.id) {
                const data = vehs.filter(item => item.id !== veh.id)
                // console.log(data);
                setReponsePub({ ...reponsePub, vehicules: data })
                // console.log(reponsePub)
                return;
            }
        }
        vehs.push(veh);
        setReponsePub({ ...reponsePub, vehicules: vehs })
        console.log(reponsePub)
    }



    /*  SNACKNAR * */
    const [messageS, setMessageS] = useState('');
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    /*  SNACKNAR * */

    const EnvoyerReponse = () => {
        console.log(JSON.stringify(reponsePub));
        SaveReponse(reponsePub, 1, setOpenSnackbar, setMessageS, setSeverity);
    }


    return (

        <Grid container spacing={6}>
            <Grid item lg={5} md={5} sm={12} xs={12} sx={{ mt: 2 }}>
                {/* <CardRoot elevation={12}> */}
                <SimpleCard>
                    <Box width="100%" overflow="auto">
                        <img style={styleImage} src={pub.client.photo}></img>
                        <p style={styleP}>Nom Client : {pub.client.nom}</p>
                        {/* <p>{vehicule.categorie}</p> */}
                        <p style={styleP}>Contact : {pub.client.contact}</p>
                        <p style={styleP}>Email : {pub.client.email}</p>
                        <ModalDetail>
                        </ModalDetail>
                    </Box>
                    <div>
                        <p>Date Location:{pub.date_debut} jusqu'au {pub.date_fin}</p>
                        <p>Nombre Personne : {pub.nombre_place} (personnes)</p>
                        <p>Categorie Voiture du CLient sont :</p>
                        {pub.categorie_vehicule.map((categorie, index) => (
                            <p key={index}>Categorie : {categorie.categorie.categorie_voiture}</p>
                        ))}
                    </div>
                </SimpleCard>

            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{ mt: 2 }}>
                <Box width="100%">
                    <SimpleCard overflow="scroll">
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Categorie</TableCell>
                                    <TableCell align="center">Prix/jours</TableCell>
                                    {/* <TableCell align="center">Type Location</TableCell> */}
                                    <TableCell align="center">Immat°</TableCell>
                                    <TableCell align="center">Places</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vehiculePropose.map((vehicule, index) => (
                                    <TableRow hover key={index}>
                                        {/* <TableCell><SimpleCard><img style={styleImage} src={vehicule.images[0]}></img></SimpleCard></TableCell> */}
                                        <TableCell align="center">{vehicule.model_vehicule.categorie_voiture.categorie_voiture}</TableCell>
                                        <TableCell align="center">Ar {vehicule.prix_par_type_location}</TableCell>
                                        {/* <TableCell align="center">{vehicule.type}</TableCell> */}
                                        <TableCell align="center">{vehicule.immatriculation}</TableCell>
                                        <TableCell align="center">{vehicule.model_vehicule.places}</TableCell>
                                        <TableCell align="center">
                                            <ModalDetail typeModal={''}>
                                                <DetailVehicule vehicule={vehicule}></DetailVehicule>
                                            </ModalDetail>
                                        </TableCell >
                                        <TableCell align="center">
                                            <Checkbox value={vehicule} name="activiter" onChange={() => AddVehicule(vehicule)} />

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </StyledTable>
                    </SimpleCard>
                </Box>
                {/* <Grid item lg={8} md={8} sm={12} xs={12} sx={{ mt: 2 }}> */}
                <Box sx={{ py: '12px' }} />
                <TextField
                    size="small"
                    fullWidth
                    type="text"
                    name="message"
                    label="Reponse Message"
                    value={reponsePub.message}
                    onChange={ecrireMessage}
                />
                {/* </Grid> */}

                <Button sx={{ mb: 3, mt: 3 }} fullWidth variant="contained" size="small" color="primary" onClick={EnvoyerReponse}>Envoyer</Button>
                <ModalFactureReponse reponsePublication={reponsePub}></ModalFactureReponse>
            </Grid>
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {messageS}
                </Alert>
            </Snackbar>
        </Grid>
    );

}

export default RepondrePublication;