import React from 'react';
import { useState } from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Icon, InputLabel, MenuItem, Select, Snackbar, TablePagination, TextField, Typography, styled } from '@mui/material';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { red } from '@mui/material/colors';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';
// 

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const DemandeClientComponent = () => {

    const [demandes, setDemande] = useState(null);

    // filtre
    const [filter, setFilter] = useState({
        idtypereparation: 0,
        month: 0,
        year: new Date().getFullYear(),
        sendFilter: false
    });

    useEffect(() => {
        if (filter.sendFilter) {
            getTypeReparation();
            setFilter({ ...filter, sendFilter: false });
        }
    }, [filter]);

    const [mois, setMois] = useState(null);
    function getMois() {
        const requestOptions = {
            url: `${myConfig.host}/api/mois`,
            method: 'GET',
        };
        axios(requestOptions).then((response) => {
            setMois(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    function getDemande() {
        setDemande(null);
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/client/demande?isEntreprise=true`,
            method: 'GET',
        };

        if (filter.sendFilter) {
            requestOptions.url += `?month=${filter.month}`;
            requestOptions.url += `?idtypereparation=${filter.idtypereparation}`;
            requestOptions.url += `?year=${filter.year}`;
        }

        axios(requestOptions).then((response) => {
            setDemande(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    const [typereparations, setTypeReparation] = useState([]);

    function getTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTypeReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getMois();
        getTypeReparation();
        getDemande();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modal reponse
    const [reponseDemande, setReponseDemande] = useState(null);
    function handleReponseDemandeChange(e) {
        setReponseDemande({ ...reponseDemande, [e.target.name]: e.target.value });
    };
    const [openModalReponse, setOpenModalReponse] = useState(false);
    const handleCloseModalReponse = () => setOpenModalReponse(false);

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);


    function displayReponseModal(demande) {
        const reponse = {
            client: demande.client,
            datedebut: demande.datedebut.split(' ')[0],
            heuredebut: demande.datedebut.split(' ')[1],
            iddemandeclient: demande.id,
            isEntreprise: true
        };
        setReponseDemande(reponse);
        setOpenModalReponse(true);
    }

    function envoyerReponse() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/client/demande/renvoyer`,
            method: 'POST',
            data: reponseDemande
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getDemande();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalReponse();
    }

    return (
        <>
            {(mois && typereparations && demandes) ?
                <>

                    <AccordionRoot>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                            >
                                <Box className="column">
                                    <Typography className="heading">Demandes des clients</Typography>
                                </Box>

                                <Box className="column">
                                    <Typography className="secondaryHeading">Filtrer</Typography>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails className="details">
                                <Box className="column helper">
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} md={6}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="recherche"
                                                type="text"
                                                label="Recherche"
                                                variant="outlined"
                                                value={recherche}
                                                onChange={(e) => setRecherche(e.target.value)}
                                                sx={{ mt: 1.5 }}
                                            />
                                            <TablePagination
                                                sx={{ px: 2 }}
                                                page={page}
                                                component="div"
                                                rowsPerPage={rowsPerPage}
                                                count={demandes.length}
                                                onPageChange={handleChangePage}
                                                rowsPerPageOptions={[2, 4, 8, 12]}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                            />

                                        </Grid>
                                        <Grid item lg={6} md={6}>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel filled id="demo-simple-select-autowidth-label">Types de reparation</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    name='idtypereparation'
                                                    value={filter.idtypereparation === 0 ? '' : filter.idtypereparation}
                                                    onChange={(e) => setFilter({ ...filter, idtypereparation: e.target.value })}
                                                    autoWidth
                                                    label="TypeReparations"
                                                >

                                                    {typereparations.map((typereparation, index) => (
                                                        <MenuItem key={index} value={typereparation.typereparation.id}>{typereparation.typereparation.nom}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel filled id="demo-simple-select-autowidth-label">Mois</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    name='month'
                                                    value={filter.month === 0 ? '' : filter.month}
                                                    onChange={(e) => setFilter({ ...filter, month: e.target.value })}
                                                    label="Mois"
                                                >

                                                    {mois.map((mois, index) => (
                                                        <MenuItem key={index} value={mois.id}>{mois.nom}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>

                                            <TextField
                                                fullWidth
                                                size="small"
                                                name="year"
                                                type="number"
                                                label="Année"
                                                variant="outlined"
                                                value={filter.year}
                                                onChange={(e) => setFilter({ ...filter, year: e.target.value })}
                                                sx={{ mt: 3 }}
                                            />

                                        </Grid>
                                    </Grid>

                                </Box>
                            </AccordionDetails>

                            <Divider />

                            <AccordionActions>
                                <Button onClick={() => setFilter({ ...filter, sendFilter: true })} size="small" color="primary">
                                    Filtrer
                                </Button>
                            </AccordionActions>
                        </Accordion>
                    </AccordionRoot>

                    <br />

                    {
                        demandes.length !== 0 ?
                            <>
                                {demandes
                                    .filter((demande) =>
                                        demande.client.nom.toLowerCase().includes(recherche) ||
                                        demande.client.prenom.toLowerCase().includes(recherche) ||
                                        demande.reparation_entrepise.nom.toLowerCase().includes(recherche) ||
                                        demande.entreprisetypedemande.typedemande.nom.toLowerCase().includes(recherche)
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((demande, index) =>
                                        <Card key={index} sx={{ width: '100%', mb: 3 }}>

                                            <CardHeader
                                                avatar={
                                                    <>
                                                        {demande.client.photoprofil ?
                                                            <>
                                                                <Avatar src={demande.client.photoprofil} />
                                                            </>
                                                            :
                                                            <>
                                                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                                    {demande.client.nom.charAt(0)}{demande.client.prenom.charAt(0)}
                                                                </Avatar>
                                                            </>
                                                        }

                                                    </>
                                                }
                                                title={`Venant de ${demande.client.nom} ${demande.client.prenom}`}
                                                action={
                                                    <>
                                                        {demande.etat === 15 &&
                                                            <Button onClick={() => displayReponseModal(demande)} variant='outlined' >
                                                                <Icon>textsms</Icon> Répondre
                                                            </Button>
                                                        }
                                                        {demande.etat === 10 &&
                                                            <>
                                                                <Chip
                                                                    variant="outlined"
                                                                    color='info'
                                                                    label="En attente de réponse"
                                                                />
                                                            </>
                                                        }
                                                    </>
                                                }
                                            />
                                            <CardContent>
                                                <Typography gutterBottom>
                                                    Type de demande : {demande.entreprisetypedemande.typedemande.nom}
                                                </Typography>

                                                <Typography variant='subtitle1' gutterBottom>
                                                    {demande.reparation_entrepise.nom}
                                                </Typography>

                                                <Swiper
                                                    spaceBetween={1}
                                                    slidesPerView={1}
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                    navigation
                                                    pagination={{ clickable: true }}
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    {demande.reparation_entrepise.images.map((reparationimg, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div
                                                                style={divStyle}
                                                            >
                                                                <img
                                                                    src={reparationimg.img}
                                                                    style={imgStyle}
                                                                    alt="" />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>

                                            </CardContent>

                                        </Card>
                                    )
                                }
                            </>
                            :
                            <>
                                <center>
                                    <Typography variant='h6'>
                                        Aucune demandes reçues
                                    </Typography>
                                </center>
                            </>
                    }



                </>
                :
                <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>
            }

            {/* modal reponse */}
            {reponseDemande &&
                <Dialog onClose={handleCloseModalReponse} aria-labelledby="customized-dialog-title" open={openModalReponse}>
                    <DialogTitle id="customized-dialog-title" onClose={handleCloseModalReponse}>
                        Répondre à {reponseDemande.client.nom} {reponseDemande.client.prenom}
                    </DialogTitle>

                    <DialogContent dividers>

                        <Typography gutterBottom>
                            Suggestion du début de la réparation
                        </Typography>

                        <TextField
                            fullWidth
                            size="small"
                            name="datedebut"
                            type="date"
                            label="Date de debut"
                            variant="outlined"
                            value={reponseDemande.datedebut}
                            onChange={handleReponseDemandeChange}
                            sx={{ mt: 1.5 }}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            name="heuredebut"
                            type="time"
                            label="Heure de debut"
                            variant="outlined"
                            value={reponseDemande.heuredebut}
                            onChange={handleReponseDemandeChange}
                            sx={{ mt: 1.5 }}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={envoyerReponse} variant='outlined' color='success' >
                            <Icon>send</Icon> Envoyer
                        </Button>
                    </DialogActions>
                </Dialog>
            }

            {/*  */}

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}
        </>
    );
};

export default DemandeClientComponent;