import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import AffectationPersonnelComponent from 'app/myComponents/Repair/Personnel/AffectationPersonnelComponent';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AffectationPersonnel = () => {
    document.title = "AFFECTATION-PERSONNEL";

    const location = useLocation();
    const personnel = (location.state.personnel);

    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Personnels', path: '/entreprise/reparation/personnels/liste' }, { name: 'Ajout', path: '/entreprise/reparation/personnels/ajout' }, { name: 'Affectation' }]}
                    />
                </Box>

                <AffectationPersonnelComponent employe={personnel} ></AffectationPersonnelComponent>

            </AppButtonRoot>
        </>
    );
};

export default AffectationPersonnel;