import { Alert, Button, DialogActions, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import ModalGenerer from "../utils/ModalGenerer";
import { CreateCondition } from "./ContratAPI";


const CreerContratCondition = () => {


    const [contrat, setContrat] = useState({
        condition: "",

    });
    const [showModal, setShowModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageSnak, setMessageSnak] = useState("");
    const [handleCloseSnackbar, setHandleCloseSnackbar] = useState();
    const [severity, setSeverity] = useState();

    const CloseModal = () => {
        setShowModal(false);
    }


    const handleChange = (e) => {
        setContrat({ ...contrat, [e.target.name]: e.target.value })
    }

    const ajouterConditionContat = () => {
        setShowModal(true);
    }

    const ValiderInsertion = () => {
        CreateCondition(contrat, setMessageSnak, setOpenSnackbar, setSeverity, setShowModal);
    }

    return (
        <>
            <h1>Votre Condition pour la reservation</h1>
            <TextField
                fullWidth
                size="medium"
                name="condition"
                type="text"
                label="condition"
                value={contrat.condition}
                onChange={handleChange}
                variant="outlined"
                maxRows={10}
                sx={{ mb: 3 }}
                multiline
                id="outlined-multiline-flexible"
            />
            <Button sx={{ ml: 2 }} variant="contained" size="small" color="success" onClick={ajouterConditionContat}>Creer</Button>
            <ModalGenerer show={showModal}>
                <h3>Votre Contrat : {contrat.condition} </h3>
                <p style={{ color: 'blue' }}>Verifier bien votre contrat avant de le valider </p>
                <DialogActions>
                    <Button sx={{ ml: 2 }} variant="contained" size="small" color="error" onClick={CloseModal}>
                        fermer
                    </Button>
                    <Button sx={{ ml: 2 }} variant="contained" size="small" color="success" onClick={ValiderInsertion}>
                        Valider
                    </Button>
                </DialogActions>
            </ModalGenerer>

            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {messageSnak}
                </Alert>
            </Snackbar>
        </>
    );
}

export default CreerContratCondition;