import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
//import { getListeiles } from "app/data/nosybe/iles";
// import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import ModalDeleteIles from "./ModalDeleteIles";
import ModalEditIles from "./ModalEditIles";
import { getIlesListAPI, getListeIlesDetail } from "./API-Iles";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListIlesComponent = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [iles, setIles] = useState([]);
    const [selectedIles, setSelectedIles] = useState(undefined);
    const [ilesTotal, setIlesTotal] = useState(0);

    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
        //getListeIles(setiless, setilesTotal, newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        //getListeiles(setiless, setilesTotal, 0, +event.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedIles(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (iles) => {
        setSelectedIles(iles);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedIles(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (iles) => {
        console.log(iles)
        setSelectedIles(iles);
        setOpenModalEdit(true);
    }

    const [dataSend, setDataSend] = useState({
        "offset": page,
        "limit": rowsPerPage,
        "search": recherche
    });


    const getilesListe = async () => {
        await getIlesListAPI(dataSend, setIles, setIlesTotal);
    }

    useEffect(() => {
        // getListeiles(setiless, setilesTotal);
        getilesListe();
        console.log("iles", iles);
    }, [selectedIles]);

    return (
        <>
            {/* <TestButton toLog={iless} /> */}
            <SimpleCard title={'Liste des iles'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {iles.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom</TableCell>

                                        {/* <TableCell align="center">Prix</TableCell> */}
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {iles.filter(val => (val.nom.toLowerCase().match(recherche))).map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{item.nom}</TableCell>
                                            {/* <TableCell align="center">{iles.prix}</TableCell> */}
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(item)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(item)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                    }
                                </TableBody>
                            </StyledTable>

                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard>
            {selectedIles &&
                <>
                    <ModalDeleteIles Iles={selectedIles} setIles={setIles} open={openModalDelete} handleClose={handleCloseModalDelete} />

                    <ModalEditIles Iles={selectedIles} setIles={setIles} open={openModalEdit} handleClose={handleCloseModalEdit} />
                </>
            }
        </>
    )
}

export default ListIlesComponent;