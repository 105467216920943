import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Card, CardMedia, Checkbox, DialogActions, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField } from '@mui/material';
import { Box, styled } from '@mui/material';
import { Paragraph } from 'app/components/Typography';
import myConfig from 'app/config/api-config';
// import useAuth from 'app/hooks/useAuth';
import axios from 'axios';
import { Formik } from 'formik';
// import { initial } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import innoCarLogo from '../../../../logo/tiny_logo.png';
import url from 'app/UrlConfig';
import ModalGenerer from 'app/myComponents/Location/utils/ModalGenerer';
import { inscriptionAdmin } from 'app/data/nosybe/admin';
import TestButton from 'app/myComponents/TestButton';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(JustifyBox)(() => ({
  height: '100%',
  padding: '32px',
  background: 'rgba(0, 0, 0, 0.01)'
}));

const JWTRegister = styled(JustifyBox)(() => ({
  background: '#1A2038',
  minHeight: '100vh !important',
  '& .card': {
    maxWidth: 800,
    minHeight: 400,
    margin: '1rem',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center'
  }
}));

// inital login credentials


// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be 6 character length')
    .required('Password is required!'),
  email: Yup.string()
    .email('Invalid Email address')
    .required('Email is required!')
});

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const MySignIn = () => {

  document.title = "Inscription";

  const theme = useTheme();
  const navigate = useNavigate();



  const [errorMessage, setErrorMessage] = useState('Une erreur est survenue lors de votre inscription!');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [activites, setActivite] = useState([]);

  function getActivite() {
    const url = `${myConfig.host}/api/entreprise/activites`;

    const requestOptions = {
      url: url,
      method: 'GET',
      headers: myConfig.apiHeaders
    };
    axios(requestOptions).then((response) => {
      // console.log('requestOptions : ', requestOptions);
      setActivite(response.data);
    }
    ).catch((e) => {
      console.log(e);
    })
  }

  // useEffect(() => {

  // }, []);

  const [initialValues, setInitialValue] = useState(
    {
      username: '',
      nomRepresentant: '',
      // images: '',
      imagesPath: '',
      email: '',
      password: '',
      confirmationPassword: '',
      contact: [],
      adresse: '',
      nif: '',
      stat: '',
      license: '',
    }
  );
  const [errors, setErrors] = useState({});

  function changeInitialValue(e) {
    setInitialValue({ ...initialValues, [e.target.name]: e.target.value });
  }

  function geolocaliser() {
    navigator.geolocation.getCurrentPosition((geoPosition) => {
      const { latitude, longitude } = geoPosition.coords;
      const position = { latitude, longitude };
      setInitialValue({ ...initialValues, position: position });
    });
  }


  const [messageEntete, setMessageEntete] = useState();
  const [message, setMessage] = useState();
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  const OpenModal = () => {
    setShowModalUpdate(true)
  }
  const CloseModal = () => {
    setShowModalUpdate(false)
  }

  const Inscription = () => {
    inscriptionAdmin(initialValues)
      .then(result => {
        if (result.data.errorInfo) {
          setMessageEntete("Error Survenue lors de l'inscription");
          setMessage("Email déjà existant");
        } else {
          setMessageEntete("Inscription Réussie");
          setMessage("Vous êtes bien inscrit veuillez attendre la validation de l'admin");
        }
        OpenModal();
      })
      .catch(error => { console.error(error) })
  }

  const handleFormSubmit = () => {
    const erName = validateName();
    const erEmail = validateEmail();
    const erPassword = validatePassword();
    if (Object.keys(errors).some(key => Boolean(errors[key])) || erName || erEmail || erPassword) {
      return;
    }
    Inscription();
  }

  function addContact() {
    const contactData = [...initialValues.contact];
    contactData.push('+261');
    setInitialValue({ ...initialValues, contact: contactData });
  }

  const removeContact = (index) => {
    const contactData = initialValues.contact.filter((_, i) => i !== index);
    setInitialValue({ ...initialValues, contact: contactData });
  }

  function handleContactChange(e, index) {
    const contactData = [...initialValues.contact];
    contactData[index] = e.target.value;
    setInitialValue({ ...initialValues, contact: contactData });
  }

  const isValidImageType = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp', 'image/svg'];
    return allowedTypes.includes(file.type);
  };

  function handleFileChange(event) {
    const file = event.target.files[0];

    if (!file) {
      // console.log("not file");
      return; // Handle empty file selection gracefully
    }

    // Validate file type (optional)
    if (!isValidImageType(file)) {
      // console.log("image not valid");
      // Display error message or handle invalid type in a suitable way
      console.error('Invalid file type. Please select an image.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target.result;
      // const imagePath = `public/img/admin/${file.name}`;
      setInitialValue({
        ...initialValues,
        images: base64,
        // imagesPath: imagePath,
      });
    };
    reader.readAsDataURL(file);
  }

  function addSpecialite(idspecialite) {
    const specialites = [...initialValues.specialites];

    const isSpecialiteAlreadyInArray = specialites.some(item => item === idspecialite);

    if (!isSpecialiteAlreadyInArray) {
      specialites.push(idspecialite);
      setInitialValue({ ...initialValues, specialites: specialites });

    }

    if (isSpecialiteAlreadyInArray) {
      const updatedTabSpecialite = specialites.filter(item => item !== idspecialite);
      setInitialValue({ ...initialValues, specialites: updatedTabSpecialite });
    }

  }

  const validateName = () => {
    let msg = undefined;
    const { username } = initialValues;
    if (!username) {
      msg = 'Nom requis'
    }
    setErrors((oldErrors) => { return { ...oldErrors, username: msg } });
    return Boolean(msg);
  }

  const validateEmail = () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let msg = undefined;
    const { email } = initialValues;
    if (!emailRegex.test(email)) {
      msg = 'Address Email Invalid';
    }
    if (!email) {
      msg = 'Email requis!';
    }
    setErrors((oldErrors) => { return { ...oldErrors, email: msg } });
    return Boolean(msg);
  };

  const validatePassword = () => {
    let msg = undefined;
    const { password } = initialValues
    if (!password) {
      msg = 'Mot de passe requis'
    } else if (password.length && password.length < 6) {
      msg = "Le mot de passe doit contenir 6 caractères";
    }
    setErrors((oldErrors) => { return { ...oldErrors, password: msg } });
    return Boolean(msg);
  }

  return (
    <JWTRegister>
      {/* <TestButton toLog={initialValues} /> */}
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img
                width="100%"
                alt="Register"
                // src="/assets/images/illustrations/posting_photo.svg"
                src={innoCarLogo}
              />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={4} height="100%">
              {/* <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                initialErrors={initialErrors}
                initialTouched={initialTouched}
                validationSchema={validationSchema}
              >
                {({ errors, touched, handleBlur, handleSubmit }) => ( */}
              <form
              // onSubmit={handleSubmit}
              >
                {/* <TestButton toLog={errors} /> */}
                <h2>NOSYBE-INSCRIPTION</h2>

                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      {/* <label htmlFor="contained-button-file">
                            <StyledButton variant="contained" component="span">
                              Ajouter votre logo
                            </StyledButton>
                          </label>
                          <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />
                          {initialValues.images &&
                            <>
                              <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                  sx={{ height: 140 }}
                                  image={initialValues.images}
                                  title="Your profil"
                                />
                              </Card>
                              <br />
                            </>
                          } */}
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="username"
                        label="Votre nom"
                        variant="outlined"
                        onBlur={validateName}
                        value={initialValues.username}
                        onChange={changeInitialValue}
                        helperText={errors.username || ''}
                        error={Boolean(errors.username)}
                        sx={{ mb: 3 }}
                      />

                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="nomRepresentant"
                        label="Nom du représentant"
                        variant="outlined"
                        // onBlur={handleBlur}
                        value={initialValues.nomRepresentant}
                        onChange={changeInitialValue}
                        // helperText={touched.nomRepresentant && errors.nomRepresentant}
                        // error={Boolean(errors.nomRepresentant && touched.nomRepresentant)}
                        sx={{ mb: 3 }}
                      />
                    </div>

                    {initialValues.contact.map((value, index) => (
                      <FormControl fullWidth variant="outlined" key={index} sx={{ mb: 2 }}>
                        <InputLabel htmlFor={`contact-input-${index}`}>Contact</InputLabel>
                        <OutlinedInput
                          id={`contact-input-${index}`}
                          size="small"
                          type="tel"
                          name="contact"
                          label="Contact"
                          value={value}
                          onChange={(e) => handleContactChange(e, index)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => removeContact(index)}
                                edge="end"
                              >
                                <Icon>clear</Icon>
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    ))}

                    <Fab onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                      <Icon>phone</Icon>
                    </Fab>
                    <br />
                    <br />

                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="adresse"
                      label="Adresse"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={initialValues.adresse}
                      onChange={changeInitialValue}
                      // helperText={touched.adresse && errors.adresse}
                      // error={Boolean(errors.adresse && touched.adresse)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="nif"
                      label="NIF"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={initialValues.nif}
                      onChange={changeInitialValue}
                      // helperText={touched.nif && errors.nif}
                      // error={Boolean(errors.nif && touched.nif)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="stat"
                      label="STAT"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={initialValues.stat}
                      onChange={changeInitialValue}
                      // helperText={touched.stat && errors.stat}
                      // error={Boolean(errors.stat && touched.stat)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="license"
                      label="License"
                      variant="outlined"
                      // onBlur={handleBlur}
                      value={initialValues.license}
                      onChange={changeInitialValue}
                      // helperText={touched.license && errors.license}
                      // error={Boolean(errors.license && touched.license)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={() => validateEmail(initialValues.email)}
                      value={initialValues.email}
                      onChange={changeInitialValue}
                      // helperText={touched.email && errors.email}
                      helperText={errors.email || ''}
                      error={Boolean(errors.email)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Mot de passe"
                      id="idmdp"
                      variant="outlined"
                      onBlur={() => validatePassword(initialValues.password)}
                      value={initialValues.password}
                      onChange={changeInitialValue}
                      helperText={errors.password || ''}
                      error={Boolean(errors.password)}
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="confirmationPassword"
                      type="password"
                      label="Confirmation de mot de passe"
                      variant="outlined"
                      id='confirmationmdpid'
                      value={initialValues.confirmationPassword}
                      onChange={changeInitialValue}
                      sx={{ mb: 2 }}
                    />
                  </div>

                </div>

                {
                  initialValues.password === initialValues.confirmationPassword ?
                    <>
                      <LoadingButton
                        onClick={handleFormSubmit}
                        fullWidth
                        type="button"
                        color="primary"
                        variant="contained"
                        sx={{ mb: 2, mt: 3 }}
                      >
                        S'inscrire
                      </LoadingButton>
                    </>
                    :
                    <>
                      <Alert severity="error">Confirmer votre mot de passe!</Alert>
                    </>
                }


                <Paragraph>
                  Deja un compte?
                  <NavLink
                    to="/entreprise/connexion"
                    style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                  >
                    Se connecter
                  </NavLink>
                </Paragraph>
              </form>
              {/* )}
              </Formik> */}
            </Box>
          </Grid>
        </Grid>
      </Card>

      {/* <>
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
        >
          <Alert
            severity='error'
          >
            {errorMessage}
          </Alert>
        </Snackbar> */}

      {/* </> */}

      {/* MODAL VALIDATION */}
      <ModalGenerer show={showModalUpdate}>
        <h1>{messageEntete}</h1>
        <h3>{message}</h3>
        <DialogActions>
          <NavLink
            to="/entreprise/connexion"
            style={{ color: theme.palette.primary.main, marginLeft: 5 }}
          >
            Se connecter
          </NavLink>

          <Button onClick={CloseModal}>
            fermer
          </Button>
        </DialogActions>
      </ModalGenerer>
      {/* MODAL VALIDATION */}
    </JWTRegister >
  );
};

export default MySignIn;
