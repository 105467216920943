import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, FormControl, Grid, Icon, InputLabel, MenuItem, Select, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

// PieChart

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
// DoughnutChart
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const ReparationPieChartComponent = () => {

    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [filter, setFilter] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        sendRequest: false
    });

    const [mois, setMois] = useState(null);
    function getMois() {
        const requestOptions = {
            url: `${myConfig.host}/api/mois`,
            method: 'GET',
        };
        axios(requestOptions).then((response) => {
            setMois(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getMois();
    }, []);

    // datas reparation
    const [dataReparation, setDataReparation] = useState(null);
    // datas typereparation
    const [dataTypeReparation, setDataTypeReparation] = useState(null);

    function getStatReparation() {
        setDataReparation(null);
        setDataTypeReparation(null);

        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/stat/reparationDemande`,
            method: 'GET',
        };
        if (filter.sendRequest === true) {
            requestOptions.url += `?month=${filter.month}`;
            requestOptions.url += `&year=${filter.year}`;
        }

        axios(requestOptions).then((response) => {
            const responses = response.data;

            let dataChart = [];
            let dataLabels = [];
            let colors = [];

            responses.reparationDemande
                .forEach(data => {
                    dataChart.push(data.pourcentage);
                    dataLabels.push(data.nom);
                    colors.push(data.codecouleur);
                });

            const dataReparation = {
                labels: dataLabels,
                datasets: [
                    {
                        label: '% de la réparation',
                        data: dataChart,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            };

            if (dataLabels.length !== 0) {
                setDataReparation(dataReparation);
            } else {
                setDataReparation([]);
            }

            dataChart = [];
            dataLabels = [];
            colors = [];

            responses.typeReparationDemande
                .forEach(data => {
                    dataChart.push(data.pourcentage);
                    dataLabels.push(data.nomtypereparation);
                    colors.push(data.typereparationcodecouleur);
                });

            const dataTypeReparation = {
                labels: dataLabels,
                datasets: [
                    {
                        label: '% type de réparation',
                        data: dataChart,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            };
            if (dataLabels.length !== 0) {
                setDataTypeReparation(dataTypeReparation);
            } else {
                setDataTypeReparation([]);
            }

        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getStatReparation();
    }, []);

    // 
    useEffect(() => {
        if (filter.sendRequest === true) {
            getStatReparation();
            setFilter({ ...filter, sendRequest: false });
        }
    }, [filter]);
    // 

    return (
        <>
            <AccordionRoot>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Box className="column">
                            <Typography className="heading">TABLEAU DE BORD</Typography>
                        </Box>

                        <Box className="column">
                            <Typography className="secondaryHeading">Filtrer</Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails className="details">
                        <Box className="column helper">
                            <Grid container spacing={3}>

                                <Grid item lg={6} md={6}>
                                    {mois ?
                                        <>
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel filled id="demo-simple-select-autowidth-label">Mois</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    name='month'
                                                    value={filter.month === 0 ? '' : filter.month}
                                                    onChange={(e) => setFilter({ ...filter, month: e.target.value })}
                                                    // autoWidth
                                                    label="Mois"
                                                >
                                                    {mois.map((mois, index) => (
                                                        <MenuItem key={index} value={mois.id}>{mois.nom}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </>
                                        :
                                        <>
                                            <center><CircularProgress></CircularProgress></center>
                                        </>
                                    }
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="year"
                                        type="number"
                                        label="Année"
                                        variant="outlined"
                                        value={filter.year}
                                        onChange={(e) => setFilter({ ...filter, year: e.target.value })}
                                        sx={{ mt: 3 }}
                                    />
                                </Grid>
                            </Grid>

                        </Box>
                    </AccordionDetails>

                    <Divider />

                    <AccordionActions>
                        <Button onClick={() => {
                            setFilter({ ...filter, sendRequest: true });
                        }} size="small" color="primary">
                            Filtrer
                        </Button>
                    </AccordionActions>
                </Accordion>
            </AccordionRoot>

            <br />

            <Grid sx={{ mb: 3 }} container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SimpleCard title="Réparations les plus demandées">
                        {dataReparation ?
                            <>
                                {dataReparation.length !== 0 ?
                                    <>
                                        <Pie data={dataReparation} />
                                    </>
                                    :
                                    <>
                                        <center>
                                            <Typography variant='h5'>Aucune réparations trouvées</Typography>
                                        </center>
                                    </>
                                }
                            </>
                            :
                            <>
                                <center>
                                    <CircularProgress></CircularProgress>
                                </center>
                            </>
                        }
                    </SimpleCard>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SimpleCard title="Types les plus demandés">
                        {dataTypeReparation ?
                            <>
                                {dataTypeReparation.length !== 0 ?
                                    <>
                                        <Doughnut data={dataTypeReparation} />
                                    </>
                                    :
                                    <>
                                        <center>
                                            <Typography variant='h5'>Aucun types de réparation trouvés</Typography>
                                        </center>
                                    </>
                                }


                            </>
                            :
                            <>
                                <center>
                                    <CircularProgress></CircularProgress>
                                </center>
                            </>
                        }
                    </SimpleCard>
                </Grid>
            </Grid>
        </>
    );
};

export default ReparationPieChartComponent;
