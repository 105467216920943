import { Box, Button, Card, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Icon, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(JustifyBox)(() => ({
    height: '100%',
    padding: '32px',
    background: 'rgba(0, 0, 0, 0.01)'
}));

const ProfilComponent = () => {

    const token = localStorage.getItem('token');

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [profil, setProfil] = useState(null);

    const getProfil = () => {
        const requestOptions = {
            url: `${myConfig.host}/api/entreprise/profileJWT`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setProfil(response.data.entreprise);

        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getProfil();
    }, [])

    const [editProfil, setEditProfil] = useState(null);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    function handleProfilChange(e) {
        setEditProfil({ ...editProfil, [e.target.name]: e.target.value });
    }

    function handleContactChange(e, index) {
        const contactData = [...editProfil.contact];
        contactData[index] = e.target.value;
        setEditProfil({ ...editProfil, contact: contactData });
    }

    function addContact() {
        const contactData = [...editProfil.contact];
        contactData.push('+261');
        setEditProfil({ ...editProfil, contact: contactData });
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setEditProfil({ ...editProfil, logo: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    function displayEditModal() {
        setEditProfil(profil);
        setOpenModalEdit(true);
    }

    function modifierProfil() {
        // update profil
        // getProfil
        setOpenModalEdit(false);
    }

    return (
        <>
            <SimpleCard title={'Votre profil'}>
                {
                    profil ?
                        <>
                            <Grid container>
                                <Grid item sm={6} xs={12}>
                                    {/* <ContentBox> */}
                                    <div style={{
                                        height: '500px',
                                        width: '100%',
                                        backgroundColor: 'lightgrey',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'

                                    }} >
                                        <img
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                maxHeight: '100%',
                                                maxWidth: '100%'
                                            }}
                                            src={profil.logo}
                                        />
                                    </div>
                                    {/* </ContentBox> */}
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <Box p={4} height="100%">

                                        <Typography variant='h3' component="div">
                                            {profil.nom}
                                            <Button onClick={displayEditModal}>
                                                <Icon>edit</Icon>
                                            </Button>
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {profil.email}
                                        </Typography>

                                        <Typography variant='body1' gutterBottom>
                                            {profil.description}
                                        </Typography>

                                        <br />

                                        {profil.contact.map((value, index) => (
                                            <Typography key={index} variant='body2'>
                                                Contact : {value.contact}
                                            </Typography>
                                        ))}

                                        <br />

                                        {profil.activites.map((activite, index) => (
                                            <Typography key={index} variant='body2'>
                                                Activité : {activite.activite.nomactivite}
                                            </Typography>
                                        ))}

                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                }

            </SimpleCard>

            {/* modal edit */}
            {
                editProfil &&
                <>
                    <Dialog maxWidth='md' onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier
                        </DialogTitle>

                        <DialogContent dividers>
                            <Box p={4} height="100%">

                                {editProfil.logo &&
                                    <>
                                        <Card sx={{ maxWidth: 345 }}>
                                            <CardMedia
                                                sx={{ height: 140 }}
                                                image={editProfil.logo}
                                                title="Your profil"
                                            />
                                        </Card>
                                        <br />
                                    </>
                                }

                                <label htmlFor="contained-button-file">
                                    <Button sx={{ mb: 3 }} variant="outlined" component="span">
                                        <Icon>camera</Icon> Votre logo
                                    </Button>
                                </label>
                                <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />


                                <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="nom"
                                    label="Votre nom"
                                    variant="outlined"
                                    value={editProfil.nom}
                                    onChange={handleProfilChange}
                                    sx={{ mb: 3 }}
                                />

                                <TextField
                                    fullWidth
                                    size="medium"
                                    type="text"
                                    name="description"
                                    label="Votre description"
                                    variant="outlined"
                                    value={editProfil.description}
                                    onChange={handleProfilChange}
                                    id="outlined-multiline-flexible"
                                    multiline
                                    maxRows={10}
                                    sx={{ mb: 3 }}
                                />

                                <TextField
                                    fullWidth
                                    size="small"
                                    type="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={editProfil.email}
                                    onChange={handleProfilChange}
                                    sx={{ mb: 3 }}
                                />

                                {editProfil.contact.map((value, index) => (
                                    <div key={index}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="tel"
                                            name="contact"
                                            label={`contact`}
                                            variant="outlined"
                                            value={value.contact}
                                            sx={{ mb: 1 }}
                                            onChange={(e) => handleContactChange(e, index)}
                                        /></div>
                                ))}

                                <Fab onClick={addContact} sx={{ zIndex: 0 }} size="small" color="primary" aria-label="Add" className="button">
                                    <Icon>add</Icon>
                                </Fab>

                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit} color='primary'>
                                Fermer
                            </Button>
                            <Button onClick={modifierProfil} color='success'>
                                Appliquer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }

            {/*  */}

        </>
    );
};

export default ProfilComponent;