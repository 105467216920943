import myConfig from "app/config/api-config";
import axios from "axios";
import { displayToast } from "./dispatch";

// Gestion Categorie
export const getCategories = (setCategories) => {
    const url = myConfig.host + "/api/admin/piece/categorie";
    const requestOptions = {
        url: url,
    }
    axios(requestOptions).then((response) => {
        setCategories(response.data.resources);
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message);
    })
}

export const insertCategorie = (categorie, setCategories) => {
    const url = myConfig.host + "/api/admin/piece/categorie";
    const requestOptions = {
        url: url,
        method: 'POST',
        data: categorie,
    }
    axios(requestOptions).then((response) => {
        const data = response.data;
        setCategories(data.resources);
        displayToast(data.message, true)
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}

export const updateCategorie = (categorie, setCategories) => {
    const url = myConfig.host + "/api/admin/piece/categorie/" + categorie.id;
    const requestOptions = {
        url: url,
        method: 'PUT',
        data: categorie,
    }
    axios(requestOptions).then((response) => {
        const data = response.data;
        setCategories(data.resources);
        displayToast(data.message, true)
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}

export const desactivateCategorie = (categorie, setCategories) => {
    const url = myConfig.host + "/api/admin/piece/categorie/" + categorie.id;
    const requestOptions = {
        url: url,
        method: 'DELETE',
    }
    axios(requestOptions).then((response) => {
        const data = response.data;
        setCategories(data.resources);
        displayToast(data.message, true)
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}

export const activateCategorie = (categorie, setCategories) => {
    const url = myConfig.host + "/api/admin/piece/categorie/activer/" + categorie.id;
    const requestOptions = {
        url: url,
        method: 'PUT',
    }
    axios(requestOptions).then((response) => {
        const data = response.data;
        setCategories(data.resources);
        displayToast(data.message, true)
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}


// Gestion Changement
export const getChangementCategorie = (setCategorieChangements) => {
    const url = myConfig.host + "/api/admin/piece/changement/categorie";
    const requestOptions = {
        url: url,
    }
    axios(requestOptions).then((response) => {
        setCategorieChangements(response.data.changements);
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}

export const acceptChange = (changement, setCategorieChangements) => {
    const url = myConfig.host + "/api/admin/piece/changement/categorie/accepter/" + changement.id;
    const requestOptions = {
        url: url,
        method: 'POST',
    }
    axios(requestOptions).then((response) => {
        const data = response.data;
        setCategorieChangements(data.changements);
        displayToast(data.message, true)
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}

export const refuseChange = (changement, setCategorieChangements) => {
    const url = myConfig.host + "/api/admin/piece/changement/categorie/refuser/" + changement.id;
    const requestOptions = {
        url: url,
        method: 'POST',
    }
    axios(requestOptions).then((response) => {
        const data = response.data;
        setCategorieChangements(data.changements);
        displayToast(data.message, true)
    }).catch((e) => {
        console.log(e);
        displayToast(e.response.data.message, false)
    })
}

export const getPrimaryRessources = (setSousCategories, setMarques) => {
    const url = myConfig.host + "/api/entreprise/piece/primaryRessource";
    axios({ url: url }).then((response) => {
        // console.log("Active Ressources: ", response);
        setSousCategories(response.data.souscategories);
        setMarques(response.data.marques);
    }).catch((e) => {
        console.error(e);
        displayToast(e.response.data.message)
    })
}

export const getSousCategorieData = (idSousCategorie, setCaracteristiques) => {
    const url = myConfig.host + "/api/client/piece/souscategorie/data/" + idSousCategorie;
    axios({ url: url }).then((response) => {
        // console.log("SousCategorie Data: ", response);
        setCaracteristiques(response.data.caracteristiques);
    }).catch((e) => {
        console.error(e);
        displayToast(e.response.data.message)
    })
}