import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { displayToast } from "app/data/piece/dispatch";
import TestButton from "app/myComponents/TestButton";
import { DeleteExcursion } from "./API-Excursion";

const ModalDeleteExcursion = ({ Excursion, setExcursion, open, handleClose }) => {
    const supprimerExcursion = async () => {
        await DeleteExcursion(Excursion.id);
        handleClose();
        displayToast("Succes de la suppression", true);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Retirer {Excursion.nom}
            </DialogTitle>

            <DialogContent dividers>
                {/* <TestButton toLog={Excursion} /> */}
                <Typography gutterBottom>
                    Vous en êtes sur?
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={supprimerExcursion} style={{ color: 'red' }}>
                    Confirmer
                </Button>
                <Button onClick={handleClose} style={{ color: 'red' }}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteExcursion;