import { Card, Box, styled, Grid, Button, DialogActions, Snackbar, Alert, Icon } from '@mui/material';
import { SimpleCard } from 'app/components';
import ModalDetail from '../utils/ModalDetail';
import DetailVehicule from '../Vehicule/DetailVehicule';
import DetailDemande from './DetailDemande';
import ModalUpdate from '../utils/ModalUpdate';
import UpdateLocation from './UpdateLocation';
import ModalDelete from '../utils/ModalDelete';
import ModalGenerer from '../utils/ModalGenerer';
import { useState } from 'react';
import ValidationDemande from './ValidationDemande';
import { ValiderReservation } from './ClientApropos';
import { divStyle, imgStyle } from '../utils/Style';



const CardRoot = styled(Card)({
    height: '250px',
    width: '200px',
    padding: '20px 24px',
});

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && '16px'
}));

const CardMobile = ({ vehicule, setRelance, relance }) => {
    const styleImage = {
        width: "100px",
        heigth: "100px"
    }

    const styleP = {
        fontSize: "10px"
    }

    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [result, setResult] = useState();
    const Valider = () => {
        setShowModalUpdate(true);
    }

    const validerVR = async () => {
        try {
            const response = await ValiderReservation(vehicule, 21);
            setShowModalUpdate(false);
            console.log(response);
            setMessage("Reservation bien validé");
            setSeverity("success");
            setOpenSnackbar(true);
            setRelance(relance + 1);

        } catch (error) {
            // Gérez les erreurs, par exemple en affichant un message d'erreur.
            console.error(error);
        }
    };

    /*  SNACKNAR * */
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const ReservationFini = async () => {
        try {
            const response = await ValiderReservation(vehicule, 31);
            setShowModalUpdate(false);
            console.log(response);
            setMessage("Reservation bien fini");
            setSeverity("success");
            setOpenSnackbar(true);
            setRelance(relance + 1);

        } catch (error) {
            // Gérez les erreurs, par exemple en affichant un message d'erreur.
            console.error(error);
        }
    }

    /*  SNACKNAR * */

    // const cardStyle
    return (

        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item lg={1} md={1}></Grid>
                <Grid item lg={5} md={5}>
                    {/* <CardRoot elevation={12}> */}
                    <SimpleCard>
                        <Box width="100%" overflow="auto">
                            <div style={divStyle}>
                                <img style={imgStyle} src={vehicule.vehicule.image_vehicule[0].image}></img>
                            </div>
                            <p style={styleP}> {vehicule.vehicule.model_vehicule.marquevoiture.marque} {vehicule.vehicule.model_vehicule.model} {"("} {vehicule.vehicule.model_vehicule.places}{" plcs)"}</p>
                            {/* <p>{vehicule.categorie}</p> */}
                            <p style={styleP}>{vehicule.immatriculation}</p>
                            <p style={styleP}>Prix par jours {new Number(vehicule.prixlocation).toLocaleString()} {"(Ariary)"}</p>
                            <p style={styleP}>Montant Total  {new Number(vehicule.montanttotal).toLocaleString()} {"(Ariary)"}</p>
                            <ModalDetail>
                                <DetailVehicule vehicule={vehicule.vehicule}></DetailVehicule>
                            </ModalDetail>
                        </Box>
                    </SimpleCard>

                    {/* </CardRoot> */}
                </Grid>

                <Grid item lg={6} md={6}>
                    {/* <SimpleCard> */}
                    {/* <SimpleCard> */}
                    {vehicule.jours !== undefined && vehicule.jours > 0 && (
                        <p style={{ color: 'red' }}> Reservation fini dans {vehicule.jours} jours</p>
                    )}
                    {vehicule.jours !== undefined && vehicule.jours < 0 && (
                        <p style={{ color: 'red' }}> Fini il y a {vehicule.jours} jours</p>
                    )}
                    <p>Date Location:{vehicule.datedebut} jusqu'au {vehicule.datefin}</p>
                    {/* <p>{vehicule.categorie}</p> */}
                    <p></p>
                    {vehicule.client !== undefined && (
                        <p>Contact client : {vehicule.client.contact}</p>
                    )}

                    <ModalDetail><DetailDemande demande={vehicule}></DetailDemande></ModalDetail>
                    <ModalUpdate> <UpdateLocation demande={vehicule}></UpdateLocation></ModalUpdate>
                    {/* <ModalDelete vehicule={vehicule}> <h3>
                        Vous etes sure de Supprimer cette Voiture {vehicule.marque} {vehicule.model}{" "}
                        de numero {vehicule.immatriculation}
                    </h3>
                        <Button>Supprimer</Button>
                    </ModalDelete> */}
                    {/* </SimpleCard> */}
                    <Box sx={{ py: '12px' }} />
                    {vehicule.jours === undefined && (
                        <>
                            {vehicule.etat_reservation !== 21 && (
                                <Button sx={{ ml: 2 }} onClick={Valider} variant="contained" size="small" color="primary">Valider</Button>
                            )}
                            {vehicule.etat_reservation === 21 && (
                                <>
                                    <p>Dejat Valider le {vehicule.date_validation}   <Icon color='success'>check</Icon></p>

                                    {/* <Button sx={{ ml: 2 }} onClick={Valider} variant="contained" size="small" color="primary">Voir Contrat</Button> */}
                                </>
                            )}
                        </>
                    )}
                    {vehicule.jours !== undefined && (
                        <>
                            <p>Dejat Valider le {vehicule.date_validation}</p>
                            {vehicule.etat_reservation === 21 && (
                                <Button onClick={Valider} sx={{ ml: 2 }} variant="contained" size="small" color="primary">Fini</Button>
                            )}
                            {vehicule.etat_reservation === 31 && (
                                <p> Reservation Fini</p>
                            )}
                            {vehicule.etat_reservation === 0 && (
                                <p> Reservation en atente d'annulation</p>
                            )}
                            <Button onClick={Valider} sx={{ ml: 2 }} variant="contained" size="small" color="success">Voir Contrat</Button>
                        </>
                    )}

                </Grid>
                <ModalGenerer show={showModalUpdate}>

                    <ValidationDemande demande={vehicule}></ValidationDemande>
                    <DialogActions>
                        {vehicule.jours === undefined && (
                            <Button onClick={validerVR} sx={{ ml: 2 }} variant="contained" size="small" color="primary">Valider</Button>
                        )}
                        {vehicule.jours !== undefined && (
                            <Button onClick={ReservationFini} sx={{ ml: 2 }} variant="contained" size="small" color="success">Fini</Button>
                        )}


                        <Button sx={{ ml: 2 }} variant="contained" size="small" color="error" onClick={() => setShowModalUpdate(false)}>

                            fermer
                        </Button>
                    </DialogActions>
                </ModalGenerer>

            </Grid >
            {/* MODAL UPDATE */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </SimpleCard >


    );
};

export default CardMobile;
