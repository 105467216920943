import { LoadingButton } from '@mui/lab';
import { Alert, Card, Grid, Snackbar, TextField } from '@mui/material';
import { Box, styled } from '@mui/material';
import myConfig from 'app/config/api-config';
import { connexionAdmin } from 'app/data/nosybe/admin';
import { connexionSuperAdmin } from 'app/data/nosybe/superadmin';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)'
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: '#1A2038',
  minHeight: '100% !important',
  '& .card': {
    maxWidth: 800,
    minHeight: 400,
    margin: '1rem',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center'
  }
}));

const AdminLogin = () => {
  document.title = "ADMIN-CONNEXION";

  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  // inital login credentials
  const [initialValues, setInitialValues] = useState(
    {
      email: '',
      password: ''
    }
  );

  const handleClose = () => setOpen(false);

  function handleChange(e) {
    // console.log("handleChange: ", e.target.name, e.target.value);
    setInitialValues({ ...initialValues, [e.target.name]: e.target.value });
  }

  const navigate = useNavigate();

  function handleFormSubmit() {
    connexionSuperAdmin(initialValues)
      .then((response) => {
        // console.log("handleFormSubmit: ", response);
        setMessage(response.data.message);
        const data = response.data
        console.log(response);
        localStorage.setItem("token", data.tokenSuperAdmin)
        localStorage.setItem("user", "admin")
        localStorage.setItem("superadmin", JSON.stringify(data.superadmin))
        // dispatchUpdate();
        navigate('/admin/entreprises');
      }).catch((e) => {
        console.error(e);
        setOpen(true)
        // setMessage(data.message)
      })
  }

  return (
    <JWTRoot>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <JustifyBox p={4} height="100%" sx={{ minWidth: 320 }}>
              <img src="/assets/images/illustrations/dreamer.svg" width="100%" alt="" />
            </JustifyBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ContentBox>
              <center>
                <h2>ADMINISTRATEUR-CONNEXION</h2>
              </center>

              <TextField
                fullWidth
                size="small"
                type="email"
                name="email"
                label="Email"
                variant="outlined"
                value={initialValues.email}
                onChange={handleChange}
                sx={{ mb: 3 }}
              />

              <TextField
                fullWidth
                size="small"
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                value={initialValues.password}
                onChange={handleChange}
                sx={{ mb: 1.5 }}
              />

              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                sx={{ my: 2 }}
                onClick={handleFormSubmit}
              >
                Connexion
              </LoadingButton>

            </ContentBox>
          </Grid>
        </Grid>
      </Card>

      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
      >
        <Alert
          severity='error'
        >
          {message}
        </Alert>
      </Snackbar>

    </JWTRoot>
  );
};

export default AdminLogin;
