import { Alert, Badge, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField, Typography, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SaveEquipementAPI } from "./Api-Equipement";
import { CoPresent } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const FormEquipement = ({ selectedHebergement, setHebergements }) => {

    const navigate = useNavigate();

    const [types, setTypes] = useState([]);
    // const navigate = useNavigate();
    // const [equipements, setEquipements] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [messageSnak, setMessageSnak] = useState("");
    // const navigate = useNavigate();

    const originalEquipement = {
        name: "",
        description: ""
    }


    const [equipement, setEquipement] = useState(selectedHebergement ? selectedHebergement : { ...originalEquipement });
    const [inputErrors, setInputErrors] = useState({});

    const handleChange = (e) => {
        console.log(e.target.name)
        setEquipement({
            ...equipement,
            [e.target.name]: e.target.value
        });
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)

    }

    const saveEquipement = async () => {
        // console.log("FTCGV")
        console.log(equipement)
        await SaveEquipementAPI(equipement, setMessageSnak, setOpenSnackbar, setSeverity);

        navigate("/entreprise/equipement/list");
    }

    return (
        <>


            <Grid container spacing={3}>
                <Grid item sm={3}></Grid>
                <Grid item sm={6}>
                    <Typography variant="h5">
                        {selectedHebergement ? 'Modifier un Equipement' : 'Ajouter un Equipement'}
                    </Typography>
                    <SimpleCard>

                        <TextField
                            fullWidth
                            size='small'
                            name="name"
                            type="text"
                            label="Titre"
                            value={equipement.name}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.titre && "error"}
                            helperText={inputErrors.titre}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="description"
                            type="text"
                            label="description"
                            value={equipement.description}
                            onChange={(e) => handleChange(e)}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.titre && "error"}
                            helperText={inputErrors.titre}
                        />

                        <StyledButton
                            // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                            onClick={saveEquipement}
                            fullWidth
                            variant="contained"
                            component="span"
                        >
                            Enregistrer
                        </StyledButton>

                    </SimpleCard>
                </Grid>

            </Grid>
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={1000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {messageSnak}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormEquipement;