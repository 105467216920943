import React from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, Autocomplete, Badge, Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select, Snackbar, Step, StepLabel, Stepper, TablePagination, TextField, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';
// 

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const ListeReparationComponent = ({ isCrud, affectation, proposition, modifSuggestion }) => {

    const [recherche, setRecherche] = useState({
        label: '',
        idtypereparation: 0,
        dureedebut: '00:30',
        dureefin: '01:30',
        prixmin: 300000,
        prixmax: 500000,
        isRecherche: false
    });

    const sendRecherche = () => {
        setRecherche({ ...recherche, isRecherche: true });
    }

    const handleAutocompleteChange = (event, newValue) => {
        if (newValue) {
            setRecherche({ ...recherche, idtypereparation: newValue.id });
        } else {
            setRecherche({ ...recherche, idtypereparation: 0 });
        }
    };

    useEffect(() => {
        if (recherche.isRecherche === true) {
            getListReparation();
            setRecherche({ ...recherche, isRecherche: false });
        }
    }, [recherche]);

    const [typereparations, setTypeReparation] = useState(null);

    function getTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typereparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            const tab = [];
            const listTypeReparations = response.data;
            listTypeReparations.forEach(typeReparation => {
                tab.push({
                    id: typeReparation.idtypereparation,
                    label: typeReparation.typereparation.nom
                });
            });
            setTypeReparation(tab);
        }).catch((e) => {
            console.log(e);
        })
    }

    const [reparations, setListReparation] = useState(null);

    const getListReparation = () => {
        let myUrl = `${myConfig.host}/api/reparation/entreprise/reparations`;
        if (recherche.isRecherche === true) {
            myUrl += `?isRecherche=${recherche.isRecherche}`;
            myUrl += `&label=${recherche.label}`;
            myUrl += `&idtypereparation=${recherche.idtypereparation}`;
            myUrl += `&prixmin=${recherche.prixmin}`;
            myUrl += `&prixmax=${recherche.prixmax}`;
            myUrl += `&dureedebut=${recherche.dureedebut}`;
            myUrl += `&dureefin=${recherche.prixmin}`;
            setListReparation(null);
        }
        const requestOptions = {
            url: myUrl,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setListReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    };

    useEffect(() => {
        getTypeReparation();
        getListReparation();
    }, []);

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleRechercheChange = (e) => {
        setRecherche({ ...recherche, [e.target.name]: e.target.value });
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [reparation, setReparation] = useState(null);
    function handleChange(e) {
        setReparation({ ...reparation, [e.target.name]: e.target.value });
    }

    // modal delete
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    function displayDeleteModal(reparation) {
        setReparation(reparation);
        setOpenModalDelete(true);
    }

    function supprimerReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/reparations/${reparation.id}`,
            method: 'DELETE'
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getListReparation();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalDelete();
    }

    // modal fiche
    const [openModalFiche, setOpenModalFiche] = useState(false);
    const handleCloseModalFiche = () => setOpenModalFiche(false);

    function displayFicheModal(reparation) {
        setReparation(reparation);
        setOpenModalFiche(true);
    }

    // modal edit
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    function displayEditModal(reparation) {
        setReparation(reparation);
        setOpenModalEdit(true);
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                const tabImage = [...reparation.images];
                tabImage.push({
                    id: 0,
                    img: base64,
                    etat: 20
                });
                setReparation({ ...reparation, images: tabImage });
            };
            reader.readAsDataURL(file);
        }
    }

    function handleEtapeOrderChange(event, idetape) {
        const etapesReparation = [...reparation.etapes];
        const etapeIndex = etapesReparation.findIndex(
            etape => etape.id === idetape
        );
        etapesReparation[etapeIndex].ordre = event.target.value;
        setReparation({ ...reparation, etapes: etapesReparation });
    }

    function removeImageReparation(i) {
        const reparationImages = [...reparation.images];

        reparationImages[i].etat = 0;

        setReparation({ ...reparation, images: reparationImages });
    }

    function modifierReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/reparations/${reparation.id}`,
            method: 'PUT',
            data: reparation
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getListReparation();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
        handleCloseModalEdit();
    }

    return (
        <>
                <AccordionRoot>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<Icon>expand_more</Icon>}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <Box className="column">
                                <Typography className="heading">Liste des réparations</Typography>
                            </Box>

                            <Box className="column">
                                <Typography className="secondaryHeading">Filtrer</Typography>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails className="details">
                            <Box className="column helper">
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            name="label"
                                            type="text"
                                            label="Recherche"
                                            variant="outlined"
                                            value={recherche.label}
                                            onChange={handleRechercheChange}
                                            sx={{ mt: 1.5 }}
                                        />
                                        <TablePagination
                                            sx={{ px: 2 }}
                                            page={page}
                                            component="div"
                                            rowsPerPage={rowsPerPage}
                                            count={reparations ? reparations.length : 0}
                                            onPageChange={handleChangePage}
                                            rowsPerPageOptions={[4, 8, 12]}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6}>
                                        {
                                            typereparations ?
                                                <>
                                                    <Autocomplete
                                                        sx={{ mb: 3 }}
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={typereparations}
                                                        size='small'
                                                        fullWidth
                                                        onChange={handleAutocompleteChange}
                                                        renderInput={
                                                            (params) =>
                                                                <TextField {...params} label="Type de la réparation" />
                                                        }
                                                    />
                                                </> :
                                                <>
                                                    <center>
                                                        <CircularProgress></CircularProgress>
                                                    </center>
                                                </>
                                        }

                                        <Grid container spacing={3}>

                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    name="dureedebut"
                                                    type="time"
                                                    label="Duree debut"
                                                    variant="outlined"
                                                    sx={{ mt: 1.5 }}
                                                    value={recherche.dureedebut}
                                                    onChange={handleRechercheChange}
                                                />
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    name="prixmin"
                                                    type="number"
                                                    label="Prix min"
                                                    variant="outlined"
                                                    sx={{ mt: 1.5 }}
                                                    value={recherche.prixmin}
                                                    onChange={handleRechercheChange}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    name="dureefin"
                                                    type="time"
                                                    label="Duree fin"
                                                    variant="outlined"
                                                    sx={{ mt: 1.5 }}
                                                    value={recherche.dureefin}
                                                    onChange={handleRechercheChange}
                                                />
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    name="prixmax"
                                                    type="number"
                                                    label="Prix max"
                                                    variant="outlined"
                                                    sx={{ mt: 1.5 }}
                                                    value={recherche.prixmax}
                                                    onChange={handleRechercheChange}
                                                />
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Box>
                        </AccordionDetails>

                        <Divider />

                        <AccordionActions>
                            <Button size="small" onClick={sendRecherche} >
                                Filtrer
                            </Button>
                        </AccordionActions>
                    </Accordion>
                </AccordionRoot>

            <br />

            {reparations ?
                <Grid container spacing={3}>
                    {reparations
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((reparation, index) => (
                            <Grid key={index} item lg={3} md={3} sm={6} xs={12}>
                                <Card sx={{ width: '100%', height: '100%' }}>
                                    <Swiper
                                        spaceBetween={1}
                                        slidesPerView={1}
                                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                                        navigation
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                    >
                                        {reparation.images.map((reparationimg, index) => (
                                            <SwiperSlide key={index}>
                                                <div
                                                    style={divStyle}
                                                >
                                                    <img
                                                        src={reparationimg.img}
                                                        style={imgStyle}
                                                        alt="" />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <CardContent>
                                        <Typography sx={{
                                            display: 'flex',
                                        }} gutterBottom variant="h5">
                                            {reparation.codecouleur &&
                                                <>
                                                    <Icon sx={{
                                                        color: reparation.codecouleur,
                                                        mt: 0.4
                                                    }}>circle</Icon>
                                                </>
                                            }
                                            {reparation.nom}
                                        </Typography>
                                        <Typography gutterBottom variant="h6">
                                            Type : {reparation.typereparation.nom}
                                        </Typography>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Prix : Ar {reparation.prix}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            Durée : {reparation.duree}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <IconButton onClick={() => displayFicheModal(reparation)} >
                                            <Icon color="info">info</Icon>
                                        </IconButton>
                                        <Link to={'/entreprise/reparation/liste/personnel'} state={{ reparation: reparation }}>
                                            <IconButton >
                                                <Icon color="info">group</Icon>
                                            </IconButton>
                                        </Link>
                                        {
                                            proposition &&
                                            <>
                                                <Button
                                                    color='success'
                                                    onClick={() => proposition(reparation)}
                                                >
                                                    <Icon>
                                                        add
                                                    </Icon>
                                                    Proposer
                                                </Button>
                                            </>
                                        }
                                        {affectation &&
                                            <>
                                                <Button color="success" variant='outlined' onClick={() => affectation(reparation)} >
                                                    <Icon>add</Icon>
                                                    Affecter
                                                </Button>
                                            </>
                                        }
                                        {isCrud &&
                                            <>
                                                <IconButton onClick={() => displayEditModal(reparation)} >
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(reparation)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </>
                                        }
                                        {
                                            modifSuggestion &&
                                            <>
                                                <IconButton onClick={() => modifSuggestion(reparation)} color='primary'>
                                                    <Icon>
                                                        add
                                                    </Icon>
                                                </IconButton>
                                            </>
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
                :
                <>
                    <center>
                        <CircularProgress></CircularProgress>
                    </center>
                </>
            }

            {reparation &&
                <>
                    {/* modal edit */}
                    <Dialog maxWidth='md' onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier
                        </DialogTitle>

                        <DialogContent dividers>

                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {reparation.images &&
                                        <>
                                            <Swiper
                                                spaceBetween={1}
                                                slidesPerView={1.1}
                                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                navigation
                                                pagination={{ clickable: true }}
                                                scrollbar={{ draggable: true }}
                                            >
                                                {reparation.images
                                                    .filter((reparationImage) => reparationImage.etat !== 0)
                                                    .map((reparationImage, index) => (
                                                        <SwiperSlide key={index}>
                                                            <Badge
                                                                sx={{
                                                                    mt: 3,
                                                                }}
                                                                badgeContent={
                                                                    <>
                                                                        <IconButton onClick={() => removeImageReparation(index)} color='error'>
                                                                            <Icon>close</Icon>
                                                                        </IconButton>
                                                                    </>}>

                                                                <div
                                                                    style={divStyle}
                                                                >
                                                                    <img
                                                                        src={reparationImage.img}
                                                                        style={imgStyle}
                                                                        alt={`Reparation img ${index}`} />
                                                                </div>
                                                            </Badge>
                                                        </SwiperSlide>
                                                    ))}
                                            </Swiper>
                                        </>
                                    }
                                    <label htmlFor="contained-button-file">
                                        <Fab component="span" style={{ zIndex: '0' }} sx={{ mb: 1.5 }} size="small" color="primary">
                                            <Icon>photo</Icon>
                                        </Fab>
                                    </label>
                                    <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />

                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    {
                                        typereparations ?
                                            <>
                                                <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                                                    <InputLabel required filled id="demo-simple-select-autowidth-label">Type de reparation</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        name='idtypereparation'
                                                        value={reparation.idtypereparation}
                                                        onChange={handleChange}
                                                        // autoWidth
                                                        label="Type de réparation"
                                                    >
                                                        {typereparations.map((typereparation, index) => (
                                                            <MenuItem key={index} value={typereparation.typereparation.id}>{typereparation.typereparation.nom}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </> :
                                            <>
                                                <center>
                                                    <CircularProgress></CircularProgress>
                                                </center>
                                            </>
                                    }
                                    <TextField
                                        fullWidth
                                        size='small'
                                        name="nom"
                                        type="text"
                                        label="Nom"
                                        value={reparation.nom}
                                        onChange={handleChange}
                                        variant="outlined"
                                        sx={{ mt: 1.5, mb: 2 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size='small'
                                        name="description"
                                        type="text"
                                        label="Description"
                                        value={reparation.description}
                                        onChange={handleChange}
                                        variant="outlined"
                                        sx={{ mt: 1.5, mb: 2 }}
                                        multiline
                                    />
                                    <TextField
                                        fullWidth
                                        size='small'
                                        name="duree"
                                        type="time"
                                        label="Duree"
                                        value={reparation.duree}
                                        onChange={handleChange}
                                        variant="outlined"
                                        sx={{ mt: 1.5, mb: 2 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size='small'
                                        name="prix"
                                        type="number"
                                        label="Prix"
                                        value={reparation.prix}
                                        onChange={handleChange}
                                        variant="outlined"
                                        sx={{ mt: 1.5, mb: 2 }}
                                    />
                                </Grid>
                            </Grid>

                            <>Ordre des etapes :</>

                            {
                                reparation.etapes &&
                                reparation.etapes
                                    .map((etape, index) => (
                                        <TextField
                                            key={index}
                                            fullWidth
                                            size='small'
                                            name="etape"
                                            type="number"
                                            label={`${etape.nom}`}
                                            value={etape.ordre}
                                            onChange={(e) => handleEtapeOrderChange(e, etape.id)}
                                            variant="outlined"
                                            sx={{ mt: 1.5, mb: 2 }}
                                        />
                                    ))
                            }

                            <Link to={'/entreprise/reparation/ajout/etapes'} state={{ reparation: reparation }}>
                                <Fab component="span" style={{ zIndex: '0' }} sx={{ mb: 1.5 }} size="small" color="primary">
                                    <Icon>add</Icon>
                                </Fab>
                            </Link>

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit} color='primary'>
                                Fermer
                            </Button>
                            <Button onClick={modifierReparation} color='success'>
                                Appliquer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}

                    {/* modal delete */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            Retirer {reparation.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={supprimerReparation} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}

                    {/* modal fiche */}
                    <Dialog onClose={handleCloseModalFiche} aria-labelledby="customized-dialog-title" open={openModalFiche}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalFiche}>
                            Fiche {reparation.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6}>
                                    {reparation.images &&
                                        <>
                                            <Swiper
                                                spaceBetween={1}
                                                slidesPerView={1}
                                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                navigation
                                                pagination={{ clickable: true }}
                                                scrollbar={{ draggable: true }}
                                            >
                                                {reparation.images.map((reparationimg, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            style={divStyle}
                                                        >
                                                            <img
                                                                src={reparationimg.img}
                                                                style={imgStyle}
                                                                alt="" />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </>
                                    }

                                </Grid>

                                <Grid item lg={6} md={6}>
                                    <Typography gutterBottom>
                                        {reparation.description}
                                    </Typography>
                                </Grid>

                            </Grid>

                            <br />

                            <Box sx={{ width: '100%' }}>
                                <Stepper alternativeLabel>
                                    {reparation.etapes &&
                                        reparation.etapes
                                            // .sort((a, b) => a.ordre - b.ordre)
                                            .map((etape, index) => (
                                                <Step key={index}>
                                                    <StepLabel>
                                                        {etape.nom}
                                                        {etape.img && <img width={'50px'} height={'50px'} src={etape.img} alt="" />}
                                                    </StepLabel>
                                                </Step>
                                            ))}

                                </Stepper>
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalFiche}>
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* */}
                </>
            }

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}

        </>
    );
};

export default ListeReparationComponent;