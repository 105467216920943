import { Button, Card, CardContent, CircularProgress, Grid, Icon, Typography, styled } from '@mui/material';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// logo
import reparationLogo from 'logo/reparationLogo.jpg';
import locationVoitureLogo from 'logo/locationVoitureLogo.png';
import ventePieceLogo from 'logo/ventePieceLogo.jpeg';
// 

// styles
import imgStyle from 'app/myComponents/Repair/imgStyle';
import divStyle from 'app/myComponents/Repair/divStyle';
import './HomePage.css';
import FadeInOut from './FadeInOut';
// 

const activites = [
    {
        activite: {
            id: 1,
            nomactivite: "Hébergement",

        }
    },
]

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const HomePage = () => {
    // fade in
    const [show, setShow] = useState(false);
    // 

    const navigate = useNavigate();

    document.title = "ACCUEIL";

    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [entrepriseActivite, setEntrepriseActivite] = useState(activites);

    const getEntrepriseActivite = () => {
        const token = localStorage.getItem("token");
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const requestOptions = {
            url: `${myConfig.host}/api/entreprise/profileJWT`,
            method: 'GET'
        };

        axios(requestOptions).then((response) => {
            const entreprise = response.data.entreprise;
            setEntrepriseActivite(entreprise.activites);
            setShow(true);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        // getEntrepriseActivite();
        setShow(true);
    }, []);

    const navigateEntreprise = (activite) => {
        localStorage.setItem("type", activite.id);

        // navigate vente piece
        if (activite.id === 1) {
            navigate("/entreprise/accueil");
        }

        // navigate reparation
        if (activite.id === 2) {
            navigate("/entreprise/reparation/publications/actualites");
        }

        // navigate location
        if (activite.id === 3) {
            navigate("/entreprise/location/clients/publications");
        }
    }


    return (
        <>
            <div style={{
                backgroundColor: '#1A2038',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <AppButtonRoot>
                    {entrepriseActivite && (show === true) ?
                        <>
                            <div>
                                <div style={{
                                    color: 'white',
                                }}
                                >
                                    <FadeInOut show={show} duration={100}>
                                        <center>
                                            <Typography variant='h2'>Bienvenue chez NosyBe</Typography>
                                            <Typography variant='h5'>Choisissez où vous voulez naviguer</Typography>
                                        </center>
                                    </FadeInOut>
                                </div>
                                <br />
                                <FadeInOut show={show} duration={1000}>
                                    <Grid container spacing={3}>
                                        {
                                            entrepriseActivite.map((activites, index) =>
                                                <Grid item key={`activite-${index}`} lg={12 / (entrepriseActivite.length)} md={12 / (entrepriseActivite.length)} sm={12} xs={12}>
                                                    <Card
                                                        className='cardHome'
                                                        sx={{
                                                            height: 550
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <div style={divStyle}>
                                                                {activites.activite.id === 1 &&
                                                                    <img src={ventePieceLogo} style={imgStyle} alt="logo ventePiece" />
                                                                }
                                                                {activites.activite.id === 2 &&
                                                                    <img src={reparationLogo} style={imgStyle} alt="logo reparationAuto" />
                                                                }
                                                                {activites.activite.id === 3 &&
                                                                    <img src={locationVoitureLogo} style={imgStyle} alt="logo locationVehicule" />
                                                                }
                                                            </div>
                                                            <br />
                                                            <center>
                                                                <Typography variant='h4'>{activites.activite.nomactivite}</Typography>
                                                            </center>
                                                            <br />
                                                            <br />
                                                            <Button onClick={() => navigateEntreprise(activites.activite)} fullWidth variant='contained'>
                                                                <Icon>telegram</Icon>
                                                                Naviguer
                                                            </Button>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </FadeInOut>

                            </div>
                        </>

                        :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                    }
                </AppButtonRoot>
            </div >
        </>
    );
};

export default HomePage;