import { ImageList, ImageListItem, Accordion, AccordionSummary, Alert, Badge, Box, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import { SimpleCard } from "app/components";
//import { getArticlePrimaryRessources } from "app/data/nosybe/Article";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "../Restaurant/CarteMap";
import url from "app/UrlConfig";
import { SaveArticle, SaveArticleAPI, UpdateArticleAPI } from "./API-Article";
import { useNavigate } from "react-router-dom";
import { forEach } from "lodash";
import { displayToast } from "app/data/piece/dispatch";

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const FormArticle = ({ selectedArticle, setArticles, closeForm }) => {

    const navigate = useNavigate();
    // console.log(selectedArticle, 'slected')

    const [Image, setimage] = useState({ id: null, photo: '', file: '' });
    const changeFormat = (d) => { return d.slice(0, 10).split('-').reverse().join('-'); }
    const str_d = new Date().toISOString();
    const originalArticle = {
        id: null,
        titre: '',
        description: {
            eng: "",
            fr: "",
            ita: ""
        },
        date: changeFormat(str_d),
        lienYoutube: "",
        photoCouverture: "",
        etat: 1,
        imagesFile: [],
        imageFile: "",
        images64: [],
        image64: "",
        images: [],
        delete_images: []
    }

    const [Article, setArticle] = useState(selectedArticle ? { ...originalArticle, ...selectedArticle, date: changeFormat(selectedArticle.date) } : { ...originalArticle });
    const [inputErrors, setInputErrors] = useState({});

    const setObjectMere = (lat, lng) => {
        setArticle({ ...Article, latitude: lat, longitude: lng });
    }
    const convertDate = (sd) => {
        let stdate = sd.split('T');
        // console.log("date", stdate[0] + ' ' + stdate[1]);
        return stdate;
    }
    const handleChange = (e) => {

        if (e.target.name == 'description') {
            setArticle({
                ...Article,
                [e.target.name]: { ...Article[e.target.name], [languages[tabDescValue2].name]: e.target.value }
            });
        } else {
            setArticle({
                ...Article,
                [e.target.name]: e.target.value
            });
        }
        console.log("Articles", Article);
    }


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    //IMAGES
    const onDrop = (fArticle) => {
        const tabImage = Article.imagesFile;
        const tabImage64 = Article.images64;
        fArticle.map((val) => {
            if (val) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64 = e.target.result;
                    setimage({ file: val, photo: base64 });
                    tabImage.push(val);
                    tabImage64.push(base64);
                };
                reader.readAsDataURL(val);
            }

        })
        setArticle({ ...Article, imagesFile: tabImage, images64: tabImage64 });

    }
    //IMAGES
    const onDropPhotoCouverture = (files) => {
        var val = files[0];
        console.log("file", val);
        setArticle({ ...Article, imageFile: val });
        if (val) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setimage({ file: val, photo: base64 });
                setArticle({ ...Article, image64: base64, imageFile: val });
            };
            reader.readAsDataURL(val);
        }

    }
    const handleFileRemoveone = () => {
        setArticle({ ...Article, imageFile: null, image64: null });
    }
    const handleFileRemove = (selectedIndex) => {
        const tabImage = Article.imagesFile.filter((_, index) => index !== selectedIndex);
        const tabImage64 = Article.images64.filter((_, index) => index !== selectedIndex);
        setArticle({ ...Article, imagesFile: tabImage, images64: tabImage64 });
    }

    const handleImageRemove = (selectedIndex) => {
        const delimage = Article.images[selectedIndex];
        const tabImage = Article.images.filter((_, index) => index !== selectedIndex);
        setArticle({ ...Article, images: tabImage, delete_images: [...Article.delete_images, delimage] });
        console.log("del", Article.delete_images);
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { getRootProps: getRootPropsOnDropPhotoCouverture, getInputProps: getInputPropsOnDropPhotoCouverture } = useDropzone({ onDrop: onDropPhotoCouverture });

    const [languages, setLanguages] = useState([{ "name": "eng", "abbrev": "MAL" }, { "name": "fr", "abbrev": "FRANCAIS" }, { "name": "ita", "abbrev": "MAL" }]);
    const [tabDescValue, setTabDescValue] = useState(0);
    const [tabDescValue2, setTabDescValue2] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("description_eng");
    const [lienexpedia, setLienExpedia] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState('success');
    const [visible, setVisible] = useState(false);


    const handleChangeTabDescValue = (Article, newValue) => {
        setTabDescValue(newValue);
        setLanguageDescChoose("description_" + languages[newValue]?.name);
    }

    const handleChangeTabDescValue2 = (Article, newValue) => {
        setTabDescValue2(newValue);
        setLanguageDescChoose("description_" + languages[newValue]?.name);
    }

    useEffect(() => {
        //getArticlePrimaryRessources(setEquipements, setTypes);

    }, [])

    const createFormData = (formData, key, data) => {
        if (!(data instanceof File) && data === Object(data) || Array.isArray(data)) {
            for (var i in data) {
                createFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }

    const saveArticle = async () => {
        console.log("Article", Article);
        const data = new FormData();
        createFormData(data, "id", Article.id);
        createFormData(data, "titre", Article.titre);
        createFormData(data, "description", Article.description);
        createFormData(data, "date", changeFormat(Article.date));
        createFormData(data, "lienYoutube", Article.lienYoutube);
        createFormData(data, "photoCouverture", Article.imageFile);
        createFormData(data, "etat", Article.etat);
        createFormData(data, "images", Article.imagesFile);
        console.log(data, " Article")
        await SaveArticleAPI(data, setMessage, setVisible, setSeverity);
        navigate("/entreprise/Article/list");
    }

    const UpdateArticle = async () => {
        console.log(Article, "Article update");
        const data = new FormData();
        createFormData(data, "id", Article.id);
        createFormData(data, "titre", Article.titre);
        createFormData(data, "description", Article.description);
        createFormData(data, "date", changeFormat(Article.date));
        createFormData(data, "lienYoutube", Article.lienYoutube);
        createFormData(data, "photoCouverture", Article.imageFile);
        createFormData(data, "etat", Article.etat);
        createFormData(data, "images", Article.imagesFile);
        createFormData(data, "delete_images", Article.delete_images);
        await UpdateArticleAPI(data, setArticle, setMessage, setVisible, setSeverity);
        closeForm();

    }

    const handleCloseSnackbar = () => {
        setVisible(false);
    }


    return (
        <>
            {/* <TestButton toLog={Article} /> */}
            <Typography variant="h5">
                {selectedArticle ? 'Modifier un Article' : 'Ajouter un Article'}
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12} md={8}>
                    <SimpleCard>
                        <TextField
                            fullWidth
                            size='small'
                            name="titre"
                            type="text"
                            label="Titre"
                            value={Article.titre}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienBooking && "error"}
                            helperText={inputErrors.lienexpedia}
                        />

                        <Tabs
                            value={tabDescValue2}
                            onChange={handleChangeTabDescValue2}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='large'
                            name="description"
                            value={Article.description[languages[tabDescValue2].name]}
                            label="Description"
                            onChange={handleChange}
                            multiline
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        />

                        <TextField
                            fullWidth
                            size='large'
                            name="date"
                            type="date"
                            value={Article.date}
                            label="Date(DD-MM-YYYY)"
                            placeholder="DD-MM-YYYY"
                            onChange={handleChange}
                            multiline
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.du && "error"}
                            helperText={inputErrors.date}
                        />

                        <TextField
                            fullWidth
                            size='large'
                            name="lienYoutube"
                            value={Article.lienYoutube}
                            label="Lien Youtube"
                            placeholder="https://www.youtube.com/"
                            onChange={handleChange}
                            multiline
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.lienYoutube && "error"}
                            helperText={inputErrors.lienYoutube}
                        />


                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Photo couverture</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootPropsOnDropPhotoCouverture()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputPropsOnDropPhotoCouverture()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                <Swiper
                                    spaceBetween={1}
                                    slidesPerView={1}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                >
                                    {(Article.image64 || Article.photoCouverture) &&
                                        <SwiperSlide>
                                            <center>
                                                <Badge
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                    badgeContent={Article.image64 &&
                                                        <>
                                                            <IconButton onClick={() => handleFileRemoveone()} color='error'>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>}>

                                                    <div
                                                        style={{
                                                            width: '100%',  // Largeur 100% de la diapositive
                                                            height: '200px',  // Hauteur fixe
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                        }}
                                                    >
                                                        <img
                                                            src={Article.image64 ? Article.image64 : url["url-api"] + 'article/image/' + Article.photoCouverture}
                                                            style={{
                                                                width: 'auto',
                                                                height: 'auto',
                                                                maxWidth: '100%',
                                                                maxHeight: '100%' // Empêche le débordement horizontal
                                                            }}
                                                            alt="Article" />
                                                    </div>
                                                </Badge>
                                            </center>

                                        </SwiperSlide>
                                    }

                                </Swiper>

                            </Accordion>
                        </AccordionRoot>
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Gallerie</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootProps()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputProps()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                {Article.images64.length > 0 &&
                                    <ImageList cols={3} rowHeight={100}>
                                        {Article.images64.map((item, index) => (
                                            <ImageListItem key={index}>
                                                <Badge
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                    badgeContent={
                                                        <>
                                                            <IconButton onClick={() => handleFileRemove(index)} color='error'>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>}></Badge>
                                                <img
                                                    src={item}
                                                    alt={"photo 0" + index}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))}

                                    </ImageList>
                                }

                            </Accordion>
                        </AccordionRoot>


                    </SimpleCard>

                </Grid>
                <Grid item sm={12} md={4}>

                    {Article.images?.length > 0 &&
                        <>
                            <h3>Photos dans serveur</h3>
                            <ImageList cols={4} rowHeight={200} rowWidth={100}>
                                {Article.images.map((item, index) => (
                                    <ImageListItem key={index}>
                                        <Badge
                                            sx={{
                                                mt: 3,
                                            }}
                                            badgeContent={
                                                <>
                                                    <IconButton onClick={() => handleImageRemove(index)} color='error'>
                                                        <Icon>close</Icon>
                                                    </IconButton>
                                                </>}></Badge>
                                        <img
                                            src={url["url-api"] + "article/image/" + item}
                                            alt={"photo 0" + index}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </>
                    }
                </Grid>
                <Grid item sm={12} md={4}>
                    <br />
                    <br />
                    <StyledButton
                        // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                        onClick={selectedArticle ? UpdateArticle : saveArticle}
                        fullWidth
                        variant="contained"
                        component="span"
                    >
                        Enregistrer
                    </StyledButton>
                </Grid>

            </Grid>
            <Snackbar
                open={visible}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormArticle;