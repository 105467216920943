import { navigationsLocation } from "app/navigationsLocation";
import { navigationsPiece } from "app/navigationsPiece";
import { navigationsRepair } from "app/navigationsRepair";
import routesLocations from "app/routesLocations";
import routesPiece from "app/routesPiece";
import routesRepair from "app/routesRepair";
import AdminEntreprise from "app/views/myViews/Admin/AdminEntreprise";
import Accueil from "app/views/myViews/VentePiece/Publication/Accueil";
import { useNavigate } from "react-router-dom";

export const manageError = (error, text) => {
    // console.error("manageError: ", error);
    if (error.response && error.response.status) {
        console.log("response error");
        const status = error.response.status;
        if (status === 401) {
            let currentUser = "entreprise";
            let localUser = localStorage.getItem('user');
            if (localUser) currentUser = localUser;
            // console.log("unauthorized");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("type");
            redirectUnauthorizedUser();
            // window.location(`/${currentUser}/connexion`);
        } else {
            console.log("simple error");
            console.error(text, error);
            displayToast(`${text} ${error.response.data.message}`, false)
        }
    } else {
        console.log("simple error");
        console.error(text, error);
    }
}

export const useManageError = () => {
    return manageError;
}

export class FunctionBundle {
    manageError(error, text) {
        // console.error("manageError: ", error);
        if (error.response && error.response.status) {
            console.log("response error");
            const status = error.response.status;
            if (status === 401) {
                let currentUser = "entreprise";
                let localUser = localStorage.getItem('user');
                if (localUser) currentUser = localUser;
                // console.log("unauthorized");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("type");
                // redirectUnauthorizedUser();
                window.location(`/${currentUser}/connexion`);
            } else {
                console.log("simple error");
                console.error(text, error);
                displayToast(`${text} ${error.response.data.message}`, false)
            }
        } else {
            console.log("simple error");
            console.error(text, error);
        }
    }
}

export const redirectUnauthorizedUser = () => {
    const unauthorizedEvent = new CustomEvent('unauthorizedUser');
    window.dispatchEvent(unauthorizedEvent);
}

export const displayToast = (message, success) => {
    const eventData = {
        message: message,
        success: success,
    }
    const toastEvent = new CustomEvent('displayToast', { detail: eventData });
    window.dispatchEvent(toastEvent);
}

export const activityLink = [
    {
        idactivity: 0,
        routes: [
            {
                path: '/admin/entreprises',
                element: <AdminEntreprise />
            },
            {
                path: '/nosybe/accueil',
                element: <Accueil />
            },
            {
                path: '/nosybe',
                element: <Accueil />
            },
            ...routesPiece,
        ],
        navigations: [
            {
                name: 'Retour',
                icon: 'home',
                path: '/admin/entreprises',
            },
            ...navigationsPiece,
        ],
    },
    {
        idactivity: 1,
        routes: routesPiece,
        navigations: navigationsPiece,
    },
    {
        idactivity: 2,
        routes: routesRepair,
        navigations: navigationsRepair,
    },
    {
        idactivity: 3,
        routes: routesLocations,
        navigations: navigationsLocation,
    },
]