import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import DemandeClientComponent from 'app/myComponents/Repair/Clients/DemandeClientComponent';
import HistoriqueClientComponent from 'app/myComponents/Repair/Clients/HistoriqueClientComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));


const DemandeClient = () => {
    document.title = "DEMANDE-CLIENT";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Clients' }, { name: 'Demandes' }]}
                />
            </Box>

            <DemandeClientComponent></DemandeClientComponent>

        </AppButtonRoot>
    );
};

export default DemandeClient;