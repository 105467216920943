import { Card, Box, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Icon } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import './modalDetail.css';



const ModalTrajet = ({ children }) => {
    const [openModalFiche, setOpenModalFiche] = useState();
    const Open = () => setOpenModalFiche(true);
    const Close = () => {
        setOpenModalFiche(false)
    };
    // if (show) {
    //     Open();
    // } else {

    // }

    return (
        <>
            <Button onClick={Open} sx={{ ml: 2 }} variant="contained" size="small" color="success">Voir Trajet</Button>

            <Dialog
                // maxWidth='lg'
                onClose={Close} aria-labelledby="customized-dialog-title" open={openModalFiche}>
                <DialogTitle id="customized-dialog-title" onClose={Close}>
                    <Button onClick={Close}>
                        X
                    </Button>
                </DialogTitle>
                <DialogContent dividers style={{ width: "1200px" }}>
                    {children}
                </DialogContent>



                <DialogActions>
                    <Button sx={{ ml: 2 }} variant="contained" size="small" color="primary" onClick={Close}>
                        Fermer
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default ModalTrajet;
