import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, DialogActions, Fab, Grid, Icon, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import url from "app/UrlConfig";
import { SimpleCard } from "app/components";
import { useEffect } from "react";
import { useState } from "react";
import ModalGenerer from "../utils/ModalGenerer";


const ListeDemandeCategorieComponent = () => {

    const [typeAction, setTypeAction] = useState("Ajout");

    const OpenModalAjout = () => {
        setShowModalUpdate(true);
    }

    const CloseModalAjout = () => {
        setShowModalUpdate(false)
    }

    const [relance, setRelance] = useState(0);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [categorieDelete, setcategorieDelete] = useState();
    const [categorieUpdate, setCategorieUpdate] = useState();
    const [recherche, setRecherche] = useState('');
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [demandeCategorie, setDemandeCategorie] = useState([]);

    const [categorieAjout, setCategorieAjout] = useState({
        id: 0,
        categorie_voiture: 'categorie voiture'
    });

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }

    const getDemandeCategorie = () => {
        fetch(url["url-api"] + "admin/demande-categorie", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setDemandeCategorie(data.data);
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDemandeCategorie()
    }, [relance])

    const handleChange = (e) => {
        setCategorieAjout({ ...categorieAjout, [e.target.name]: e.target.value })
    }
    const handleChangeUpdate = (e) => {
        setCategorieUpdate({ ...categorieUpdate, [e.target.name]: e.target.value })
    }

    const OpenModalUpdateDelete = (categorie, typeAction) => {
        setTypeAction(typeAction);
        // console.log(marque);
        if (typeAction === "DELETE") {
            setcategorieDelete(categorie);
        } if (typeAction === "UPDATE") {
            setCategorieUpdate(categorie);
        }
        OpenModalAjout();
    }

    const AjoutCategorie = () => {
        fetch(url["url-api"] + "admin/categorie", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(categorieAjout)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setSeverity('success');
                    setMessage('Ajout nouveau Categorie réussi');
                    setRelance(relance + 1)
                    setOpenSnackbar(true)
                } else {
                    setSeverity('error')
                    setMessage('Une Erreur est survenue lors de l\'ajout nouveau Categorie');
                    setRelance(relance + 1)
                    setOpenSnackbar(true)
                }
            })
            .catch(error => console.log(error))
    }

    const UpdateCategorie = () => {
        setCategorieAjout(categorieUpdate);
        AjoutCategorie();
        valider(categorieUpdate);

    }
    const valider = (demande) => {
        fetch(url["url-api"] + "admin/demande-categorie", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(demande)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setSeverity('success');
                    setMessage('Validation Demande Succé');
                    setRelance(relance + 1)
                    setShowModalUpdate(false)
                    setOpenSnackbar(true)
                } else {
                    setSeverity('error')
                    setMessage('Une Erreur est survenue lors de validation ');
                    setRelance(relance + 1)
                    setShowModalUpdate(false)
                    setOpenSnackbar(true)
                }
            })
            .catch(error => console.log(error))
    }



    return (
        <div>
            <SimpleCard>
                <Fab onClick={() => OpenModalUpdateDelete(categorieAjout, "Ajout")} color="primary" aria-label="Add" className="button">
                    <Icon>add</Icon>
                </Fab>
                <Box sx={{ py: '12px' }} />
                Ajout Nouveau categorie
                <Box sx={{ py: '12px' }} />
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
            </SimpleCard>
            <Box width={'100%'} sx={{ py: '12px' }}>
                <Grid container spacing={3}>

                    {demandeCategorie
                        .filter((categorie) =>
                            categorie.entreprise.nom.toLowerCase().includes(recherche) ||
                            categorie.categorie_voiture.toLowerCase().includes(recherche)
                        )
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((categorie, index) =>
                            <Grid item lg={4} md={4} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar src={categorie.entreprise.logo} alt='' />
                                        }
                                        title={`${categorie.entreprise.nom}`}
                                        subheader={`${categorie.entreprise.email}`}
                                    />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Categorie :</Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='body1'>{categorie.categorie_voiture}</Typography>
                                                <Typography gutterBottom variant='body1'>Le : {categorie.date_demande}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardActions sx={{ justifyContent: 'right' }}>
                                        <IconButton onClick={() => OpenModalUpdateDelete(categorie, "UPDATE")} >
                                            <Icon color="success">edit</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => OpenModalUpdateDelete(categorie, "DELETE")} >
                                            <Icon color="error">delete</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => valider(categorie)}>
                                            <Icon color='success'>check</Icon>
                                        </IconButton>

                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>
            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                {typeAction === "Ajout" && (
                    <SimpleCard title={'Ajout d\'une nouvelle Categorie'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="categorie_voiture"
                            type="text"
                            label="Categorie Voiture"
                            value={categorieAjout.categorie_voiture}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={AjoutCategorie}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "UPDATE" && (
                    <SimpleCard title={'Modifier d\'une Marque'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="categorie_voiture"
                            type="text"
                            label="Categorie Voiture"
                            value={categorieUpdate.categorie_voiture}
                            onChange={handleChangeUpdate}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={UpdateCategorie}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeAction === "DELETE" && (
                    <div>
                        <h1>Confirmation suppression</h1>
                        <h3>
                            Vous etes sure de Supprimer le marque {categorieDelete.categorie_voiture}
                        </h3>
                        <DialogActions>
                            <Button onClick={CloseModalAjout}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </div>
                )}

            </ModalGenerer>
            {/* MODAL UPDATE */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>

    );


}

export default ListeDemandeCategorieComponent;