import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { getListeHebergement } from "app/data/nosybe/hebergement";
// import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import ModalDeleteHebergement from "./ModalDeleteHebergement";
import ModalEditHebergement from "./ModalEditHebergement";
import { getHebergementsListAPI, getListeHebergementDetail } from "./API-Hebergement";
import { getAdminConnected } from "app/data/nosybe/admin";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListHebergementComponent = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [hebergements, setHebergements] = useState([]);
    const [selectedHebergement, setSelectedHebergement] = useState(undefined);
    const [hebergementTotal, setHebergementTotal] = useState(0);

    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    // const [rowsPerPage, setRowsPerPage] = useState(2);
    const handleChangePage = async (_, newPage) => {
        setPage(newPage);
        // getListeHebergement(setHebergements, setHebergementTotal, newPage, rowsPerPage);
        await getHebergementsListAPI({ ...dataSend, offset: newPage * rowsPerPage }, setHebergements, setHebergementTotal);

    };
    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        // getListeHebergement(setHebergements, setHebergementTotal, 0, +event.target.value);
        await getHebergementsListAPI({ ...dataSend, offset: 0, limit: +event.target.value }, setHebergements, setHebergementTotal);

    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedHebergement(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (hebergement) => {
        setSelectedHebergement(hebergement);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedHebergement(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (hebergement) => {
        // console.log(hebergement)
        setSelectedHebergement(hebergement);
        setOpenModalEdit(true);
    }

    const [dataSend, setDataSend] = useState({
        "offset": page,
        "limit": rowsPerPage,
        "search": recherche,
        "user": getAdminConnected().id
    });


    const getHebergementListe = async () => {
        await getHebergementsListAPI(dataSend, setHebergements, setHebergementTotal);
    }

    useEffect(() => {
        // getListeHebergement(setHebergements, setHebergementTotal);
        getHebergementListe();
    }, [selectedHebergement]);

    return (
        <>
            {/* <TestButton toLog={hebergements} /> */}
            <SimpleCard title={'Liste des hébergements'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={recherche}
                        onChange={(e) => setRecherche(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {hebergements.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Titre</TableCell>
                                        <TableCell align="center">Email</TableCell>

                                        <TableCell align="center">Site Web</TableCell>
                                        <TableCell align="center">Etoile</TableCell>
                                        {/* <TableCell align="center">Prix</TableCell> */}
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hebergements.map((hebergement, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{hebergement.titre_fr}</TableCell>
                                            <TableCell align="center">{hebergement.email}</TableCell>

                                            <TableCell align="center"><a href={hebergement.siteweb}>{hebergement.siteweb}</a></TableCell>
                                            <TableCell align="center">{hebergement.etoile}</TableCell>
                                            {/* <TableCell align="center">{hebergement.prix}</TableCell> */}
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(hebergement)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(hebergement)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={hebergementTotal}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[25, 50, 100]}
                                // rowsPerPageOptions={[2, 5, 10]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard >
            {selectedHebergement &&
                <>
                    <ModalDeleteHebergement hebergement={selectedHebergement} setHebergements={setHebergements} open={openModalDelete} handleClose={handleCloseModalDelete} />

                    <ModalEditHebergement hebergement={selectedHebergement} setHebergements={setHebergements} open={openModalEdit} handleClose={handleCloseModalEdit} />
                </>
            }
        </>
    )
}

export default ListHebergementComponent;