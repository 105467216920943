import url from "app/UrlConfig";
import axios from "axios";
import { displayToast } from "app/data/piece/dispatch";


export const SaveArticleAPI = async (Article, setMessage, setVisible, setSeverity) => {
    await axios.post(url["url-api"] + "article/add", Article)
        //.then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("request avec success");
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage(data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            setSeverity('error');
            setMessage(error)
            setVisible(true);
        })
}

export const getArticleListAPI = async (data, setArticle, setLigneNumber) => {
    await axios.get(url["url-api"] + "article")
        //.then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setArticle(data.data);
                setLigneNumber(data.LigneNumber);
                // setArticles(data.data.ori);
            }
        })
        .catch((error) => {
            console.log(error);
        })
}

export const getArticleList = async (setArticle, setArticleTotal, per_page = 15, page = 1) => {
    console.log("page", page, "row", per_page)
    await axios.get(url["url-api"] + "article/" + per_page + "/page/" + (page + 1))
        //.then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setArticle(data.data.data);
                setArticleTotal(data.data.total);
                // setArticles(data.data.ori);
            }
        })
        .catch((error) => {
            console.log(error);
        })
}

export const getListeArticleDetail = async (idArticle, setArticle) => {
    new Promise((resolve, reject) => {
        fetch(url["url-api"] + "article/" + idArticle, {
            method: 'GET',
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // console.log(data.data)
                    setArticle(data.data[0]);
                    if (data.data.length > 0) {

                        resolve(data.data[0]);

                        return data.data[0];
                    }
                }
            })
            .catch((error) => {
                reject(error);
            })
    })
}


export const UpdateArticleAPI = async (Article, setArticle, setMessage, setVisible, setSeverity) => {

    //console.log(JSON.stringify(Article))
    await axios.post(url["url-api"] + "article/update", Article)
        //.then(response => response.json())
        .then(data => {
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("Modification avec Success");
                setVisible(true);
                displayToast("Modification avec Success", true);
            } else {
                setSeverity('error');
                setMessage("Error " + data.message)
                setVisible(true);
                displayToast("Error " + data.message, false);
            }
        })
        .catch((error) => {
            console.log(error);
            setSeverity('error');
            setMessage("Error " + error);
            setVisible(true);
            displayToast("Error " + error, false);
        });
}

export const DeleteArticle = async (idArticle, setArticle, setMessage, setVisible, setSeverity) => {
    await axios.delete(url["url-api"] + "article/delete/" + idArticle)

        //.then(response => response.json())
        .then(data => {
            if (data.data.status === 200) {
                // setSeverity('success');
                // setMessage("Suppression avec Success")
                // setVisible(true);
            } else {
                // setSeverity('error');
                // setMessage("Error " + data.message)
                // setVisible(true);
            }
        })
        .catch((error) => {
            setSeverity('error');
            setMessage("Error " + error)
            setVisible(true);
        })


}