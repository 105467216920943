import { Autocomplete, Button, DialogActions, Fab, FormControl, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import url from 'app/UrlConfig';


// const AutoComplete = styled(Autocomplete)(() => ({
//     width: 300,
//     marginBottom: '16px',
// }));

const styleImage = {
    width: "100px",
    heigth: "80px"
}

const typeLocations = [
    { Etattype: 10, "type": "Hors Carburant" }, { Etattype: 20, "type": "Avec Carburant" }
];


const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const UpdateChauffeurComponent = ({ chauffeurArgs }) => {

    const navigate = useNavigate();
    const [indice, setIndice] = useState();

    const [chauffeur, setChauffeur] = useState(chauffeurArgs);

    function handleChange(e) {
        setChauffeur({ ...chauffeur, [e.target.name]: e.target.value });
    }

    const UpdateChauffeur = async () => {
        console.log(JSON.stringify(chauffeur))
        fetch(url['url-api'] + "admin/chauffeur", {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(chauffeur)
        })
            .then(response => response.json())
            .then(data => { console.log("DATA ::: >>> ", data) })
            .catch(error => { console.log("ERROR ::: >>> ", error) })
        navigate('/entreprise/location/chauffeur/liste')
    }
    const changeIndice = (ind) => {
        setIndice(ind)
        console.log(ind)
    }



    return (
        // {vehicule!=undefined && (

        // )}
        <div>
            <Grid item lg={4}>
                <SimpleCard title={'Update d\'une Chauffeur'}>
                    {/* <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}> */}

                    {/* </FormControl> */}
                    <img style={styleImage} src={chauffeur.photo}></img>
                    <TextField
                        fullWidth
                        size='small'
                        name="nom"
                        type="text"
                        label="Nom"
                        aria-readonly
                        value={chauffeur.nom}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="prenom"
                        type="text"
                        label="Prenom"
                        aria-readonly
                        value={chauffeur.prenom}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="contact"
                        type="text"
                        label="Contact"
                        value={chauffeur.contact}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        size='small'
                        name="email"
                        type="text"
                        label="Email"
                        defaultValue={chauffeur.email}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />

                    <TextField
                        fullWidth
                        size='small'
                        name="date_naissance"
                        type="date"
                        label="Date"
                        defaultValue={chauffeur.date_naissance}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ mt: 1.5, mb: 2 }}
                    />
                    {/* <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" /> */}

                    <StyledButton onClick={UpdateChauffeur} fullWidth variant="contained" component="span">
                        Modifier
                    </StyledButton>
                </SimpleCard>
            </Grid>
        </div>
    );
};

export default UpdateChauffeurComponent;