import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { getListeHebergement } from "app/data/nosybe/hebergement";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { getEquipement } from "../Equipement/Api-Equipement";
import { getRestaurant } from "../Restaurant/API-Restaurant";
import ModalUpdateRestaurant from "../Restaurant/ModalUpdateRestaurant";
import ModalDeleteRestaurant from "../Restaurant/ModalDeleteRestaurant";
import { getEvenement } from "./API-Evenement";
import ModalDeleteEvenement from "./ModalDeleteEvenement";
import ModalUpdateEvenement from "./ModalUpdateEvenement";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListEvenement = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [restaurants, setRestaurant] = useState([]);
    const [selectedEvenement, setSelectedEvenement] = useState(undefined);
    const [search, setSearch] = useState("none");
    const [restaurantTotal, setRestaurantTotal] = useState(0);

    const [evenements, setEvenement] = useState([]);
    const [evenementTotal, setEvenementTotal] = useState(0);





    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);

    const [dataSend, setDataSend] = useState({
        "offset": page,
        "limit": rowsPerPage,
        "dataRecherche": { "recherche": "", "dateDebut": "", "dateFin": "" }
    });


    const handleChangePage = async (_, newPage) => {
        setPage(newPage);
        // console.log(newPage)
        setDataSend({ ...dataSend, offset: newPage * rowsPerPage });

        // await getRestaurant({ ...dataSend, offset: newPage * rowsPerPage }, setRestaurant, setRestaurantTotal);
        await getEvenement({ ...dataSend, offset: newPage * rowsPerPage }, setEvenement, setEvenementTotal);

        // await getEquipement(page, rowsPerPage, search, setEquipements, setEquipementTotal);
        // getListeHebergement(setHebergements, setHebergementTotal, newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setDataSend({ ...dataSend, offset: 0, limit: +event.target.value })
        // await getRestaurant(dataSend, setRestaurant, setRestaurantTotal);
        await getEvenement(dataSend, setEvenement, setEvenementTotal);
        // await getEquipement(page, +event.target.value, search, setEquipements, setEquipementTotal);
        // getListeHebergement(setHebergements, setHebergementTotal, 0, +event.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedEvenement(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (evenement) => {
        setSelectedEvenement(evenement);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedEvenement(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (evenement) => {
        setSelectedEvenement(evenement);
        setOpenModalEdit(true);
    }


    const getEvenementListe = async () => {
        await getEvenement(dataSend, setEvenement, setEvenementTotal);
    }

    useEffect(() => {
        getEvenementListe();

    }, [selectedEvenement]);



    // { "attr": "name", "val": "Ho", "type": "str" }, { "attr": "description", "val": "Hot", "type": "str" }



    return (
        <>
            {/* <TestButton toLog={hebergements} /> */}
            <SimpleCard title={'Liste des evenements'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {evenements.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom </TableCell>
                                        <TableCell align="center">lien</TableCell>
                                        <TableCell align="center">email</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {evenements.map((evenement, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{evenement.name_fr}</TableCell>
                                            <TableCell align="center"><a href={evenement.siteweb}>{evenement.siteweb}</a></TableCell>
                                            <TableCell align="center">{evenement.email}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(evenement)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(evenement)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={evenementTotal}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[2, 5, 10]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard >
            {selectedEvenement &&
                <>
                    <ModalDeleteEvenement evenement={selectedEvenement} setEvenement={setSelectedEvenement} open={openModalDelete} handleClose={handleCloseModalDelete}></ModalDeleteEvenement>
                    <ModalUpdateEvenement evenementEdit={selectedEvenement} setEvenementEdit={setSelectedEvenement} open={openModalEdit} handleClose={handleCloseModalEdit}></ModalUpdateEvenement>
                    {/* <ModalUpdateRestaurant restaurantEdit={selectedRestaurant} setRestaurantEdit={setSelectedRestaurant} open={openModalEdit} handleClose={handleCloseModalEdit}></ModalUpdateRestaurant> */}
                    {/* <ModalEditHebergement hebergement={selectedHebergement} setHebergements={setHebergements} open={openModalEdit} handleClose={handleCloseModalEdit} /> */}
                    {/* <ModalDeleteRestaurant restaurant={selectedRestaurant} setRestaurantEdit={setSelectedRestaurant} open={openModalDelete} handleClose={handleCloseModalDelete} ></ModalDeleteRestaurant> */}
                </>
            }
        </>
    )
}

export default ListEvenement;