import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import React from 'react';
import { Fragment } from 'react';

const MySkeletonComponent = () => {

    const cardArray = new Array(10).fill(null);

    return (
        <>
            {cardArray.map((_, index) =>
                <Card key={index} sx={{ width: '100%', mb: 3 }}>
                    <CardHeader
                        avatar={
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        }

                        title={

                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />

                        }
                        subheader={
                            <Skeleton animation="wave" height={10} width="40%" />
                        }
                    />

                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />


                    <CardContent>

                        <Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </Fragment>

                    </CardContent>
                </Card>
            )}

        </>
    );
};

export default MySkeletonComponent;