import { Card, Box, styled, Grid, Button } from '@mui/material';

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CalendarPicker, CalendarPickerSkeleton } from '@mui/lab';
import { CalendarMonth } from '@mui/icons-material';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import event from './event';
import url from 'app/UrlConfig';



const CalendrierAgenda = ({ publication }) => {

    const localizer = momentLocalizer(moment)
    const [event, setEvent] = useState([]);

    const getReservationCalendar = async () => {
        const token = localStorage.getItem("token");
        console.log(token)
        fetch(url['url-api'] + "admin/reservations-calendar", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data)
                if (data.status === 200) {
                    setEvent(data.data);
                    return data.data
                }
            })
            .catch(error => console.log(error))
    }



    useEffect(() => {
        getReservationCalendar();
    }, [])


    return (
        <>
            <div>
                <Calendar
                    culture='fr'
                    messages={{
                        allDay: "Tous les jours",
                        previous: "Précédent",
                        next: "Suivant",
                        today: "Aujourd'hui",
                        month: "Mois",
                        week: "Semaine",
                        day: "Jour",
                        agenda: "Agenda",
                        date: "Date",
                        time: "Heure",
                        event: "Evenement",

                    }}
                    onSelectSlot={(slotInfo) => {
                        console.log(slotInfo)
                    }}
                    localizer={localizer}
                    events={event}
                    startAccessor="datedebut"
                    endAccessor="datefin"
                    style={{ height: 500 }}
                />
            </div>
        </>

    );
};

export default CalendrierAgenda;
