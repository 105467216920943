import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { displayToast } from "app/data/piece/dispatch";
import TestButton from "app/myComponents/TestButton";
import { DeleteRestaurant } from "./API-Restaurant";

const ModalDeleteRestaurant = ({ restaurant, setRestaurant, open, handleClose }) => {
    const supprimerRestaurant = async () => {
        await DeleteRestaurant(restaurant.id, setRestaurant);
        handleClose();
        displayToast("Succes de la suppression", true);
    }



    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Retirer {restaurant.name_fr}
            </DialogTitle>

            <DialogContent dividers>
                {/* <TestButton toLog={hebergement} /> */}
                <Typography gutterBottom>
                    Vous en êtes sur?
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={supprimerRestaurant} style={{ color: 'red' }}>
                    Confirmer
                </Button>
                <Button onClick={handleClose} style={{ color: 'red' }}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteRestaurant;