import { activateCategorie, desactivateCategorie, getCategories, insertCategorie, updateCategorie } from "app/data/piece/categorie";
import { useEffect } from "react";
import { useState } from "react";
import { SimpleCard } from "app/components";
import { Box, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TablePagination, TextField, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

const CategorieComponent = () => {
    const [categories, setCategories] = useState([]);
    const [categorieEdit, setCategorieEdit] = useState(null);
    const [categorieCreate, setCategorieCreate] = useState({
        nom: '',
        image: '',
    });

    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalActivate, setOpenModalActivate] = useState(false);

    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);


    //page handling
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //create modal
    const handleCloseModalCreate = () => setOpenModalCreate(false);
    const createCategorie = () => {
        insertCategorie(categorieCreate, setCategories);
        handleCloseModalCreate();
    }
    const onDrop = (files) => {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setCategorieCreate({ ...categorieCreate, image: base64 });
                setCategorieEdit({ ...categorieEdit, image: base64 });
            };
            reader.readAsDataURL(file);
        }
    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    //edit modal
    const handleCloseModalEdit = () => setOpenModalEdit(false);
    const displayEditModal = (categorie) => {
        setCategorieEdit({ ...categorie });
        setOpenModalEdit(true);
    }
    const editCategorie = () => {
        updateCategorie(categorieEdit, setCategories);
        handleCloseModalEdit();
    }

    //delete modal
    const handleCloseModalDelete = () => setOpenModalDelete(false);
    const displayDeleteModal = (categorie) => {
        setCategorieEdit(categorie);
        setOpenModalDelete(true);
    }
    const deleteCategorie = () => {
        desactivateCategorie(categorieEdit, setCategories);
        handleCloseModalDelete();
    }

    //activate modal
    const handleCloseModalActivate = () => setOpenModalActivate(false);
    const displayActivateModal = (categorie) => {
        setCategorieEdit(categorie);
        setOpenModalActivate(true);
    }
    const reactivateCategorie = () => {
        activateCategorie(categorieEdit, setCategories);
        handleCloseModalActivate();
    }

    const test = () => {
        console.log("categories create: ", categorieCreate);
    }


    useEffect(() => {
        getCategories(setCategories);
    }, [])

    return (
        <>
            {/* <button onClick={test}>test</button> */}
            <SimpleCard title={'Categorie'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
                <IconButton onClick={() => setOpenModalCreate(true)}>
                    <Icon color='primary'>add</Icon>
                </IconButton>
                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={categories.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 4, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box sx={{ mt: 3 }} width={'100%'}>
                <Grid container spacing={3}>
                    {categories
                        .filter((categorie) =>
                            categorie.nom.toLowerCase().includes(recherche.toLowerCase())
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((categorie, index) =>
                            <Grid key={index} item lg={3} md={3}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <div
                                        style={{
                                            width: '100%',  // Largeur 100% de la diapositive
                                            height: '200px',  // Hauteur fixe
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                        }}
                                    >
                                        <img
                                            src={categorie.image}
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                maxWidth: '100%',
                                                maxHeight: '100%' // Empêche le débordement horizontal
                                            }}
                                            alt={`Pièce img`} />
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom sx={{ textAlign: 'center' }} variant="h5" component="div">
                                            {categorie.nom}
                                        </Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton onClick={() => displayEditModal(categorie)}>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        {categorie.etat === 0 ?
                                            <IconButton onClick={() => displayActivateModal(categorie)}>
                                                <Icon color='warning'>restore</Icon>
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => displayDeleteModal(categorie)}>
                                                <Icon color='error'>delete</Icon>
                                            </IconButton>
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>

            {/* modal create start */}
            <Dialog fullWidth={true} onClose={handleCloseModalCreate} aria-labelledby="customized-dialog-title" open={openModalCreate}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalCreate}>
                    Ajout de nouvelle categorie
                </DialogTitle>

                <DialogContent dividers>
                    {/* <button onClick={test}>test</button> */}
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        name='nom'
                        value={categorieCreate.nom}
                        onChange={e => setCategorieCreate({ ...categorieCreate, nom: e.target.value })}
                        label='Nom'
                        sx={{ mt: 2 }}
                    />

                    <Box sx={{ py: '6px' }} />

                    <div
                        {...getRootProps()}
                        style={{
                            width: '100%',
                            // minHeight: '200px',
                            height: '100px',
                            padding: '20px',
                            border: '2px dashed #cccccc',
                            textAlign: 'center',
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'lightgray',
                        }}
                        className="dropzone"
                    >
                        <input {...getInputProps()} />
                        <Icon fontSize="large">photo_camera</Icon>
                        <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                            Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                        </Typography>
                    </div>

                    {categorieCreate.image &&
                        <>
                            <div
                                style={{
                                    width: '100%',  // Largeur 100% de la diapositive
                                    height: '100px',  // Hauteur fixe
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'lightgrey',  // Couleur de fond noire
                                }}
                            >
                                <img
                                    src={categorieCreate.image}
                                    style={{
                                        width: 'auto',
                                        height: 'auto',
                                        maxWidth: '100%',
                                        maxHeight: '100%' // Empêche le débordement horizontal
                                    }}
                                    alt={`Pièce img`} />
                            </div>
                        </>
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalCreate}>
                        Fermer
                    </Button>
                    <Button color='success' onClick={createCategorie}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            {/* create end */}

            {categorieEdit &&
                <>
                    {/* modal edit start */}
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier {categorieEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='nom'
                                value={categorieEdit.nom}
                                onChange={e => setCategorieEdit({ ...categorieEdit, nom: e.target.value })}
                                label='Nom'
                                sx={{ mt: 2 }}
                            />

                            <Box sx={{ py: '6px' }} />

                            <div
                                {...getRootProps()}
                                style={{
                                    width: '100%',
                                    // minHeight: '200px',
                                    height: '100px',
                                    padding: '20px',
                                    border: '2px dashed #cccccc',
                                    textAlign: 'center',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: 'lightgray',
                                }}
                                className="dropzone"
                            >
                                <input {...getInputProps()} />
                                <Icon fontSize="large">photo_camera</Icon>
                                <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                    Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                </Typography>
                            </div>

                            {categorieEdit.image &&
                                <>
                                    <div
                                        style={{
                                            width: '100%',  // Largeur 100% de la diapositive
                                            height: '100px',  // Hauteur fixe
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                        }}
                                    >
                                        <img
                                            src={categorieEdit.image}
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                maxWidth: '100%',
                                                maxHeight: '100%' // Empêche le débordement horizontal
                                            }}
                                            alt={`Pièce img`} />
                                    </div>
                                </>
                            }
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>
                            <Button color='success' onClick={editCategorie}>
                                Modifier
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* edit end */}

                    {/* modal delete start */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            Retirer {categorieEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={deleteCategorie} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* delete end */}

                    {/* modal activate start */}
                    <Dialog onClose={handleCloseModalActivate} aria-labelledby="customized-dialog-title" open={openModalActivate}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalActivate}>
                            Activer {categorieEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={reactivateCategorie} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* activate end */}
                </>
            }

        </>
    );
}
export default CategorieComponent;