



import { Card, Box, styled, Grid, Button } from '@mui/material';
import { SimpleCard } from 'app/components';
import ModalDetail from '../utils/ModalDetail';
import DetailVehicule from '../Vehicule/DetailVehicule';
import DetailDemande from './DetailDemande';
import ModalUpdate from '../utils/ModalUpdate';
import UpdateLocation from './UpdateLocation';
import ModalDelete from '../utils/ModalDelete';
import CardMobile from './CardMobile';
import { ValiderReservation } from './ClientApropos';



const CardRoot = styled(Card)({
    height: '250px',
    width: '200px',
    padding: '20px 24px',
});

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && '16px'
}));

const ValidationDemande = ({ demande }) => {
    const styleImage = {
        width: "100px",
        heigth: "100px"
    }

    const styleP = {
        fontSize: "10px"
    }

    const valider = () => {
        const val = ValiderReservation(demande);
        console.log(val)
    }

    // const cardStyle
    return (

        <div>
            <DetailDemande demande={demande}></DetailDemande>
            <Box sx={{ py: '12px' }} />
            <Button sx={{ ml: 2 }} variant="contained" size="small" color="primary">Generer Contrat</Button>

        </div>

    );
};

export default ValidationDemande;
