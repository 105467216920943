import { Box, Container } from "@mui/material";
import { Breadcrumb } from "app/components";
import ListeMarqueEntrepriseComponent from "app/myComponents/Location/Entreprise/ListeMarqueEntreprise";
import ListeMarqueComponent from "app/myComponents/Location/Vehicule/ListeMarqueComponent";




const ListeMarqueEntreprisePage = () => {
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Material", path: "/material" }, { name: "Table" }]} />
            </Box>
            {/* <SimpleCard title="Liste Vehicules"> */}
          <ListeMarqueEntrepriseComponent></ListeMarqueEntrepriseComponent>
            {/* </SimpleCard> */}
        </Container>
    );
}

export default ListeMarqueEntreprisePage;