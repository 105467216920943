export const activites = [
    {
        id: 1,
        nom: "motorise"
    },
    {
        id: 2,
        nom: "nautique"
    },
    {
        id: 3,
        nom: "terrestre"
    },
    {
        id: 4,
        nom: "visite"
    },
    {
        id: 5,
        nom: "aerienne"
    }
];