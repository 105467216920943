import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

// bar chart.js

import { Bar } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Grid, Icon, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// 

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const RecetteDepenseComponent = () => {

    const token = localStorage.getItem("token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // datas

    const [filter, setFilter] = useState({
        sendFilter: false,
        year: new Date().getFullYear()
    });

    const [mois, setMois] = useState([]);
    const getMois = () => {
        const requestOptions = {
            url: `${myConfig.host}/api/mois`,
            method: 'GET',
        };
        axios(requestOptions).then((response) => {
            if (mois.length === 0) {
                const tmpMois = mois;
                response.data.forEach(month => {
                    const nom = month.nom.charAt(0) + month.nom.charAt(1) + month.nom.charAt(2);
                    tmpMois.push(nom);
                });
                setMois(tmpMois);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const getRecetteDepense = () => {
        setInit(false);
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/stat/recetteDepense`,
            method: 'GET',
        };
        if (filter.sendFilter) {
            requestOptions.url += `?year=${filter.year}`;
        }
        axios(requestOptions).then((response) => {

            const listRecettes = [];
            const listDepenses = [];

            const tabRecettes = response.data.tabRecettes;
            const tabDepenses = response.data.tabDepenses;

            tabRecettes.forEach(tabRecette => {
                listRecettes.push(parseInt(tabRecette.recette));
            });
            tabDepenses.forEach(tabDepense => {
                listDepenses.push(parseInt(tabDepense.depense));
            });

            const labels = mois;

            const data = {
                labels,
                datasets: [
                    {
                        label: 'Recette',
                        data: listRecettes,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Dépense',
                        data: listDepenses,
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };

            setMyData(data);

            // avant

            const listRecettesAvant = [];
            const listDepensesAvant = [];

            const tabRecettesAvant = response.data.tabRecettesAvant;
            const tabDepensesAvant = response.data.tabDepensesAvant;

            tabRecettesAvant.forEach(tabRecette => {
                listRecettesAvant.push(parseInt(tabRecette.recette));
            });
            tabDepensesAvant.forEach(tabDepense => {
                listDepensesAvant.push(parseInt(tabDepense.depense));
            });

            const dataAvant = {
                labels,
                datasets: [
                    {
                        label: 'Recette',
                        data: listRecettesAvant,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Dépense',
                        data: listDepensesAvant,
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            };

            setMyDataAvant(dataAvant);

            setInit(false);

        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getMois();
    }, []);

    useEffect(() => {
        if (filter.sendFilter) {
            getRecetteDepense();
            setFilter({ ...filter, sendFilter: false });
        }
    }, [filter]);

    //

    // Bar chart
    const [init, setInit] = useState(true);

    const [myDatas, setMyData] = useState(null);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Recettes & Dépenses ${filter.year}`,
            },
        },
    };

    const [myDatasAvant, setMyDataAvant] = useState(null);
    const optionsAvant = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Recettes & Dépenses ${filter.year - 1}`,
            },
        },
    };
    // 

    return (
        <>
            <AccordionRoot>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Box className="column">
                            <Typography className="heading">TABLEAU DE BORD</Typography>
                        </Box>

                        <Box className="column">
                            <Typography className="secondaryHeading">Filtrer</Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails className="details">
                        <Box className="column helper">
                            <TextField
                                value={filter.year}
                                onChange={(e) => setFilter({ ...filter, year: e.target.value })}
                                name='year'
                                label='Année'
                                type='number'
                                size='small'
                                fullWidth
                            />
                        </Box>
                    </AccordionDetails>

                    <Divider />

                    <AccordionActions>
                        <Button onClick={(e) => setFilter({ ...filter, sendFilter: true })} size="small" color="primary">
                            Filtrer
                        </Button>
                    </AccordionActions>
                </Accordion>
            </AccordionRoot>
            <br />
            <SimpleCard title="Recettes et dépenses">

                {
                    init === false &&
                    <>
                        {
                            <>
                                {
                                    myDatas && myDatasAvant ?
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <Bar options={options} data={myDatas} />;
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <Bar options={optionsAvant} data={myDatasAvant} />;
                                                </Grid>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <center>
                                                <CircularProgress></CircularProgress>
                                            </center>
                                        </>
                                }
                            </>
                        }
                    </>
                }


            </SimpleCard>
        </>
    );
};

export default RecetteDepenseComponent;
