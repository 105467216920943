import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { displayToast } from "app/data/piece/dispatch";
import TestButton from "app/myComponents/TestButton";
import { DeleteArticle } from "./API-Article";

const ModalDeleteArticle = ({ Article, setArticle, open, handleClose }) => {
    const supprimerArticle = async () => {
        await DeleteArticle(Article.id);
        handleClose();
        displayToast("Succes de la suppression", true);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Retirer {Article.nom}
            </DialogTitle>

            <DialogContent dividers>
                {/* <TestButton toLog={Article} /> */}
                <Typography gutterBottom>
                    Vous en êtes sur?
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={supprimerArticle} style={{ color: 'red' }}>
                    Confirmer
                </Button>
                <Button onClick={handleClose} style={{ color: 'red' }}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDeleteArticle;