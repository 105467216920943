import React from 'react';
import GoogleMapReact from 'google-map-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { TextField } from '@mui/material';
import Marker from './Marker';

const CarteMap = ({ apiKey, setLongitude, setLatitude, setMereObject, lati, longi }) => {

    const [currentLocation, setCurrentLocation] = useState({ lat: lati, lng: longi });

    // const [lat, setLat] = useState(lati ? lati : -13.330993056594197);
    const [lat, setLat] = useState(lati);

    // const [long, setLong] = useState(longi ? longi : 48.256898613913386);
    const [long, setLong] = useState(longi);

    console.log(lati, " == ", longi)

    useEffect(() => {
        // console.log("effect: ", lati, longi);
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         const { latitude, longitude } = position.coords;
        //         setCurrentLocation({ lat: latitude, lng: longitude });
        //     }, (error) => {
        //         console.error('Error obtener:', error);
        //     });
        // } else {
        //     console.error('Le navigateur ne supporte pas la géolocalisation.');
        // }
    }, []);

    // useEffect(() => {
    //     console.log(lati, longi)
    // }, [lat, long])

    const handleMapClick = (lat, lng) => {
        // console.log(lat, 'Coordenadas clickeadas:', lat, lng);
        setLong(Number(lat.lng))
        setLat(Number(lat.lat))
        setLongitude(Number(lat.lng))
        setLatitude(Number(lat.lat))
        setMereObject(Number(lat.lat), Number(lat.lng));
        // setMere({...objectMere,});
    }

    const changeLong = (event) => {
        setLong(Number(event.target.value))
        setLongitude(Number(event.target.value))
        setMereObject(Number(lati), Number(event.target.value));
    }

    const changeLat = (event) => {
        setLat(Number(event.target.value))
        setLatitude(Number(event.target.value))
        setMereObject(Number(event.target.value), Number(longi));
    }


    return (
        <>
            <div style={{ height: '400px', width: '100%', position: 'relative' }}>
                <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 999 }}>
                    <input
                        type="text"
                        placeholder="Longitude"
                        value={longi}
                        // onChange={handleSearchChange}
                        onChange={(e) => changeLong(e)}
                    />
                    <br />
                    <input
                        type="text"
                        placeholder="latitude"
                        value={lati}
                        onChange={(e) => changeLat(e)}
                    // onChange={handleSearchChange}
                    />

                    {/* <TextField
                        fullWidth
                        // size='small'
                        name="description"
                        label="Description"

                        multiline
                        maxRows={2}
                        sx={{ mt: 1.5, mb: 2 }}

                    /> */}
                    <br />
                    <button >Valide</button>
                </div>
                <GoogleMapReact
                    onClick={handleMapClick}
                    bootstrapURLKeys={{ key: apiKey }}
                    defaultCenter={{ lat: currentLocation.lat, lng: currentLocation.lng }}
                    defaultZoom={10} // Nivel de zoom por defecto
                >
                    {/* Marcador en el mapa */}
                    <AnyReactComponent
                        lat={37.7749}
                        lng={-122.4194}
                        text="Carte"
                    />
                    {lat !== 0 && long !== 0 && (
                        <Marker
                            lat={lati}
                            lng={longi}
                            text="Votre Place"
                        />
                    )}
                </GoogleMapReact>
            </div >
        </>
    );
}
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default CarteMap;