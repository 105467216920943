import { useTheme, Select, MenuItem } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getStatistiqueCategorie } from '../ControllerAPI/API-DemandeMarque';
import { SimpleCard } from 'app/components';

const BarStateCategorie = ({ height, color = [], labelsAffiche, anne, categorie }) => {
    const theme = useTheme();

    const [dataActus, setDataActus] = useState([]);
    const [categorieVoiture, setCategorieVoiture] = useState("");
    const [dataCategorie, setDataCategorie] = useState([])
    const [categ, setCateg] = useState(categorie);
    const [year, setYear] = useState(anne);

    const getAllData = async () => {
        await getStatistiqueCategorie(anne, categ, setDataActus, setCategorieVoiture, setDataCategorie);
    }

    useEffect(() => {
        getAllData();
    }, [categ, year])



    const option = {
        grid: { top: '10%', bottom: '10%', right: '5%' },
        legend: {
            itemGap: 20,
            icon: 'circle',
            textStyle: {
                fontSize: 13,
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily
            }
        },
        label: {
            fontSize: 10,
            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily
        },
        xAxis: {
            type: 'category',
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            data: labelsAffiche,
            axisLine: { show: true },
            axisTick: { show: true },
            axisLabel: {
                fontSize: 10,
                fontFamily: 'roboto',
                color: theme.palette.text.secondary
            }
        },
        yAxis: {
            type: 'value',
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: {
                lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 }
            },
            axisLabel: { color: theme.palette.text.secondary, fontSize: 13, fontFamily: 'roboto' }
        },
        series: [
            {
                data: dataActus[0],
                type: 'bar',
                color: 'blue',
                stack: categorieVoiture,
                name: categorieVoiture,
                smooth: true,
                symbolSize: 4,
                lineStyle: { width: 1 }
            }
        ]
    };

    const [filtre, setFiltre] = useState({
        categorie: 2
    })


    const handleChange = (e) => {
        setFiltre({ ...filtre, [e.target.name]: e.target.value });
        setCateg(e.target.value);
    }

    return (
        <SimpleCard>
            <center>
                <h1>Nombre reservation par categorie dans une année</h1>
                {dataCategorie !== undefined && (
                    <Select
                        size="small"
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name='categorie'
                        onChange={handleChange}
                        value={filtre.categorie}
                        autoWidth
                        label="Categorie Voiture"
                    >
                        {dataCategorie.map((categ, index) => (
                            <MenuItem key={index} value={categ.id}>{categ.categorie_voiture}</MenuItem>
                        ))}
                    </Select>
                )}
            </center>

            <ReactEcharts style={{ height: height }} option={{ ...option, color: [...color] }} />
        </SimpleCard>
    );

};

export default BarStateCategorie;



