import Categorie from "./views/myViews/Admin/VentePiece/Categorie";
import CategorieChangement from "./views/myViews/Admin/VentePiece/CategorieChangement";
import Marque from "./views/myViews/Admin/VentePiece/Marque";
import MarqueChangement from "./views/myViews/Admin/VentePiece/MarqueChangement";
import SousCategorie from "./views/myViews/Admin/VentePiece/SousCategorie";
import SousCategorieChangement from "./views/myViews/Admin/VentePiece/SousCategorieChangement";

const routesPieceAdmin = [

    //Categories
    {
        path: '/admin/pieces/categories',
        element: <Categorie />
    },
    {
        path: '/admin/pieces/categories/changement',
        element: <CategorieChangement />
    },

    //Sous-Categories
    {
        path: '/admin/pieces/souscategories',
        element: <SousCategorie />
    },
    {
        path: '/admin/pieces/souscategories/changement',
        element: <SousCategorieChangement />
    },

    //Marques
    {
        path: '/admin/pieces/marques',
        element: <Marque />
    },
    {
        path: '/admin/pieces/marques/changement',
        element: <MarqueChangement />
    },
];

export default routesPieceAdmin;