import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import TypeReparationComponent from 'app/myComponents/Repair/Admin/Reparation/TypeReparationComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));


const TypeReparation = () => {
    document.title = "TYPE-REPARATIONS";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Reparations' }, { name: 'TypeReparations' }]}
                />
            </Box>

            <TypeReparationComponent></TypeReparationComponent>

        </AppButtonRoot>
    );
};

export default TypeReparation;