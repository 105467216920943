import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import AjoutReparationComponent from 'app/myComponents/Repair/Reparation/AjoutReparationComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AjoutReparation = () => {
    document.title = "AJOUT-REPARATION";

    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Reparations', path: '/entreprise/reparation/liste' }, { name: 'Ajout' }]}
                    />
                </Box>

                <AjoutReparationComponent></AjoutReparationComponent>

            </AppButtonRoot>
        </>
    );
};

export default AjoutReparation;