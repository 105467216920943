import { Card, CardContent, CardHeader, Tab, Tabs, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';

const ConseilVoyageComponent = ({
    languages,
    accueil,
    setAccueil
}) => {
    const [tabNameValue, setTabNameValue] = useState(0);
    const [languageChoose, setLanguageChoose] = useState("eng");

    const handleChangeTabNameValue = (event, newValue) => {
        // console.log(newValue, " language ", languages[newValue].name, " SHOW == ", presentation.description[languages[newValue]?.name])
        setTabNameValue(newValue);
        setLanguageChoose(languages[newValue]?.name);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeDescription = (event) => {
        // console.log(languageChoose);
        setAccueil({ ...accueil, conseilVoyage: { ...accueil.conseilVoyage, [languageChoose]: event.target.value } })
    }
    const handleChangeDetail = (event) => {
        // console.log(languageChoose);
        setAccueil({ ...accueil, conseilVoyage_detail: { ...accueil.conseilVoyage_detail, [languageChoose]: event.target.value } })
    }
    return (
        <Card sx={{ m: 3 }}>
            <CardHeader title={`Conseil de voyage`} />
            <CardContent>
                <Tabs
                    value={tabNameValue}
                    onChange={handleChangeTabNameValue}
                    aria-label="basic tabs example"
                >
                    {languages?.map((language, index) =>
                        <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                    )}
                </Tabs>
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="description"
                    type="text"
                    label="Description"
                    value={accueil.conseilVoyage[languageChoose] ? accueil.conseilVoyage[languageChoose] : ''}
                    onChange={(e) => handleChangeDescription(e)}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
                <TextField
                    multiline
                    fullWidth
                    size='small'
                    name="description"
                    type="text"
                    label="Details"
                    value={accueil.conseilVoyage_detail[languageChoose] ? accueil.conseilVoyage_detail[languageChoose] : ''}
                    onChange={(e) => handleChangeDetail(e)}
                    variant="outlined"
                    sx={{ mt: 1.5, mb: 2 }}
                />
            </CardContent>
        </Card>
    );
};

export default ConseilVoyageComponent;