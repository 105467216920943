import { lazy } from 'react';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import MyLogin from './views/myViews/SignIn/MyLogin';
import MySignIn from './views/myViews/SignIn/MySignIn';
import routesRepair from './routesRepair';

import AdminLogin from './views/myViews/Admin/SignIn/AdminLogin';
import routesAdmin from './routesAdmin';
import routesPiece from './routesPiece';

import routesLocations from './routesLocations';
import HomePage from './views/myViews/HomePage';
import routesNosyBe from './routesNosyBe';
import Redirect from './views/myViews/SignIn/Redirect';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

// echart page
const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

const routes = [
  {
    element: (
      // <AuthGuard>
      <MatxLayout />
      // </AuthGuard>
    ),
    children: [
      // ...materialRoutes,
      // // dashboard route
      // {
      //   path: '/dashboard/default',
      //   element: <Analytics />,
      //   auth: authRoles.admin
      // },


      // e-chart rooute
      {
        path: '/charts/echarts',
        element: <AppEchart />,
        auth: authRoles.editor
      },

      // nosybe
      ...routesNosyBe,

      // admin
      ...routesAdmin,

      ...routesRepair,

      // repair
      // vente piece
      ...routesPiece,

      // repair
      ...routesLocations,

    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  // { path: '/session/signin', element: <JwtLogin /> },
  // { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },

  { path: '/', element: <MyLogin /> },
  { path: '*', element: <NotFound /> },

  // Modif Reynolds
  {
    path: '/entreprise/activites', element: <><HomePage></HomePage></>
  },
  {
    path: '/entreprise/connexion', element: <MyLogin></MyLogin>
  },
  //Modif Dan
  {
    path: '/entreprise/inscription', element: <MySignIn></MySignIn>
  },
  {
    path: '/admin/connexion', element: <AdminLogin></AdminLogin>
  },



  {
    path: '/entreprise/redirection', element: <Redirect />
  },
  // 
];

export default routes;
