import { ImageList, ImageListItem, Accordion, AccordionSummary, Alert, Badge, Box, Button, Checkbox, Fab, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import { SimpleCard } from "app/components";
//import { getExcursionPrimaryRessources } from "app/data/nosybe/Excursion";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CarteMap from "../Restaurant/CarteMap";
import url from "app/UrlConfig";
import { SaveExcursion, SaveExcursionAPI, UpdateExcursionAPI } from "./API-Excursion";
import { useNavigate } from "react-router-dom";
import { forEach } from "lodash";
import { displayToast } from "app/data/piece/dispatch";

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const FormExcursion = ({ selectedExcursion, setExcursions, closeForm }) => {

    const navigate = useNavigate();
    // console.log(selectedExcursion, 'slected')
    const Admin = JSON.parse(localStorage.getItem("admin") || null);
    const [Image, setimage] = useState({ id: null, photo: '', file: '' });

    const originalExcursion = {
        id: null,
        nom: '',
        labelle: {
            eng: "",
            fr: "",
            ita: ""
        },
        description: {
            eng: "",
            fr: "",
            ita: ""
        },
        phone: "",
        site: "",
        email: "",
        etat: 1,
        imageFile: '',
        image64: "",
        image: "",
    }



    const [Excursion, setExcursion] = useState(selectedExcursion ? { ...originalExcursion, ...selectedExcursion } : { ...originalExcursion });
    const [inputErrors, setInputErrors] = useState({});

    const handleChange = (e) => {

        if (e.target.name == 'description') {
            setExcursion({
                ...Excursion,
                [e.target.name]: { ...Excursion[e.target.name], [languages[tabDescValue].name]: e.target.value }
            }
            );
        } else if (e.target.name == 'labelle') {
            setExcursion({
                ...Excursion,
                [e.target.name]: { ...Excursion[e.target.name], [languages[tabDescValue2].name]: e.target.value }
            }
            );
        } else {
            setExcursion({
                ...Excursion,
                [e.target.name]: e.target.value
            });
        }
        console.log(Excursion);
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    //IMAGES
    const onDrop = (files) => {
        var val = files[0];
        console.log("file", val);
        setExcursion({ ...Excursion, imageFile: val });
        if (val) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setimage({ file: val, photo: base64 });
                setExcursion({ ...Excursion, image64: base64, imageFile: val });
            };
            reader.readAsDataURL(val);
        }

    }
    const handleFileRemove = () => {
        setExcursion({ ...Excursion, imageFile: null, image64: null });
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const [languages, setLanguages] = useState([{ "name": "eng", "abbrev": "MAL" }, { "name": "fr", "abbrev": "FRANCAIS" }, { "name": "ita", "abbrev": "MAL" }]);
    const [tabDescValue, setTabDescValue] = useState(0);
    const [tabDescValue2, setTabDescValue2] = useState(0);
    const [languageDescChoose, setLanguageDescChoose] = useState("description_eng");
    const [lienexpedia, setLienExpedia] = useState("");

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState('success');
    const [visible, setVisible] = useState(false);


    const handleChangeTabDescValue = (event, newValue) => {
        setTabDescValue(newValue);
        //setLanguageDescChoose("description_" + languages[newValue]?.name);
    }
    const handleChangeTabDescValue2 = (event, newValue) => {
        setTabDescValue2(newValue);
        //setLanguageDescChoose("description_" + languages[newValue]?.name);
    }

    useEffect(() => {
        //getExcursionPrimaryRessources(setEquipements, setTypes);
        console.log(Excursion, "hello");
    }, [])

    const createFormData = (formData, key, data) => {
        if (!(data instanceof File) && data === Object(data) || Array.isArray(data)) {
            for (var i in data) {
                createFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }

    const saveExcursion = async () => {

        const data = new FormData();
        createFormData(data, "id", Excursion.id);
        createFormData(data, "nom", Excursion.nom);
        createFormData(data, "labelle", Excursion.labelle);
        createFormData(data, "description", Excursion.description);
        createFormData(data, "phone", Excursion.phone);
        createFormData(data, "site", Excursion.site);
        createFormData(data, "email", Excursion.email);
        createFormData(data, "etat", Excursion.etat);
        createFormData(data, "idAdmin", Admin.id);
        createFormData(data, "image", Excursion.imageFile);
        console.log(data, " Excursion")
        await SaveExcursionAPI(data, setMessage, setVisible, setSeverity);
        navigate("/entreprise/Excursion/list");
    }

    const UpdateExcursion = async () => {
        console.log(Excursion, "Excursion update");
        const data = new FormData();
        createFormData(data, "id", Excursion.id);
        createFormData(data, "nom", Excursion.nom);
        createFormData(data, "labelle", Excursion.labelle);
        createFormData(data, "description", Excursion.description);
        createFormData(data, "phone", Excursion.phone);
        createFormData(data, "site", Excursion.site);
        createFormData(data, "email", Excursion.email);
        createFormData(data, "etat", Excursion.etat);
        createFormData(data, "idAdmin", Admin.id);
        createFormData(data, "image", Excursion.imageFile);

        await UpdateExcursionAPI(data, setExcursion, setMessage, setVisible, setSeverity);
        closeForm();

    }

    const handleCloseSnackbar = () => {
        setVisible(false);
    }


    return (
        <>
            {/* <TestButton toLog={Excursion} /> */}
            <Typography variant="h5">
                {selectedExcursion ? 'Modifier un Excursion' : 'Ajouter un Excursion'}
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12} md={8}>
                    <SimpleCard>
                        <TextField
                            fullWidth
                            size='small'
                            name="nom"
                            type="text"
                            label="Nom"
                            value={Excursion.nom}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.nom && "error"}
                            helperText={inputErrors.nom}
                        />

                        <Tabs
                            value={tabDescValue2}
                            onChange={handleChangeTabDescValue2}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='large'
                            name='labelle'
                            value={Excursion.labelle[languages[tabDescValue2].name]}
                            label="labelle"
                            onChange={handleChange}
                            multiline
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.labelle && "error"}
                            helperText={inputErrors.labelle}
                        />

                        <Tabs
                            value={tabDescValue}
                            onChange={handleChangeTabDescValue}
                            aria-label="basic tabs example"
                        >
                            {languages?.map((language, index) =>
                                <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                            )}
                        </Tabs>
                        <TextField
                            fullWidth
                            size='large'
                            name='description'
                            value={Excursion.description[languages[tabDescValue].name]}
                            label="Description"
                            onChange={handleChange}
                            multiline
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.description && "error"}
                            helperText={inputErrors.description}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="phone"
                            type="tel"
                            label="Téléphone"
                            value={Excursion.phone}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.phone && "error"}
                            helperText={inputErrors.phone}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="site"
                            type="url"
                            label="Site"
                            value={Excursion.site}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.site && "error"}
                            helperText={inputErrors.site}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="email"
                            type="email"
                            label="E-mail"
                            value={Excursion.email}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            {...inputErrors.email && "error"}
                            helperText={inputErrors.email}
                        />
                        <AccordionRoot>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<Icon>expand_more</Icon>}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <Box className="column">
                                        <h3>Gallerie</h3>
                                        {/* <Typography className="heading">Liste des réparations</Typography> */}
                                    </Box>

                                    <Box className="column">
                                        {/* <h3>Photos</h3> */}
                                        {/* <Typography className="secondaryHeading">Filtrer</Typography> */}
                                    </Box>
                                </AccordionSummary>
                                {/* <Typography variant="h6" >
                                    Photos
                                </Typography> */}
                                <div
                                    {...getRootProps()}
                                    style={{
                                        width: '100%',
                                        minHeight: '200px',
                                        height: '200px',
                                        padding: '20px',
                                        border: '2px dashed #cccccc',
                                        textAlign: 'center',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: 'lightgray',
                                    }}
                                    className="dropzone"
                                >
                                    <input {...getInputProps()} />
                                    <Icon fontSize="large">photo_camera</Icon>
                                    <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
                                        Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers
                                    </Typography>
                                </div>
                                <Swiper
                                    spaceBetween={1}
                                    slidesPerView={1}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                >
                                    {(Excursion.image64 || Excursion.image) &&
                                        <SwiperSlide>
                                            <center>
                                                <Badge
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                    badgeContent={Excursion.image64 &&
                                                        <>
                                                            <IconButton onClick={() => handleFileRemove()} color='error'>
                                                                <Icon>close</Icon>
                                                            </IconButton>
                                                        </>}>

                                                    <div
                                                        style={{
                                                            width: '100%',  // Largeur 100% de la diapositive
                                                            height: '200px',  // Hauteur fixe
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                        }}
                                                    >
                                                        <img
                                                            src={Excursion.image64 ? Excursion.image64 : url["url-api"] + 'excursion/image/' + Excursion.image}
                                                            style={{
                                                                width: 'auto',
                                                                height: 'auto',
                                                                maxWidth: '100%',
                                                                maxHeight: '100%' // Empêche le débordement horizontal
                                                            }}
                                                            alt="excursion" />
                                                    </div>
                                                </Badge>
                                            </center>

                                        </SwiperSlide>
                                    }

                                </Swiper>

                            </Accordion>
                        </AccordionRoot>
                        <br />
                        <br />
                        <StyledButton
                            // onClick={selectedPiece ? () => modifPiece() : () => savePiece()}
                            onClick={selectedExcursion ? UpdateExcursion : saveExcursion}
                            fullWidth
                            variant="contained"
                            component="span"
                        >
                            Enregistrer
                        </StyledButton>

                    </SimpleCard>

                </Grid>

            </Grid>
            <Snackbar
                open={visible}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default FormExcursion;