import { Card, Box, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';


const DialogRoot = styled('div')(({ theme }) => ({
    '& form': {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        width: 'fit-content'
    },
    '& .formControl': {
        marginTop: theme.spacing(2),
        minWidth: 120
    },
    '& .formControlLabel': {
        marginTop: theme.spacing(1)
    }
}));




const ModalGenerer = ({ children, show }) => {
    const [openModalFiche, setOpenModalFiche] = useState();
    const Open = () => setOpenModalFiche(true);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const Close = () => {
        setOpenModalFiche(false)
    };

    return (
        <DialogRoot>
            <Dialog
                onClose={Close} aria-labelledby="customized-dialog-title"
                open={show}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={Close}>
                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>
            </Dialog>
        </DialogRoot>
    );
};

export default ModalGenerer;
