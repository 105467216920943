import AllDashBordSuivi from "./myComponents/Location/AllDashBordSuivi";
import ListeMarqueEntrepriseComponent from "./myComponents/Location/Entreprise/ListeMarqueEntreprise";
import RepondrePublication from "./myComponents/Location/Locations/RepondrePublication";
import VoirTrajet from "./myComponents/Location/Map/VoirTrajet";
import AjoutvehiculeComponent from "./myComponents/Location/Vehicule/AjoutVehicule";
import CategorieVoiture from "./myComponents/Location/Vehicule/CategorieVoiture";
import ListeModelVoiture from "./myComponents/Location/Vehicule/ListeModelVoiture";
import ListeMarqueEntreprisePage from "./views/ViewLocations/AproposEntreprise/ListeMarqueEntreprisePage";
import PageCreationEntreprise from "./views/ViewLocations/AproposEntreprise/PageCreationEntreprise";
import DemandeLocatonPage from "./views/ViewLocations/Location/DemandeLocation";
import ListeChauffeurPage from "./views/ViewLocations/Location/ListeChauffeurPage";
import ListeReservationPage from "./views/ViewLocations/Location/ListeReservationPage";
import PageCalendrier from "./views/ViewLocations/Location/PageCalendrier";
import PagePublicationCLient from "./views/ViewLocations/Location/PagePublicationClient";
import RepondrePublicationPage from "./views/ViewLocations/Location/RepondrePublicationPage";
import ListeCategoriePage from "./views/ViewLocations/Vehicules/ListeCategoriePage";
import ListeMarquePage from "./views/ViewLocations/Vehicules/ListeMarque";
import ListeVehiculePage from "./views/ViewLocations/Vehicules/ListeVehiculePage";
import PageAjoutChauffeur from "./views/ViewLocations/Vehicules/PageAjoutChauffeur";
import HistoriqueClient from "./views/myViews/Reparation/Clients/HistoriqueClient";
import AffectationEtape from "./views/myViews/Reparation/Etapes/AffectationEtape";
import AjoutEtape from "./views/myViews/Reparation/Etapes/AjoutEtape";
import ListeEtape from "./views/myViews/Reparation/Etapes/ListeEtape";
import AffectationPersonnel from "./views/myViews/Reparation/Personnels/AffectationPersonnel";
import AjoutPersonnel from "./views/myViews/Reparation/Personnels/AjoutPersonnel";
import ListPersonnel from "./views/myViews/Reparation/Personnels/ListPersonnel";
import AjoutReparation from "./views/myViews/Reparation/Reparations/AjoutReparation";
import AjoutReparationEtape from "./views/myViews/Reparation/Reparations/AjoutReparationEtape";
import ListeReparation from "./views/myViews/Reparation/Reparations/ListeReparation";
import ListReparationPersonnel from "./views/myViews/Reparation/Reparations/ListeReparationPersonnel";
import ListeTarif from "./views/myViews/Reparation/Tarifs/ListeTarif";
import MyDashboard from "./views/myViews/Reparation/dashboard/MyDashboard";

const routesLocations = [

    // dashboard
    {
        path: '/entreprise/locations/dashboard',
        element: <AllDashBordSuivi />
    },

    // client Demande reservation
    {
        path: '/entreprise/locations/clients/demandes-reservation',
        element: <DemandeLocatonPage></DemandeLocatonPage>
    },
    ///Client Publication
    {
        path: '/entreprise/location/clients/publications',
        element: <PagePublicationCLient></PagePublicationCLient>
    },
    ///Client Historique
    {
        path: '/entreprise/location/clients/historique',
        element: <HistoriqueClient></HistoriqueClient>
    },
    {
        path: '/entreprise/locations/clients/publications/repondre-publication',
        element: <RepondrePublicationPage />
    },

    // LISTE vehicule
    {
        path: '/entreprise/locations/liste',
        element: <ListeVehiculePage></ListeVehiculePage>,
    },
    {
        path: '/entreprise/locations/liste/personnel',
        element: <ListReparationPersonnel></ListReparationPersonnel>
    },

    ////Ajout vehicule
    {
        path: '/entreprise/locations/ajout',
        element: <AjoutvehiculeComponent></AjoutvehiculeComponent>
    },
    {
        path: '/entreprise/locations/marque',
        element: <ListeMarquePage></ListeMarquePage>
    },
    ////Calendrier
    {
        path: '/entreprise/locations/calendrier',
        element: <PageCalendrier></PageCalendrier>
    },

    ///Liste Planning
    {
        path: '/entreprise/locations/planning',
        element: <ListeReservationPage></ListeReservationPage>
    },

    // Locations Proches
    {
        path: '/entreprise/locations/locations-proches',
        element: <HistoriqueClient></HistoriqueClient>
    },
    //// Historique Location
    {
        path: '/entreprise/locations/historiques-locations',
        element: <HistoriqueClient></HistoriqueClient>
    },
    //// SUIVI Itineraire 
    {
        path: '/entreprise/locations/suivi',
        element: <VoirTrajet></VoirTrajet>
    },

    // tarifs
    {
        path: '/entreprise/locations/tarifs/liste',
        element: <HistoriqueClient></HistoriqueClient>
    },

    // personnels
    {
        path: '/entreprise/reparation/personnels/liste',
        element: <ListPersonnel></ListPersonnel>
    },
    {
        path: '/entreprise/location/chauffeur/ajout',
        element: <PageAjoutChauffeur></PageAjoutChauffeur>
    },
    {
        path: '/entreprise/location/chauffeur/liste',
        element: <ListeChauffeurPage></ListeChauffeurPage>
    },
    {
        path: '/entreprise/locations/categorie',
        element: <ListeCategoriePage></ListeCategoriePage>
    },
    {
        path: 'entreprise/locations/marque-entreprise',
        element: <ListeMarqueEntreprisePage></ListeMarqueEntreprisePage>
    },
    {
        path: 'entreprise/locations/modele',
        element: <ListeModelVoiture></ListeModelVoiture>
    }, {
        path: 'entreprise/location/contrat',
        element: <PageCreationEntreprise></PageCreationEntreprise>
    }
]

export default routesLocations;