import { useState } from "react";
import { Button } from "@mui/material";
import { SimpleCard } from "app/components";
import PresentationComponent from "./Content/PresentationComponent";
import AProposComponent from "./Content/AProposComponent";
import ConseilVoyageComponent from "./Content/ConseilVoyageComponent";
import ConditionVenteComponent from "./Content/ConditionVenteComponent";
import AboutUsComponent from "./Content/AboutUsComponent";
import SocialComponent from "./Content/SocialComponent";
import { getAccueil, validateAccueil } from "app/api/accueil/accueil";
import { useEffect } from "react";
import { displayToast } from "app/data/piece/dispatch";

const AccueilComponent = () => {

    const languages = [{ "name": "eng", "abbrev": "ENG" }, { "name": "fr", "abbrev": "FR" }, { "name": "ita", "abbrev": "ITA" }];
    const [accueil, setAccueil] = useState({
        presentation: { "fr": "", "eng": "", "ita": "" },
        apropos: { "fr": "", "eng": "", "ita": "" },
        apropos_detail: { "fr": "", "eng": "", "ita": "" },
        conseilVoyage: { "fr": "", "eng": "", "ita": "" },
        conseilVoyage_detail: { "fr": "", "eng": "", "ita": "" },
        conditionVente: { "fr": "", "eng": "", "ita": "" },
        aboutUs: { "fr": "", "eng": "", "ita": "" },
        facebook: "",
        instagram: "",
        youtube: "",
        twitter: "",
        contact: "",
        email: ""
    });
    const [loadedData, setLoadedData] = useState(false);

    const formatDataAccueil = (dataAccueil) => {
        let keys = Object.keys(accueil);
        let keysDataAccueil = Object.keys(dataAccueil);
        let accueilTmp = { ...accueil };
        keysDataAccueil.forEach(keyDataAccueil => {
            if (keys.includes(keyDataAccueil) || keyDataAccueil === 'id') {
                accueilTmp[keyDataAccueil] = dataAccueil[keyDataAccueil];
            }
        });

        dataAccueil.accueilchamps.forEach(accueilchamp => {
            let multilingues = {};
            accueilchamp.accueilmultilingues.forEach(accueilmultilingue => {
                multilingues[accueilmultilingue.langue.nom] = accueilmultilingue.description;
                multilingues['idActiviteMultilingue-' + accueilmultilingue.langue.nom] = accueilmultilingue.id;
            });
            accueilTmp[accueilchamp.label] = multilingues;
        });

        setAccueil(accueilTmp);
    }

    const getDataAccueil = () => {
        getAccueil().then((response) => {
            formatDataAccueil(response.data.accueil);
            setLoadedData(true);
        }).catch((e) => {
            console.log(e);
            displayToast("Une erreur est survenue pour le chargement des données de l'accueil");
        })
    }

    useEffect(() => {
        getDataAccueil();

    }, []);

    const validate = () => {
        validateAccueil(accueil).then((response) => {
            displayToast("Ajout avec success", "success");
        }).catch((e) => {
            console.log('error validateAccueil : ', e);
            displayToast("Une erreur est survenue : " + e.response.data.error);
        })
    }

    return (
        <>
            <SimpleCard title={`Gestion de contenu de la page d' accueil`}>

                {
                    accueil &&
                    <>
                        <PresentationComponent
                            languages={languages}
                            accueil={accueil}
                            setAccueil={setAccueil}
                        />
                        <AProposComponent
                            languages={languages}
                            accueil={accueil}
                            setAccueil={setAccueil}
                            loadedData={loadedData}
                        />
                        <ConseilVoyageComponent
                            languages={languages}
                            accueil={accueil}
                            setAccueil={setAccueil}
                        />
                        <ConditionVenteComponent
                            languages={languages}
                            accueil={accueil}
                            setAccueil={setAccueil}
                        />
                        <AboutUsComponent
                            languages={languages}
                            accueil={accueil}
                            setAccueil={setAccueil}
                        />
                        <SocialComponent
                            accueil={accueil}
                            setAccueil={setAccueil}
                        />
                        <Button onClick={() => validate()} fullWidth variant='contained'>Valider</Button>
                    </>
                }

            </SimpleCard>
            {/* <TextField
                fullWidth
                size="small"
                name="recherche"
                type="text"
                label="Recherche"
                variant="outlined"
                value={recherche}
                onChange={(e) => setRecherche(e.target.value)}
                sx={{ mt: 1.5 }}
            />
            <FiltrePublication />
            <Box sx={{ py: '12px' }} />
            {publications.map((publication) => <div key={publication.id}><PublicationCard publication={publication} action /></div>)} */}
        </>
    );
}
export default AccueilComponent;