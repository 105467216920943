import { Autocomplete, Box, Button, DialogActions, Fab, Grid, Icon, ImageList, ImageListItem, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react';
import { useEffect } from 'react';

import url from 'app/UrlConfig';
import ModalGenerer from '../utils/ModalGenerer';


const AutoComplete = styled(Autocomplete)(() => ({
    width: 300,
    marginBottom: '16px',
}));

const typeLocations = [
    { Etattype: 10, "type": "Hors Carburant" }, { Etattype: 20, "type": "Avec Carburant" }, { Etattype: 30, "type": "Avec Carburant avec Condition" }
];

const suggestions = [
    { id: 1, marque: "Toyota", model: 'LC 300', categorie: "4x4" },
    { id: 2, marque: "Toyota", model: 'RAV4', categorie: "4x4" },
    { id: 3, marque: "Renault", model: 'Clio', categorie: "Plaisir" },
    { id: 4, marque: "Mercedes", model: 'Sprinter', categorie: "Familial" },
    { id: 5, marque: "Ford", model: 'Raptor', categorie: "4x4" },
    { id: 6, marque: "BMW", model: 'X5', categorie: "4x4" },
    { id: 7, marque: "Toyota", model: 'LX', categorie: "4x4" },
    { id: 8, marque: "ISUZU", model: 'SUV', categorie: "BUS" }
];

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const AjoutvehiculeComponent = () => {

    // const navigate = useNavigate();

    const [veh, setVehicule] = useState({
        id_model: 0,
        immatricule: "",
        description: '',
        prix: 0,
        typeLocation: 0,
        condition: '',
        places: 0,
        images: []
    }
    );

    function handleChange(e) {
        setVehicule({ ...veh, [e.target.name]: e.target.value });
        if (e.target.name === "typeLocation") {
            if (e.target.value === 30) {
                setType(false);
            } else {
                setType(true)
            }

        }
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                const tabImage = [...veh.images];
                tabImage.push(base64);
                setVehicule({ ...veh, images: tabImage });
            };
            reader.readAsDataURL(file);
        }
    }

    const ajouterVehicule = async () => {
        console.log(JSON.stringify(veh))
        const token = localStorage.getItem("token");
        // console.log(veh);
        console.log(url['url-api'] + "admin/vehicule")
        fetch(url['url-api'] + "admin/vehicule", {
            method: 'POST',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(veh)
        })
            .then(response => response.json())
            .then(data => {

                if (data.errorInfo) {
                    OpenModal();
                } else {
                    // window.location.href = "/entreprise/locations/liste"
                }
            })
            .catch(error => { console.log("ERROR", error) })

    }

    const [modeleVs, setModels] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [categories, setCategories] = useState([]);
    const [type, setType] = useState(true);

    //GET MODEL MARQUE 

    const getModelsAPI = () => {
        const token = localStorage.getItem("token");
        console.log(url['url-api'] + "admin/model-vehicule")
        fetch(url['url-api'] + "admin/model-vehicule", {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        }).then(response => response.json())
            .then(data => {
                console.log(data)
                setModels(data)
            })
            .catch(error => { console.log(error) })
    }

    const getCategories = () => {
        fetch(url['url-api'] + "admin/categories-vehicules", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => { setCategories(data) })
            .catch(error => { console.log(error) })
    }

    ///================

    useEffect(() => {
        getModelsAPI();
        getCategories();
    }, [])

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedOption(newValue);
        // alert(newValue.id)
        if (newValue != null) {
            veh.id_model = newValue.id;
        }
    };

    const [showModalUpdate, setShowModalUpdate] = useState(false);

    const OpenModal = () => {
        setShowModalUpdate(true)
    }
    const CloseModal = () => {
        setShowModalUpdate(false)
    }

    return (
        <div>
            <Grid container spacing={6}>
                <Grid item lg={3} md={3} sm={12} xs={12} sx={{ mt: 2 }}></Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                    {/* <h1>Ajout d'une nouvelle Vehicule</h1> */}
                    <SimpleCard title={'Ajout d\'une nouvelle Vehicule'}>

                        <Grid>
                            <Fragment>
                                <AutoComplete
                                    options={modeleVs}
                                    getOptionLabel={(option) => option.marquevoiture.marque + " " + option.model}
                                    onChange={handleAutocompleteChange}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField {...params} label="marque , model et categorie voiture" variant="outlined" fullWidth />
                                    )}
                                />
                            </Fragment>
                            <Fragment>
                                <AutoComplete
                                    options={categories}
                                    getOptionLabel={(option) => option.categorie_voiture}
                                    onChange={(e, newVal, type) => handleAutocompleteChange(e, newVal, 'categorie')}
                                    size="small"
                                    width="50px"
                                    // autowidth
                                    renderInput={(params) => (
                                        <TextField {...params} label="categorie" variant="outlined" fullWidth />
                                    )}
                                />
                            </Fragment>
                        </Grid>

                        <TextField
                            fullWidth
                            size='small'
                            name="places"
                            type="number"
                            label="places"
                            value={veh.places}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            size='medium'
                            name="description"
                            type="text"
                            label="description"
                            value={veh.description}
                            onChange={handleChange}
                            multiline
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />

                        <TextField
                            fullWidth
                            size='small'
                            name="immatricule"
                            type="text"
                            label="Immatriculation"
                            value={veh.immatricule}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            size='small'
                            name="prix"
                            type="number"
                            label="Prix"
                            value={veh.prix}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <InputLabel filled id="demo-simple-select-autowidth-label">Type de Location</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name='typeLocation'
                            onChange={handleChange}
                            value={veh.typeLocation}
                            autoWidth
                            label="Categorie Vehicules"
                        >

                            {typeLocations.map((typeLocation, index) => (
                                <MenuItem key={index} value={typeLocation.Etattype}>{typeLocation.type}</MenuItem>
                            ))}
                        </Select>
                        <Box sx={{ py: '12px' }} />
                        {/* </Grid> */}
                        <div hidden={type}>
                            <TextField
                                fullWidth
                                size='medium'
                                name="condition"
                                type="text"
                                label="condition"
                                value={veh.condition}
                                onChange={handleChange}
                                multiline
                                variant="outlined"
                                sx={{ mt: 1.5, mb: 2 }}
                            />
                        </div>
                        <Box sx={{ py: '12px' }} />
                        <label htmlFor="contained-button-file">
                            <Fab component="span" style={{ zIndex: '0' }} size="small" color="primary">
                                <Icon>photo</Icon>
                            </Fab>
                        </label>
                        <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />

                        {veh.images.length !== 0 &&

                            <ImageList sx={{ width: '100%', height: 200 }} cols={3} rowHeight={164}>
                                {veh.images.map((img, index) => (
                                    <div key={index}>
                                        <ImageListItem>
                                            <img
                                                src={`${img}`}
                                                alt={`Reparation img ${index}`}
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    </div>
                                ))}
                            </ImageList>

                        }

                        <StyledButton onClick={ajouterVehicule} fullWidth variant="contained" component="span">
                            Ajouter
                        </StyledButton>

                    </SimpleCard>
                </Grid>
            </Grid>
            {/* MODAL VALIDATION */}
            <ModalGenerer show={showModalUpdate}>
                <h1>Erreur</h1>
                <h3 style={{ color: 'red' }}>L'immatriculation que vous avez entré est déjàt exister</h3>
                <DialogActions>
                    <Button onClick={CloseModal}>
                        fermer
                    </Button>
                </DialogActions>
            </ModalGenerer>
            {/* MODAL VALIDATION */}
        </div >

    );
};

export default AjoutvehiculeComponent;