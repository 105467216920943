import { Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TablePagination, TextField, Typography } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const TypeReparationComponent = () => {

    const [typereparations, setTypeReparation] = useState(null);

    function getTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typereparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTypeReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTypeReparation();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [createTypeReparation, setCreateTypeReparation] = useState(
        {
            nom: 'Refroidissement',
            couleur: '#000000'
        }
    );

    function handleCreateTypeReparationChange(e) {
        setCreateTypeReparation({ ...createTypeReparation, [e.target.name]: e.target.value });
    }


    // modif typeReparation
    const [modifTypeReparation, setModifTypeReparation] = useState(null);

    // modal create
    const [openModalCreation, setOpenModalCreation] = useState(false);
    const handleCloseModalCreation = () => setOpenModalCreation(false);

    function ajouterTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typereparations`,
            method: 'POST',
            data: createTypeReparation
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setTypeReparation(null);
                getTypeReparation();
            }
        }).catch((e) => {
            console.log(e);
        })
        handleCloseModalCreation();
    }

    // modal edit
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    function displayEditModal(typeReparation) {
        setModifTypeReparation(typeReparation);
        setOpenModalEdit(true);
    }

    function modifierTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typereparations/${modifTypeReparation.id}`,
            method: 'PUT',
            data: modifTypeReparation
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setTypeReparation(null);
                getTypeReparation();
            }
        }).catch((e) => {
            console.log(e);
        })
        handleCloseModalEdit();
    }

    // modal delete
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    function displayDeleteModal(typeReparation) {
        setModifTypeReparation(typeReparation);
        setOpenModalDelete(true);
    }

    function supprimerTypeReparation() {
        handleCloseModalDelete();
    }

    return (
        <>
            <SimpleCard title={'Types de réparation'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
                <IconButton onClick={() => setOpenModalCreation(true)}>
                    <Icon color='primary'>add</Icon>
                </IconButton>
                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={typereparations ? typereparations.length : 0}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 4, 8, 12]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box sx={{ mt: 3 }} width={'100%'}>
                {
                    typereparations ?
                        <>
                            <Grid container spacing={3}>
                                {typereparations
                                    .filter((typereparation) =>
                                        typereparation.nom.toLowerCase().includes(recherche)
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((typereparation, index) =>
                                        <Grid key={index} item lg={3} md={3}>
                                            <Card sx={{ maxWidth: 345 }}>

                                                <CardContent>
                                                    <Typography gutterBottom sx={{ textAlign: 'center' }} variant="h5" component="div">
                                                        {typereparation.nom}
                                                    </Typography>
                                                </CardContent>

                                                <CardActions>
                                                    <IconButton onClick={() => displayEditModal(typereparation)}>
                                                        <Icon color='success'>edit</Icon>
                                                    </IconButton>
                                                    <IconButton onClick={() => (displayDeleteModal(typereparation))}>
                                                        <Icon color='error'>delete</Icon>
                                                    </IconButton>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )}
                            </Grid>
                        </>
                        :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                }

            </Box>


            {
                modifTypeReparation &&
                <>
                    {/* modal edit */}
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier
                        </DialogTitle>

                        <DialogContent dividers>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='nom'
                                value={modifTypeReparation.nom}
                                onChange={(e) => setModifTypeReparation({ ...modifTypeReparation, nom: e.target.value })}
                                label='Nom'
                                sx={{ mt: 2 }}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>
                            <Button color='success' onClick={modifierTypeReparation}>
                                Modifier
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}

                    {/* modal delete */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            Retirer {modifTypeReparation.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={supprimerTypeReparation} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}
                </>
            }

            {/* modal create */}
            <Dialog onClose={handleCloseModalCreation} aria-labelledby="customized-dialog-title" open={openModalCreation}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalCreation}>
                    Ajout de nouvelle type de réparation
                </DialogTitle>

                <DialogContent dividers>
                    <TextField
                        fullWidth
                        type='text'
                        variant='outlined'
                        size='small'
                        name='nom'
                        value={createTypeReparation.nom}
                        onChange={handleCreateTypeReparationChange}
                        label='Nom'
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        type='color'
                        variant='outlined'
                        size='small'
                        name='couleur'
                        value={createTypeReparation.couleur}
                        onChange={handleCreateTypeReparationChange}
                        label='Code couleur'
                        sx={{ mt: 2 }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalCreation}>
                        Fermer
                    </Button>
                    <Button color='success' onClick={ajouterTypeReparation}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            {/* */}
        </>
    );
};

export default TypeReparationComponent;