import { Card, CardHeader, TextField } from '@mui/material';
import React from 'react';

const SocialComponent = ({
    accueil,
    setAccueil
}) => {

    const socialNetworks = [
        {
            name: "facebook",
            label: "Compte/Page Facebook",
            type: "text"
        },
        {
            name: "instagram",
            label: "Compte/Page Instagram",
            type: "text"
        },
        {
            name: "youtube",
            label: "Compte Youtube",
            type: "text"
        },
        {
            name: "twitter",
            label: "Compte/Page Twitter",
            type: "text"
        },
        {
            name: "contact",
            label: "Numéro de téléphone",
            type: "text"
        },
        {
            name: "email",
            label: "Adresse mail",
            type: "email"
        },
    ];

    const handleChangeAccueil = (event) => {
        setAccueil({ ...accueil, [event.target.name]: event.target.value });
    }

    return (
        <>
            <Card sx={{ m: 3 }}>
                <CardHeader title={`Réseaux sociaux`} />
                {
                    socialNetworks.map((socialNetwork, index) =>
                        <TextField
                            key={index}
                            sx={{ m: 2, pr: 5 }}
                            fullWidth
                            size='small'
                            name={socialNetwork.name}
                            type={socialNetwork.type}
                            label={socialNetwork.label.toLocaleUpperCase()}
                            value={accueil[socialNetwork.name]}
                            onChange={handleChangeAccueil}
                        />
                    )
                }
            </Card>
        </>
    );
};

export default SocialComponent;