import { Box, Button, Card, CardMedia, DialogActions, Fab, FormControlLabel, Icon, Radio, RadioGroup, TextField, Typography, styled } from '@mui/material';
import url from 'app/UrlConfig';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import ModalGenerer from '../utils/ModalGenerer';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const AjoutChauffeurComponent = () => {

    const navigate = useNavigate();
    const [messageEntete, setMessageEntete] = useState('Ajout Nouveau Chauffeur');
    const [message, setMessage] = useState('Vous etes sure d\'ajouter le nouveau chauffeur');
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("token"))

    const OpenModal = () => {
        setShowModalUpdate(true)
    }
    const CloseModal = () => {
        setShowModalUpdate(false)
    }

    const [genres, setGenre] = useState([
        {
            id: 1,
            nom: 'Homme'
        },
        {
            id: 2,
            nom: 'Femme'
        }
    ]);

    const [initialValues, setInitialValue] = useState(
        {
            nom: 'RAKOTO',
            prenom: 'Dadatoa',
            datenaissance: '1997-01-01',
            idgenre: 1,
            images: '',
            email: 'dadatoa@gmail.com',
            contact: ['+261'],
            idreparation: 0,
            salaire: 200000
        }
    );

    function changeInitialValue(e) {
        setInitialValue({ ...initialValues, [e.target.name]: e.target.value });
    }

    function addContact() {
        const contactData = [...initialValues.contact];
        contactData.push('+261');
        setInitialValue({ ...initialValues, contact: contactData });
    }

    function handleContactChange(e, index) {
        const contactData = [...initialValues.contact];
        contactData[index] = e.target.value;
        setInitialValue({ ...initialValues, contact: contactData });
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                setInitialValue({ ...initialValues, images: base64 });
            };
            reader.readAsDataURL(file);
        }
    }

    const addChauffeur = () => {
        OpenModal();
    }

    const Validation = () => {
        fetch(url['url-api'] + "admin/chauffeur", {
            method: 'POST',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(initialValues)
        })
            .then(response => response.json())
            .then(data => {
                setMessageEntete("Validation ajout chauffeur")
                if (data.etat == 200) {
                    setMessage("Le chauffeur est bien Enregistré. ");
                    OpenModal();
                    navigate('/entreprise/location/chauffeur/liste');
                } else {
                    setMessage("Error : " + data.message);
                    OpenModal();
                }
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <SimpleCard title={'Ajout d\'un nouveau chauffeur'}>

                <label htmlFor="contained-button-file">
                    <StyledButton sx={{ mb: 1 }} variant="outlined" component="span">
                        <Icon>photo_camera</Icon>Ajouter votre image
                    </StyledButton>
                </label>
                <input onChange={handleFileChange} accept="image/*" style={{ visibility: 'hidden' }} className="input" id="contained-button-file" multiple type="file" />
                {initialValues.images &&
                    <>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={initialValues.images}
                                title="Votre profil"
                            />
                        </Card>
                        <br />
                    </>
                }
                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    name="nom"
                    label="Votre nom"
                    variant="outlined"
                    value={initialValues.nom}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    name="prenom"
                    label="Votre prenom"
                    variant="outlined"
                    value={initialValues.prenom}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                <Box sx={{ mb: 3 }}>
                    <Typography>Genre</Typography>
                    <RadioGroup
                        className="group"
                    >
                        {genres.map((genre, index) => (
                            <FormControlLabel key={index} name='idgenre' onChange={changeInitialValue} value={genre.id} control={< Radio />} label={genre.nom} />
                        ))}
                    </RadioGroup>
                </Box>

                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    name="datenaissance"
                    label="Votre date de naissance"
                    variant="outlined"
                    value={initialValues.datenaissance}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                <TextField
                    fullWidth
                    size="small"
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    value={initialValues.email}
                    onChange={changeInitialValue}
                    sx={{ mb: 3 }}
                />

                {initialValues.contact.map((value, index) => (
                    <div key={index}>
                        <TextField
                            fullWidth
                            size="small"
                            type="tel"
                            name="contact"
                            label="Contact"
                            variant="outlined"
                            value={value}
                            sx={{ mb: 1 }}
                            onChange={(e) => handleContactChange(e, index)}
                        /></div>
                ))}

                <Fab style={{ zIndex: '0' }} sx={{ mb: 1.5 }} onClick={addContact} size="small" color="primary" aria-label="Add" className="button">
                    <Icon>add</Icon>
                </Fab>

                {/* <Link to={'/entreprise/reparation/personnels/ajout/affectation'} state={{ personnel: initialValues }}> */}
                <StyledButton onClick={addChauffeur} fullWidth variant="contained" component="span">
                    Ajouter
                </StyledButton>
                {/* </Link> */}

            </SimpleCard>
            {/* MODAL VALIDATION */}
            <ModalGenerer show={showModalUpdate}>
                <h1>{messageEntete}</h1>
                <h3>{message}</h3>
                <DialogActions>
                    <NavLink
                        onClick={Validation}
                    >
                        Valider
                    </NavLink>

                    <Button onClick={CloseModal}>
                        annuler
                    </Button>
                </DialogActions>
            </ModalGenerer>
            {/* MODAL VALIDATION */}
        </>
    );
};

export default AjoutChauffeurComponent;