import AjoutActivite from "./views/myViews/Nosybe/Activite/AjoutActivite";
import ListeActivitePage from "./views/myViews/Nosybe/Activite/ListeActivitePage";
import EntrepriseValidation from "./views/myViews/Nosybe/EntrepriseValidation";
import AjoutEquipement from "./views/myViews/Nosybe/Equipement/AjoutEquipement";
import ListEquipement from "./views/myViews/Nosybe/Equipement/ListEquipement";
import AjoutEvenement from "./views/myViews/Nosybe/Evenement/AjoutEvenement";
import ListEvenementPage from "./views/myViews/Nosybe/Evenement/ListEvenementPage";
import AjoutHebergement from "./views/myViews/Nosybe/Hebergement/AjoutHebergement";
import ListHebergement from "./views/myViews/Nosybe/Hebergement/ListHebergement";
import AjoutIles from "./views/myViews/Nosybe/Iles/AjoutIles";
import ListIles from "./views/myViews/Nosybe/Iles/ListIles";
import AjoutRestaurant from "./views/myViews/Nosybe/Restaurant/AjoutRestaurant";
import ListeRestaurantPage from "./views/myViews/Nosybe/Restaurant/ListeRestaurantPage";
import Accueil from "./views/myViews/VentePiece/Publication/Accueil";
import AjoutExcursion from "./views/myViews/Nosybe/Excursion/AjoutExcursion";
import ListExcursion from "./views/myViews/Nosybe/Excursion/ListExcursion";
import AjoutArticle from "./views/myViews/Nosybe/Article/AjoutArticle";
import ListArticle from "./views/myViews/Nosybe/Article/ListArticle";
import Profil from "./views/myViews/Reparation/Profil/Profil";

const routesPiece = [

    //Profile
    {
        path: '/entreprise/profile',
        element: <Profil />,
    },

    //Accueil
    {
        path: '/entreprise/accueil',
        element: <Accueil />
    },
    //iles
    // {
    //     path: '/entreprise/iles/ajout',
    //     element: <AjoutIles />
    // },
    // {
    //     path: '/entreprise/iles/list',
    //     element: <ListIles />
    // },
    //Article
    {
        path: '/entreprise/article/ajout',
        element: <AjoutArticle />
    },
    {
        path: '/entreprise/article/list',
        element: <ListArticle />
    },
    //excursion
    {
        path: '/entreprise/excursion/ajout',
        element: <AjoutExcursion />
    },
    {
        path: '/entreprise/excursion/list',
        element: <ListExcursion />
    },

    //Hebergement
    {
        path: '/entreprise/hebergement/ajout',
        element: <AjoutHebergement />
    },
    {
        path: '/entreprise/equipement/ajout',
        element: <AjoutEquipement />
    },
    {
        path: '/entreprise/equipement/list',
        element: <ListEquipement />
    },
    {
        path: '/entreprise/hebergement/list',
        element: <ListHebergement />
    },

    {
        path: '/entreprise/validation',
        element: <EntrepriseValidation />
    },

    {
        path: 'entreprise/activity/ajout',
        element: <AjoutActivite />
    },
    {
        path: 'entreprise/activity/list',
        element: <ListeActivitePage />
    },

    {
        path: 'entreprise/restaurant/ajout',
        element: <AjoutRestaurant />
    },
    {
        path: 'entreprise/restaurant/list',
        element: <ListeRestaurantPage />
    },

    {
        path: 'entreprise/evenement/ajout',
        element: <AjoutEvenement />
    },
    {
        path: 'entreprise/evenement/list',
        element: <ListEvenementPage />
    }
];
export default routesPiece;