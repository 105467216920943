import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import MarqueChangementComponent from 'app/myComponents/VentePiece/Admin/MarqueChangementComponent';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const MarqueChangement = () => {
    document.title = "Changement Marque";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Vente Piece' },
                        { name: 'Marques', path: '/admin/pieces/marques' },
                        { name: 'Changement' }
                    ]}
                />
            </Box>

            <MarqueChangementComponent />

        </AppButtonRoot>
    )
}
export default MarqueChangement;