import { Button, Tab, Tabs, TextField } from '@mui/material';
import { getOperateur, validateOperateur } from 'app/api/description/description';
import { SimpleCard } from 'app/components';
import { displayToast } from 'app/data/piece/dispatch';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const OperateurComponent = () => {
    const [operateur, setOperateur] = useState({
        hebergement: { "fr": "", "eng": "", "ita": "" },
        restaurant: { "fr": "", "eng": "", "ita": "" },
        activite: { "fr": "", "eng": "", "ita": "" },
        evenement: { "fr": "", "eng": "", "ita": "" },
        // "prestataire d'excursion": { "fr": "", "eng": "", "ita": "" },
    });
    const keysOperateur = Object.keys(operateur);
    const languages = [{ "name": "eng", "abbrev": "ENG" }, { "name": "fr", "abbrev": "FR" }, { "name": "ita", "abbrev": "ITA" }];
    const [tabNameValue, setTabNameValue] = useState(0);
    const [languageChoose, setLanguageChoose] = useState("eng");
    const [isUpdate, setIsUpdate] = useState(false);

    const handleChangeTabNameValue = (event, newValue) => {
        // console.log(newValue, " language ", languages[newValue].name, " SHOW == ", presentation.description[languages[newValue]?.name])
        setTabNameValue(newValue);
        setLanguageChoose(languages[newValue]?.name);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeDescription = (event) => {
        // console.log(languageChoose);
        setOperateur({ ...operateur, [event.target.name]: { ...operateur[event.target.name], [languageChoose]: event.target.value } })
    }

    const getOperateurData = () => {
        getOperateur().then((response) => {
            let data = response.data;
            if (data) {
                if (data.operateur) {
                    let operateurs = data.operateur;
                    if (operateurs.length > 0) {
                        formatOperateurData(operateurs);
                    }
                }
            }
        }).catch((e) => {
            displayToast("Une erreur est survenue : " + e.message);
        });
    }

    const formatOperateurData = (listOperateur) => {
        let operateurTmp = {};
        listOperateur.forEach(operateurItem => {
            operateurTmp[operateurItem.operateur] = {
                "fr": operateurItem.fr,
                "eng": operateurItem.eng,
                "ita": operateurItem.ita,
            }
        });
        setIsUpdate(true);
        setOperateur(operateurTmp);
    }

    const validate = () => {
        validateOperateur(operateur, isUpdate).then((response) => {
            displayToast(response.data.message, 'success');
        }).catch((e) => {
            // console.log('Error validateOperateur : ', e);
            displayToast("Une erreur est survenue : " + e.message);
        })
    }

    useEffect(() => {
        getOperateurData();
    }, []);

    return (
        <>
            <SimpleCard title={`Description des opérateurs`}>
                <Tabs
                    sx={{ mt: 1.5, mb: 2 }}
                    value={tabNameValue}
                    onChange={handleChangeTabNameValue}
                    aria-label="basic tabs example"
                >
                    {languages?.map((language, index) =>
                        <Tab key={language.abbrev} label={language.name} {...a11yProps(index)} />
                    )}
                </Tabs>

                {keysOperateur.map((keyOperateurs, index) => {
                    return (
                        <TextField
                            key={index}
                            multiline
                            fullWidth
                            size='small'
                            name={keyOperateurs}
                            type="text"
                            label={keyOperateurs.toUpperCase()}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            value={operateur[keyOperateurs][languageChoose] ? operateur[keyOperateurs][languageChoose] : ''}
                            onChange={(e) => handleChangeDescription(e)}
                        />
                    );

                })}
                <Button
                    fullWidth
                    variant='contained'
                    sx={{ mt: 1.5, mb: 2 }}
                    onClick={() => validate()}
                >Valider</Button>
            </SimpleCard>
        </>
    );
};

export default OperateurComponent;