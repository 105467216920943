import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import ListePersonnelComponent from 'app/myComponents/Repair/Personnel/ListePersonnelComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));


const ListPersonnel = () => {
    document.title = "LISTE-PERSONNEL";

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[{ name: 'Personnels', path: '/entreprise/reparation/personnels/liste' }, { name: 'Liste' }]}
                />
            </Box>

            <ListePersonnelComponent></ListePersonnelComponent>

        </AppButtonRoot>
    );
};

export default ListPersonnel;