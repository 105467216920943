// const host = "http://localhost:8000"
// const host = "https://nosybe.aiolia.us"
const host = "https://destination-nosybe.com"
// const host = process.env.BACKEND_URL

const apiHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
}

const myConfig = {
    host,
    apiHeaders,
}


export default myConfig;