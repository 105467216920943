import { Alert, Box, Button, DialogActions, Fab, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { useEffect, useState } from "react";
import ModalGenerer from "../utils/ModalGenerer";
import url from "app/UrlConfig";
const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));


const CategorieVoiture = () => {

    const [rechercheEntreprise, setRechercheEntreprise] = useState({
        recherche: '',
        etat: 11
    });


    const [categorieDelete, setcategorieDelete] = useState();
    const [categorieUpdate, setCategorieUpdate] = useState();
    const [message, setMessage] = useState('');
    const [stateLance, setStateLance] = useState(0);
    const [severity, setSeverity] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [categorieAjout, setCategorieAjout] = useState({
        id: 0,
        categorie_voiture: 'categorie voiture'
    });
    const [categories, setCategorie] = useState([]);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [typeModal, setTypeModal] = useState("Ajout");

    const OpenModalAjout = () => {
        setShowModalUpdate(true);
    }

    const CloseModalAjout = () => {
        setShowModalUpdate(false)
    }

    const handleChange = (e) => {
        setCategorieAjout({ ...categorieAjout, [e.target.name]: e.target.value })
    }
    const handleChangeUpdate = (e) => {
        setCategorieUpdate({ ...categorieUpdate, [e.target.name]: e.target.value })
    }

    const AjouteCategorie = () => {
        console.log(categorieAjout);
        fetch(url["url-api"] + "admin/demande/categorie", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(categorieAjout)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status === 200) {
                    setMessage('Votre demande d\'ajouter un categorie est envoyé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                    setStateLance(stateLance + 1)
                } else {
                    setSeverity('error');
                    setMessage('Categorie déjat existé')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                }
            })
            .catch(error => console.log(error))
    }

    const getCategories = () => {
        fetch(url["url-api"] + "admin/categories-vehicules", {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(reponse => reponse.json())
            .then(data => {
                console.log(data)
                setCategorie(data)
            })
            .catch(error => console.log(error))
    }

    const OpenModalUpdateDelete = (categorie, typeAction) => {
        setTypeModal(typeAction);
        console.log(categorie);
        if (typeAction === "DELETE") {
            setcategorieDelete(categorie);
        } if (typeAction === "UPDATE") {
            setCategorieUpdate(categorie);
        }
        OpenModalAjout();
    }


    const Delete = () => {
        fetch(url["url-api"] + "admin/categorie/" + categorieDelete.id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status == 200) {
                    setMessage('Suppression Succés')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                    setStateLance(stateLance + 1);
                } if (data.status == 500) {
                    setSeverity('error');
                    setMessage('Suppression n\'est pas succés')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                    setStateLance(stateLance + 1)
                }
            })
            .catch(error => console.log(error))
    }

    const Update = () => {
        fetch(url["url-api"] + "admin/demande/categorie", {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(categorieUpdate)
        })
            .then(reponse => reponse.json())
            .then(data => {
                if (data.status == 200) {
                    setMessage('Demande modification Succés')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                    setStateLance(stateLance + 1);
                } if (data.status == 500) {
                    setSeverity('error');
                    setMessage('Modification n\'est pas succés')
                    setOpenSnackbar(true)
                    setShowModalUpdate(false)
                    setStateLance(stateLance + 1)
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getCategories();
    }, [stateLance])

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }
    return (

        <div>
            <SimpleCard>

                <Fab onClick={() => OpenModalUpdateDelete(categorieAjout, "Ajout")} color="primary" aria-label="Add" className="button">
                    <Icon>add</Icon>
                </Fab>
                <Box sx={{ py: '12px' }} />
                Ajout Nouveau categorie
                <Box sx={{ py: '12px' }} />
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={rechercheEntreprise.recherche}
                    onChange={(e) => setRechercheEntreprise({ ...rechercheEntreprise, recherche: e.target.value.toLowerCase() })}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
            </SimpleCard>
            <Box sx={{ py: '12px' }} />
            <SimpleCard title={'Liste des Catégorie'}>
                <Box width="100%" overflow="auto">
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="center">Categorie</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {categories
                                .filter((categorie) => categorie.categorie_voiture.toLowerCase().includes(rechercheEntreprise.recherche))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((categorie, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell>{categorie.id}</TableCell>
                                        <TableCell align="center">{categorie.categorie_voiture}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => OpenModalUpdateDelete(categorie, "UPDATE")} >
                                                <Icon color="success">edit</Icon>
                                            </IconButton>
                                            <IconButton onClick={() => OpenModalUpdateDelete(categorie, "DELETE")} >
                                                <Icon color="error">delete</Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </StyledTable>
                    <TablePagination
                        sx={{ px: 2 }}
                        page={page}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={categories.length}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </Box>
            </SimpleCard>
            {/* MODAL UPDATE */}
            <ModalGenerer show={showModalUpdate}>
                {typeModal === "Ajout" && (
                    <SimpleCard title={'Ajout d\'une nouvelle Categorie'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="categorie_voiture"
                            type="text"
                            label="Categorie Voiture"
                            value={categorieAjout.categorie_voiture}
                            onChange={handleChange}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={AjouteCategorie}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}
                {typeModal === "DELETE" && (
                    <div>
                        <h1>Confirmation suppression</h1>
                        <h3>
                            Vous etes sure de Supprimer le Categorie {categorieDelete.categorie_voiture}
                        </h3>
                        <DialogActions>
                            <Button onClick={Delete}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </div>
                )}
                {typeModal === "UPDATE" && (
                    <SimpleCard title={'Update un Marque'}>
                        <TextField
                            fullWidth
                            size='small'
                            name="categorie_voiture"
                            type="text"
                            label="Categorie Voiture"
                            value={categorieUpdate.categorie_voiture}
                            onChange={handleChangeUpdate}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                        />
                        <DialogActions>
                            <Button onClick={Update}>
                                Valider
                            </Button>
                            <Button onClick={CloseModalAjout}>
                                fermer
                            </Button>
                        </DialogActions>
                    </SimpleCard>
                )}

            </ModalGenerer>
            {/* MODAL UPDATE */}
            {/* MODAL DELETE*/}

            {/* MODAL DELETE FIN* */}
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={
                    {
                        "horizontal": "right",
                        "vertical": "top"
                    }
                }
            >
                <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );

}

export default CategorieVoiture;