import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FormExcursion from './FormExcursion';
import { useEffect } from "react";
import { useState } from "react";
import { getListeExcursionDetail } from "./API-Excursion";


const ModalEditExcursion = ({ Excursion, setExcursion, open, handleClose }) => {

    console.log(Excursion, " Excursion")

    const [ExcursionUpdate, setExcursionUpdate] = useState(Excursion);


    const getExcursionDetailFX = async () => {
        await getListeExcursionDetail(Excursion.id, setExcursionUpdate);
    }

    useEffect(() => {
        //getExcursionDetailFX();
    }, [])

    return (
        <Dialog maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modification
            </DialogTitle>

            <DialogContent dividers>
                {ExcursionUpdate && (
                    <FormExcursion selectedExcursion={ExcursionUpdate} setExcursions={setExcursion} closeForm={handleClose} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalEditExcursion;