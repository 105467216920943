import ActivityDescription from "./views/myViews/Nosybe/Accueil/ActivityDescription";
import OperateurDescription from "./views/myViews/Nosybe/Accueil/OperateurDescription";
import Accueil from "./views/myViews/VentePiece/Publication/Accueil";

const routesNosyBe = [

    //Accueil
    {
        path: '/nosybe/description-operateurs',
        element: <OperateurDescription />
    },
    {
        path: '/nosybe/description-activites',
        element: <ActivityDescription />
    },

];
export default routesNosyBe;