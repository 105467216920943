import { styled } from '@mui/material';
import ReparationEvolutionComponent from 'app/myComponents/Repair/Dashboard/ReparationEvolutionComponent';
import React from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));


const ReparationEvolution = () => {
    document.title = "DASHBOARD";

    return (
        <>
            <AppButtonRoot>
                <ReparationEvolutionComponent
                    height="350px"
                />
            </AppButtonRoot>
        </>
    );
};

export default ReparationEvolution;
