import url from "app/UrlConfig";
import axios from "axios";


export const SaveEquipementAPI = async (equipement, setMessage, setVisible, setSeverity) => {
    try {
        fetch(url["url-api"] + "equipement", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(equipement)
        })
            .then(respose => respose.json())
            .then(data => {
                if (data.status === 200) {
                    setSeverity('success');
                    setMessage("Ajout Equipement avec success");
                    setVisible(true);
                    return
                } else {
                    setSeverity('error');
                    setMessage("Une erreur est survenue " + data.message);
                    setVisible(true);
                }
            })
            .catch((error) => {
                console.log(error)
                setMessage("Une erreur est survenue ");
                setVisible(true);
            })
    } catch (error) {

    }
}

export const getEquipement = async (offset, limit, search, setEquipements, setNumberLigne) => {
    // console.log(url["url-api"] + "equipement-get/" + offset + "/" + limit + "/" + search)
    return new Promise((resolve, reject) => {
        try {
            fetch(url["url-api"] + "equipement-get/" + offset + "&&" + limit + "&&" + search, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data.data)
                    if (data.status === 200) {
                        // console.log(data.data)
                        setEquipements(data.data);
                        setNumberLigne(data.LigneNumber);
                        resolve(data.data);
                        return data.data
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })
        } catch (error) {
            reject(error)
        }
    })

}

export const SaveRestaurantAPI = async (restaurant, setMessage, setVisible, setSeverity) => {

    await axios.post(url["url-api"] + "restaurant", restaurant)
        .then(resp => {
            console.log(resp.data)
            if (resp.data.status === 200) {
                setSeverity('success');
                setMessage("Ajout Equipement avec success");
                setVisible(true);


            } else {
                setSeverity('error');
                setMessage("Une erreur est survenue " + resp.data.message);
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            // setMessage("Une erreur est survenue ");
            // setVisible(true);
        })
}