import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, TablePagination, TextField, Typography } from '@mui/material';
import { SimpleCard } from 'app/components';
import { getMarquesChangements } from 'app/data/piece/changement';
import { useEffect } from 'react';
import { useState } from 'react';

const MarqueChangementComponent = () => {
    const [marqueChangements, setMarqueChangements] = useState([]);

    const [changement, setChangement] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    //page handling
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modal
    const handleCloseModal = () => setOpenModal(false);
    const displayModal = (changement) => {
        setChangement(changement);
        setOpenModal(true);
    }
    const validerChangement = () => {
        handleCloseModal();
    }


    useEffect(() => {
        setMarqueChangements(getMarquesChangements());
    }, [])

    return (
        <>
            <SimpleCard title={'Les Changements de categorie par les entreprises'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />

                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={marqueChangements.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box width={'100%'} sx={{ mt: 3 }}>
                <Grid container spacing={3}>
                    {marqueChangements
                        .filter((marqueChangement) =>
                            marqueChangement.entreprise.nom.toLowerCase().includes(recherche) ||
                            marqueChangement.nom.toLowerCase().includes(recherche)
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((marqueChangement, index) =>
                            <Grid item lg={4} md={4} key={index}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar src={marqueChangement.entreprise.logo} alt='' />
                                        }
                                        title={`${marqueChangement.entreprise.nom}`}
                                        subheader={`${marqueChangement.entreprise.email}`}
                                    />
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Actuel :</Typography>
                                                {marqueChangement.marque &&
                                                    <>
                                                        <Typography variant='body1'>{marqueChangement.marque.souscategorie.nom}</Typography>
                                                        <Typography variant='body1'>{marqueChangement.marque.nom}</Typography>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item lg={6} md={6}>
                                                <Typography gutterBottom variant='subtitle1'>Changement :</Typography>
                                                <Typography gutterBottom variant='body1'>{marqueChangement.souscategorie.nom}</Typography>
                                                <Typography gutterBottom variant='body1'>{marqueChangement.nom}</Typography>
                                                <Typography gutterBottom variant='body1'>Le : {marqueChangement.date}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardActions sx={{ justifyContent: 'right' }}>
                                        <IconButton onClick={() => displayModal(marqueChangement)}>
                                            <Icon color='success'>check</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>

            {changement &&
                <>
                    {/* modal delete */}
                    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                            Validation de changement
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur de valider le changement de {changement.entreprise.nom} le {changement.date}?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={validerChangement} color='success'>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}
                </>
            }
        </>
    )
}
export default MarqueChangementComponent;