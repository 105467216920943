import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FormIles from './FormIles';
import { useEffect } from "react";
import { useState } from "react";
import { getListeIlesDetail } from "./API-Iles";


const ModalEditIles = ({ Iles, setIles, open, handleClose }) => {

    console.log(Iles, " Iles")

    const [IlesUpdate, setIlesUpdate] = useState(Iles);


    const getIlesDetailFX = async () => {
        await getListeIlesDetail(Iles.id, setIlesUpdate);
    }

    useEffect(() => {
        //getIlesDetailFX();
    }, [])

    return (
        <Dialog maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modification
            </DialogTitle>

            <DialogContent dividers>
                {IlesUpdate && (
                    <FormIles selectedIles={IlesUpdate} setIless={setIles} closeForm={handleClose} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalEditIles;