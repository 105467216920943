import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import FormRestaurant from "./FormRestaurant";
import { useState } from "react";
import { useEffect } from "react";
import { getRestaurantById } from "./API-Restaurant";

const ModalUpdateRestaurant = ({ restaurantEdit, setRestaurantEdit, open, handleClose }) => {

    console.log(restaurantEdit.id, "RYG")
    const [restaurantUpdate, setRestaurantUpdate] = useState(undefined);


    const getRestaurantByIdDetail = async () => {
        await getRestaurantById(restaurantEdit.id, setRestaurantUpdate);
    }

    useEffect(() => {
        getRestaurantByIdDetail();
    }, [])


    return (
        <Dialog maxWidth='xl' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modifier {restaurantEdit.name_fr}
            </DialogTitle>

            <DialogContent dividers>
                {restaurantUpdate && (
                    <FormRestaurant selectedRestaurant={restaurantUpdate} setrestaurant={setRestaurantEdit} closeForm={handleClose} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalUpdateRestaurant;