import React from 'react';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Radio, RadioGroup, TablePagination, TextField, Typography } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import myConfig from 'app/config/api-config';

const TypeDemandeComponent = () => {
    const [typedemandes, setTypeDemande] = useState(null);

    function getTypeDemande() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typedemandes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTypeDemande(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTypeDemande();
    }, []);

    const [recherche, setRecherche] = useState({
        label: '',
        etat: 20
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // create typeDemande
    const [createTypeDemande, setCreateTypeDemande] = useState(
        {
            nom: 'Region'
        }
    );

    function handleCreateTypeDemandeChange(e) {
        setCreateTypeDemande({ ...createTypeDemande, [e.target.name]: e.target.value });
    }

    // modif typeDemande
    const [modifTypeDemande, setModifTypeDemande] = useState(null);

    // modal create
    const [openModalCreation, setOpenModalCreation] = useState(false);
    const handleCloseModalCreation = () => setOpenModalCreation(false);

    function ajouterTypeDemande() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typedemandes`,
            method: 'POST',
            data: createTypeDemande
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setTypeDemande(null);
                getTypeDemande();
            }
        }).catch((e) => {
            console.log(e);
        })
        handleCloseModalCreation();
    }

    // modal edit
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    function displayEditModal(typeDemande) {
        setModifTypeDemande(typeDemande);
        setOpenModalEdit(true);
    }

    function modifierTypeDemande() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typedemandes/${modifTypeDemande.id}`,
            method: 'PUT',
            data: modifTypeDemande
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setTypeDemande(null);
                getTypeDemande();
            }
        }).catch((e) => {
            console.log(e);
        })
        handleCloseModalEdit();
    }

    // modal delete
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    function displayDeleteModal(typeDemande) {
        setModifTypeDemande(typeDemande);
        setOpenModalDelete(true);
    }

    function supprimerTypeDemande() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/typedemandes/valider`,
            method: 'POST',
            data: modifTypeDemande
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setTypeDemande(null);
                getTypeDemande();
            }
        }).catch((e) => {
            console.log(e);
        })
        handleCloseModalDelete();
    }

    return (
        <>
            <SimpleCard title={'Types de demandes'}>
                <Button color='primary' onClick={() => setOpenModalCreation(true)}>
                    <Icon>add</Icon>
                    Ajouter
                </Button>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche.label}
                    onChange={(e) => setRecherche({ ...recherche, label: e.target.value.toLowerCase() })}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
                <RadioGroup
                    className="group"
                    row
                >
                    <FormControlLabel value={20} onChange={(e) => setRecherche({ ...recherche, etat: parseInt(e.target.value) })} control={< Radio />} label={'Dispo'} />
                    <FormControlLabel value={10} onChange={(e) => setRecherche({ ...recherche, etat: parseInt(e.target.value) })} control={< Radio />} label={'Demande'} />
                </RadioGroup>
                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={typedemandes ? typedemandes.length : 0}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 4, 8, 12]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box sx={{ mt: 3 }} width={'100%'}>
                {
                    typedemandes ?
                        <>
                            <Grid container spacing={3}>
                                {typedemandes
                                    .filter((typeDemande) =>
                                        typeDemande.nom.toLowerCase().includes(recherche.label) &&
                                        typeDemande.etat === recherche.etat
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((typeDemande, index) =>
                                        <Grid key={index} item lg={3} md={3}>
                                            <Card sx={{ maxWidth: 345 }}>
                                                <CardContent>
                                                    <Typography gutterBottom sx={{ textAlign: 'center' }} variant="h5" component="div">
                                                        {typeDemande.nom}
                                                    </Typography>
                                                </CardContent>

                                                <CardActions>
                                                    {
                                                        typeDemande.etat === 20 ?
                                                            <>
                                                                <IconButton onClick={() => displayEditModal(typeDemande)}>
                                                                    <Icon color='success'>edit</Icon>
                                                                </IconButton>
                                                                <IconButton onClick={() => (displayDeleteModal(typeDemande))}>
                                                                    <Icon color='error'>delete</Icon>
                                                                </IconButton>
                                                            </>
                                                            :
                                                            <>
                                                                <IconButton onClick={() => displayDeleteModal(typeDemande)}>
                                                                    <Icon color='success'>check</Icon>
                                                                </IconButton>
                                                            </>
                                                    }

                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )}
                            </Grid>
                        </>
                        :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                }

            </Box>


            {
                modifTypeDemande &&
                <>
                    {/* modal edit */}
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier
                        </DialogTitle>

                        <DialogContent dividers>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='nom'
                                value={modifTypeDemande.nom}
                                onChange={(e) => setModifTypeDemande({ ...modifTypeDemande, nom: e.target.value })}
                                label='Nom'
                                sx={{ mt: 2 }}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>
                            <Button color='success' onClick={modifierTypeDemande}>
                                Modifier
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}

                    {/* modal delete */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            {modifTypeDemande.etat === 20 ? <>Retirer</> : <>Valider cette demande</>} {modifTypeDemande.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={supprimerTypeDemande} color={modifTypeDemande.etat === 20 ? 'error' : 'success'}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}
                </>
            }

            {/* modal create */}
            <Dialog onClose={handleCloseModalCreation} aria-labelledby="customized-dialog-title" open={openModalCreation}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalCreation}>
                    Ajout de nouvelle type de demande
                </DialogTitle>

                <DialogContent dividers>
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        name='nom'
                        value={createTypeDemande.nom}
                        onChange={handleCreateTypeDemandeChange}
                        label='Nom'
                        sx={{ mt: 2 }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalCreation}>
                        Fermer
                    </Button>
                    <Button color='success' onClick={ajouterTypeDemande}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            {/* */}
        </>
    );
};

export default TypeDemandeComponent;