import { Autocomplete, Grid, styled, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { Fragment } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const AutoComplete = styled(Autocomplete)(() => ({
    width: 300,
    marginBottom: '16px',
}));

const suggestions = [
    { id: 1, marque: "Toyota", model: 'LC 300', categorie: "4x4" },
    { id: 2, marque: "Toyota", model: 'RAV4', categorie: "4x4" },
    { id: 3, marque: "Renault", model: 'Clio', categorie: "Plaisir" },
    { id: 4, marque: "Mercedes", model: 'Sprinter', categorie: "Familial" },
    { id: 5, marque: "Ford", model: 'Raptor', categorie: "4x4" },
    { id: 6, marque: "BMW", model: 'X5', categorie: "4x4" },
    { id: 7, marque: "Toyota", model: 'LX', categorie: "4x4" },
    { id: 8, marque: "ISUZU", model: 'SUV', categorie: "BUS" }
];

// const filter = createFilterOptions();

const AutocompleteModel = () => {

    const [models, setModels] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        setModels(suggestions)

    }, [])

    const val = useRef();

    const getV = () => {
        // alert(par)
        alert(val.current.value)
    }
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedOption(newValue);
        alert(newValue.id)
    };

    return (
        <Grid>
            <Fragment>
                <AutoComplete
                    options={suggestions}
                    getOptionLabel={(option) => option.marque + "-" + option.model + "-" + option.categorie}
                    onChange={handleAutocompleteChange}

                    renderInput={(params) => (
                        <TextField {...params} label="marque , model et categorie voiture" variant="outlined" fullWidth />
                    )}
                />
            </Fragment>
        </Grid>
    );
};

export default AutocompleteModel;
