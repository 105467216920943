import { Delete, Edit } from '@mui/icons-material';
import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import { red } from '@mui/material/colors';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import getPrixRepair from 'app/config/Repair/getPrixRepair';
// 

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const SuggestionComponent = () => {

    const [suggestions, setSuggestion] = useState(null);

    const getSuggestion = () => {
        // 
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/publications`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setSuggestion(response.data);
        }).catch((e) => {
            console.log(e);

        })
    };

    const [tarifs, setTarif] = useState([]);

    function getTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTarif(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getTarif();
        getSuggestion();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [checkAffectationTotal, setCheckAffectationTotal] = useState(false);
    const [totalPrixSuggestions, setTotalPrixSuggestion] = useState([]);

    useEffect(() => {
        if (suggestions && checkAffectationTotal === false) {
            const totalPrix = [...totalPrixSuggestions];
            for (let index = 0; index < suggestions.length; index++) {
                const suggestion = suggestions[index];
                const total = 0;
                for (let index = 0; index < suggestion.publicationpropositiondetails.length; index++) {
                    const reparation = suggestion.publicationpropositiondetails[index].reparation;
                    total += parseInt(reparation.prix);
                }
                totalPrix.push(total);
            }
            setCheckAffectationTotal(true);
            setTotalPrixSuggestion(totalPrix);
        }
    }, [suggestions])

    // modal
    const [modalSuggestion, setModalSuggestion] = useState(null);

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);


    // modal edit
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    function handleDescriptionChange(e) {
        const updatedModalSuggestion = { ...modalSuggestion };
        updatedModalSuggestion.reponse.description = e.target.value;

        setModalSuggestion(updatedModalSuggestion);
    }

    function displayEditModal(suggestion) {
        setModalSuggestion(suggestion);
        setOpenModalEdit(true);
    }

    function modifierSuggestion() {
        setOpenModalEdit(false);
    }

    function handleSuggestionReparationChange(reparation) {

        const updatedModalSuggestion = { ...modalSuggestion };

        const tabReparations = [...updatedModalSuggestion.reponse.reparations];

        const isReparationAlreadyInArray = tabReparations.some(item => item.id === reparation.id);

        if (!isReparationAlreadyInArray) {
            tabReparations.push(reparation);
        }

        updatedModalSuggestion.reponse.reparations = tabReparations;

        setModalSuggestion(updatedModalSuggestion);
    }

    function removeReparation(reparation) {
        const updatedModalSuggestion = { ...modalSuggestion };

        const tabReparation = [...updatedModalSuggestion.reponse.reparations];

        const updatedTabReparation = tabReparation.filter(item => item.id !== reparation.id);

        updatedModalSuggestion.reponse.reparations = updatedTabReparation;

        setModalSuggestion(updatedModalSuggestion);
    }

    // modal delete
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    function displayDeleteModal(suggestion) {
        setModalSuggestion(suggestion);
        setOpenModalDelete(true);
    }

    function supprimerSuggestion() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/publications/${modalSuggestion.id}`,
            method: 'DELETE'
        }
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                getSuggestion();
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })

        handleCloseModalDelete();
    }

    return (
        <>
            <SimpleCard title={'Suggestions envoyées'}>
                {suggestions ?
                    <>
                        <Box width="100%" overflow="auto">
                            {suggestions.map((suggestion, i) => (
                                <Grid key={i} container spacing={3} sx={{ mb: 3 }}>

                                    <Grid item lg={4} md={4} xs={12}>
                                        <Card sx={{ mb: 3, height: '100%' }}>
                                            <CardHeader
                                                avatar={
                                                    <>
                                                        {suggestion.publication.client.photoprofil ?
                                                            <>
                                                                <Avatar src={suggestion.publication.client.photoprofil} />
                                                            </>
                                                            :
                                                            <>
                                                                <Avatar sx={{ bgcolor: red[500] }}>
                                                                    {suggestion.publication.client.nom.charAt(0)}{suggestion.publication.client.prenom.charAt(0)}
                                                                </Avatar>
                                                            </>
                                                        }
                                                    </>

                                                }
                                                title={`${suggestion.publication.client.nom} ${suggestion.publication.client.prenom}`}
                                                subheader={`${suggestion.publication.date}`}
                                            />
                                            <Swiper
                                                spaceBetween={1}
                                                slidesPerView={1}
                                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                navigation
                                                pagination={{ clickable: true }}
                                                scrollbar={{ draggable: true }}
                                            >
                                                {suggestion.publication.publicationimages.map((publicationimg, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            style={{
                                                                width: '100%',  // Largeur 100% de la diapositive
                                                                height: '300px',  // Hauteur fixe
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                backgroundColor: 'lightgrey',  // Couleur de fond noire
                                                            }}
                                                        >
                                                            <img
                                                                src={publicationimg.img}
                                                                style={{
                                                                    width: 'auto',
                                                                    height: 'auto',
                                                                    maxWidth: '100%',
                                                                    maxHeight: '100%'  // Empêche le débordement horizontal
                                                                }}
                                                                alt="" />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>

                                            <CardContent>
                                                <Typography variant="body2">
                                                    {suggestion.publication.description}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item lg={8} md={8} xs={12}>

                                        <Card sx={{ mb: 3, width: '100%', height: '100%' }}>
                                            <CardContent>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    Envoyée le : {suggestion.date}
                                                </Typography>
                                                <Typography >
                                                    {suggestion.description}
                                                </Typography>

                                                <StyledTable>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center">Nom</TableCell>
                                                            <TableCell align="center">Prix</TableCell>
                                                            <TableCell align="center">Duree</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {suggestion.publicationpropositiondetails
                                                            .map((publicationpropositiondetail, index) => (
                                                                <TableRow hover key={index}>
                                                                    <TableCell align="center">
                                                                        <center>
                                                                            {publicationpropositiondetail.reparation.images && <> <Avatar src={publicationpropositiondetail.reparation.images[0].img}></Avatar> </>}
                                                                        </center>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {publicationpropositiondetail.reparation.nom}
                                                                    </TableCell>
                                                                    <TableCell align="right">Ar {publicationpropositiondetail.reparation.prix}</TableCell>
                                                                    <TableCell align="center">{publicationpropositiondetail.reparation.duree}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        {tarifs.map((tarif, index) => (
                                                            <TableRow key={index} hover>
                                                                <TableCell align="center">Marge</TableCell>
                                                                <TableCell align="center">{tarif.typedemande.nom}</TableCell>
                                                                <TableCell align="center" >{tarif.valeur} %</TableCell>
                                                                <TableCell align="right" >Total: Ar {getPrixRepair(totalPrixSuggestions[i], tarif.valeur)}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </StyledTable>
                                            </CardContent>
                                            <CardActions>
                                                <Link to={'/entreprise/reparation/publications/suggestions/modif'} state={{ suggestion: suggestion }}>
                                                    <Button size="small" color='success'><Edit></Edit></Button>
                                                </Link>
                                                <Button size="small" color='error' onClick={() => displayDeleteModal(suggestion)}><Delete></Delete></Button>
                                            </CardActions>
                                        </Card>

                                    </Grid>

                                </Grid>
                            ))}

                        </Box>
                    </>
                    :
                    <>
                        <center>
                            <CircularProgress></CircularProgress>
                        </center>
                    </>}


            </SimpleCard>

            {modalSuggestion &&
                <>

                    {/* modal delete */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            Retirer vos suggestions
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={supprimerSuggestion} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}
                </>
            }

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}
        </>
    );
};

export default SuggestionComponent;