import React from 'react';
import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Snackbar, TablePagination, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useEffect } from 'react';
import { useState } from 'react';
import { listClient } from 'fake-db/listClient';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AdminClient = () => {
    document.title = "ADMIN-CLIENTS";

    const [rechercheClient, setRechercheClient] = useState({
        recherche: '',
        etat: 21
    });

    const etats = [
        {
            nom: 'Bloqué',
            value: 0
        },
        {
            nom: 'Demande',
            value: 11
        },
        {
            nom: 'Inscrit',
            value: 21
        }
    ];

    const [clients, setClient] = useState([]);;

    function getClient() {
        setClient(listClient);
    }

    useEffect(() => {
        getClient();
    }, []);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modal
    const [clientModal, setClientModal] = useState(null);

    const [openModalEdit, setOpenModalEdit] = useState(false);
    const handleCloseModalEdit = () => setOpenModalEdit(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = () => setOpenSnackbar(false);

    const [severity, setSeverity] = useState('info');

    function displayModal(client) {
        setClientModal(client);
        setOpenModalEdit(true);
    }

    function validerModifClient() {
        handleCloseModalEdit();
        if (clientModal.etat === 21) {
            setSeverity('error');
        }
        if (clientModal.etat === 11) {
            setSeverity('success');
        }
        if (clientModal.etat === 0) {
            setSeverity('info');
        }

        setOpenSnackbar(true);
    }

    return (
        <>
            <AppButtonRoot>
                <SimpleCard title={'Recherche'}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6}>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='recherche'
                                value={rechercheClient.recherche}
                                onChange={(e) => setRechercheClient({ ...rechercheClient, recherche: e.target.value.toLowerCase() })}
                                label='Recherche'
                                sx={{ mt: 2 }}
                            />
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={clients.length}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[2, 5, 10, 25]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </Grid>
                        <Grid item lg={6} md={6}>
                            <RadioGroup
                                className="group"
                            >
                                <Typography>
                                    Etat des comptes
                                </Typography>
                                {etats.map((etat, index) => (
                                    <FormControlLabel key={index} name='etat' value={etat.value} onChange={(e) => setRechercheClient({ ...rechercheClient, etat: parseInt(e.target.value) })} control={< Radio />} label={etat.nom} />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </SimpleCard>

                <Box sx={{ mt: 3 }} width={'100%'}>
                    <Grid container spacing={3}>
                        {clients
                            .filter((client) =>
                                client.etat === rechercheClient.etat &&
                                client.nom.toLowerCase().includes(rechercheClient.recherche)
                            )
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((client, index) =>
                                <Grid key={index} item lg={4} md={4}>
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={client.profil}
                                            title={client.nom}
                                        />
                                        <CardContent>

                                            <Typography gutterBottom variant="h5" component="div">
                                                {client.nom} {client.prenom}
                                            </Typography>

                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {client.email}
                                            </Typography>

                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                Créé le : {client.dateCreation}<br />
                                                Derniere activité : {client.dateDerniereActivite}
                                            </Typography>

                                        </CardContent>

                                        <CardActions>
                                            {client.etat === 0 &&
                                                <Button onClick={() => displayModal(client)} color='primary' size="small">Re activer?</Button>
                                            }
                                            {client.etat === 11 &&
                                                <Button onClick={() => displayModal(client)} color='success' size="small">Valider?</Button>
                                            }
                                            {client.etat === 21 &&
                                                <Button onClick={() => displayModal(client)} color='error' size="small">Bloquer?</Button>
                                            }
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                    </Grid>
                </Box>

            </AppButtonRoot>

            {/* modal fiche */}
            {clientModal &&
                <>
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Validation de {clientModal.nom} {clientModal.prenom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography sx={{ textAlign: 'center' }} gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>

                            <Button onClick={validerModifClient} sx={clientModal.etat === 21 ? { color: 'red' } : clientModal.etat === 0 ? { color: 'blue' } : { color: 'green' }}>
                                Valider
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={openSnackbar}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={3000}
                        anchorOrigin={
                            {
                                "horizontal": "right",
                                "vertical": "top"
                            }
                        }
                    >
                        <Alert onClose={handleCloseSnackbar} sx={{ m: 1 }} severity={severity} variant="filled">
                            Email envoyé vers {clientModal.nom} {clientModal.prenom}!
                        </Alert>
                    </Snackbar>
                </>
            }

            {/* */}

        </>
    );
};

export default AdminClient;