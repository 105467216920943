import myConfig from "app/config/api-config";
import axios from "axios"

export const getOperateur = () => {
    return axios.get(`${myConfig.host}/api/operateurDescription`);
}

export const validateOperateur = (payload, isUpdate) => {
    let payloadTmp = { ...payload };
    payloadTmp.isUpdate = isUpdate;
    return axios.post(`${myConfig.host}/api/operateurDescription`, payloadTmp);
}