import url from "app/UrlConfig"


export const SaveEvenement = async (evenement, setEvenement, setMessage, setVisible, setSeverity) => {
    console.log(evenement)
    fetch(url["url-api"] + "evenement", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(evenement)
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.status === 200) {
                console.log(data.data)
                // setEvenement();
                setSeverity('success');
                setMessage("Ajout Equipement avec success");
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage("Une erreur est survenue " + data.message);
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getEvenement = async (search, setEvenement, setLineNumber) => {
    fetch(url["url-api"] + "evenement-get", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(search)
    })
        .then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setEvenement(data.data);
                setLineNumber(data.LigneNumber);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const DeleteEvenement = async (idEvenement, setVisible) => {
    fetch(url["url-api"] + "evenement/" + idEvenement, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.status === 200) {
                setVisible();
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getEvenementById = async (idEvenement, setEvenement) => {
    console.log("dvsdvdvscvncs")
    fetch(url["url-api"] + "evenement/" + idEvenement, {
        method: 'GET',
        headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then(data => {
            console.log(data.data)
            if (data.status === 200) {
                setEvenement(data.data);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export const UpdateEvenementAPI = async (evenement, setEvenement) => {
    fetch(url["url-api"] + "evenementUpdate/" + evenement.id, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(evenement)
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.status === 200) {
                setEvenement(data.data);
            }
        })
        .catch((error) => {
            console.log(error)
        })
}