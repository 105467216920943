import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';
import { useState } from 'react';
import CardActions from '@mui/material/CardActions';
import MessageIcon from '@mui/icons-material/Message';
import { Link } from 'react-router-dom';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import styled from '@emotion/styled';

// swiper
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import MySkeletonComponent from '../MySkeletonComponent';
import divStyle from '../divStyle';
import imgStyle from '../imgStyle';
// 

const AccordionRoot = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .heading': { fontSize: theme.typography.pxToRem(15) },
    '& .secondaryHeading': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(15)
    },
    '& .icon': {
        width: 20,
        height: 20,
        verticalAlign: 'bottom'
    },
    '& .details': { alignItems: 'center' },
    '& .column': { flexBasis: '33.33%' },
    '& .helper': {
        padding: theme.spacing(1, 2),
        borderLeft: `2px solid ${theme.palette.divider}`
    },
    '& .link': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline' }
    }
}));

const ActualiteComponent = () => {

    const currentDate = new Date();

    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const [actualites, setActualite] = useState(null);

    const getActualite = () => {
        setActualite(null);
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/client/actualites`,
            method: 'GET'
        };
        if (filter.sendFilter) {
            requestOptions.url += `?1=1`;
            requestOptions.url += `&datedebut=${filter.datedebut}`;
            requestOptions.url += `&datefin=${filter.datefin}`;
        }
        axios(requestOptions).then((response) => {
            setActualite(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getActualite();
    }, []);

    // filtre

    const [filter, setFilter] = useState({
        idtypereparation: 0,
        datedebut: ``,
        datefin: `${year}-${month}-${day}`,
        sendFilter: false
    });

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (filter.sendFilter) {
            getActualite();
            setFilter({ ...filter, sendFilter: false });
        }
    }, [filter]);

    // modal client
    const [client, setClient] = useState({});
    const [openModalClient, setOpenModalClient] = useState(false);
    function handleCloseModalClient() {
        setOpenModalClient(false)
    }
    function displayModalCient(client) {
        setClient(client);
        setOpenModalClient(true);
    }

    return (
        <>
            <AccordionRoot>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Box className="column">
                            <Typography className="heading">Publication des clients</Typography>
                        </Box>

                        <Box className="column">
                            <Typography className="secondaryHeading">Filtrer</Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails className="details">
                        <Box className="column helper">

                            <Grid container spacing={3}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='date'
                                        label='Date de début'
                                        name='datedebut'
                                        value={filter.datedebut}
                                        onChange={handleFilterChange}

                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='date'
                                        label='Date fin'
                                        name='datefin'
                                        value={filter.datefin}
                                        onChange={handleFilterChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>

                    <Divider />

                    <AccordionActions>
                        <Button onClick={() => setFilter({ ...filter, sendFilter: true })} size="small" color="primary">
                            Filtrer
                        </Button>
                    </AccordionActions>
                </Accordion>
            </AccordionRoot>

            <br />

            {(actualites) ?
                <>
                    {actualites.map((actualite, index) => (
                        <Card key={index} sx={{ width: '100%', mb: 3 }}>
                            <Button onClick={() => displayModalCient(actualite.client)} color='inherit'>
                                <CardHeader
                                    avatar={
                                        <>
                                            {actualite.client.photoprofil ?
                                                <>
                                                    <Avatar src={actualite.client.photoprofil} />
                                                </>
                                                :
                                                <>
                                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                        {actualite.client.nom.charAt(0)}{actualite.client.prenom.charAt(0)}
                                                    </Avatar>
                                                </>
                                            }
                                        </>

                                    }
                                    title={`${actualite.client.nom} ${actualite.client.prenom}`}
                                    subheader={`Publié le ${actualite.date}`}
                                />
                            </Button>
                            <Swiper
                                spaceBetween={1}
                                slidesPerView={1}
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {actualite.publicationimages.map((publicationimg, index) => (
                                    <SwiperSlide key={index}>
                                        <div
                                            style={divStyle}
                                        >
                                            <img
                                                src={publicationimg.img}
                                                style={imgStyle}
                                                alt="" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <CardContent>
                                <Typography variant="body2">
                                    {actualite.description}
                                </Typography>
                            </CardContent>

                            <CardActions disableSpacing>
                                <Link to={'/entreprise/reparation/publications/actualites/reponse'} state={{ actualite: actualite }}>
                                    <IconButton>
                                        <MessageIcon />
                                    </IconButton>
                                </Link>
                            </CardActions>

                        </Card>
                    ))}
                </>
                :
                <>
                    <>
                        <MySkeletonComponent />
                    </>
                </>
            }

            {/* modal fiche */}
            <Dialog onClose={handleCloseModalClient} aria-labelledby="customized-dialog-title" open={openModalClient}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalClient}>
                    A propos
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6}>
                            <div style={divStyle}>
                                <img style={imgStyle} src={client.photoprofil ? client.photoprofil : ''} alt="Profil du client" />
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6}>
                            <Typography gutterBottom variant="h5" component="div">
                                Info : {client.nom} {client.prenom}
                            </Typography>

                            <Typography gutterBottom variant="body1" component="div">
                                Email : {client.email}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenModalClient(false)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            {/* */}

        </>
    );
};

export default ActualiteComponent;