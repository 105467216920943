import { Box, Button, Container, TextField, styled, useTheme } from '@mui/material';
import { SimpleCard } from 'app/components';
import ComparisonChart from 'app/views/charts/echarts/ComparisonChart';
import DoughnutChart from 'app/views/charts/echarts/Doughnut';
import LineChart from 'app/views/charts/echarts/LineChart';
import React from 'react';
import { useState } from 'react';



const MyDashboard = () => {
    const theme = useTheme();
    document.title = "DASHBOARD";

    const StyledButton = styled(Button)(({ theme }) => ({
        margin: theme.spacing(1),
    }));

    const [year, setYear] = useState(new Date().getFullYear());

    return (
        <>
            <Container>
                <Box sx={{ py: '12px' }} />

                <SimpleCard title={'TABLEAU DE BORD'}>
                    <TextField
                        type="number"
                        name="annee"
                        label="Annee"
                        value={year}
                        onChange={(e) => {
                            setYear(e.target.value);
                            if (year <= 0) {
                                e.currentTarget.style.color = 'red';
                            }
                            else {
                                e.currentTarget.style.color = '';
                            }
                        }}
                    />
                    <StyledButton variant="outlined" color="primary">
                        Filtrer
                    </StyledButton>
                </SimpleCard>

                <Box sx={{ py: '12px' }} />

                <SimpleCard title="Reparation les plus demandées">
                    <DoughnutChart
                        height="350px"
                        color={[
                            theme.palette.primary.dark,
                            theme.palette.primary.main,
                            theme.palette.primary.light
                        ]}
                    />
                </SimpleCard>

                <Box sx={{ py: '12px' }} />

                <SimpleCard title="Evolution de prix">
                    <LineChart
                        height="350px"
                        color={[theme.palette.primary.main, theme.palette.primary.light]}
                    />
                </SimpleCard>

                <Box sx={{ py: '12px' }} />

                <SimpleCard title="Recettes et dépenses">
                    <ComparisonChart
                        height="350px"
                        color={[theme.palette.primary.dark, theme.palette.primary.light]}
                    />
                </SimpleCard>
            </Container>
        </>
    );
};

export default MyDashboard;
