import { Box, Icon, IconButton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, styled } from "@mui/material";
import { SimpleCard } from "app/components";
import { getListeHebergement } from "app/data/nosybe/hebergement";
import TestButton from "app/myComponents/TestButton";
import { useEffect } from "react";
import { useState } from "react";
import { getEquipement } from "../Equipement/Api-Equipement";
import { getRestaurant } from "./API-Restaurant";
import ModalUpdateRestaurant from "./ModalUpdateRestaurant";
import ModalDeleteRestaurant from "./ModalDeleteRestaurant";
import { getAdminConnected } from "app/data/nosybe/admin";


const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const ListRestaurant = () => {

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);

    const [hebergements, setHebergements] = useState([]);
    const [restaurants, setRestaurant] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(undefined);
    const [hebergementTotal, setHebergementTotal] = useState(0);
    const [search, setSearch] = useState("none");
    const [equipements, setEquipements] = useState([]);
    const [equipementTotal, setEquipementTotal] = useState(0);
    const [restaurantTotal, setRestaurantTotal] = useState(0);
    const [searchData, setSearchData] = useState([]);





    //PAGINATION AND SEARCH
    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [dataSend, setDataSend] = useState({
        "offset": page,
        "limit": rowsPerPage,
        "recherche": searchData,
        "user": getAdminConnected().id
    });


    const handleChangePage = async (_, newPage) => {
        setPage(newPage);
        // console.log(newPage)
        setDataSend({ ...dataSend, offset: newPage * rowsPerPage });

        await getRestaurant({ ...dataSend, offset: newPage * rowsPerPage }, setRestaurant, setRestaurantTotal);

        // await getEquipement(page, rowsPerPage, search, setEquipements, setEquipementTotal);
        // getListeHebergement(setHebergements, setHebergementTotal, newPage, rowsPerPage);
    };
    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setDataSend({ ...dataSend, offset: 0, limit: +event.target.value })
        await getRestaurant(dataSend, setRestaurant, setRestaurantTotal);
        // await getEquipement(page, +event.target.value, search, setEquipements, setEquipementTotal);
        // getListeHebergement(setHebergements, setHebergementTotal, 0, +event.target.value);
    };

    //delete modal
    const handleCloseModalDelete = () => {
        setSelectedRestaurant(null);
        setOpenModalDelete(false);
    }
    const displayDeleteModal = (restaurant) => {
        setSelectedRestaurant(restaurant);
        setOpenModalDelete(true);
    }

    //edit modal
    const handleCloseModalEdit = () => {
        setSelectedRestaurant(null);
        setOpenModalEdit(false);
    }
    const displayEditModal = (restaurant) => {
        setSelectedRestaurant(restaurant);
        setOpenModalEdit(true);
    }

    const getEquipementsListe = async () => {
        const equipement = await getEquipement(page, rowsPerPage, search, setEquipements, setEquipementTotal);
        console.log(equipement)
        setEquipements(equipement);
    }

    const getRestaurantListe = async () => {
        await getRestaurant(dataSend, setRestaurant, setRestaurantTotal)
    }

    useEffect(() => {
        // getEquipementsListe();
        getRestaurantListe()

    }, [selectedRestaurant]);



    // { "attr": "name", "val": "Ho", "type": "str" }, { "attr": "description", "val": "Hot", "type": "str" }



    return (
        <>
            {/* <TestButton toLog={hebergements} /> */}
            <SimpleCard title={'Liste des réstaurants'}>
                <Box width="100%" overflow="auto">
                    <TextField
                        fullWidth
                        size="small"
                        name="recherche"
                        type="text"
                        label="Recherche"
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{ mt: 1.5 }}
                    />
                    {restaurants.length > 0 ?
                        <>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nom </TableCell>
                                        <TableCell align="center">lien</TableCell>
                                        <TableCell align="center">email</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {restaurants.map((restaurant, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{restaurant.name_fr}</TableCell>
                                            <TableCell align="center"><a href={restaurant.siteweb}>{restaurant.siteweb}</a></TableCell>
                                            <TableCell align="center">{restaurant.email}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => displayEditModal(restaurant)}>
                                                    <Icon color="success">edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => displayDeleteModal(restaurant)} >
                                                    <Icon color="error">delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                            <TablePagination
                                sx={{ px: 2 }}
                                page={page}
                                component="div"
                                rowsPerPage={rowsPerPage}
                                count={restaurantTotal}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[25, 50, 100]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                            />
                        </>
                        :
                        <h5>Pas de résultat</h5>
                    }
                </Box>
            </SimpleCard >
            {selectedRestaurant &&
                <>
                    <ModalUpdateRestaurant restaurantEdit={selectedRestaurant} setRestaurantEdit={setSelectedRestaurant} open={openModalEdit} handleClose={handleCloseModalEdit}></ModalUpdateRestaurant>
                    {/* <ModalEditHebergement hebergement={selectedHebergement} setHebergements={setHebergements} open={openModalEdit} handleClose={handleCloseModalEdit} /> */}
                    <ModalDeleteRestaurant restaurant={selectedRestaurant} setRestaurantEdit={setSelectedRestaurant} open={openModalDelete} handleClose={handleCloseModalDelete} ></ModalDeleteRestaurant>
                </>
            }
        </>
    )
}

export default ListRestaurant;