import url from "app/UrlConfig";
import axios from "axios";
//import { displayToast } from "app/data/piece/dispatch";


export const SaveExcursionAPI = async (Excursion, setMessage, setVisible, setSeverity) => {
    await axios.post(url["url-api"] + "excursion/add", Excursion)
        //.then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("request avec success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage(data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            console.log(error)
            setSeverity('error');
            setMessage(error)
            setVisible(true);
        })
}

export const getExcursionListAPI = async (idAdmin, setExcursion, setLigneNumber) => {
    await axios.get(url["url-api"] + "excursion/admin/" + idAdmin)
        //.then(response => response.json())
        .then(data => {
            if (data.status === 200) {
                setExcursion(data.data);
                setLigneNumber(data.LigneNumber);
                // setExcursions(data.data.ori);
            }
        })
        .catch((error) => {
            console.log(error);
        })
}

export const getListeExcursionDetail = async (idExcursion, setExcursion) => {
    new Promise((resolve, reject) => {
        fetch(url["url-api"] + "excursion-get/" + idExcursion, {
            method: 'GET',
            headers: { "Content-Type": "application/json" }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 200) {
                    // console.log(data.data)
                    setExcursion(data.data[0]);
                    if (data.data.length > 0) {

                        resolve(data.data[0]);

                        return data.data[0];
                    }
                }
            })
            .catch((error) => {
                reject(error);
            })
    })
}


export const UpdateExcursionAPI = async (Excursion, setExcursion, setMessage, setVisible, setSeverity) => {

    //console.log(JSON.stringify(Excursion))
    await axios.post(url["url-api"] + "excursion/update", Excursion)
        //.then(response => response.json())
        .then(data => {
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("Modification avec Success");
                setVisible(true);
                //displayToast("Modification avec Success", true);
            } else {
                setSeverity('error');
                setMessage("Error " + data.message)
                setVisible(true);
                //displayToast("Error " + data.message, false);
            }
        })
        .catch((error) => {
            console.log(error);
            setSeverity('error');
            setMessage("Error " + error);
            setVisible(true);
            //displayToast("Error " + error, false);
        });
}

export const DeleteExcursion = async (idExcursion, setExcursion, setMessage, setVisible, setSeverity) => {
    await axios.delete(url["url-api"] + "excursion/delete/" + idExcursion)

        //.then(response => response.json())
        .then(data => {
            if (data.data.status === 200) {
                setSeverity('success');
                setMessage("Suppression avec Success")
                setVisible(true);
            } else {
                setSeverity('error');
                setMessage("Error " + data.message)
                setVisible(true);
            }
        })
        .catch((error) => {
            setSeverity('error');
            setMessage("Error " + error)
            setVisible(true);
        })

}