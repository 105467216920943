import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, IconButton, Radio, RadioGroup, TablePagination, TextField, Typography } from '@mui/material';
import { SimpleCard } from 'app/components';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { listChangementTypeReparations } from 'fake-db/listChangementTypeReparation';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const ChangementTypeReparationComponent = () => {

    const [changementTypeReparations, setChangementTypeReparation] = useState(null);

    function getChangementTypeReparation() {
        const requestOptions = {
            url: `${myConfig.host}/api/admin/typereparations/changement`,
            method: 'GET',
        };
        axios(requestOptions).then((response) => {
            setChangementTypeReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getChangementTypeReparation();
    }, []);

    // recherche & pagination
    const [recherche, setRecherche] = useState({
        label: '',
        etat: 20
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // modal
    const [changement, setChangement] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => setOpenModal(false);

    function displayModal(changementTypeReparation) {
        setChangement(changementTypeReparation);
        setOpenModal(true);
    }

    function validerChangement() {
        const requestOptions = {
            url: `${myConfig.host}/api/admin/typereparations/changement/valider`,
            method: 'PUT',
            data: changement
        };
        axios(requestOptions).then((response) => {
            console.log(response.data);
            if (response.data.code === 200) {
                setChangementTypeReparation(null);
                getChangementTypeReparation();
            }
            handleCloseModal();
        }).catch((e) => {
            console.log(e);
        })
    }

    return (
        <>
            <SimpleCard title={'Les Changements par les entreprises'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche.label}
                    onChange={(e) => setRecherche({ ...recherche, label: e.target.value.toLowerCase() })}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />

                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={changementTypeReparations ? changementTypeReparations.length : 0}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 4, 8, 12]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box width={'100%'} sx={{ mt: 3 }}>
                {
                    changementTypeReparations ?
                        <>
                            <Grid container spacing={3}>
                                {changementTypeReparations
                                    .filter((changementTypeReparation) =>
                                        changementTypeReparation.etat === recherche.etat && (
                                            changementTypeReparation.entreprise.nom.toLowerCase().includes(recherche.label) ||
                                            changementTypeReparation.changement.toLowerCase().includes(recherche.label) ||
                                            changementTypeReparation.typereparation.nom.toLowerCase().includes(recherche.label)
                                        )
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((changementTypeReparation, index) =>
                                        <Grid item lg={3} md={3} key={index}>
                                            <Card>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar src={changementTypeReparation.entreprise.logo} alt='' />
                                                    }
                                                    title={`${changementTypeReparation.entreprise.nom}`}
                                                    subheader={`${changementTypeReparation.entreprise.email}`}
                                                />
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={6} md={6}>
                                                            <Typography gutterBottom variant='subtitle1'>Type :</Typography>
                                                            <Typography gutterBottom variant='body1'>{changementTypeReparation.typereparation.nom}</Typography>
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Typography gutterBottom variant='subtitle1'>Changement</Typography>
                                                            <Typography gutterBottom variant='body1'>{changementTypeReparation.changement}</Typography>
                                                            <Typography gutterBottom variant='body1'>Le : {changementTypeReparation.date}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>

                                                <CardActions sx={{ justifyContent: 'right' }}>

                                                    <IconButton onClick={() => displayModal(changementTypeReparation)}>
                                                        <Icon color='success'>check</Icon>
                                                    </IconButton>

                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )}
                            </Grid>
                        </>
                        :
                        <>
                            <center>
                                <CircularProgress></CircularProgress>
                            </center>
                        </>
                }

            </Box>

            {changement &&
                <>
                    {/* modal delete */}
                    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
                            Validation de changement
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur de valider le changement de {changement.entreprise.nom} le {changement.date}?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={validerChangement} color='success'>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*  */}
                </>
            }
        </>
    );
};

export default ChangementTypeReparationComponent;