import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import AjoutReparationEtapeComponent from 'app/myComponents/Repair/Reparation/AjoutReparationEtapeComponent';
import React from 'react';
import { useLocation } from 'react-router-dom';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const AjoutReparationEtape = () => {
    document.title = "AJOUT-ETAPE-REPARATION";
    const location = useLocation();
    const reparation = location.state.reparation;
    return (
        <>
            <AppButtonRoot>
                <Box className="breadcrumb">
                    <Breadcrumb
                        routeSegments={[{ name: 'Reparations', path: '/entreprise/reparation/liste' }, { name: "Ajout" }, { name: 'Etapes', path: '/entreprise/reparation/etapes/liste' },]}
                    />
                </Box>

                <AjoutReparationEtapeComponent entretien={reparation}></AjoutReparationEtapeComponent>

            </AppButtonRoot>
        </>
    );
};

export default AjoutReparationEtape;