import { dispatchUpdate } from 'app/data/piece/users'
import { listEntreprise } from 'fake-db/listEntreprise'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const EntrepriseValidation = () => {
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.setItem("type", 1);
        localStorage.setItem("user", "entreprise");
        const entreprise = listEntreprise.find(entreprise => entreprise.email === 'nosybefun@yopmail.com');
        localStorage.setItem("entreprise", JSON.stringify(entreprise));
        dispatchUpdate();
        navigate("/entreprise/accueil");
    })

    return (
        <></>
    )
}

export default EntrepriseValidation;