import { Card, Box, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Icon, ImageList, ImageListItem, Fab } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useEffect } from 'react';
import { useState } from 'react';
import ModalTrajet from '../utils/ModalTrajet';
import TrajetLocation from './TrajetLocation';
import { divStyle, imgStyle } from '../utils/Style';
import { getContrat } from '../Entreprise/ContratAPI';





const DetailDemande = ({ demande }) => {
    const [openModalFiche, setOpenModalFiche] = useState();
    const Open = () => setOpenModalFiche(true);
    const Close = () => {
        setOpenModalFiche(false)
    };
    const [photoP, setPhotoP] = useState(demande.vehicule.image_vehicule[0].image);
    const [contrat, setContrat] = useState([]);
    // if (show) {
    //     Open();
    // } else {

    // }

    // id: 5,
    // marque: "ISUZU",
    // model: "SUV",
    // categorie: "BUS",
    // prix: 300000,
    // immatriculation: "5563 TBC",
    // places: 42,
    // images: [
    //     'https://www.top-garage.fr/app/uploads/iStock-124202360.jpg',
    //     'https://d3t0tbmlie281e.cloudfront.net/igi/oscaro/jv3HIXLPTBOPbVSe.medium',
    //     'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHh4hCmABAevWMxubkOeUB9DPNxKEWCAboKut2U1QbpGkbhpdJXUwUxbNrEHiLjBhj5qM&usqp=CAU'
    // ],
    // Etattype: 10,
    // "type": "Hors Carburant",
    // datedebut: "03/06/2023",
    // datefin: "09/0/2023",
    // nombrejours: 6,
    // client: {
    //     contact: "+261 34 66 607 45",
    //     nom: "RAKOTO3"
    // },
    // montant_total: 20000000

    const ChangePhoto = (img) => {
        // console.log(demande)
        setPhotoP(img);

    }

    useEffect(() => {
        getContrat(setContrat);
    }, [])

    return (
        <>
            <div>
                <p>
                    <SimpleCard>
                        <img style={{ width: '10%', height: 50 }} src={`${demande.client.photo}`} alt={`Client img ${photoP}`} loading="lazy"></img>
                    </SimpleCard>
                </p>
                <p>Nom client : {demande.client.nom}</p>
                <p>Contact client : {demande.client.contact}</p>
                <SimpleCard>
                    <div style={divStyle}>
                        <img style={imgStyle} src={`${photoP}`} alt={`VOiture img ${photoP}`} loading="lazy"></img>
                    </div>
                </SimpleCard>
                {demande.vehicule.image_vehicule.length !== 0 &&
                    <>
                        <ImageList sx={{ width: '100%', height: 200 }} cols={3} rowHeight={164}>
                            {demande.vehicule.image_vehicule.map((img, ind) => {
                                // console.log("loop: ", img, index);
                                return (
                                    <div key={ind}>

                                        <ImageListItem>
                                            <img
                                                src={`${img.image}`}
                                                alt={`Reparation img ${ind}`}
                                                loading="lazy"
                                                onClick={() => ChangePhoto(img)}
                                            />
                                        </ImageListItem>

                                    </div>
                                )
                            })}
                        </ImageList>
                    </>
                }
                <p>Marque : {demande.vehicule.model_vehicule.marquevoiture.marque}</p>
                <p>Model : {demande.vehicule.model_vehicule.model} {"("}{demande.vehicule.model_vehicule.places}{" plcs)"}</p>
                <p>categorie :  {demande.vehicule.model_vehicule.categorie_voiture.categorie_voiture}</p>
                <p>Immatriculation :  {demande.vehicule.immatriculation}</p>
                <p>prix par jours : {new Number(demande.prixlocation).toLocaleString()} Ar </p>
                <p>Montant Total : {new Number(demande.montanttotal).toLocaleString()} Ar</p>
                <p> Date debut : {demande.datedebut} jusqu'au {demande.datefin}</p>
                {/* <p>Nom Client : {demande.client.nom}</p> */}
                {contrat !== undefined && (
                    <>
                        {contrat.map((cond, index) => (
                            <p style={{ color: 'red' }}>N:B : {cond.condition} </p>
                        ))}

                    </>

                )}


                <ModalTrajet><TrajetLocation></TrajetLocation></ModalTrajet>

            </div>
        </>
    );
};

export default DetailDemande;
