import ChangementTypeReparation from "./views/myViews/Admin/Reparation/ChangementTypeReparation";
import TypeDemande from "./views/myViews/Admin/Reparation/TypeDemande";
import TypeReparation from "./views/myViews/Admin/Reparation/TypeReparation";

const routesRepairAdmin = [

    // type reparations
    {
        path: '/admin/reparations/typereparations',
        element: <TypeReparation></TypeReparation>
    },
    {
        path: '/admin/reparations/typereparations/changement',
        element: <ChangementTypeReparation></ChangementTypeReparation>
    },

    // type demandes
    {
        path: '/admin/reparations/typedemandes',
        element: <TypeDemande></TypeDemande>
    },
]

export default routesRepairAdmin;
