import { Box, styled } from '@mui/material';
import { getActivites } from 'app/api/activites/activites';
import { Breadcrumb } from 'app/components';
import ActivityDescriptionComponent from 'app/myComponents/VentePiece/Accueil/ActivityDescriptionComponent';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const AppButtonRoot = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
    '& .button': { margin: theme.spacing(1) },
    '& .input': { display: 'none' },
}));

const ActivityDescription = () => {
    const languages = [{ "name": "eng", "abbrev": "ENG" }, { "name": "fr", "abbrev": "FR" }, { "name": "ita", "abbrev": "ITA" }];
    const [activite, setActivite] = useState(null);
    const [keyActivites, setKeyActivites] = useState(null);

    const getDataActivites = () => {
        let dataActivities = getActivites();
        let activiteTmp = initActivite(dataActivities);
        setKeyActivites(Object.keys(activiteTmp));
        setActivite(activiteTmp);
    }

    const initActivite = (dataActivities) => {

        let activiteTmp = {};

        dataActivities.forEach(dataActivitie => {
            let multilingue = {};
            languages.forEach(language => {
                multilingue[language.name] = "";
            });
            activiteTmp[dataActivitie.nom] = multilingue;
        });

        return activiteTmp;
    }

    useEffect(() => {
        getDataActivites();
    }, []);

    // useEffect(() => {
    //     console.log('activite : ', activite);
    // }, [activite]);

    return (
        <AppButtonRoot>
            <Box className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Accueil' },
                        { name: 'Description des activités' }
                    ]}
                />
            </Box>
            {
                activite &&
                <ActivityDescriptionComponent
                    activite={activite}
                    languages={languages}
                    keyActivites={keyActivites}
                    setActivite={setActivite}
                />
            }
        </AppButtonRoot>
    );
};

export default ActivityDescription;