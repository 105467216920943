import React from 'react';

import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Icon, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { useMemo } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import myConfig from 'app/config/api-config';
import axios from 'axios';
import { useEffect } from 'react';
import ListeReparationComponent from '../Reparation/ListeReparationComponent';
import getPrixRepair from 'app/config/Repair/getPrixRepair';

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: 2
}));

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));

const SuggestionModifComponent = ({ suggestion }) => {

    const navigate = useNavigate();

    const [suggestionEntreprise, setSuggestionEntreprise] = useState(suggestion);

    // tarifs
    const [tarifs, setTarif] = useState(null);

    function getTarif() {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/typedemandes`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setTarif(response.data);
        }).catch((e) => {
            console.log(e);
        })
    }

    // reparations
    const [reparations, setListReparation] = useState(null);

    const getListReparation = () => {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/reparations`,
            method: 'GET'
        };
        axios(requestOptions).then((response) => {
            setListReparation(response.data);
        }).catch((e) => {
            console.log(e);
        })
    };

    useEffect(() => {
        getTarif();
        getListReparation();
    }, []);

    const [recherche, setRecherche] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // alerts
    const [colorMessage, setColorMessage] = useState('info');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // add new reparation
    const addNewReparation = (reparation) => {
        const tabReparation = [...suggestionEntreprise.publicationpropositiondetails];
        tabReparation.push({
            id: 0,
            idpublicationproposition: suggestionEntreprise.id,
            idreparation: reparation.id,
            etat: 20,
            reparation: reparation
        });
        setSuggestionEntreprise({ ...suggestionEntreprise, publicationpropositiondetails: tabReparation });
    };

    // remove reparation
    function removeReparation(reparation) {
        const tabReparation = [...suggestionEntreprise.publicationpropositiondetails];

        const updatedTabReparation = tabReparation.filter(publicationpropositiondetail => publicationpropositiondetail.reparation.id !== reparation.id);

        setSuggestionEntreprise({ ...suggestionEntreprise, publicationpropositiondetails: updatedTabReparation });
    }

    // total
    function calculPrixTotal() {
        let result = 0;

        for (let index = 0; index < suggestionEntreprise.publicationpropositiondetails.length; index++) {
            const element = suggestionEntreprise.publicationpropositiondetails[index].reparation;
            result += parseInt(element.prix);
        }

        return result;
    }

    const total = useMemo(() =>
        calculPrixTotal()
        , [suggestionEntreprise.publicationpropositiondetails])

    // modifier
    const modifier = () => {
        const requestOptions = {
            url: `${myConfig.host}/api/reparation/entreprise/publications/${suggestionEntreprise.id}`,
            method: 'PUT',
            data: suggestionEntreprise
        };
        axios(requestOptions).then((response) => {
            if (response.data.code === 200) {
                setColorMessage('success');
                navigate('/entreprise/reparation/publications/suggestions');
            }
            if (response.data.code === 500) {
                setColorMessage('error');
            }
            setMessage(response.data.message);
            setOpen(true);
        }).catch((e) => {
            console.log(e);
            setColorMessage('error');
            setMessage(e.message);
            setOpen(true);
        })
    }


    return (
        <>

            <SimpleCard title={`Modifier vos suggestions`}>
                <Grid container spacing={3}>

                    <Grid item lg={4} md={4}>
                        <TextField
                            fullWidth
                            size='medium'
                            name="description"
                            type="text"
                            label="Description"
                            value={suggestionEntreprise.description}
                            onChange={(e) => setSuggestionEntreprise({ ...suggestionEntreprise, description: e.target.value })}
                            variant="outlined"
                            sx={{ mt: 1.5, mb: 2 }}
                            multiline
                        />
                    </Grid>
                    <Grid item lg={8} md={8}>
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Nom</TableCell>
                                    <TableCell align="center">Prix</TableCell>
                                    <TableCell align="center">Duree</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {suggestionEntreprise.publicationpropositiondetails
                                    .map((publicationpropositiondetail, index) => (
                                        <TableRow hover key={index}>

                                            <TableCell sx={{ display: 'flex' }} align="center">
                                                {publicationpropositiondetail.reparation.images &&
                                                    publicationpropositiondetail.reparation.images.length !== 0 &&
                                                    <Avatar src={publicationpropositiondetail.reparation.images[0].img} />
                                                }
                                                {publicationpropositiondetail.reparation.nom}
                                            </TableCell>
                                            <TableCell align="right">Ar {publicationpropositiondetail.reparation.prix}</TableCell>
                                            <TableCell align="center">{publicationpropositiondetail.reparation.duree}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => removeReparation(publicationpropositiondetail.reparation)} color='error'>
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {tarifs && tarifs.map((tarif, index) => (
                                    <TableRow key={index} hover>
                                        <TableCell align="center">Marge</TableCell>
                                        <TableCell align="center">{tarif.typedemande.nom}</TableCell>
                                        <TableCell align="center" >{tarif.valeur} %</TableCell>
                                        <TableCell align="right" >Total: Ar {getPrixRepair(total, tarif.valeur)}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </StyledTable>

                        <StyledButton onClick={modifier} fullWidth color='success' variant="outlined">
                            <Icon>edit</Icon> Modifier
                        </StyledButton>

                    </Grid>

                </Grid>
            </SimpleCard>

            <br />

            <ListeReparationComponent modifSuggestion={addNewReparation}></ListeReparationComponent>

            {/* alert */}
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}
            >
                <Alert
                    severity={colorMessage}
                >
                    {message}
                </Alert>
            </Snackbar>
            {/*  */}

        </>
    );
};

export default SuggestionModifComponent;