import { Autocomplete, Box, Button, DialogActions, Fab, FormControl, Grid, Icon, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';




const AutoComplete = styled(Autocomplete)(() => ({
    width: 300,
    marginBottom: '16px',
}));

const typeLocations = [
    { Etattype: 10, "type": "Hors Carburant" }, { Etattype: 20, "type": "Avec Carburant" }
];


const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const styleImage = {
    width: "100px",
    heigth: "80px"
}

const DetailChauffeur = ({ chauffeurArg }) => {

    const navigate = useNavigate();

    const [chauffeur, setChauffeur] = useState(chauffeurArg);

    const styleP = {
        fontSize: "10px"
    }

    useEffect(() => {
        setChauffeur(chauffeurArg);
    }, [])


    return (
        // {vehicule!=undefined && (

        // )}

        <div>
            {/* <Grid container spacing={3}>
                <Grid item lg={4} md={4}>
                    {/* <CardRoot elevation={12}> */}
            {/* <SimpleCard>
                <Box width="100%" overflow="auto"> */}

            <img style={styleImage} src={chauffeur.photo}></img>
            <p style={styleP}>Nom chauffeur : {chauffeur.nom}</p>
            <p style={styleP}>Prenom chauffeur : {chauffeur.prenom}</p>
            <p style={styleP}>Contact : {chauffeur.contact}</p>
            <p style={styleP}>Email : {chauffeur.email}</p>
            <p style={styleP}>date naissance  : {chauffeur.date_naissance}</p>
            {/*  </Box>
                    </SimpleCard>

                    </CardRoot>
            </Grid>
            </Grid > */}
        </div>

    );
};

export default DetailChauffeur;