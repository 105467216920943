import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import routes from './routes';
import { SettingsProvider } from './contexts/SettingsContext';

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <>
        {/* <AuthProvider> */}
        < MatxTheme >
          <CssBaseline />
          {content}
        </MatxTheme >
        {/* </AuthProvider> */}
      </>
    </SettingsProvider>
  );
};

export default App;
