import { Dialog, DialogContent, DialogTitle } from "@mui/material";


import { useState } from "react";
import { useEffect } from "react";
import FormEvenement from "./FormEvenement";
import { getEvenementById } from "./API-Evenement";

const ModalUpdateEvenement = ({ evenementEdit, setEvenementEdit, open, handleClose }) => {

    // console.log(evenementEdit.id, "RYG")
    const [evenementUpdate, setEvenementUpdate] = useState(undefined);


    const getEvenementByIdDetail = async () => {
        await getEvenementById(evenementEdit.id, setEvenementUpdate);
    }

    useEffect(() => {
        getEvenementByIdDetail();
    }, [])


    return (
        <Dialog maxWidth='xl' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Modifier {evenementEdit.name_fr}
            </DialogTitle>

            <DialogContent dividers>
                {evenementUpdate && (
                    <FormEvenement selectedEvenement={evenementUpdate} setevenement={setEvenementEdit} closeForm={handleClose} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ModalUpdateEvenement;