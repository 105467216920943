export default [
    {
        'title': '5896TBC ',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    }, {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': '9632TBA',
        'allDay': true,
        'datedebut': '2023-11-14 00:00:00',
        'datefin': '2023-11-16 00:00:00'
    },
    {
        'title': 'Long Event',
        'datedebut': new Date(2023, 11, 7),
        'datefin': new Date(2023, 11, 10)
    },

    {
        'title': 'DTS STARTS',
        'datedebut': new Date(2023, 11, 13, 0, 0, 0),
        'datefin': new Date(2023, 11, 20, 0, 0, 0)
    },

    {
        'title': 'DTS ENDS',
        'datedebut': new Date(2023, 10, 6, 0, 0, 0),
        'datefin': new Date(2023, 10, 13, 0, 0, 0)
    },

    {
        'title': 'Some Event',
        'datedebut': new Date(2023, 11, 9, 0, 0, 0),
        'datefin': new Date(2023, 11, 9, 0, 0, 0)
    },
    {
        'title': 'Conference',
        'datedebut': new Date(2023, 11, 11),
        'datefin': new Date(2023, 11, 13),
        desc: 'Big conference for important people'
    },
    {
        'title': 'Meeting',
        'datedebut': new Date(2023, 3, 12, 10, 30, 0, 0),
        'datefin': new Date(2023, 3, 12, 12, 30, 0, 0),
        desc: 'Pre-meeting meeting, to prepare for the meeting'
    },
    {
        'title': 'Lunch',
        'datedebut': new Date(2023, 3, 12, 12, 0, 0, 0),
        'datefin': new Date(2023, 3, 12, 13, 0, 0, 0),
        desc: 'Power lunch'
    },
    {
        'title': 'Meeting',
        'datedebut': new Date(2023, 3, 12, 14, 0, 0, 0),
        'datefin': new Date(2023, 3, 12, 15, 0, 0, 0)
    },
    {
        'title': 'Happy Hour',
        'datedebut': new Date(2023, 3, 12, 17, 0, 0, 0),
        'datefin': new Date(2023, 3, 12, 17, 30, 0, 0),
        desc: 'Most important meal of the day'
    },
    {
        'title': 'Dinner',
        'datedebut': new Date(2023, 3, 12, 20, 0, 0, 0),
        'datefin': new Date(2023, 3, 12, 21, 0, 0, 0)
    },
    {
        'title': 'Birthday Party',
        'datedebut': new Date(2023, 3, 13, 7, 0, 0),
        'datefin': new Date(2023, 3, 13, 10, 30, 0)
    },
    {
        'title': 'Birthday Party 2',
        'datedebut': new Date(2023, 3, 13, 7, 0, 0),
        'datefin': new Date(2023, 3, 13, 10, 30, 0)
    },
    {
        'title': 'Birthday Party 3',
        'datedebut': new Date(2023, 3, 13, 7, 0, 0),
        'datefin': new Date(2023, 3, 13, 10, 30, 0)
    },
    {
        'title': 'Late Night Event',
        'datedebut': new Date(2023, 3, 17, 19, 30, 0),
        'datefin': new Date(2023, 3, 18, 2, 0, 0)
    },
    {
        'title': 'Multi-day Event',
        'datedebut': new Date(2023, 3, 20, 19, 30, 0),
        'datefin': new Date(2023, 3, 22, 2, 0, 0)
    }
]
