import url from "app/UrlConfig";
import { getCategorie } from "app/myComponents/Nosybe/Hebergement/API-Hebergement";

const equipements = [
    {
        id: 1,
        nom: 'Piscine',
    },
    {
        id: 2,
        nom: 'Boutique',
    },
    {
        id: 3,
        nom: 'Transfert',
    },
]

const types = [
    {
        id: 1,
        nom: "Appartement",
    },
    {
        id: 2,
        nom: "Auberge de jeunesse",
    },
    {
        id: 3,
        nom: "B&B/Chambre d'hôtes",
    },
    {
        id: 4,
        nom: "Day use",
    },
    {
        id: 5,
        nom: "Maison d'hôtes",
    },
    {
        id: 6,
        nom: "Hôtel",
    },
    {
        id: 7,
        nom: "Maison de vacances",
    },
    {
        id: 8,
        nom: "Villa",
    }
]

const listHebergement = [
    {
        titre: 'ARC EN CIEL',
        description: 'L’Arc en Ciel est un hôtel de charme 3 étoiles, situé sur la côte ouest de l’île de Nosy Be.',
        email: 'info@hotelarcenciel.net',
        siteweb: 'https://www.hotelarcenciel.net/fr/',
        etoile: 3,
        prix: 0,
        latitude: 0,
        longitude: 0,
        contacts: [
            '+261 32 02 049 39',
        ],
        photos: [],
        types: [
            {
                id: 1,
                nom: "Lodges",
            },
            {
                id: 2,
                nom: "Residences & villas",
            },
        ],
        equipements: [
            {
                id: 1,
                nom: 'Piscine',
            },
            {
                id: 2,
                nom: 'Boutique',
            },
        ],
    },
    {
        titre: 'VANILA HOTEL AND SPA',
        description: "Le Vanila Hotel & Spa est un hôtel 3 étoiles du groupe Sound's Hotels situé en bord de mer sur la côte ouest de Nosy Be,",
        email: "booking@vanila-hotel.com",
        siteweb: "https://vanila-hotel.com/",
        etoile: 3,
        prix: 110,
        latitude: 0,
        longitude: 0,
        contacts: [
            "+261 38 15 001 41",
        ],
        photos: [],
        types: [
            {
                id: 3,
                nom: "Auberges",
            },
            {
                id: 4,
                nom: "Chambres d’hôtes",
            },
        ],
        equipements: [
            {
                id: 1,
                nom: 'Piscine',
            },
            {
                id: 3,
                nom: 'Transfert',
            },
        ],
    },
    {
        titre: "HÔTEL & RÉSIDENCE SARIMANOK",
        description: "Hôtel & résidence Sarimanok NOSY BE-Madagascar",
        email: "hotelsarimanok@gmail.com",
        siteweb: "www.hotel-sarimanok-nosy-be.com",
        etoile: 2,
        prix: 58,
        latitude: 0,
        longitude: 0,
        contacts: [],
        photos: [],
        types: [
            {
                id: 5,
                nom: "Bed & Breakfast",
            },
            {
                id: 6,
                nom: "Autre",
            },
        ],
        equipements: [
            {
                id: 1,
                nom: 'Piscine',
            },
            {
                id: 3,
                nom: 'Transfert',
            },
        ],
    },
]

export const getListeHebergement = (setHebergements, setHebergementTotal, page = 0, perPage = 2) => {
    const startIndex = page * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = listHebergement.slice(startIndex, endIndex);
    setHebergements(paginatedData);
    setHebergementTotal(listHebergement.length);
}

export const getEquipements = (setEquipements) => {
    setEquipements(equipements)
}

export const getTypes = (setTypes) => {
    setTypes(types)
}

export const getHebergementPrimaryRessources = (setEquipements, setTypes) => {
    // setEquipements(equipements)
    setEquipements([])
    // setTypes([])
    getCategorie(setTypes)
}

