import { useState } from "react";
import { useEffect } from "react";
import { SimpleCard } from "app/components";
import { Box, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select, TablePagination, TextField, Typography } from "@mui/material";

const MarqueComponent = () => {
    const [sousCategories, setSousCategories] = useState([]);
    const [marques, setMarques] = useState([]);
    const [marqueEdit, setMarqueEdit] = useState(null);
    const [marqueCreate, setMarqueCreate] = useState({
        idsouscategorie: 0,
        nom: '',
    });

    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const [recherche, setRecherche] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    //page handling
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //create modal
    const handleCloseModalCreate = () => setOpenModalCreate(false);
    const handleCreateChange = (event) => {
        setMarqueCreate({
            ...marqueCreate,
            [event.target.name]: event.target.value,
        })
    }
    const createMarque = () => {
        handleCloseModalCreate();
    }

    //edit modal
    const handleCloseModalEdit = () => setOpenModalEdit(false);
    const displayEditModal = (marque) => {
        setMarqueEdit(marque);
        setOpenModalEdit(true);
    }
    const handleEditChange = (event) => {
        setMarqueEdit({
            ...marqueEdit,
            [event.target.name]: event.target.value,
        })
    }
    const editMarque = () => {
        handleCloseModalEdit();
    }

    //delete modal
    const handleCloseModalDelete = () => setOpenModalDelete(false);
    const displayDeleteModal = (marque) => {
        setMarqueEdit(marque);
        setOpenModalDelete(true);
    }
    const deleteMarque = () => {
        handleCloseModalDelete();
    }


    useEffect(() => {

    }, [])

    return (
        <>
            <SimpleCard title={'Marque'}>
                <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    name='recherche'
                    value={recherche}
                    onChange={(e) => setRecherche(e.target.value.toLowerCase())}
                    label='Recherche'
                    sx={{ mt: 2 }}
                />
                <IconButton onClick={() => setOpenModalCreate(true)}>
                    <Icon color='primary'>add</Icon>
                </IconButton>
                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={marques.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
            </SimpleCard>

            <Box sx={{ mt: 3 }} width={'100%'}>
                <Grid container spacing={3}>
                    {marques
                        .filter((marque) =>
                            marque.nom.toLowerCase().includes(recherche)
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((marque, index) =>
                            <Grid key={index} item lg={3} md={3}>
                                <Card sx={{ maxWidth: 345 }}>

                                    <CardContent>
                                        <Typography gutterBottom sx={{ textAlign: 'center' }} variant="h5" component="div">
                                            {marque.nom}
                                        </Typography>
                                    </CardContent>

                                    <CardActions>
                                        <IconButton onClick={() => displayEditModal(marque)}>
                                            <Icon color='success'>edit</Icon>
                                        </IconButton>
                                        <IconButton onClick={() => displayDeleteModal(marque)}>
                                            <Icon color='error'>delete</Icon>
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                </Grid>
            </Box>

            {/* modal create start */}
            <Dialog onClose={handleCloseModalCreate} aria-labelledby="customized-dialog-title" open={openModalCreate}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseModalCreate}>
                    Ajout de nouvelle marque
                </DialogTitle>

                <DialogContent dividers>
                    {/* <button onClick={test}>test</button> */}
                    <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                        <InputLabel filled id="demo-simple-select-autowidth-label">Sous-Categorie</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name='idsouscategorie'
                            value={sousCategories.length !== 0 && marqueCreate.idsouscategorie && marqueCreate.idsouscategorie !== 0 ? marqueCreate.idsouscategorie : ''}
                            onChange={handleCreateChange}
                            autoWidth
                            label="Sous-Categorie"
                        >
                            {sousCategories.map((sousCategorie, index) => (
                                <MenuItem key={index} value={sousCategorie.id}>{sousCategorie.nom}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        name='nom'
                        value={marqueCreate.nom}
                        onChange={handleCreateChange}
                        label='Nom'
                        sx={{ mt: 2 }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseModalCreate}>
                        Fermer
                    </Button>
                    <Button color='success' onClick={createMarque}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            {/* create end */}

            {marqueEdit &&
                <>
                    {/* modal edit start */}
                    <Dialog onClose={handleCloseModalEdit} aria-labelledby="customized-dialog-title" open={openModalEdit}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalEdit}>
                            Modifier {marqueEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <FormControl fullWidth variant="standard" sx={{ mb: 1.5 }}>
                                <InputLabel filled id="demo-simple-select-autowidth-label">Sous-Categorie</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    name='idsouscategorie'
                                    value={sousCategories.length !== 0 && marqueEdit.idsouscategorie && marqueEdit.idsouscategorie !== 0 ? marqueEdit.idsouscategorie : ''}
                                    onChange={handleEditChange}
                                    autoWidth
                                    label="Sous-Categorie"
                                >
                                    {sousCategories.map((sousCategorie, index) => (
                                        <MenuItem key={index} value={sousCategorie.id}>{sousCategorie.nom}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='nom'
                                value={marqueEdit.nom}
                                onChange={handleEditChange}
                                label='Nom'
                                sx={{ mt: 2 }}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleCloseModalEdit}>
                                Fermer
                            </Button>
                            <Button color='success' onClick={editMarque}>
                                Modifier
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* edit end */}

                    {/* modal delete start */}
                    <Dialog onClose={handleCloseModalDelete} aria-labelledby="customized-dialog-title" open={openModalDelete}>
                        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalDelete}>
                            Retirer {marqueEdit.nom}
                        </DialogTitle>

                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Vous en etes sur?
                            </Typography>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={deleteMarque} style={{ color: 'red' }}>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* delete end */}
                </>
            }
        </>
    )
}
export default MarqueComponent;