import url from "app/UrlConfig"


export const getStatistiqueFulle = (anneActu, annePrecedent, moi, setDataState) => {
    const token = localStorage.getItem("token")
    fetch(url["url-api"] + "admin/chiffre-affaire-full/" + anneActu + "&&" + annePrecedent + "&&" + moi, {
        method: 'GET',
        headers:
        {
            "Authorization": "Bearer" + token,
            "Content-Type": "application/json"
        }
    })
        .then(reponse => reponse.json())
        .then(data => {
            console.log(data.data)
            if (data.status === 200) {
                setDataState(data.data);
            }
        })
        .catch(error => {
            console.log(error)
        })
}


export const getCategorieByData = async (data) => {
    return new Promise((resolve, reject) => {
        try {
            let dataCategorie = [];
            for (let i = 0; i < data.length; i++) {
                dataCategorie[i] = { "id": data.id, "categorie": data.categorie_voiture };
            }
            resolve(dataCategorie);
            return dataCategorie;
        } catch (error) {
            reject(error);
        }
    })

}

export const getStatistiqueCategorie = async (anne, categorie, setData, setCategorieVoiture, setCategorieData) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        fetch(url["url-api"] + 'admin/statistique-categorie/' + anne + "&&" + categorie, {
            method: 'GET',
            headers:
            {
                "Authorization": "Bearer" + token,
                "Content-Type": "application/json"
            }
        })
            .then(reponse => reponse.json())
            .then(data => {
                // console.log(data.data[1][0].categorie)
                if (data.status === 200) {
                    //const d = getCategorieByData(data.data).then();
                    // console.log(data.)
                    setData(data.data);
                    setCategorieData(data.data[2]);
                    console.log(data.data[2])
                    setCategorieVoiture(data.data[1][0].categorie);
                    resolve(data.data)
                }
            })
            .catch(error => {
                reject(error)
                console.log(error)
            })
    })

}

