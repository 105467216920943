import { CssBaseline, ThemeProvider } from '@mui/material';
import myConfig from 'app/config/api-config';
import { activityLink, displayToast } from 'app/data/piece/dispatch';
import { selectType } from 'app/data/piece/users';
import useSettings from 'app/hooks/useSettings';
import GeneralDialog from 'app/myComponents/VentePiece/GeneralDialog';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MatxTheme = ({ children }) => {
  const { settings } = useSettings();
  let activeTheme = { ...settings.themes[settings.activeTheme] };

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");

  const message = ('Veuillez vous connecter');
  const errorData = { message: message };

  const execpt = [
    '/',
    '/admin/connexion',
    '/entreprise/connexion',
    '/entreprise/inscription'
  ]

  const checkToken = () => {
    const pathname = location.pathname;
    const match = execpt.find((path) => pathname === path)
    if (match) return;

    if (!token || !user) {
      navigate('/entreprise/connexion', { state: errorData });
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const requestOptions = {
      url: `${myConfig.host}/api/entreprise/profileJWT`,
      method: 'GET'
    };
    axios(requestOptions).then((response) => {
      if (response.data.code === 200) {
        // console.log("checkToken: ", response.data.entreprise);

        const activities = response.data.entreprise.activites;
        let route = undefined;
        activities.forEach(activity => {
          const activityRoute = activityLink.find((link) => activity.idactivite === link.idactivity);
          route = activityRoute.routes.find((route) => pathname === route.path);
          if (route) selectType(activity.idactivite);
        });

        //MILA AVADIKA FONCTION DE DECONNECTION NA TOAST NON AUTHORISER
        // if (!route) displayToast('Navigation non autoriser!', false)
      } else if (response.data.code === 500) {
        navigate('/entreprise/connexion', { state: errorData });
      }
    }).catch((e) => {
      console.log(e);
      navigate('/entreprise/connexion', { state: errorData });
    })

  }

  useEffect(() => {
    // checkToken();
  }, []);


  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <GeneralDialog />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
